export const mobileReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_NODES':
      const dataNodes = action.payload;
      return {
        ...state,
        nodes: dataNodes ? action.payload?.buildings[0]?.floors[0]?.nodes : [],
      };

    case 'ADD_ZONES':
      const zones = action.payload;
      const isZones = action.payload.length > 0;
      return {
        ...state,
        zonesState: {
          zones: [...zones],
          isZones: isZones,
        },
        firstModal: {
          ...state.firstModal,
          firstModalZonesState: {
            ...state.firstModal.firstModalZonesState,
            zones: [...zones],
          },
        },
      };

    case 'SELECT_ONE_DEVICE':
      const { type } = action.payload;
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalDeviceState: {
            ...state.secondModal.secondModalDeviceState,
            devicesFilter: {
              ...state.secondModal.secondModalDeviceState.devicesFilter,
              [type]: {
                ...state.secondModal.secondModalDeviceState.devicesFilter[type],
                isSelected: !state.secondModal.secondModalDeviceState.devicesFilter[type]?.isSelected,
              },
            },
          },
        },
      };

    case 'SELECT_ALL_DEVICE':
      const isSelectAllDeviceState = action.payload;
      const deviceFilter = state?.secondModal?.secondModalDeviceState?.devicesFilter;
      Object.keys(deviceFilter).map(
        (key: any) => (deviceFilter[key] = { ...deviceFilter[key], isSelected: !isSelectAllDeviceState }),
      );
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalDeviceState: {
            ...state.secondModal.secondModalDeviceState,
            devicesFilter: {
              ...deviceFilter,
            },
          },
        },
      };

    case 'SELECT_CLEAR_ALL_DEVICE':
      const devicesFilter = state?.secondModal?.secondModalDeviceState?.devicesFilter;
      Object.keys(devicesFilter).map((key: any) => (devicesFilter[key] = { ...devicesFilter[key], isSelected: false }));
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalDeviceState: {
            ...state.secondModal.secondModalDeviceState,
            devicesFilter: {
              ...devicesFilter,
            },
          },
        },
      };

    case 'HANDLE_CLOSE_DEVICE_MODAL':
      const devicesFilterFirstModal = state?.firstModal?.firstModalDeviceState?.devicesFilter;
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalDeviceState: {
            ...state.secondModal.secondModalDeviceState,
            devicesFilter: {
              ...devicesFilterFirstModal,
            },
          },
        },
      };

    case 'HANDLE_DONE_DEVICE_MODAL':
      const devicesTemporaryState = state?.secondModal?.secondModalDeviceState?.devicesFilter;
      const checkedDevicesState = Object.keys(devicesTemporaryState).filter(
        (key: any) => devicesTemporaryState[key].isSelected,
      );

      const checkedDevicesCount = checkedDevicesState.length;

      return {
        ...state,
        firstModal: {
          ...state.firstModal,
          firstModalDeviceState: {
            ...state.firstModal.firstModalDeviceState,
            checkedDevicesCount: checkedDevicesCount,
            devicesFilter: {
              ...devicesTemporaryState,
            },
          },
        },
      };

    case 'SELECT_ONE_ATTACH':
      const { typeAttach } = action.payload;
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalAttachState: {
            ...state.secondModal.secondModalAttachState,
            attachFilter: {
              ...state.secondModal.secondModalAttachState.attachFilter,
              [typeAttach]: {
                ...state.secondModal.secondModalAttachState.attachFilter[typeAttach],
                isSelected: !state.secondModal.secondModalAttachState.attachFilter[typeAttach]?.isSelected,
              },
            },
          },
        },
      };

    case 'SELECT_ALL_ATTACH':
      const isSelectAllAttachState = action.payload;
      const attachFilter = state?.secondModal?.secondModalAttachState?.attachFilter;
      Object.keys(attachFilter).map(
        (key: any) => (attachFilter[key] = { ...attachFilter[key], isSelected: !isSelectAllAttachState }),
      );
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalAttachState: {
            ...state.secondModal.secondModalAttachState,
            attachFilter: {
              ...attachFilter,
            },
          },
        },
      };

    case 'SELECT_CLEAR_ALL_ATTACH':
      const attachFilters = state?.secondModal?.secondModalAttachState?.attachFilter;
      Object.keys(attachFilters).map((key: any) => (attachFilters[key] = { ...attachFilters[key], isSelected: false }));
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalAttachState: {
            ...state.secondModal.secondModalAttachState,
            attachFilter: {
              ...attachFilters,
            },
          },
        },
      };

    case 'HANDLE_DONE_ATTACH_MODAL':
      const attachTemporaryState = state?.secondModal?.secondModalAttachState?.attachFilter;
      const checkedAttachState = Object.keys(attachTemporaryState).filter(
        (key: any) => attachTemporaryState[key].isSelected,
      );
      const checkedAttachCount = checkedAttachState.length;

      return {
        ...state,
        firstModal: {
          ...state.firstModal,
          firstModalAttachState: {
            ...state.firstModal.firstModalAttachState,
            checkedAttachCount: checkedAttachCount,
            attachFilter: {
              ...attachTemporaryState,
            },
          },
        },
      };

    case 'HANDLE_CLOSE_ATTACH_MODAL':
      const attachFilterFirstModal = state?.firstModal?.firstModalAttachState?.attachFilter;
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalAttachState: {
            ...state.secondModal.secondModalAttachState,
            attachFilter: {
              ...attachFilterFirstModal,
            },
          },
        },
      };

    case 'SELECT_ONE_ENTITY':
      const { typeEntity } = action.payload;
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalEntityState: {
            ...state.secondModal.secondModalEntityState,
            entityFilter: {
              ...state.secondModal.secondModalEntityState.entityFilter,
              [typeEntity]: {
                ...state.secondModal.secondModalEntityState.entityFilter[typeEntity],
                isSelected: !state.secondModal.secondModalEntityState.entityFilter[typeEntity]?.isSelected,
              },
            },
          },
        },
      };

    case 'SELECT_ALL_ENTITY':
      const isSelectAllEntityState = action.payload;
      const entityFilter = state?.secondModal?.secondModalEntityState?.entityFilter;
      Object.keys(entityFilter).map(
        (key: any) => (entityFilter[key] = { ...entityFilter[key], isSelected: !isSelectAllEntityState }),
      );
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalEntityState: {
            ...state.secondModal.secondModalEntityState,
            entityFilter: {
              ...entityFilter,
            },
          },
        },
      };

    case 'SELECT_CLEAR_ALL_ENTITY':
      const entityFilters = state?.secondModal?.secondModalEntityState?.entityFilter;
      Object.keys(entityFilters).map((key: any) => (entityFilters[key] = { ...entityFilters[key], isSelected: false }));
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalEntityState: {
            ...state.secondModal.secondModalEntityState,
            entityFilter: {
              ...entityFilters,
            },
          },
        },
      };

    case 'HANDLE_DONE_ENTITY_MODAL':
      const entityTemporaryState = state?.secondModal?.secondModalEntityState?.entityFilter;
      const checkedEntityState = Object.keys(entityTemporaryState).filter(
        (key: any) => entityTemporaryState[key].isSelected,
      );

      const checkedEntityCount = checkedEntityState.length;

      return {
        ...state,
        firstModal: {
          ...state.firstModal,
          firstModalEntityState: {
            ...state.firstModal.firstModalEntityState,
            checkedEntityCount: checkedEntityCount,
            entityFilter: {
              ...entityTemporaryState,
            },
          },
        },
      };

    case 'HANDLE_CLOSE_ENTITY_MODAL':
      const entityFilterFirstModal = state?.firstModal?.firstModalEntityState?.entityFilter;
      return {
        ...state,
        secondModal: {
          ...state.secondModal,
          secondModalEntityState: {
            ...state.secondModal.secondModalEntityState,
            entityFilter: {
              ...entityFilterFirstModal,
            },
          },
        },
      };

    case 'HANDLE_DONE_MAIN_MODAL':
      const {
        firstModalAttachState,
        firstModalDeviceState,
        firstModalInventoriesState,
        firstModalEntityState,
        firstModalZonesState,
      } = state?.firstModal;
      return {
        ...state,
        attachState: { ...firstModalAttachState },
        deviceState: { ...firstModalDeviceState },
        inventoriesState: { ...firstModalInventoriesState },
        entityState: { ...firstModalEntityState },
        zonesState: { ...firstModalZonesState },
      };

    case 'SELECT_CLEAR_MAIN_MODAL':
      const devicesClearMainState = state?.firstModal?.firstModalDeviceState?.devicesFilter;
      Object.keys(devicesClearMainState).map(
        (key: any) => (devicesClearMainState[key] = { ...devicesClearMainState[key], isSelected: false }),
      );

      const attachClearMainState = state?.firstModal?.firstModalAttachState?.attachFilter;
      Object.keys(attachClearMainState).map(
        (key: any) => (attachClearMainState[key] = { ...attachClearMainState[key], isSelected: false }),
      );

      const entityClearMainState = state?.firstModal?.firstModalEntityState?.entityFilter;
      Object.keys(entityClearMainState).map(
        (key: any) => (entityClearMainState[key] = { ...entityClearMainState[key], isSelected: false }),
      );

      const inventoriesClearMainState = JSON.parse(JSON.stringify(state?.inventoriesState?.inventoriesFilter));
      inventoriesClearMainState.forEach((item: any, index: number) => {
        inventoriesClearMainState[index].isSelected = false;
      });

      return {
        ...state,
        firstModal: {
          ...state.firstModal,
          firstModalDeviceState: {
            ...state.firstModal.firstModalDeviceState,
            checkedDevicesCount: 0,
            devicesFilter: {
              ...devicesClearMainState,
            },
          },
          firstModalAttachState: {
            ...state.firstModal.firstModalAttachState,
            checkedAttachCount: 0,
            attachFilter: {
              ...attachClearMainState,
            },
          },
          firstModalEntityState: {
            ...state.firstModal.firstModalEntityState,
            checkedEntityCount: 0,
            entityFilter: {
              ...entityClearMainState,
            },
          },
          firstModalInventoriesState: {
            ...state.firstModal.firstModalInventoriesState,
            checkedInventoriesCount: 0,
            inventoriesFilter: inventoriesClearMainState,
          },
          firstModalZonesState: {
            ...state.zonesState,
            isZones: false,
          },
        },
      };

    case 'HANDLE_CLOSE_MAIN_MODAL':
      const devicesState = JSON.parse(JSON.stringify(state?.deviceState));

      const attachState = JSON.parse(JSON.stringify(state?.attachState));

      const entityState = JSON.parse(JSON.stringify(state?.entityState));

      const inventoriesState = JSON.parse(JSON.stringify(state?.inventoriesState));

      const zonesState = state.zonesState;

      return {
        ...state,
        firstModal: {
          firstModalDeviceState: {
            ...devicesState,
          },
          firstModalAttachState: {
            ...attachState,
          },
          firstModalEntityState: {
            ...entityState,
          },
          firstModalInventoriesState: { ...inventoriesState },
          firstModalZonesState: {
            ...zonesState,
          },
        },
        secondModal: {
          secondModalDeviceState: {
            ...devicesState,
          },
          secondModalAttachState: {
            ...attachState,
          },
          secondModalEntityState: {
            ...entityState,
          },
          secondModalInventoriesState: { ...inventoriesState },
        },
      };

    case 'SELECT_ZONES':
      const isZonesState = state?.firstModal?.firstModalZonesState?.isZones;

      return {
        ...state,
        firstModal: {
          ...state.firstModal,
          firstModalZonesState: {
            ...state.firstModal.firstModalZonesState,
            isZones: !isZonesState,
          },
        },
      };

    default:
      return state;
  }
};
