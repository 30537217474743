/**
 * @description
 * This is an advanced component that covers
 * all the features that cthings details buttons require.
 * Highly customisable.
 *
 */

import React, { FC, ReactNode } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: transparent;
  background-color: transparent;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  padding: 4px 18px;
  border: 1px solid;
  border-color: gray3;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: gray2;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.2px;
  user-select: none;
  transition: all 0.3s linear;
  max-width: 100px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  &:hover {
    background-color: gray2;
    color: white;
    border-color: gray2;
  }
`;
const ButtonText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export interface DeleteButtonProps {
  onClick: any;
  className?: string;
  children?: ReactNode;
}

export const DetailsButton: FC<DeleteButtonProps> = ({ onClick, children, ...props }) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);
  return (
    <Button onClick={onClick} theme={theme} {...props}>
      <ButtonText>{children || language ? language.detailsButton : 'Details'}</ButtonText>
    </Button>
  );
};
