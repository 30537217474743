/**
 * @description
 * This is component for field for add comment
 * all the functionalities cthings require for Comments
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';

export interface EditCommentType {
  valueComment: string;
  editComment: string;
  id: number | '';
}

const Content = styled.div`
  width: 100%;
  min-height: 65px;
  padding: 10px 0;
  display: flex;
  overflow: visible;
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid;
  border-color: gray3;
  z-index: 1;
  &.last {
    border: none;
  }
`;
const Comment = styled(Text)`
  word-break: break-all;
`;

const CommentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const InformationBlock = styled.div``;

export interface CommentItemProps {
  id: number;
  textComment: string;
  dateCom: string;
  author: string;
  updateDate: string;
  className?: string;
}

export const CommentItem: FC<CommentItemProps> = ({ id, textComment, dateCom, ...props }) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const [modal, setModal] = useState(false);
  const [date, setDate] = useState<string>('');

  const buttonRef = useRef<any>(null);
  const modalRef = useRef<any>(null);

  const language = useSelector(selectLanguageStrings);

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (
        modal &&
        !(
          (buttonRef && buttonRef.current && buttonRef.current.contains(event.target)) ||
          (modalRef.current && modalRef.current.contains(event.target))
        )
      ) {
        setModal(false);
      }
    },
    [modal],
  );

  useEffect(() => {
    const today = new Date();
    const todayDate = moment(`${today}`).format('D.MM.yyyy');

    const formatDate = moment(`${dateCom}`).format('D.MM.yyyy, HH:mm');
    const formatDateDay = moment(`${dateCom}`).format('D.MM.yyyy');
    const formatDateTime = moment(`${dateCom}`).format('HH:mm');

    const previousTodayDate = moment(`${today}`)
      .subtract(1, language ? language.formatDateDays : 'days')
      .format('D.MM.yyyy');

    if (todayDate === formatDateDay) {
      setDate(language ? language.formatDateToday : 'Today, ' + formatDateTime);
    } else {
      setDate(formatDate);
    }

    if (previousTodayDate === formatDateDay) {
      setDate(language ? language.formatDateYesterday : 'Yesterday, ' + formatDateTime);
    }
  });

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  return (
    <Content isEdit={id} theme={theme} {...props}>
      <CommentBlock>
        <Comment type={TextType.TEXT_14_BLACK} color={gray1} weight={'normal'} lineHeight="150%">
          {textComment}
        </Comment>
        <InformationBlock>
          <Text type={TextType.TEXT_12_GRAY} color={gray2} weight={'normal'} lineHeight="24px">
            {date}
          </Text>
        </InformationBlock>
      </CommentBlock>
    </Content>
  );
};

CommentItem.defaultProps = {};
