import React from 'react';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';

import { Charging } from './components/charging/Charging';
import { Indicator } from './components/indicator/Indicator';
// @ts-ignore
import styled from '@xstyled/styled-components';

const Wrapper = styled.div`
  margin-left: ${(props: any) => (props.isModal ? '6px' : '0')};
`;

export const Battery = ({
  batteryLevel,
  isCharging,
  state,
}: {
  batteryLevel: number;
  isCharging?: boolean;
  state: TableCustomComponentState | undefined;
}) => {
  return (
    <Wrapper isModal={state === TableCustomComponentState.VIEW_MODAL}>
      {isCharging ? <Charging /> : <Indicator batteryLevel={batteryLevel} />}
    </Wrapper>
  );
};
