import React from 'react';
import { Redirect } from 'react-router-dom';
import { CampusMapInsight, Dashboard, Insights } from '../containers/common';
import { Documents } from '../containers/common/documents/Documents';
import { ProblemsDetected } from '../containers/common/problemsDetected/ProblemsDetected';
import { SmartSearch } from '../containers/common/smartSearch/SmartSearch';
import { ErrorPage, ErrorPageType } from '../containers/errorPage/ErrorPage';
import { ComponentsPage } from '../containers/management/componentsPage/ComponentsPage';
import { DeviceDetails } from '../containers/management/deviceDetails/DeviceDetails';
import { Devices } from '../containers/management/devices/Devices';
import { Inventories } from '../containers/management/inventories/Inventories';
import { OrderDetails } from '../containers/management/orderDetails/OrdersDetails';
import { Orders } from '../containers/management/orders/Orders';
import { Processes } from '../containers/management/processes/Processes';
import { Stages } from '../containers/management/stages/Stages';
import { Users } from '../containers/management/users/Users';
import { Zones } from '../containers/management/zones/Zones';
import { ZonesDetails } from '../containers/management/zonesDetails/ZonesDetails';
import { DeviceDetailsType } from '../enums/View';
import withAuth from '../features/authentication/withAuth';
import Errors from '../layouts/errors/Errors';
import { ManagementMobile } from '../layouts/managementMobile/ManagementMobile';
import User from '../layouts/user/User';
import { injectPathParams, PATHS } from './paths';
import UserSwitcher from './UserSwitcher';

export const routes = [
  {
    path: PATHS.USER,
    render: withAuth(User),
    routes: [
      { path: PATHS.USER_SEARCH, component: SmartSearch },
      {
        path: PATHS.USER_PROBLEMS,
        component: ProblemsDetected,
      },
      { path: PATHS.USER_DASHBOARD, component: Dashboard },
      {
        path: PATHS.USER_DASHBOARD_REDIRECT,
        component: () => <Redirect to={injectPathParams(PATHS.USER_DASHBOARD, { type: 'overall' })} />,
      },

      {
        path: PATHS.USER_DOCUMENTS,
        component: Documents,
      },
    ],
  },
  {
    path: PATHS.USER_SITES_REDIRECT,
    render: withAuth(ManagementMobile),
    routes: [
      {
        path: PATHS.USER_INSIGHTS_DETAILS,
        component: CampusMapInsight,
      },
      {
        path: PATHS.USER_INSIGHTS,
        component: ({ match }: any) => <Insights match={match} />,
      },

      { path: PATHS.MANAGE_ZONES_DETAILS, component: ZonesDetails },

      { path: PATHS.MANAGE_ZONES, component: Zones },

      {
        path: PATHS.USER_SITES_REDIRECT,
        component: () => <Redirect to={injectPathParams(PATHS.USER_INSIGHTS, {})} />,
      },
    ],
  },
  {
    path: PATHS.USER_INVENTORIES_REDIRECT,
    render: withAuth(ManagementMobile),
    routes: [{ path: PATHS.MANAGE_INVENTORIES, component: Inventories }],
  },
  {
    path: PATHS.USER_USERS_REDIRECT,
    render: withAuth(ManagementMobile),
    routes: [{ path: PATHS.MANAGE_USERS, component: Users }],
  },
  {
    path: PATHS.USER_DEVICES_REDIRECT,
    render: withAuth(ManagementMobile),
    routes: [
      {
        path: PATHS.USER_MANAGE_DEVICES_DETAILS_NODE,
        component: ({ match }: any) => <DeviceDetails match={match} type={DeviceDetailsType.NODE} />,
      },
      {
        path: PATHS.USER_MANAGE_DEVICES_DETAILS_TRACKER,
        component: ({ match }: any) => <DeviceDetails match={match} type={DeviceDetailsType.TRACKER} />,
      },
      {
        path: PATHS.MANAGE_DEVICES_DETAILS,
        component: DeviceDetails,
      },
      { path: PATHS.MANAGE_DEVICES, component: Devices },
    ],
  },
  {
    path: PATHS.USER_OPERATIONS_REDIRECT,
    render: withAuth(ManagementMobile),
    routes: [
      {
        path: PATHS.MANAGE_STAGES,
        component: Stages,
      },
      {
        path: PATHS.MANAGE_PROCESSES,
        component: Processes,
      },

      {
        path: PATHS.USER_OPERATIONS_REDIRECT,
        component: () => <Redirect to={injectPathParams(PATHS.MANAGE_STAGES, { offset: '0' })} />,
      },
    ],
  },
  {
    path: PATHS.USER_SERVICES_REDIRECT,
    render: withAuth(ManagementMobile),
    routes: [
      {
        path: PATHS.MANAGE_ORDERS_DETAILS,
        component: OrderDetails,
      },
      {
        path: PATHS.MANAGE_ORDERS,
        component: Orders,
      },
      {
        path: PATHS.MANAGE_COMPONENTS,
        component: ComponentsPage,
      },

      {
        path: PATHS.USER_SERVICES_REDIRECT,
        component: () => <Redirect to={injectPathParams(PATHS.MANAGE_STAGES, { offset: '0' })} />,
      },
    ],
  },
  {
    path: PATHS.MANAGE,
    render: withAuth(ManagementMobile),
    routes: [
      {
        path: PATHS.MANAGE_DEVICES_DETAILS,
        component: DeviceDetails,
      },
      { path: PATHS.MANAGE_DEVICES, component: Devices },

      { path: PATHS.MANAGE_USERS, component: Users },
      {
        path: PATHS.MANAGE_ORDERS_DETAILS,
        component: OrderDetails,
      },
      { path: PATHS.MANAGE_ORDERS, component: Orders },
      { path: PATHS.MANAGE_STAGES, component: Stages },
      { path: PATHS.MANAGE_PROCESSES, component: Processes },
      { path: PATHS.MANAGE_COMPONENTS, component: ComponentsPage },
      { path: PATHS.MANAGE_ZONES_DETAILS, component: ZonesDetails },
      { path: PATHS.MANAGE_ZONES, component: Zones },
      { path: PATHS.MANAGE_INVENTORIES, component: Inventories },
    ],
  },
  {
    path: PATHS.ERROR,
    render: withAuth(Errors),
    routes: [
      { path: PATHS.ERROR_403, component: () => <ErrorPage type={ErrorPageType.ERROR_403} /> },
      { path: PATHS.ERROR_500, component: () => <ErrorPage type={ErrorPageType.ERROR_500} /> },
      { path: PATHS.ERROR_404, component: () => <ErrorPage type={ErrorPageType.ERROR_404} /> },
      { path: PATHS.ERROR_401, component: () => <ErrorPage type={ErrorPageType.ERROR_401} /> },
    ],
  },

  {
    path: PATHS.REDIRECT,
    component: withAuth(UserSwitcher),
  },
];
