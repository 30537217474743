export const theme = {
  colors: {
    primary: '#0084B6',
    secondary1: '#043A4F',
    secondary2: '#005F83',
    secondary3: '#D5EFF9',
    black: '#28293d',
    black1: '#1C1C28',
    black2: 'rgb(0, 0, 0, 0.6)',
    black3: '#070707',
    gray1: '#555770',
    gray2: '#8f90a6',
    gray3: '#c7c9d9',
    gray4: '#e4e4eb',
    gray5: '#f2f2f5',
    gray6: '#EBEBF0',
    gray7: '#F7F7F9',
    gray8: '#88afff',
    white: '#ffffff',
    white1: '#f9f9fa',
    white2: 'rgba(255, 255, 255, 0.7)',
    red1: '#F44450',
    red2: '#d7343f',
    red3: '#f44450',
    red4: '#ff8d95',
    red5: '#FFE6E6',
    orange: '#ff8800',
    orange2: '#ED7A11',
    orange3: '#FA8A0A',
    orange4: '#FFF8E6',
    orange5: '#E67A00',
    orange6: '#ff8800',
    orange7: '#ED8628',
    orange8: '#FFF8E5',
    green: '#33894e',
    green1: '#023010',
    green2: '#136b2f',
    green3: '#add0b8',
    green4: '#CBEDD6',
    green5: '#EBF4EE',
    green6: '#6DC121',
    green7: '#C1FF89',
    green8: '#4C9906',
    green9: '#CBEDD6',
    green10: '#269E39',
    green11: '#3BB94F',
    green12: '#E4F6E7',
    green13: '#9FB917',
    green14: '#78A705',
    blue: '#5887EA',
    blue1: '#2C90B6',
    blue2: '#B8D2DD',
    blue3: '#5084F0',
    blue5: '#0084B6',
    blue6: '#005F83',
    blue7: '#E5F0FF',
    blue8: '#2750A9',
    blue9: '#366CDC',
    blue10: '#0AB4B1',
    blue11: '#EFF6FF',
    blue12: '#63b8d8',
    teal: '#08cedc',
    teal2: '#E6FFFF',
    teal3: '#00B7C4',
    purple: '#502fc4',
    purple2: '#E4DDFE',
    purple3: '#3B238F',
    purple4: '#7541A9',
    light: '#FAFAFC',
    yellow1: '#FFFEE6',
    yellow2: '#E6B800',
    yellow3: '#EABA00',
    lighter: '#6698FA',
    pink: '#A3007F',
  },
  shadows: {
    primary: '0px 0px 1px rgba(40,41,61,0.04), 0px 2px 4px rgba(96,97,112,0.16)',
    additionalShadow1: '0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)',
    additionalShadow2: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
    additionalShadow3: '0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)',
    additionalShadow4: '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)',
    additionalShadow5: '0px 2px 2px rgba(40, 41, 61, 0.04), 0px 2px 2px rgba(96, 97, 112, 0.16)',
    additionalShadow6: '0px 1px 2px rgba(40, 41, 61, 0.2), 0px 2px 4px rgba(96, 97, 112, 0.2)',
    additionalShadow7: '0px 0px 8px rgba(40, 41, 61, 0.04), 0px 0px 24px rgba(96, 97, 112, 0.16)',
    additionalShadow8: '0px 1px 1px rgba(40, 41, 61, 0.04), 0px 2px 2px rgba(96, 97, 112, 0.16)',
    additionalShadow9: '0px -2px 15px rgba(0, 0, 0, 0.07)',
  },
  borders: {
    card: '3px solid',
  },
  borderRadius: {
    primary: '8px',
    additional2: '2px',
    additional4: '4px',
    additional6: '6px',
    additional10: '10px',
    additional14: '14px',
    additional16: '16px',
    additional24: '24px',
    round: '50%',
  },
};
