import { useEffect, useState } from 'react';
import { DEFAULT_TABLE_DATA } from '../features/universalTable/context';
import { API_URL, NEW_ID } from '../consts';
import { HttpService } from '../utils/http';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { SortingData } from '../features/universalTable/types';

export const useComponentsList = (
  page: number,
  pageSize: number,
  setComponents: any,
  searchField: string,
  sortingData: SortingData,
  setPlaceholder?: any,
  stopPlaceholder?: any,
) => {
  const { sort_field, sort_type } = sortingData;
  const [localComponents, setLocalComponents] = useState<any>(DEFAULT_TABLE_DATA);
  const offset = pageSize * (page - 1);
  const trigger = [pageSize, offset, searchField, sort_field, sort_type] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);

  useEffect(() => {
    const url = `${API_URL}/v1/components/?limit=${pageSize}&offset=${offset}${
      sort_field && sort_type ? `&sort_type=${sort_type}&sort_field=${sort_field}` : ''
    }&search_field=${searchField}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data;
        const { pagination, payload, metadata } = responseData;
        const result = {
          list: payload.map((item: any) => ({ ...item, isChecked: false, isEdit: false })),
          pagination,
          metadata,
        };
        setComponents && setComponents(result.list, result.pagination, result.metadata);
        setLocalComponents(result);
      } catch (e) {
        //
      }
    })();
  }, [...trigger, loaderOptions]);

  return [localComponents.list, localComponents.pagination];
};

export const updateComponent = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
) => {
  const url = `${API_URL}/v1/components/${id}`;
  if (id !== NEW_ID) {
    (async () => {
      try {
        data.process = typeof data.process_name === 'string' ? undefined : data.process_name;
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  } else {
    const url = `${API_URL}/v1/components/`;
    (async () => {
      try {
        data.process_id = data.process_name.id;
        data.process_name = data.process_name.name;
        let responseData = (await HttpService.post(url, data)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  }
};

export const deleteComponentsGlobal = (
  id: string[],
  deleteComponent: any,
  deleteCheckedComponents: any,
  handleAddNotification: Function,
) => {
  const data = { component_ids: id };
  const url = `${API_URL}/v1/components/`;
  const deleteOneStage = () => deleteComponent(id[0]);
  (async () => {
    try {
      let responseData = (await HttpService.delete(url, data)).data;
      handleAddNotification({
        message: 'Successfully deleted',
        type: 'success',
      });
    } catch (e) {
      const error = e as any;
      handleAddNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
  if (id.length === 1) {
    deleteOneStage();
  } else {
    deleteCheckedComponents();
  }
};

export const useGetProcess = () => {
  const [localProcesses, setLocalProcesses] = useState();
  useEffect(() => {
    const url = `${API_URL}/v1/processes/list`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id }));
        setLocalProcesses(result);
      } catch (e) {
        //
      }
    })();
  }, []);
  return localProcesses;
};
