/**
 * @description
 * This is component modal window detached devices for popupInfoModal for floorPlan.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { useCustomHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../../routes/routeInterfaces';
import { ContentType } from '../../../../../../components/insightBadges/InsightBadges';

export const PopupBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow1};
  position: absolute;
  top: 15px;
  left: 15px;
  transition: all 2s linear;
`;
const Wrapper = styled.div`
  width: 240px;
  box-sizing: border-box;
  padding: 12px 18px;
  display: flex;
  flex-direction: column;
`;
const DescriptionBlock = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;
const PopupButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props: any) => (props.isPackage ? 'space-between' : 'flex-end')};
  &:first-child {
    margin-right: 16px;
  }
`;
const DescriptionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DeviceButton = styled(CommonButton)`
  font-size: 14px;
`;

export interface PopupFloorType {
  infoArr: any;
  popupInfo: any;
  languageStrings?: any;
}

export const DetachedModal: FC<PopupFloorType> = ({ infoArr, popupInfo, languageStrings }) => {
  const theme = useTheme();
  const customHistory = useCustomHistory();
  const { black, gray1 } = theme.colors;
  const { roleName, name, packageName, id, buildingId } = popupInfo.content;

  const newInfoArr = infoArr && Object.values(infoArr);

  return (
    <Wrapper>
      <Text type={TextType.TEXT_14_BLACK} weight={'600'} color={black} margin={'0 0 8px 0'} lineHeight={'24px'}>
        {name ?? 'N/A'}
      </Text>
      <DescriptionBlock>
        {newInfoArr.map((item: any, index: number) => {
          return item.map((elem: any) => {
            return elem.values.map((val: any) => {
              const { label, value } = val;
              return (
                <>
                  <DescriptionRow>
                    <Text type={TextType.TEXT_12_GRAY} color={gray1} weight={'400'} lineHeight={'24px'}>
                      {label}
                    </Text>
                    <Text type={TextType.TEXT_12_GRAY} color={black} weight={'500'} lineHeight={'24px'}>
                      {value}
                    </Text>
                  </DescriptionRow>
                </>
              );
            });
          });
        })}
      </DescriptionBlock>
      <PopupButtons isPackage={!!packageName}>
        {id && buildingId && (
          <DeviceButton
            width={'94px'}
            height={'26px'}
            onClick={() => {
              const to =
                roleName === 'TAG' ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER : View.USER_MANAGE_DEVICES_DETAILS_NODE;
              to &&
                customHistory.push(to, {
                  pathParams: { id, buildingId, offset: 0 },
                });
            }}
          >
            {languageStrings ? languageStrings.labelDevice : 'Device'}
          </DeviceButton>
        )}
      </PopupButtons>
    </Wrapper>
  );
};
