/**
 * @description
 * This is component that displays mobile version of DescriptionPopup
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../../../../../styles/media';
import { CloseWhiteRoundButton } from '../../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { InputModal } from '../../../../../../../components/inputModal/InputModal';
import { CommonButton } from '@bit/first-scope.common-button';
import { SelectStagesState } from '../../../../../../../app/state/manageStagesSlice';
import { StagesFunctional } from '../../../stagesComponent/components/stagesFunctional/StagesFunctional';
import { EditModal } from './components/editModal/EditModal';
import { DeleteModal } from './components/deleteModal/DeleteModal';
import { Modal } from '@bit/first-scope.modal';
import { useWindowSize } from '../../../../../../../styles/style.context';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';

const Background = styled(Modal)`
  display: none;
  ${media.phone`
    display: flex;
  `}
`;
const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000000;
  border-radius: ${(props: any) =>
    `${props.theme.borderRadius.additional16} ${props.theme.borderRadius.additional16} 0 0`};
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled(Text)`
  font-size: 18px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;
const StageField = styled(StagesFunctional)`
  margin-top: 0;
  margin-bottom: 14px;
`;

export const MobileView = ({
  isEdit,
  value,
  closeModal,
  accessData,
  handleDeleteOnClick,
}: {
  closeModal: any;
  value: any;
  isEdit?: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  handleDeleteOnClick: () => void;
}) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const theme = useTheme();
  const { black, white, red3 } = theme.colors;
  const { name, coordinates, associated_stages, description } = value;
  const windowWidth = useWindowSize()[0];

  const languageStrings = useSelector(selectLanguageStrings);

  const fieldsArr = [
    {
      fieldName: languageStrings ? languageStrings.labelPositionX : 'Position X',
      value: Math.floor(coordinates.center.x),
    },
    {
      fieldName: languageStrings ? languageStrings.labelPositionY : 'Position Y',
      value: Math.floor(coordinates.center.y),
    },
    { fieldName: languageStrings ? languageStrings.labelWidth : 'Width', value: coordinates.width },
    { fieldName: languageStrings ? languageStrings.labelHeight : 'Height', value: coordinates.height },
    // { fieldName: 'Associated stages', value: associated_stages },
    { fieldName: languageStrings ? languageStrings.labelDescription : 'Description', value: description },
  ];
  const stagesList = useSelector(SelectStagesState);

  const openDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };
  const openEditModal = () => {
    setIsOpenEditModal(true);
  };
  const closeEditModal = () => {
    setIsOpenEditModal(false);
  };

  return (
    <Background isOpenModal={true}>
      <Wrapper
        theme={theme}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <TitleWrapper>
          <Title type={TextType.TITLE_PRIMARY}>{name}</Title>
          <CloseWhiteRoundButton onClick={closeModal} />
        </TitleWrapper>
        {fieldsArr.map(
          (item: any, index: number) => {
            const { fieldName, value } = item;
            // if (index === 4) {
            //   return <StageField data={stagesList} name={fieldName} placeholder={'N/A'} isDisabled value={value} />;
            // } else {
            return (
              <InputModal
                value={value}
                onChange={() => {}}
                name={fieldName}
                placeholder={''}
                disabled
                rows={index === 4 ? 2 : 1}
              />
            );
          },
          /*}*/
        )}
        <ButtonsWrapper>
          {accessData?.delete && (
            <CommonButton
              onClick={openDeleteModal}
              width={'100%'}
              backgroundColor={white}
              borderColor={red3}
              color={black}
              backgroundColorHover={red3}
              borderColorHover={red3}
            >
              {languageStrings ? languageStrings.deleteButton : 'Delete'}
            </CommonButton>
          )}
          {accessData?.edit && (
            <CommonButton onClick={openEditModal} width={'100%'}>
              {languageStrings ? languageStrings.editButton : 'Edit'}
            </CommonButton>
          )}
        </ButtonsWrapper>
        {accessData?.edit && <EditModal isOpenModal={isOpenEditModal} closeModal={closeEditModal} />}
        {accessData?.delete && (
          <DeleteModal isOpenModal={isOpenDeleteModal} closeModal={closeDeleteModal} deleteZone={handleDeleteOnClick} />
        )}
      </Wrapper>
    </Background>
  );
};
