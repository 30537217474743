import { useEffect, useState } from 'react';
import { NEW_ID } from '../consts';

import { API_URL } from '../consts';
import { HttpService } from '../utils/http';

export const deleteUserGlobal = (
  id: string[],
  deleteUser: any,
  deleteCheckedUsers: any,
  handleAddNotification: Function,
) => {
  const data = { user_ids: id };
  const url = `${API_URL}/v1/users/delete`;
  const deleteOneUser = () => deleteUser(id[0]);
  (async () => {
    try {
      let responseData = (await HttpService.post(url, data)).data;
      handleAddNotification({ message: 'Successfully deleted', type: 'success' });
    } catch (e) {
      const error = e as any;
      handleAddNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
  if (id.length === 1) {
    deleteOneUser();
  } else {
    deleteCheckedUsers();
  }
};

export const useGetClients = () => {
  const [localClients, setLocalClients] = useState();
  useEffect(() => {
    const url = `${API_URL}/v1/clients/`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id }));
        setLocalClients(result);
      } catch (e) {
        //
      }
    })();
  }, []);
  return localClients;
};

export const updateUsers = (
  id: string,
  data: {
    name: string;
    email: string;
    phone: string;
    client_id: string;
    role: number;
  },
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/users/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  } else {
    const url = `${API_URL}/v1/users/`;

    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        responseData.payload.role_name = responseData.payload.role_name === '1' ? 'ADMIN' : 'USER';
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  }
};
