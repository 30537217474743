/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholder for table row require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

const Block = styled.div`
  height: 16px;
  max-width: ${(props: any) => (props.width ? props.width : '100px')};
  width: 100%;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  background-color: gray5;
`;

export interface ComponentProps {
  width: string;
}

export const PlaceholderBlock: FC<ComponentProps> = ({ width }) => {
  const theme = useTheme();

  return <Block theme={theme} width={width} />;
};
