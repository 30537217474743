import { useEffect, useMemo, useState } from 'react';
import { API_URL, NEW_ID } from '../consts';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { HttpService } from '../utils/http';

export const useManageZonesDetails = (
  floor_id: string,
  setPlaceholder?: any,
  stopPlaceholder?: any,
  height?: number,
  width?: number,
  activeId?: string | null,
  isUpdateZones?: boolean,
  setIsUpdateZones?: (state: boolean) => void,
) => {
  const [localZonesDetails, setLocalZonesDetails] = useState<any>([]);
  //const stagesList = useGetStagesList();

  const scaledData = useMemo(() => {
    let scaledState = [];
    if (localZonesDetails && localZonesDetails.length > 0 && height && width) {
      scaledState = localZonesDetails.map((el: any) => {
        const { location, coordinates: baseCoords, associated_stages } = el;
        const { floor } = location;
        const { center } = baseCoords;
        const ratio = width / floor.image_width;
        const coordinates = {
          width: baseCoords.width,
          height: baseCoords.height,
          scaled_center: {
            x: center.x * ratio,
            y: center.y * ratio,
          },
          center: baseCoords.center,
        };
        //const associatedStages = { list: stagesList, selected_stages: associated_stages };
        return { ...el, active: el.id === activeId, associated_stages: associated_stages, coordinates };
      });
    }
    return scaledState;
  }, [height, width, localZonesDetails, activeId, isUpdateZones]);

  const trigger = [floor_id] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/zones/floor/${floor_id}?limit=100&offset=0`;
    (async () => {
      if (floor_id) {
        try {
          let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
          const result = responseData.map((el: any) => {
            return el;
          });
          setLocalZonesDetails(responseData);
        } catch (e) {
          //
        }
      }
    })();
  }, [...trigger, loaderOptions, isUpdateZones]);

  useEffect(() => {
    setIsUpdateZones && setIsUpdateZones(false);
  }, [isUpdateZones]);

  return [scaledData, setLocalZonesDetails] as const;
};

export const updateZone = (
  id: string,
  data: any,
  options?: {
    localUpdate?: (id: string, data: any) => void;
    triggerNotification?: (success: boolean, message?: string) => void;
    handleRevert?: () => void;
  },
) => {
  if (id === NEW_ID) {
    const url = `${API_URL}/v1/zones/`;
    (async () => {
      try {
        let { payload } = (await HttpService.post(url, data)).data;
        const { id, ...newData } = payload;
        options?.triggerNotification && options?.triggerNotification(true);
        options?.localUpdate && options?.localUpdate(id, newData);
      } catch (e) {
        options?.triggerNotification && options?.triggerNotification(false, e?.response?.data?.detail);
      }
    })();
  } else {
    const url = `${API_URL}/v1/zones/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        options?.triggerNotification && options?.triggerNotification(true);
      } catch (e) {
        options?.triggerNotification && options?.triggerNotification(false, e?.response?.data?.detail);
        options?.handleRevert && options?.handleRevert();
      }
    })();
  }
};
