/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for users table require
 * Highly customisable.
 *
 */

import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../../styles/media';
import { deleteUserGlobal, updateUsers, useGetClients } from '../../../api/manageUsers';
import { useTableFunctions } from '../../../features/universalTable/context';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { ActionType, InlineActionType, RowItemType } from '../../../features/universalTable/types';
import { API_URL, roleList } from '../../../consts';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { useMediaType, useWindowSize } from '../../../styles/style.context';
import { View } from '../../../routes/routeInterfaces';
import { useLocation } from 'react-router-dom';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { sizes } from '../../../styles/media';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

export interface UsersProps {
  match: any;
  className?: string;
}

export const Users: FC<UsersProps> = ({ match, ...props }) => {
  const { startAddProcess, setSearchValue, getSearchValue, resetTable } = useTableFunctions();
  const { actual: searchValue } = getSearchValue();
  const mediaType = useMediaType();
  const clients = useGetClients();
  const keyword = 'UsersTable';
  const offsetKey = 'offset';
  const accessData = useSelector(selectAccess).management.user;

  const offset = match.params[offsetKey];
  const { state }: any = useLocation();

  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper {...props}>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfUsersPage : 'Users'}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        searchInputValue={searchValue}
        addButtonOnClick={startAddProcess}
        handleChangeSearchInputValue={setSearchValue}
        accessData={accessData}
      />
      <UniversalTable
        banAutoFocus
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 7 : 12 },
        }}
        mainApiUrl={`${API_URL}/v1/users/`}
        keyword={keyword}
        additionalKeywords={['ManageLayoutSearch']}
        offset={offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_USERS}
        updateItems={updateUsers}
        deleteItems={deleteUserGlobal}
        allowDelete={accessData?.delete}
        actionSet={[{ type: ActionType.EDIT }]}
        inlineActionSet={[{ type: InlineActionType.DETAILS }]}
        idItemForViewModal={state?.state?.id}
        accessData={accessData}
        pageSize={mediaType.phone ? 6 : 18}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter name',
            label: languageStrings ? languageStrings.labelName : 'Name',
            defaultValue: '',
            type: RowItemType.INPUT,
          },
          {
            fieldName: 'email',
            placeholder: languageStrings ? languageStrings.enterEmailPlaceholder : 'Enter email',
            label: languageStrings ? languageStrings.labelEmail : 'Email',
            width: '1fr',
            defaultValue: '',
            type: RowItemType.INPUT_EMAIL,
          },
          {
            fieldName: 'phone',
            placeholder: languageStrings ? languageStrings.enterPhonePlaceholder : 'Enter phone',
            label: languageStrings ? languageStrings.labelPhone : 'Phone',
            defaultValue: '',
            type: RowItemType.INPUT_PHONE_NUMBER,
          },
          {
            fieldName: { name: 'client_name', id: 'client_id' },
            type: RowItemType.SELECT,
            placeholder: languageStrings ? languageStrings.chooseCustonerPlaceholder : 'Choose customer',
            label: languageStrings ? languageStrings.labelCustomer : 'Customer',
            defaultValue: /*clients && (clients as any).length > 0 && (clients[0] as any).id*/ '',
            selectItems: clients,
          },
          {
            fieldName: { name: 'role_name', id: 'role' },
            type: RowItemType.SELECT,
            placeholder: languageStrings ? languageStrings.chooseRolePlaceholder : 'Choose role',
            label: languageStrings ? languageStrings.labelRole : 'Role',
            defaultValue: '',
            width: mediaType.desktop ? '80px' : '100px',
            selectItems: roleList,
          },
        ]}
      />
    </Wrapper>
  );
};
