import React from 'react';
import { useFilters } from '../../../Content/filterContext/сontext';
import { getIsAllDeviceSelect, getIsNodes, getAllDeviceClear } from '../../../Content/filterContext/selectors';
import { ChooseParameterSelect } from '../../../../../../components/chooseParameterSelect/ChooseParameterSelect';

const FilterDevice = ({ languageStrings }: any) => {
  const { state, dispatch } = useFilters();
  const { deviceState } = state;
  const { checkedDevicesCount, isOpenDeviceList, devicesFilter } = deviceState;
  const isSelectAllDevice = getIsAllDeviceSelect(state);
  const isAllDeviceClear = getAllDeviceClear(state);
  const isNodes = getIsNodes(state);

  const handleSelectAllDevices = () => {
    dispatch({
      type: 'SELECT_ALL_DEVICE',
      payload: { isSelectAllDevice: isSelectAllDevice, isClearAll: false },
    });
  };

  const handleSelectOneDevices = (type: string) => {
    dispatch({ type: 'SELECT_ONE_DEVICE', payload: { type: type, isAllDeviceClear: isAllDeviceClear } });
  };

  const handleDeviceList = (state: boolean) => {
    dispatch({ type: 'HANDLE_DEVICE_LIST', payload: state });
  };

  return (
    <ChooseParameterSelect
      name={languageStrings ? languageStrings.labelDevices : 'Devices'}
      parameters={devicesFilter}
      //@ts-ignore
      handleCheckedItem={handleSelectOneDevices}
      handleSelectAll={handleSelectAllDevices}
      setIsOpenSelectList={handleDeviceList}
      isSelectAll={isSelectAllDevice}
      isOpenSelectList={isOpenDeviceList}
      isSearchActive={false}
      isDisabled={!isNodes}
      checkedCount={checkedDevicesCount}
      maxWidth={'185px'}
    />
  );
};

export { FilterDevice };
