/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for small loaders require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { keyframes, useTheme } from '@xstyled/styled-components';

const loading = keyframes`
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; } 
`;

const Circle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.primary)};
    border-radius: 100%;
    -webkit-animation: ${loading} 1.2s infinite ease-in-out both;
    animation: ${loading} 1.2s infinite ease-in-out both;
  }

  &.sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  &.sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  &.sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  &.sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  &.sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  &.sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &.sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  &.sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  &.sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  &.sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  &.sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  &.sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  &.sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  &.sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  &.sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  &.sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  &.sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  &.sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  &.sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  &.sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  &.sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  &.sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
`;
const Spinner = styled.div`
  margin: 0 8px 0 0;
  width: 16px;
  height: 16px;
  position: relative;
`;
const StyledContainer = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
  border-radius: ${(props: any) => (props.borderRadius ? props.borderRadius : '0.25rem')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.01);
`;

export interface LoaderProps {
  color?: string;
  style?: any;
  className?: string;
}

export const SmallLoader: FC<LoaderProps> = ({ color, style, ...props }) => {
  const theme = useTheme();
  return (
    <StyledContainer
      width={style && style.width}
      height={style && style.height}
      borderRadius={style && style.borderRadius}
      flex={style && style.flex}
      {...props}
    >
      <Spinner>
        <Circle className="sk-circle1 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle2 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle3 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle4 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle5 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle6 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle7 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle8 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle9 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle10 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle11 sk-circle" backgroundColor={color} theme={theme} />
        <Circle className="sk-circle12 sk-circle" backgroundColor={color} theme={theme} />
      </Spinner>
    </StyledContainer>
  );
};
