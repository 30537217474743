/**
 * @description
 * This is component for marker of map list device
 * all the functionalities cthings require for CustomMarker
 * Highly customisable.
 *
 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
// @ts-ignore
import { Marker } from 'react-map-gl';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

const MarkerWrapper = styled.div`
  & > div {
    z-index: ${(props: any) => props.zIndex};
  }
`;

export const CustomMarker = ({ children, setCentre, zoom, handleClick, isReq, ...props }: any) => {
  const markerRef = useRef(null);
  const handleLocalClick = (e: any) => {
    e.preventDefault();

    handleClick && handleClick();
    const calcMisplacedLat = (zoom: number, latitude: number) => {
      const y =
        (512 / (2 * Math.PI)) *
          Math.pow(2, zoom) *
          (Math.PI - Math.log(Math.tan((Math.PI / 4) * (1 + latitude / 90)))) +
        100;
      const newLat =
        ((Math.atan(Math.exp(Math.PI - ((y / 512) * 2 * Math.PI) / Math.pow(2, zoom))) / Math.PI) * 4 - 1) * 90;

      return newLat;
    };

    setCentre &&
      props.latitude &&
      props.longitude &&
      setCentre({ lat: calcMisplacedLat(zoom, props.latitude), lng: props.longitude });
  };

  return (
    <MarkerWrapper zIndex={isReq ? 2 : 1} onClick={handleLocalClick} ref={markerRef}>
      <Marker {...props}>{children}</Marker>
    </MarkerWrapper>
  );
};
