/**
 * @description
 * This is component button for editing
 * all the functionalities cthings require for EditButton
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import Tooltip, { useTooltip } from '../tooltip/Tooltip';

import { ReactComponent as EditImage } from '../../assets/edit.svg';

const Button = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  cursor: pointer;
  position: relative;
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.gray3};
    transition: stroke 0.3s ease;
  }

  &:hover > svg > path {
    stroke: ${(props: any) => props.theme.colors.gray2};
  }
`;

export interface EditButtonProps {
  tooltipText: string;
  color?: string;
  colorHover?: string;
  onClick: any;
  className?: string;
}

export const EditButton: FC<EditButtonProps> = ({ color, colorHover, onClick, tooltipText, ...props }) => {
  const theme = useTheme();
  const [tooltipIds, , hideTooltip] = useTooltip();

  const handleClick = () => {
    // @TODO Alex - remove test functionality of tooltips
    hideTooltip(tooltipIds[0]);
    onClick && onClick();
  };

  return (
    <Button onClick={handleClick} color={color} colorHover={colorHover} theme={theme} {...props}>
      <Tooltip id={tooltipIds[0]} text={tooltipText}>
        <EditImage />
      </Tooltip>
      {/*<Tooltip
        type={TooltipType.ARROW_BOTTOM}
        text={tooltipText}
        bottom={'20px'}
        right={'-46.5px'}   
        visibility={clicked}
      />*/}
    </Button>
  );
};

export default EditButton;
