/**
 * @description
 * This is component for draggable table
 * all the functionalities cthings require for DraggableTable
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';

import { Pagination } from '../pagination/pagination/Pagination';
import { TableHeader } from './components/TableHeader';
import { TableRow } from './components/TableRow';

import { media } from '../../styles/media';
import { withMobileDraggableContainer } from '../draggableContainerHOC/withMobileDraggableContainer';

const Wrapper = styled.div`
  width: 680px;
  ${media.tablet`
    width: 100%;
  `}
`;

const ContainerTableRow = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;

const DraggableTable = ({
  data,
  page,
  onPageChange,
  pageSize,
  eventNum,
}: {
  data: any[];
  page: number;
  onPageChange: any;
  pageSize: number;
  eventNum: number;
}) => {
  //@TODO Alex add the useEffect like functionality for mediaType
  // add ability to sort the table, refactor the select field in table

  return (
    <Wrapper>
      <TableHeader labels={['Date', 'Place', 'Problems']} />
      <ContainerTableRow>
        {data.map((event) => (
          <TableRow value={event} first />
        ))}
      </ContainerTableRow>
      <Pagination onPageChange={onPageChange} currentPage={page} pageSize={pageSize} eventNum={eventNum} />
    </Wrapper>
  );
};

export default withMobileDraggableContainer(true)(DraggableTable);
