/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for navigation require
 * Highly customisable.
 *
 */

import { ArrowButton, ArrowButtonType } from '@bit/first-scope.arrow-button';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
//images
import ClaspLogo from '../../assets/Clasp.svg';
import ValmontLogo from '../../assets/Valmont.png';
import { useTooltip } from '../../components/tooltip';
import { isClasp } from '../../consts';
import { DashboardType } from '../../enums/Dashboard';
import { injectPathParams, PATHS } from '../../routes/paths';
import { media } from '../../styles/media';
import { useStyleContext } from '../../styles/style.context';
import { MobileNavigation } from './components/MobileNavigation';
import { MenuItem, MenuItemType } from './MenuItem';
import { useDevicesForNav } from '../../api/locations';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { LanguageItem } from './components/LanguageItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 55px;
  height: 100%;
  padding: 30px 15px 30px 0;
  background-color: #ffffff;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow3};
  transition: all 0.3s ease;
  /* overflow-y: auto;
  overflow-x: hidden; */
  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
  &::-webkit-scrollbar-button {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &.open {
    width: 180px;
  }
  ${media.tablet`
    padding: 0 20px;
    bottom: initial;
    right: 0;
    width: 100%;
    height: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border-bottom: 1px solid;
    border-color: gray3;
    
    &.hidden {
      transition: all .3s ease;
      transform: translateY(-100%);
    }
  `}
`;

const IconArrowContainer = styled.div`
  padding-left: 20px;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  &.open {
    justify-content: space-between;
  }
  ${media.tablet`
    display: none;
  `}
`;

const LogoContainer = styled.div`
  width: 0;
  overflow: hidden;
  height: 18px;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
`;

const Logo = styled.img`
  transition: none;
  width: 98px;
  height: 16px;
`;

const ArrowContainer = styled.div`
  transition: all 0.3s ease;
  flex: 1;
  display: flex;
  justify-content: center;
  &.open {
    display: block;
    flex: 0;
    transform: rotate(-180deg);
  }
`;

const BodyNavigation = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  ${media.tablet`
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
  `}
`;

export interface NavigationProps {
  onLogout: any;
  userStatus?: string;
  withLanguages?: boolean;
  className?: string;
}

export const Navigation: FC<NavigationProps> = ({ onLogout, userStatus, withLanguages, ...props }) => {
  const theme = useTheme();
  const [media] = useStyleContext();
  const nav: any = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const languageStrings = useSelector(selectLanguageStrings);

  const devices = useDevicesForNav();

  const sitesRoutingArray = devices.geopivoted.map((item: any, id: number) => {
    return {
      name: item[0].name,
      to: injectPathParams(PATHS.USER_INSIGHTS_DETAILS, { id: item[0].building_id, name: item[0].name }),
    };
  });

  const sitesRoutingArrayWithAllSitesLink = [
    {
      name: languageStrings ? languageStrings.labelAllSites : 'All sites',
      to: injectPathParams(PATHS.USER_INSIGHTS, { lat: 52.3, lon: 21.1, zoom: 3 }),
    },
    { view: languageStrings ? languageStrings.labelLine : 'LINE' },
    ...sitesRoutingArray,
  ];

  const sitesWithDevices = sitesRoutingArrayWithAllSitesLink.concat([
    { view: languageStrings ? languageStrings.labelLine : 'LINE' },
    {
      name: languageStrings ? languageStrings.labelZones : 'Zones',
      to: injectPathParams(PATHS.MANAGE_ZONES, { offset: '0' }),
    },
  ]);

  const sitesWithoutDevices = [
    {
      name: languageStrings ? languageStrings.labelAllSites : 'All sites',
      to: injectPathParams(PATHS.USER_INSIGHTS, { lat: 52.3, lon: 21.1, zoom: 3 }),
    },
    { view: languageStrings ? languageStrings.labelLine : 'LINE' },
    {
      name: languageStrings ? languageStrings.labelZones : 'Zones',
      to: injectPathParams(PATHS.MANAGE_ZONES, { offset: '0' }),
    },
  ];

  const sitesList = devices?.geopivoted.length ? sitesWithDevices : sitesWithoutDevices;

  useEffect(() => {
    const handleScroll = () => {
      if (!isHidden && window.scrollY > scrollY) {
        setIsHidden(true);
        setScrollY(window.scrollY);
      } else if (isHidden && window.scrollY < scrollY) {
        setIsHidden(false);
        setScrollY(window.scrollY);
      } else if (window.scrollY < 0) {
        setIsHidden(false);
      } else {
        setScrollY(window.scrollY);
      }
    };

    const addEventScroll = () => {
      if (nav) {
        window.addEventListener('scroll', handleScroll);
      }
      window.addEventListener('touchmove', handleScroll);
    };

    const removeEventListener = () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };

    media.mediaType.phone ? addEventScroll() : removeEventListener();
    return () => {
      removeEventListener();
    };
  }, [isHidden, scrollY]);

  const outsideHandler = useCallback(
    (e: any) => {
      isOpen && !nav.current.contains(e.target) && setIsOpen(false);
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const menuItemArray = [
    {
      type: MenuItemType.SEARCH,
      route: PATHS.USER_SEARCH,
      pathForActive: PATHS.USER_SEARCH,
      value: languageStrings ? languageStrings.labelSearch : 'Search',
    },
    {
      type: MenuItemType.DASHBOARDS,
      route: PATHS.USER_DASHBOARD,
      value: languageStrings ? languageStrings.labelDashboards : 'Dashboards',
      pathForActive: PATHS.USER_DASHBOARD_REDIRECT,
      childRoutes: [
        {
          name: languageStrings ? languageStrings.labelOverall : 'Overall',
          to: injectPathParams(PATHS.USER_DASHBOARD, { type: DashboardType.OVERALL }),
        },
        {
          name: languageStrings ? languageStrings.labelOrders : 'Orders',
          to: injectPathParams(PATHS.USER_DASHBOARD, { type: DashboardType.ORDERS }),
        },
        {
          name: languageStrings ? languageStrings.labelInventories : 'Inventories',
          to: injectPathParams(PATHS.USER_DASHBOARD, { type: DashboardType.INVENTORIES }),
        },
      ],
    },
    {
      type: MenuItemType.SITES,
      route: PATHS.USER_SITES_REDIRECT,
      value: languageStrings ? languageStrings.labelSites : 'Sites',
      pathForActive: PATHS.USER_SITES_REDIRECT,
      childRoutes: sitesList,
    },
    {
      type: MenuItemType.INVENTORIES,
      route: injectPathParams(PATHS.MANAGE_INVENTORIES, { offset: '0' }),
      pathForActive: PATHS.MANAGE_INVENTORIES,
      value: languageStrings ? languageStrings.labelInventories : 'Inventories',
    },
    {
      type: MenuItemType.USERS,
      route: injectPathParams(PATHS.MANAGE_USERS, { offset: '0' }),
      pathForActive: PATHS.MANAGE_USERS,
      value: languageStrings ? languageStrings.labelUsers : 'Users',
    },
    {
      type: MenuItemType.DEVICES,
      pathForActive: PATHS.USER_DEVICES_REDIRECT,
      route: injectPathParams(PATHS.MANAGE_DEVICES, { offset: '0' }),
      value: languageStrings ? languageStrings.labelDevices : 'Devices',
    },
    {
      type: MenuItemType.OPERATIONS,
      route: PATHS.USER_OPERATIONS_REDIRECT,
      value: languageStrings ? languageStrings.labelOperations : 'Operations',
      pathForActive: PATHS.USER_OPERATIONS_REDIRECT,
      childRoutes: [
        {
          name: languageStrings ? languageStrings.labelStages : 'Stages',
          to: injectPathParams(PATHS.MANAGE_STAGES, { offset: '0' }),
        },
        {
          name: languageStrings ? languageStrings.labelProcesses : 'Processes',
          to: injectPathParams(PATHS.MANAGE_PROCESSES, { offset: '0' }),
        },
      ],
    },
    {
      type: MenuItemType.SERVICES,
      route: PATHS.USER_SERVICES_REDIRECT,
      value: languageStrings ? languageStrings.labelServices : 'Services',
      pathForActive: PATHS.USER_SERVICES_REDIRECT,
      childRoutes: [
        {
          name: languageStrings ? languageStrings.labelOrders : 'Orders',
          to: injectPathParams(PATHS.MANAGE_ORDERS, { offset: '0' }),
        },
        {
          name: languageStrings ? languageStrings.labelComponents : 'Components',
          to: injectPathParams(PATHS.MANAGE_COMPONENTS, { offset: '0' }),
        },
      ],
    },
    {
      type: MenuItemType.DOCUMENTS,
      route: PATHS.USER_DOCUMENTS,
      pathForActive: PATHS.USER_DOCUMENTS,
      value: languageStrings ? languageStrings.labelDocuments : 'Documents',
    },
  ];

  const [tooltipIds] = useTooltip(menuItemArray.length);

  return (
    <Wrapper
      ref={nav}
      theme={theme}
      className={`${isOpen && !media.mediaType.tablet && 'open'} ${media.mediaType.phone && isHidden && 'hidden'}`}
    >
      <IconArrowContainer className={isOpen ? 'open' : ''}>
        <LogoContainer className={isOpen ? 'open' : ''}>
          {isClasp ? <Logo src={ClaspLogo} alt={'Clasp logo'} /> : <Logo src={ValmontLogo} alt={'Valmont logo'} />}
        </LogoContainer>
        <ArrowContainer className={isOpen ? 'open' : ''}>
          <ArrowButton type={ArrowButtonType.RIGHT} onClick={() => setIsOpen((val) => !val)} />
        </ArrowContainer>
      </IconArrowContainer>
      <BodyNavigation>
        {media.mediaType.tablet ? (
          <MobileNavigation menuItemArray={menuItemArray} userStatus={userStatus} onLogout={onLogout} withLanguages />
        ) : (
          <>
            {menuItemArray.map((item, index) => {
              const { value, route, type, childRoutes, pathForActive } = item;

              return (
                <MenuItem
                  key={index}
                  type={type}
                  value={value}
                  to={route}
                  isOpen={isOpen}
                  tooltipId={tooltipIds[index]}
                  childRoutes={childRoutes ? childRoutes : null}
                  pathForActive={pathForActive ? pathForActive : null}
                />
              );
            })}
          </>
        )}
      </BodyNavigation>
      {!media.mediaType.tablet && <div style={{ flex: 1, minHeight: '50px' }}></div>}
      {!media.mediaType.tablet && withLanguages && <MenuItem type={MenuItemType.LANGUAGES} isOpen={isOpen} />}
      {!media.mediaType.tablet && (
        <MenuItem type={MenuItemType.LOGOUT} isOpen={isOpen} userName={userStatus} onLogout={onLogout} />
      )}
    </Wrapper>
  );
};
