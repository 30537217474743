/**
 * @description
 * This is component for no data placeholder in different places
 * all the functionalities cthings require for placeholders
 * good customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { ReactComponent as NoDataImage } from '../../assets/No_Data.svg';

const MainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 18px 20px 0;
`;

const NoDataBackground = styled.div`
  width: 100%;
  height: 100%;
  min-height: 94px;
  box-sizing: border-box;
  background-color: gray7;
  border-radius: ${(props: any) => props.theme.borderRadius.additional6};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > span {
    font-weight: 400;
  }
`;

export const NoDataPlaceholder = ({ ...props }: { className?: string }) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);
  return (
    <MainWrapper {...props}>
      <NoDataBackground theme={theme}>
        <NoDataImage />
        <Text type={TextType.TEXT_14_GRAY}>{language ? language.noDataPlaceholder : 'No data'}</Text>
      </NoDataBackground>
    </MainWrapper>
  );
};
