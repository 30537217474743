import { HttpService } from '../utils/http';

export const setLanguageIdentity = (lang: string) => {
  const url = `https://identity.cthings.co/users/me/lang?lang=${lang}`;
  (async () => {
    try {
      const responseData = (await HttpService.patch(url, {})).data;
      console.log(responseData);
    } catch (e) {}
  })();
};
