import { useState, useEffect, useMemo } from 'react';
import { API_URL, SOCKET_URL } from '../consts';
import { HttpService } from '../utils/http';
import { io, Socket } from 'socket.io-client';
import { OnlineStatusType } from '../features/clustering/types';
import ls from '../utils/ls';
import { LsValueType } from '../enums/LsValueType';
import { applyToArray, fakeApiExtend } from '../features/apiBuilder/fakeApiEtxendFunctions';
import { ContentType } from '../components/insightBadges/InsightBadges';
import { createId } from '../utils/uuid';

const defaultData = {
  buildings: [
    {
      id: 'string',
      floors: [
        {
          id: 'string',
          nodes: [
            {
              id: 'string',
              positioning_role: 0,
              base_role: 1,
              pixel_position: {
                x: 0,
                y: 0,
              },
              options: { hasOffline: false, hasNodes: false, hasTrackers: false },
              online_status: OnlineStatusType.OFFLINE,
              base_role_name: 'string',
              positioning_role_name: 'string',
            },
          ],
        },
      ],
    },
  ],
  last_updated_at: createId(),
};

export const useFloorplanLocationLive = (ids: string, floor_ids: string, height: number, width: number) => {
  const [data, setData] = useState<typeof defaultData>();
  const [connected, setConnected] = useState(false);
  const [firstFlag, setFirstFlag] = useState(false);

  const resetData = () => {
    setData(defaultData);
  };

  const token = ls.get(LsValueType.token);

  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io(SOCKET_URL, { transports: ['websocket'] });
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  const scaledData = useMemo(() => {
    const scaleNode = (baseNode: any, imageWidth: number, imageHeight: number) => {
      const scaledX = (width / imageWidth) * baseNode.pixel_position.x;
      const scaledY = (height / imageHeight) * baseNode.pixel_position.y;
      const hasOffline = baseNode.online_status === OnlineStatusType.OFFLINE;
      const hasNodes = !hasOffline && baseNode.base_role === 2;
      const hasTrackers = !hasOffline && baseNode.base_role === 1;
      const options = { hasOffline, hasNodes, hasTrackers };
      const scaledNode = { ...baseNode, pixel_position: { x: scaledX, y: scaledY }, options };
      return scaledNode;
    };

    const scaleFloor = (floor: any) => {
      const resultNodes = floor.nodes.map((node: any) => {
        const scaledNode = scaleNode(node, floor.image_width, floor.image_height);
        return scaledNode;
      });
      return { ...floor, nodes: resultNodes };
    };

    if (height && width && data && data.buildings) {
      const resultFloors = data.buildings[0].floors.map((floor: any) => {
        const scaledFloor = scaleFloor(floor);
        return scaledFloor;
      });
      return { ...data, buildings: [{ ...data.buildings[0], floors: resultFloors }] };
    }
  }, [height, width, data?.last_updated_at]);

  const arrayFloorIds = [ids];

  /*useEffect(() => {
    const url = `${API_URL}/v1/nodes/rtlocation?building_ids=${arrayFloorIds}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;

        setData(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);*/

  useEffect(() => {
    if (socket) {
      socket?.on('connect', () => {
        console.log('connected1');
        setConnected(true);
      });
      socket?.on('disconnect', () => {
        console.log('disconnected');
        setConnected(true);
      });

      socket?.on('rtlocation', (val: any) => {
        const data = JSON.parse(val).payload;

        // SOCKET IS WORKING WITH FAKE DATA

        setData((value: any) => {
          const existenceFlag = value && value.buildings;
          const newFloors = data.buildings[0].floors;

          const resultFloors = existenceFlag
            ? value.buildings[0].floors.map((oldFloor: any) => {
                const newFloorIndex = newFloors.findIndex((floor: any) => floor.id === oldFloor.id);
                if (newFloorIndex !== -1) {
                  const newFloor = newFloors[newFloorIndex];
                  const resultNodes = oldFloor.nodes.map((node: any) => {
                    const newNodeIndex = newFloor.nodes.findIndex((newNode: any) => newNode.id === node.id);
                    if (newNodeIndex !== -1) {
                      const baseNode = newFloor.nodes[newNodeIndex];
                      return baseNode;
                    } else {
                      return node;
                    }
                  });
                  return { ...oldFloor, nodes: resultNodes, nodeCount: resultNodes.length };
                } else {
                  return oldFloor;
                }
              })
            : data.buildings[0].floors;
          const resultBuildings = [{ ...data.buildings[0], floors: resultFloors }];

          return {
            ...data,
            buildings: resultBuildings,
          };
        });
      });

      return () => {
        socket?.disconnect();
        setConnected(false);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (ids && floor_ids && socket && connected) {
      const data = {
        building_ids: [ids],
        floor_ids: [floor_ids],
        pixel_height: height || 2,
        pixel_width: width || 2,
        retries: 5,
        access_token: token,
      };

      if (firstFlag) {
        setFirstFlag(false);
      } else {
        console.log('PING');
        socket.emit('rtlocation', data);
      }
    }
  }, [ids, floor_ids, connected]);

  return [scaledData, { resetData }] as const;
};
