import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';

import { media } from '../../../../../styles/media';
import { InlineActionType, RowItemType, TableCustomComponentState } from '../../../../../features/universalTable/types';
import { UniversalTable } from '../../../../../features/universalTable/UniversalTable';
import { API_URL } from '../../../../../consts';
import { withTableContextProvider } from '../../../../../features/universalTable/context';
import { View } from '../../../../../routes/routeInterfaces';
import { useWindowSize } from '../../../../../styles/style.context';
import { Quantity } from './components/Quantity';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.desktop`
    margin-top: 30px;
  `}
`;

export interface ComponentsTableType {
  pathParams: any;
  id: string;
}

const ComponentsTablePlain: React.FC<ComponentsTableType> = ({ id, pathParams }) => {
  const keyword = 'ComponentsTable';
  const offsetKey = 'componentsOffset';
  const width = useWindowSize()[0];

  const offset = pathParams[offsetKey];

  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <Text margin={'0 0 15px 0'} type={TextType.SEMITITLE}>
        {language ? language.titleOfComponentsPage : 'Components'}
      </Text>
      <UniversalTable
        placeholderProps={{ keyword, placeholderOptions: { countRows: 3 } }}
        offset={offset}
        offsetKey={offsetKey}
        pathParams={pathParams}
        tablePath={View.MANAGE_ORDERS_DETAILS}
        mainApiUrl={`${API_URL}/v1/order/details/${id}/components`}
        keyword={keyword}
        updateItems={() => {}}
        inlineActionSet={[
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        withHeaderInMobile={width < 769}
        withHeaderGridTemplate={'1fr 1fr 40px'}
        withRowGridTemplate={'1fr 1fr 40px'}
        columnStructure={[
          {
            fieldName: 'request_id',
            placeholder: language ? language.enterIdPlaceholder : 'Enter id',
            label: language ? language.labelProductionID : 'Production ID',
            defaultValue: '',
            shownInHeader: width < 769,
          },
          {
            fieldName: 'index',
            placeholder: language ? language.enterIndexPlaceholder : 'Enter index',
            label: language ? language.labelIndex : 'Index',
            defaultValue: '',
            shownInHeader: width < 769,
          },
          {
            fieldName: 'index_desc',
            placeholder: language ? language.enterIndexDescriptionPlaceholder : 'Enter index description',
            label: language ? language.labelIndexDescription : 'Index description',
            defaultValue: '',
          },
          {
            fieldName: 'quantity',
            placeholder: language ? language.enterQuantityPlaceholder : 'Enter quantity',
            label: language ? language.labelQuantity : 'Quantity',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
              <Quantity value={value} state={state} setValue={setValue} />
            ),
          },
          {
            fieldName: 'status',
            placeholder: language ? language.enterStatusPlaceholder : 'Enter status',
            label: language ? language.labelStatus : 'Status',
            defaultValue: '',
          },
        ]}
        onPageChange={() => {}}
      />
    </Wrapper>
  );
};
export const ComponentsTable = withTableContextProvider(ComponentsTablePlain);
