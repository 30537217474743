import { TextType } from '@bit/first-scope.text';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import React, { FC, useEffect } from 'react';
import Tooltip from '../../../components/tooltip';
import { getAccess } from '../../../routes/paths';
import { useStyleContext } from '../../../styles/style.context';
import { MenuItemType } from '../MenuItem';
import { ItemImage } from './ItemImages';
import {
  ItemWrapper,
  LineForModal,
  ManageList,
  ManageModal,
  StyledManageArrowImage,
  StyledManageNavlink,
  StyledNavLink,
  StyledText,
  TextWrapperManage,
} from './styled';

export interface ManageItemTypes {
  type: MenuItemType;
  manageRef: any;
  isActive: boolean;
  to: any;
  childRoutes: any;
  isOpen: boolean;
  tooltipId?: string;
  value?: string;
  setOpenManageList: any;
  openManageList: boolean;
  appendSecondaryItems?: (val: any) => void;
  clearSecondaryItems?: () => void;
}

export const ManageItem: FC<ManageItemTypes> = ({
  type,
  manageRef,
  isActive,
  to,
  childRoutes,
  isOpen,
  tooltipId,
  value,
  setOpenManageList,
  openManageList,
  appendSecondaryItems,
  clearSecondaryItems,
}) => {
  const theme = useTheme();

  const [media] = useStyleContext();

  useEffect(() => {
    openManageList && childRoutes.length && appendSecondaryItems && appendSecondaryItems(childRoutes);
  }, [openManageList]);

  const { additional4 } = theme.borderRadius;
  const { black, primary } = theme.colors;

  return (
    <ItemWrapper ref={manageRef} className={isActive && 'active'}>
      <Tooltip
        id={!isOpen && !openManageList ? tooltipId ?? '' : ''}
        text={!isOpen && !openManageList ? value ?? '' : ''}
      >
        <StyledNavLink
          to={to}
          access={getAccess(to)}
          onClick={(e: any) => {
            e.preventDefault();
            setOpenManageList && setOpenManageList((el: boolean) => !el);
            clearSecondaryItems && clearSecondaryItems();
          }}
          borderRadius={`0 ${additional4} ${additional4} 0`}
          className={isActive ? 'active' : ''}
          primary={primary}
          black={black}
        >
          <ItemImage type={type} />
          <TextWrapperManage className={isOpen ? 'open' : ''}>
            <StyledText
              type={TextType.MENU_ITEM_TEXT_PRIMARY}
              color={theme.colors.black}
              hoverColor={theme.colors.primary}
            >
              {value}
            </StyledText>
            <StyledManageArrowImage className={openManageList ? 'open' : ''} />
          </TextWrapperManage>
        </StyledNavLink>
      </Tooltip>
      {!media.mediaType.tablet && isOpen && childRoutes && childRoutes.length && (
        <ManageList
          className={openManageList ? 'open' : ''}
          height={16 * childRoutes.length + 15 * (childRoutes.length - 1) + 10 + 'px'}
        >
          {childRoutes.map((item: any, index: number) => {
            const { name, to, view } = item;
            return to ? (
              view === 'LINE' ? (
                <LineForModal />
              ) : (
                <StyledManageNavlink to={to} access={getAccess(to)}>
                  {name}
                </StyledManageNavlink>
              )
            ) : null;
          })}
        </ManageList>
      )}
      {!media.mediaType.tablet && !isOpen && childRoutes && childRoutes.length && (
        <ManageModal
          height={16 * childRoutes.length + 15 * (childRoutes.length - 1) + 24 + 'px'}
          className={openManageList ? 'open' : ''}
        >
          {childRoutes.map((item: any, index: number) => {
            const { name, to, view } = item;
            return to ? (
              view === 'LINE' ? (
                <LineForModal className="modal" />
              ) : (
                <StyledManageNavlink
                  style={{ marginLeft: 0, marginTop: 0 }}
                  to={to}
                  access={getAccess(to)}
                  onClick={() => setOpenManageList(false)}
                >
                  {name}
                </StyledManageNavlink>
              )
            ) : null;
          })}
        </ManageModal>
      )}
    </ItemWrapper>
  );
};
