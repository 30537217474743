import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../app/state/userSlice';
import { NEW_ID } from '../consts';
import { API_URL } from '../consts';
import { generateApi, useApiBuilder } from '../features/apiBuilder/useApiBuilder';
import { ApiCallType, HttpService } from '../utils/http';

export const useGetTypes = () => {
  const [data, setData] = useState<Object[]>([]);
  const url = `${API_URL}/v1/inventory_types/`;

  const [localData, useApiCall] = useApiBuilder({
    url,
    data,
    transformPayload: (payload) => payload.map((item: any) => ({ name: item.name, id: item.id })),
  });

  useApiCall({
    handleSuccess: (value: Object[]) => setData(value),
  });

  return [localData, setData];
};

export const updateInventories = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/inventories/${id}`;
    (async () => {
      try {
        data.inventory_type_id = data?.inventory_type?.id;
        data.tracker_id = data?.tracker?.id === '' ? null : data?.tracker?.id;
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  } else {
    const url = `${API_URL}/v1/inventories/`;
    (async () => {
      try {
        data.inventory_type_id = data?.inventory_type?.id;
        data.tracker_id = data?.tracker?.id === '' ? null : data?.tracker?.id;
        let responseData = (await HttpService.post(url, data)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  }
};

export const deleteInventoriesGlobal = (
  id: string[],
  deleteInventories: any,
  deleteCheckedInventories: any,
  handleAddNotification: Function,
) => {
  const data = { inventories_ids: id };
  const url = `${API_URL}/v1/inventories/delete/`;
  const deleteOneInventories = () => deleteInventories(id[0]);
  (async () => {
    try {
      let responseData = (await HttpService.delete(url, data)).data;
      handleAddNotification({ message: 'Successfully deleted', type: 'success' });
    } catch (e) {
      const error = e as any;
      handleAddNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
  if (id.length === 1) {
    deleteOneInventories();
  } else {
    deleteCheckedInventories();
  }
};

export const createInventoriesTypeFunctions = () => {
  const updateInventoriesType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { id: string; name: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const { id, ...remainingBody } = body;
    const url = `${API_URL}/v1/inventory_types/${id}`;
    return generateApi({ url: url, callType: ApiCallType.PATCH, body: remainingBody })({ handleSuccess, handleError });
  };

  const createInventoriesType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { name: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const url = `${API_URL}/v1/inventory_types/`;
    return generateApi({ url, callType: ApiCallType.POST, body })({ handleSuccess, handleError });
  };

  const deleteInventoriesType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { id: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const { id } = body;
    const url = `${API_URL}/v1/inventory_types/${id}`;
    return generateApi({ url, callType: ApiCallType.DELETE })({ handleSuccess, handleError });
  };

  return { updateInventoriesType, createInventoriesType, deleteInventoriesType };
};

export const deleteInventoriesType = (
  id: string[],
  deleteInventories: any,
  deleteCheckedInventories: any,
  handleAddNotification: Function,
) => {
  const data = { inventories_ids: id };
  const url = `${API_URL}/v1/inventory_types/${id[0]}`;
  const deleteOneInventories = () => deleteInventories(id[0]);
  (async () => {
    try {
      let responseData = await HttpService.delete(url, data);
      handleAddNotification({ message: 'Successfully deleted', type: 'success' });
    } catch (e) {
      const error = e as any;
      handleAddNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
  if (id.length === 1) {
    deleteOneInventories();
  } else {
    deleteCheckedInventories();
  }
};

export const useGetDeviceUnattached = () => {
  const [unattachedDevice, setUnattachedDevice] = useState([] as any[]);
  const language = useSelector(selectLanguageStrings);
  // It is id for unassigned device, for unassigned functionality in edit modals
  const idForUnassign = '62baea9d388a5efe84e73338';
  useEffect(() => {
    const url = `${API_URL}/v1/devices/unattached`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id }));
        const resultWithUnassigned = [
          { name: language ? language.lableUnassigned : 'Unassigned', id: idForUnassign },
          ...result,
        ];
        setUnattachedDevice(resultWithUnassigned);
      } catch (e) {
        //
      }
    })();
  }, []);
  return unattachedDevice;
};

export const setInventoriesAttach = (
  inventoryId: string,
  data: object,
  setIsLoader?: any,
  setIsAttach?: any,
  addNotification?: any,
) => {
  const url = `${API_URL}/v1/devices/${inventoryId}/attach`;
  (async () => {
    try {
      let responseData = (await HttpService.patch(url, data)).data;
      setIsLoader(false);
      addNotification({
        message: `Successfully attached`,
        type: 'success',
      });
      setIsAttach(true);
    } catch (e) {
      const error = e as any;
      setIsLoader(false);
      addNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
      //
    }
  })();
};

export const useGetInventories = () => {
  const [inventoriesList, setInventoriesList] = useState([]);
  useEffect(() => {
    const url = `${API_URL}/v1/inventories/`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.filter((inventory: any) => !inventory?.currently_attached);
        setInventoriesList(result);
      } catch (e) {
        //
      }
    })();
  }, []);
  return inventoriesList;
};
