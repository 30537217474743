import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: gray5;
  margin-right: 6px;
`;

const PointText = styled(Text)`
  font-weight: 500;
  &.twoLetters {
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }
`;

export const Point = ({ value }: { value: string }) => {
  return (
    <Wrapper>
      <PointText type={TextType.TEXT_12_GRAY} className={value.length > 1 ? 'twoLetters' : ''}>
        {value}
      </PointText>
    </Wrapper>
  );
};
