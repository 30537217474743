/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for content of documents require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { CardType } from '../../../../../enums/CardType';
import { DocumentsCard } from '../documentsCard/DocumentsCard';
import { media } from '../../../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { FileNameType } from '../../../../../enums/FileNameType';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 46px;
  box-sizing: border-box;
  ${media.phone`
    padding: 46px 16px;
  `}
`;

const StyledDashboardCard = styled(DocumentsCard)`
  cursor: default;
  ${media.tablet`
    max-width: 280px;
  `}
  ${media.tabletS`
    max-width: 100%;
  `}
`;

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  margin-top: 56px;
  ${media.tablet`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  `}
`;

export const ContentDocuments = () => {
  const languageStrings = useSelector(selectLanguageStrings);
  return (
    <Wrapper>
      <Text type={TextType.TITLE_H1}>{languageStrings ? languageStrings.titleOfDocumentsPage : 'Documents'}</Text>
      <CardsWrapper>
        <StyledDashboardCard
          title={languageStrings ? languageStrings.privacyPolicy : 'Privacy Policy'}
          languageStrings={languageStrings}
          type={CardType.DOCUMENTS}
          filename={FileNameType.PRIVACY_POLICY}
        />
        <StyledDashboardCard
          title={languageStrings ? languageStrings.termsOfService : 'Terms of Service'}
          languageStrings={languageStrings}
          type={CardType.DOCUMENTS}
          filename={FileNameType.TERMS_OF_SERVICE}
        />
        <StyledDashboardCard
          title={languageStrings ? languageStrings.userGuide : 'User Guide'}
          languageStrings={languageStrings}
          type={CardType.DOCUMENTS}
          filename={FileNameType.USER_GUIDE}
        />
      </CardsWrapper>
    </Wrapper>
  );
};
