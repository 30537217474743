import React, { FC, useEffect } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import {
  WrapperLanguages,
  SelectedLang,
  StyledLangArrowImage,
  SelectedLangSec,
  ListLanguages,
  ItemLang,
} from './styled';
import { Text, TextType } from '@bit/first-scope.text';
import { useStyleContext } from '../../../styles/style.context';
import { useLanguagePicker } from '../../../languages/useLanguagePicker';

export interface LanguageItemTypes {
  isOpen: boolean;
  appendSecondaryItems?: (val: any) => void;
  clearSecondaryItems?: () => void;
}

export const LanguageItem: FC<LanguageItemTypes> = ({ isOpen, appendSecondaryItems, clearSecondaryItems }) => {
  const theme = useTheme();

  const { black } = theme.colors;
  const [media] = useStyleContext();

  const {
    changeLanguageHandler,
    languagePickerRef,
    languages,
    languageIndex,
    openLangList,
    setOpenLangList,
  } = useLanguagePicker();

  useEffect(() => {
    openLangList && languages.length && appendSecondaryItems && appendSecondaryItems(languages);
  }, [openLangList]);

  return (
    <WrapperLanguages
      ref={languagePickerRef}
      onClick={() => {
        clearSecondaryItems && clearSecondaryItems();
      }}
    >
      <SelectedLang
        className={isOpen ? 'openNav' : ''}
        onClick={() => {
          setOpenLangList((el: boolean) => !el);
        }}
      >
        <Text color={black} type={TextType.TEXT_14_BLACK}>
          {languages[languageIndex].name}
        </Text>
        <StyledLangArrowImage className={openLangList ? 'open' : ''} />
      </SelectedLang>
      <SelectedLangSec className={isOpen ? 'openNav' : ''} onClick={() => setOpenLangList((el: boolean) => !el)}>
        <Text color={black} type={TextType.TEXT_14_BLACK}>
          {languages[languageIndex].shortName}
        </Text>
      </SelectedLangSec>
      {!media.mediaType.tablet && (
        <ListLanguages className={openLangList ? 'open' : ''}>
          {languages.map((item: any, index: number) => {
            return (
              <ItemLang
                key={index}
                className={
                  index === languageIndex && index === languages.length - 1
                    ? 'selected last'
                    : index === languageIndex
                    ? 'selected'
                    : index === languages.length - 1
                    ? 'last'
                    : ''
                }
                onClick={() => changeLanguageHandler(languages[index].id)}
              >
                {item.name}
              </ItemLang>
            );
          })}
        </ListLanguages>
      )}
    </WrapperLanguages>
  );
};
