import React, { FC } from 'react';
import { Modal } from '@bit/first-scope.modal';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../../../../styles/media';
import { CloseButtonRound } from '../../../closeButton/CloseButtonRound';
import { InputModal } from '../../../../../../components/inputModal/InputModal';
import { CommonButton } from '@bit/first-scope.common-button';
import { getRequiredDateFormat } from '../../../../../../utils/date/date-format';
import { ContentType } from '../../../../../../components/insightBadges/InsightBadges';

import { useCustomHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../../routes/routeInterfaces';

const StyledModal = styled(Modal)`
  width: 100%;
  z-index: 10000000;
  & > div {
    width: 100%;
    align-items: center;
  }
  ${media.tablet`
  align-items: flex-end;
  & > div {
    width: 100%;
    align-items: flex-end;
  }
  `}
`;

const StyledContainer = styled(ModalContainer)`
  width: 100%;
  align-items: flex-start;
  padding: 24px;
  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    width: 100%;
    height: 100%;
    border-radius: 24px 24px 0 0;
    `}
  ${media.phone`
    padding: 16px;
    grid-template-columns: 1fr;
    grid-gap: 0;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > div {
    display: none;
  }
  ${media.tablet`
    grid-area: 1/span 2;
    & > div {
      display: flex;
    }   
  `}
  ${media.phone`
    grid-area: auto;  
  `}
`;

const DescriptionRowTitle = styled.div`
  width: 100%;
  display: flex;
`;

const StyledText = styled(Text)`
  margin-right: 11px;
  font-size: 18px;
`;

const StyledButton = styled(CommonButton)`
  width: 104px;
  ${media.tablet`
    grid-area: auto/2;
    width: 100%;
    height: 40px;
  `}
  ${media.phone`
    grid-area: auto;
  `}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  & > button:first-child {
    margin-right: 16px;
  }
  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-area: auto/span 2;
    grid-gap: 20px;
    margin-top: 0;
    justify-content: space-between;
    & > button:first-child {
    margin-right: 10px;
    }; 
  `}
  ${media.phone`
    display: grid;
    grid-area: auto;
    grid-gap: 10px;
    margin-top: 20px; 
  `}
`;

export interface AttachedPackageType {
  popupInfo: any;
  setPopupInfo: any;
  isOpen: boolean;
  pathParams: any;
  roleName: string;
  languageStrings?: any;
}

export const AttachedPackage: FC<AttachedPackageType> = ({
  popupInfo,
  setPopupInfo,
  pathParams,
  roleName,
  languageStrings,
}) => {
  const theme = useTheme();
  const customHistory = useCustomHistory();
  const { white, black } = theme.colors;

  const { packageName, order_id, deviceId, lastUpdate, component_index, production_id, package_id } = popupInfo.content;

  const inputFields = [
    {
      name: languageStrings ? languageStrings.labelPackageName : 'Package name',
      value: packageName,
    },
    {
      name: languageStrings ? languageStrings.labelOrderID : 'Order ID',
      value: order_id,
    },
    {
      name: languageStrings ? languageStrings.labeldeviceID : 'Device ID',
      value: deviceId,
    },
    {
      name: languageStrings ? languageStrings.labelLastUpdate : 'Last update',
      value: getRequiredDateFormat(lastUpdate, 'DD.MM.YYYY, HH:mm'),
    },
    {
      name: languageStrings ? languageStrings.labelComponentIndex : 'Component Index',
      value: component_index,
    },
    {
      name: languageStrings ? languageStrings.labelProductionID : 'Production ID',
      value: production_id,
    },
  ];

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setPopupInfo({ content: {}, isOpen: false });
    customHistory.replace(View.USER_INSIGHTS_DETAILS, {
      pathParams: { id: pathParams.id, name: 'noselection' },
    });
  };

  return (
    <StyledModal isOpenModal={popupInfo.isOpen}>
      <StyledContainer>
        <TitleWrapper>
          <DescriptionRowTitle>
            <StyledText type={TextType.TITLE_PRIMARY}>
              {languageStrings ? languageStrings.labelPackage : 'Package'}
            </StyledText>
          </DescriptionRowTitle>
          <CloseButtonRound closeOnClick={handleCloseModal} />
        </TitleWrapper>
        {inputFields.map((inputFieldData: any) => {
          const { name, value } = inputFieldData;
          return (
            <InputModal
              key={1}
              type={'text'}
              name={name}
              value={value}
              disabled={true}
              placeholder={''}
              onChange={() => {}}
            />
          );
        })}
        <ButtonWrapper isPackage={popupInfo?.content?.package}>
          <StyledButton
            onClick={() => {
              const to = View.MANAGE_ORDERS_DETAILS;
              to &&
                customHistory.push(to, {
                  state: { package_id },
                  pathParams: { id: order_id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
                });
            }}
            backgroundColor={white}
            color={black}
            width={'104px'}
          >
            {languageStrings ? languageStrings.packageButton : 'Package'}
          </StyledButton>

          <StyledButton
            onClick={() => {
              const { id, buildingId } = popupInfo.content;
              const to =
                roleName !== ContentType.UNKNOWN
                  ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER
                  : View.USER_MANAGE_DEVICES_DETAILS_NODE;
              to &&
                customHistory.push(to, {
                  pathParams: { id: id, buildingId: buildingId, offset: 0 },
                });
            }}
            width={'104px'}
          >
            {languageStrings ? languageStrings.deviceButton : 'Device'}
          </StyledButton>
        </ButtonWrapper>
      </StyledContainer>
    </StyledModal>
  );
};
