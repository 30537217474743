import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emptyAccess, selectAccess, selectLanguage, setAccess } from '../app/state/userSlice';

import { API_URL } from '../consts';
import { View } from '../routes/routeInterfaces';

import { HttpService } from '../utils/http';
import { useCustomHistory } from '../utils/react-router-dom-abstraction';

export const useUserAccess = () => {
  const globalAccess = useSelector(selectAccess);
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const [data, setData] = useState(globalAccess);
  const customHistory = useCustomHistory();
  //@TODO Alex test UseEffect()
  useEffect(() => {
    const url = `${API_URL}/v1/users/sectionsaccess`;

    (async () => {
      try {
        const responseData = (await HttpService.get(url)).data.payload;
        const { dashboard, insight, management, problems, settings } = responseData;
        const { client, user, devices } = management;
        const permission = dashboard || insight || problems || settings || client.view || user.view || devices.view;
        dispatch(setAccess({ ...responseData, permission }));
        setData({ ...responseData, permission });
        !permission && customHistory.push(View.ERROR_403);
      } catch (e) {
        dispatch(setAccess({ ...emptyAccess, permission: false }));
        setData({ ...emptyAccess, permission: false });
      }
    })();
  }, [dispatch, language]);
  //@NOTE don't use effect here since this hook is the only place allowed to edit section access
  /*useEffect(() => {
      setData(globalAccess);
    }, [globalAccess]);*/

  return data;
};
