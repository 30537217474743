/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for page number in pagination of universal table require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { ArrowButton } from '@bit/first-scope.arrow-button';
import { Text, TextType } from '@bit/first-scope.text';

import { ArrowButtonType } from '../../../../../components/arrowButton/ArrowButton';
import { media, mediaType } from '../../../../../styles/media';
import { GoToPagination } from '../goToPagination/GoToPagination';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledArrowButton = styled(ArrowButton)`
  margin: ${(props: any) => (props.type === ArrowButtonType.LEFT ? '0 18px 0 0' : '0 0 0 18px')};
  ${media.phone`
    margin: ${(props: any) => (props.type === ArrowButtonType.LEFT ? '0 8px 0 0' : '0 0 0 8px')};
  `}
`;

const NumberingWrapper = styled.div`
  display: flex;
`;

const NumberWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding: ${(props: any) => (props.isBigNumber ? '0 7px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.round};
  margin: 0 11px;

  cursor: ${(props: any) => (props.editInProcess ? 'auto' : 'pointer')};
  user-select: none;

  &.bigNumber {
    padding: 0 7px;
    background-color: ${(props: any) => props.theme.colors.secondary3};
    border-radius: 15px;
  }

  &:hover {
    background-color: ${(props: any) => props.theme.colors.secondary3};
    border-radius: ${(props: any) => (props.isBigNumber ? '15px' : props.theme.borderRadius.round)};
  }

  &:hover > span {
    color: primary;
  }

  &.selected {
    background-color: ${(props: any) => props.theme.colors.secondary3};
  }
  &.selected > span {
    color: primary;
  }

  ${media.phone`
    margin: 0 4px;
  `}
`;

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 11px;
  ${media.phone`
    margin: 0 6px;
  `}
`;

export interface PaginationNumberingProps {
  pagesNum: number;
  currentPage: number;
  onPageChange: any;
  editInProcess?: boolean;
  className?: string;
}

export const PaginationNumbering: FC<PaginationNumberingProps> = ({
  pagesNum,
  onPageChange,
  currentPage,
  editInProcess,
  ...props
}) => {
  // @NOTE middleArraySize has to be ALWAYS an odd number for logic of pagination to work
  const middleArraySize = mediaType.phone ? 1 : 3;
  const distanceToArrayCenter = (middleArraySize - 1) / 2;
  const endDelta = pagesNum - currentPage - 1;
  const cutNegative = (num: number) => (num >= 0 ? num : 0);
  const size = pagesNum >= middleArraySize ? middleArraySize : pagesNum;
  const backOffset = cutNegative(distanceToArrayCenter - endDelta);
  const offset = 1 + cutNegative(currentPage - backOffset - distanceToArrayCenter);
  const middleArray = [...(Array(size) as any).keys()].map((key) => key + offset);
  const theme = useTheme();
  const arrOfNum = Array.from(Array(pagesNum).keys());

  const handleLeftArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(-1, true);
  };
  const handleRightArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(1, true);
  };

  const handleItemClick = (value: number) => {
    onPageChange(value);
  };

  const ifStartNumber = currentPage > distanceToArrayCenter;
  const ifStartDots = currentPage > distanceToArrayCenter + 1 && arrOfNum.length > 5;
  const ifEndNumber = endDelta > distanceToArrayCenter;
  const ifEndDots = endDelta > distanceToArrayCenter + 1 && arrOfNum.length > 5;

  const getLength = (number: number) => {
    return number.toString().length;
  };

  return (
    <Wrapper {...props}>
      {currentPage > 0 && (
        <StyledArrowButton
          onClick={handleLeftArrowClick}
          color={theme.colors.primary}
          colorHover={theme.colors.secondary3}
          type={ArrowButtonType.LEFT}
        />
      )}

      <NumberingWrapper>
        {ifStartNumber && pagesNum !== 3 && (
          <NumberWrapper
            editInProcess={editInProcess}
            theme={theme}
            onClick={() => {
              handleItemClick(0);
            }}
            className={currentPage === 0 ? 'selected' : ''}
          >
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              {'1'}
            </Text>
          </NumberWrapper>
        )}
        {ifStartDots && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {middleArray.map((item) => {
          const lengthNumber = getLength(item);

          const index = item - 1;
          return (
            <NumberWrapper
              editInProcess={editInProcess}
              onClick={() => {
                handleItemClick(index);
              }}
              key={index}
              className={
                lengthNumber > 3 && currentPage === index ? 'bigNumber' : currentPage === index ? 'selected' : ''
              }
              isBigNumber={lengthNumber > 3}
            >
              <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
                {item}
              </Text>
            </NumberWrapper>
          );
        })}
        {ifEndDots && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {ifEndNumber && pagesNum !== 3 && (
          <NumberWrapper
            editInProcess={editInProcess}
            onClick={() => {
              handleItemClick(pagesNum - 1);
            }}
            className={
              getLength(pagesNum) > 3 && currentPage === pagesNum - 1
                ? 'bigNumber'
                : currentPage === pagesNum - 1
                ? 'selected'
                : ''
            }
            isBigNumber={getLength(pagesNum) > 3}
          >
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              {pagesNum}
            </Text>
          </NumberWrapper>
        )}
      </NumberingWrapper>

      {endDelta > 0 && (
        <StyledArrowButton
          onClick={handleRightArrowClick}
          color={theme.colors.primary}
          colorHover={theme.colors.secondary3}
          type={ArrowButtonType.RIGHT}
        />
      )}
      <GoToPagination handleGoToPage={handleItemClick} pagesCount={pagesNum} />
    </Wrapper>
  );
};
