import React, { useEffect, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CommentItem } from '../../../../../../../components/comments/components/CommentItem';
import { media } from '../../../../../../../styles/media';
import arrowUpCircleIconGray from '../../../../../../../assets/arrow-up-circle-colored.svg';
import arrowUpCircleIconBlue from '../../../../../../../assets/arrow_up_circle_blue.svg';
import commentPlaceholder from '../../../../../../../assets/comment-placeholder.svg';
import { TableCustomComponentState } from '../../../../../../../features/universalTable/types';
import { useTableContext } from '../../../../../../../features/universalTable/context';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';

const CommentContent = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
`;

const CommentHistory = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  padding: 0 4px 0px 12px;
  flex: 1;
  background-color: white;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  border: ${(props: any) => (props.state === TableCustomComponentState.EDIT ? '1px solid' : 'none')};
  border-color: gray3;
  background-color: gray7;
  ${media.tablet`
    padding: 0px 4px 0px 16px;
  `}
`;

const ContentHistory = styled.div`
  box-sizing: border-box;
  padding: 0 20px 0 0;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: gray7;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: 9px;
    width: 4px;
  }
  &::-webkit-scrollbar-button {
    height: 4px;
  }

  ${media.tablet`
    padding: 0 12px 0 0;
  `}
`;

const CommentsPlaceholder = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CommentsPlaceholderImage = styled.img`
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  margin-right: 4px;
`;

const CommentsPlaceholderText = styled.span`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: gray2;
`;

const WrapperComments = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: ${(props: any) => (props.isEdit ? 'column' : 'row')};
  align-items: ${(props: any) => (props.isEdit ? 'flex-start' : 'center')};
  background-color: gray4;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  padding: 8px 62px 8px 12px;
  position: relative;
`;

const CommentTextarea = styled(TextareaAutosize)`
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: ${(props: any) => (props.isEdit ? '72' : '112')}px;
  overflow: overlay;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  outline: transparent;
  resize: none;
  box-sizing: border-box;
  color: gray1;
  &::-webkit-input-placeholder {
    color: gray2;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: 9px;
    width: 4px;
    margin: 15px 0;
  }
  ${media.tablet`
    font-size: 16px;
  `}
`;

const SubmitButton = styled.button`
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: pointer;
`;

const SubmitImage = styled.img`
  width: 100%;
  height: 100%;
`;

export interface NewCommentType {
  remark: string;
  datetime: any;
}

export const Comments = ({
  setOrderPackageComment,
  idOrder,
  state,
  setValue,
  useDetails,
}: {
  setOrderPackageComment: any;
  idOrder: string;
  state?: TableCustomComponentState;
  setValue?: any;
  useDetails: any;
}) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);
  const [{ openId: idPackage }] = useTableContext();
  const [details] = useDetails(idOrder, idPackage);
  const { remarks } = details;
  const [comments, setComments] = useState<any[]>(remarks || []); //state for show comments from API in component
  const [newComment, setNewComment] = useState<NewCommentType>({
    remark: '',
    datetime: '',
  }); // state for submit new comment
  const image = newComment.remark !== '' ? arrowUpCircleIconBlue : arrowUpCircleIconGray;

  const handlerOnSetNewComment = (e: any) => {
    const comment = e.currentTarget.value;
    setNewComment((el: NewCommentType) => ({ ...el, remark: comment, datetime: new Date() }));
  };

  const submitNewComment = () => {
    setOrderPackageComment(newComment, idPackage);
    setComments(() => [...comments, newComment]);

    setNewComment((el: NewCommentType) => ({ ...el, remark: '', datetime: '' }));
  };

  useEffect(() => {
    setComments(remarks || []);
  }, [remarks]);

  return (
    <CommentContent>
      <CommentHistory theme={theme} state={state}>
        <ContentHistory>
          {comments.length ? (
            <>
              {comments.slice().map((el: any, i: number) => (
                <CommentItem
                  id={remarks ? remarks.length - 1 - i : el.datetime}
                  textComment={el.remark}
                  dateCom={el.datetime}
                  author={''}
                  updateDate={''}
                  className={i === comments.length - 1 ? 'last' : ''}
                />
              ))}
            </>
          ) : (
            <CommentsPlaceholder>
              <CommentsPlaceholderImage src={commentPlaceholder} alt={'message'} />
              <CommentsPlaceholderText>
                {language ? language.noCommentsYetPlaceholder : 'No comments yet'}
              </CommentsPlaceholderText>
            </CommentsPlaceholder>
          )}
        </ContentHistory>
      </CommentHistory>

      {state === TableCustomComponentState.EDIT && (
        <WrapperComments>
          <CommentTextarea
            value={newComment.remark}
            placeholder={language ? language.writeCommentPlaceholder : 'Write a comment...'}
            onChange={(e: any) => handlerOnSetNewComment(e)}
          />
          <SubmitButton onClick={submitNewComment} disabled={newComment.remark === ''}>
            <SubmitImage src={image} alt="submit" />
          </SubmitButton>
        </WrapperComments>
      )}
    </CommentContent>
  );
};
