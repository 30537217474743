import { useEffect, useState } from 'react';
import { API_URL } from '../consts';
import { callFakeApi } from '../features/apiBuilder/fakeApi';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { HttpService } from '../utils/http';

export const useManageDeviceDetails = (
  isReloadRequestAPI: boolean,
  setIsReloadRequestAPI: any,
  id: string,
  setPlaceholder?: any,
  stopPlaceholder?: any,
) => {
  const [localDeviceDetails, setLocalDeviceDetails] = useState();
  const trigger = [id] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/devices/deviceinfo/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
        setLocalDeviceDetails(responseData);
        setIsReloadRequestAPI(false);
      } catch (e) {
        setIsReloadRequestAPI(false);
        //
      }
    })();
  }, [isReloadRequestAPI]);

  return localDeviceDetails;
};

export const useDeviceDetailsOrders = () => {
  const [localOrderList, setLocalOrderList] = useState();
  useEffect(() => {
    const url = `${API_URL}/v1/orders/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setLocalOrderList(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);

  return localOrderList;
};

export const useOrderList = () => {
  const [listOrder, setListOrder] = useState([]);
  useEffect(() => {
    const url = `${API_URL}/v1/orders/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setListOrder(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);
  return listOrder;
};

export const getDeviceDetailsComponents = (id: any, setComponents: any, setStages: any, setProcess: any) => {
  const url = `${API_URL}/v1/order/details/${id}/package/`;
  (async () => {
    try {
      let responseData = (await HttpService.get(url)).data.payload.available;
      setComponents(responseData.components);
      setProcess(responseData.status);

      return responseData;
    } catch (e) {
      //
    }
  })();
};
// attachToTracker & deAttachToTracker  not used anywhere
export const attachToTracker = (id: string, data: any) => {
  const url = `${API_URL}/v1/order/details/${id}/package/addpackage/`;
  (async () => {
    try {
      const responseData = (await HttpService.post(url, data)).data.payload;
    } catch (e) {
      //
    }
  })();
};

export const deAttachToTracker = (id: string) => {
  const url = `${API_URL}/v1/devices/deviceinfo/${id}/detach/`;
  (async () => {
    try {
      await HttpService.patch(url, id);
    } catch (e) {
      //
    }
  })();
};

export const deAttachDevice = (idDevice: string, handleAddNotification: any, setIsLoader: any) => {
  const url = `${API_URL}/v1/devices/deviceinfo/${idDevice}/detach/`;
  (async () => {
    try {
      await HttpService.patch(url, {});
      setIsLoader(false);
      handleAddNotification({
        message: `Successfully detached`,
        type: 'success',
      });
    } catch (e) {
      setIsLoader(false);
      handleAddNotification({
        message: `An unexpected error has occurred. Please, try again`,
        type: 'error',
      });
      //
    }
  })();
};

// @TODO Liza use this api

const getFakeInventoryAttachResponse = (data: any) => ({
  id: 'inventory_id',
  name: 'inventory_name',
  ...data,
});

// data is the param u need
export const attachToFakeInventory = (data: any) =>
  callFakeApi({
    result: getFakeInventoryAttachResponse,
    options: data,
    callback: (response) => {},
  });
attachToFakeInventory({ third_key: 'test' });

export const getPackageList = (
  orderId: string,
  setPackages: any,
  setIsLoaderPackageModal: any,
  addNotification: any,
) => {
  const url = `${API_URL}/v1/order/details/${orderId}/packages`;
  (async () => {
    try {
      const responseData = (await HttpService.get(url, {})).data.payload;
      const result = responseData.filter((onePackage: any) => !onePackage?.tracker);
      setIsLoaderPackageModal(false);
      !result.length &&
        addNotification({
          message: `There are no free packages. Please choose another order.`,
          type: 'error',
        });
      setPackages(result);
    } catch (e) {
      setIsLoaderPackageModal(false);
      //
    }
  })();
};
