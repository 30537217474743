/**
 * @description
 * This is component the covered functionality for popupInfoModal for floorPlan desktop.
 *
 */

import React, { FC, useCallback, useEffect, useRef } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { View } from '../../../../routes/routeInterfaces';
import { useCustomHistory } from '../../../../utils/react-router-dom-abstraction';
import { Tracker } from './components/tracker/Tracker';
import { ContentType } from '../../../../components/insightBadges/InsightBadges';
import { DetachedModal } from './components/detachedModal/DetachedModal';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';

export const PopupBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow1};
  position: absolute;
  top: 15px;
  left: 15px;
  transition: all 2s linear;
`;

export interface PopupFloorType {
  setPopupInfo: (val: any) => void;
  popupInfo: any;
  pathParams: any;
}

export const PopupFloor: FC<PopupFloorType> = ({ setPopupInfo, popupInfo, pathParams }) => {
  const theme = useTheme();
  const customHistory = useCustomHistory();
  const modalRef: any = useRef<any>(null);

  const languageStrings = useSelector(selectLanguageStrings);

  const {
    name,
    packageName,
    package_id,
    deviceId,
    lastUpdate,
    order_name,
    component_index,
    production_id,
    inventory_id,
    inventoryName,
    inventoryType,
  } = popupInfo.content;

  const roleName = inventory_id
    ? ContentType.TAG_INVENTORY
    : package_id
    ? ContentType.TAG_PACKAGE
    : ContentType.UNKNOWN;

  const setTracker = useCallback(
    (type: any) => {
      const tracker: any = {
        [ContentType.TAG_PACKAGE]: {
          items: [
            {
              values: [
                {
                  label: languageStrings ? languageStrings.labelPackageName : 'Package name',
                  value: packageName ?? 'N/A',
                },
                { label: languageStrings ? languageStrings.labelOrderID : 'Order ID', value: order_name ?? 'N/A' },
              ],
              blurred: false,
              hidden: false,
            },
            {
              values: [
                { label: languageStrings ? languageStrings.labeldeviceID : 'Device ID', value: deviceId ?? 'N/A' },
                {
                  label: languageStrings ? languageStrings.labelLastUpdate : 'Last update',
                  value: lastUpdate ? getRequiredDateFormat(lastUpdate, 'DD.MM.YYYY, HH:mm') : 'N/A',
                },
              ],
              blurred: false,
              hidden: false,
            },
            {
              values: [
                {
                  label: languageStrings ? languageStrings.labelComponentIndex : 'Component Index',
                  value: component_index ?? 'N/A',
                },
                {
                  label: languageStrings ? languageStrings.labelProductionID : 'Production ID',
                  value: production_id ?? 'N/A',
                },
              ],
              blurred: true,
              hidden: true,
            },
          ],
        },
        [ContentType.TAG_INVENTORY]: {
          items: [
            {
              values: [
                {
                  label: languageStrings ? languageStrings.labelInventoryName : 'Inventory Name',
                  value: inventoryName ?? 'N/A',
                },
                {
                  label: languageStrings ? languageStrings.labelInventoryType : 'Inventory type',
                  value: inventoryType ?? 'N/A',
                },
              ],
              blurred: true,
              hidden: false,
            },
            {
              values: [
                { label: languageStrings ? languageStrings.labeldeviceID : 'Device ID', value: deviceId ?? 'N/A' },
                {
                  label: languageStrings ? languageStrings.labelLastUpdate : 'Last update',
                  value: lastUpdate ? getRequiredDateFormat(lastUpdate, 'DD.MM.YYYY, HH:mm') : 'N/A',
                },
              ],
              blurred: true,
              hidden: false,
            },
          ],
        },
        [ContentType.UNKNOWN]: {
          items: [
            {
              values: [
                { label: languageStrings ? languageStrings.labeldeviceID : 'Device ID', value: deviceId ?? 'N/A' },
                {
                  label: languageStrings ? languageStrings.labelLastUpdate : 'Last update',
                  value: lastUpdate ? getRequiredDateFormat(lastUpdate, 'DD.MM.YYYY, HH:mm') : 'N/A',
                },
              ],
              blurred: true,
              hidden: false,
            },
          ],
        },
      };
      return tracker[type];
    },
    [component_index, deviceId, inventoryName, inventoryType, lastUpdate, order_name, packageName, production_id],
  );

  const outsideHandler = useCallback(
    (e: any) => {
      if (popupInfo?.isOpen && !modalRef?.current?.contains(e.target)) {
        setPopupInfo({ content: {}, isOpen: false });
        customHistory.replace(View.USER_INSIGHTS_DETAILS, {
          pathParams: { id: pathParams.id, name: 'noselection' },
        });
      }
    },
    [popupInfo.isOpen],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  return (
    <>
      {name && (
        <PopupBlock ref={modalRef} theme={theme} isLazy={roleName === 'UNKNOWN'}>
          {roleName === ContentType.UNKNOWN ? (
            <DetachedModal popupInfo={popupInfo} infoArr={setTracker(roleName)} languageStrings={languageStrings} />
          ) : (
            <Tracker
              popupInfo={popupInfo}
              infoArr={setTracker(roleName)}
              roleName={roleName}
              languageStrings={languageStrings}
            />
          )}
        </PopupBlock>
      )}
    </>
  );
};
