import { DashboardItemIconType, DashboardItemState, DashboardItemType } from '../enums/Dashboard';

export const defaultData = {
  upper_section: [
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
  ],
  bottom_section: [],
};

export const overallData = {
  upper_section: [
    {
      tile_type: DashboardItemType.COMPLEX_CARD,
      data: {
        title: 'Total devices',
        first_item: { title: 'Nodes', value: 22 },
        second_item: { title: 'Trackers', value: 21 },
        icon_type: DashboardItemIconType.DEVICE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Offline',
        value: 10,
        icon_type: DashboardItemIconType.OFFLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Site Name',
        index: '1',
        value: 25,
        icon_type: DashboardItemIconType.SITE,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Site Name',
        index: '2',
        value: 12,
        icon_type: DashboardItemIconType.SITE,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Site Name',
        index: '3',
        value: 25,
        icon_type: DashboardItemIconType.SITE,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Production Zone',
        index: 'A',
        value: 25,
        icon_type: DashboardItemIconType.PRODUCTION,
        state: DashboardItemState.WARNING,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse PZ',
        index: 'B',
        value: 12,
        icon_type: DashboardItemIconType.WAREHOUSE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse SHIPPING',
        index: 'C',
        value: 25,
        icon_type: DashboardItemIconType.SHIPPING,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Mostostal Galvanising Zone',
        index: 'D',
        value: 25,
        icon_type: DashboardItemIconType.PRODUCTION,
        state: DashboardItemState.SUCCESS,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse GALVA',
        index: 'E',
        value: 9,
        icon_type: DashboardItemIconType.WAREHOUSE,
        state: DashboardItemState.CRITICAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Loading Zone',
        index: 'F',
        value: 18,
        icon_type: DashboardItemIconType.LOADING,
        state: DashboardItemState.SUCCESS,
      },
      width: 1,
    },
  ],
  bottom_section: [
    {
      tile_type: DashboardItemType.DISTRIBUTION_GRAPH,
      data: {
        title: 'Devices destribution per Sites',
        graph_data: [
          { name: 'Site 1 Name', value: 15 },
          { name: 'Site 2 Name', value: 10 },
          { name: 'Site 3 Name', value: 0 },
        ],
      },
      width: 2,
    },
    {
      tile_type: DashboardItemType.DISTRIBUTION_GRAPH,
      data: {
        title: 'Devices destribution per Zones',
        graph_data: [
          { name: 'Production', value: 15 },
          { name: 'Warehouse PZ', value: 10 },
          { name: 'Warehouse SHIPPING', value: 0 },
          { name: 'Galvanizing', value: 0 },
          { name: 'Warehouse Galva', value: 0 },
          { name: 'Loading Zone', value: 11 },
        ],
      },
      width: 2,
    },
  ],
};

export const ordersData = {
  upper_section: [
    {
      tile_type: DashboardItemType.COMPLEX_CARD,
      data: {
        title: 'Total Orders',
        first_item: { title: 'Completed', value: 22 },
        second_item: { title: 'Ongoing', value: 21 },
        icon_type: DashboardItemIconType.ORDER,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Total Packages',
        value: 12,
        icon_type: DashboardItemIconType.PACKAGE,
        state: DashboardItemState.NORMAL,
      },
      width: 2,
    },

    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Production Zone',
        index: 'A',
        value: 25,
        icon_type: DashboardItemIconType.PRODUCTION,
        state: DashboardItemState.WARNING,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse PZ',
        index: 'B',
        value: 12,
        icon_type: DashboardItemIconType.WAREHOUSE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse SHIPPING',
        index: 'C',
        value: 25,
        icon_type: DashboardItemIconType.SHIPPING,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Mostostal Galvanising Zone',
        index: 'D',
        value: 25,
        icon_type: DashboardItemIconType.PRODUCTION,
        state: DashboardItemState.SUCCESS,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse GALVA',
        index: 'E',
        value: 9,
        icon_type: DashboardItemIconType.WAREHOUSE,
        state: DashboardItemState.CRITICAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Loading Zone',
        index: 'F',
        value: 18,
        icon_type: DashboardItemIconType.LOADING,
        state: DashboardItemState.SUCCESS,
      },
      width: 1,
    },
  ],
  bottom_section: [
    {
      tile_type: DashboardItemType.CAPACITY_GRAPH,
      data: {
        title: 'Zones Capacity',
        graph_data: [
          { name: 'A', value: 15 },
          { name: 'B', value: 10 },
          { name: 'C', value: 0 },
          { name: 'D', value: 11 },
          { name: 'E', value: 10 },
          { name: 'F', value: 4 },
          { name: 'Overall', value: 12 },
        ],
      },
      width: 2,
    },
  ],
};

export const inventoriesData = {
  upper_section: [
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Type A',
        value: 22,
        icon_type: DashboardItemIconType.INVENTORY,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Type B',
        value: 12,
        icon_type: DashboardItemIconType.INVENTORY,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Type C',
        value: 10,
        icon_type: DashboardItemIconType.INVENTORY,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Production Zone',
        index: 'A',
        value: 25,
        icon_type: DashboardItemIconType.PRODUCTION,
        state: DashboardItemState.WARNING,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse PZ',
        index: 'B',
        value: 12,
        icon_type: DashboardItemIconType.WAREHOUSE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse SHIPPING',
        index: 'C',
        value: 25,
        icon_type: DashboardItemIconType.SHIPPING,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Mostostal Galvanising Zone',
        index: 'D',
        value: 25,
        icon_type: DashboardItemIconType.PRODUCTION,
        state: DashboardItemState.SUCCESS,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Warehouse GALVA',
        index: 'E',
        value: 9,
        icon_type: DashboardItemIconType.WAREHOUSE,
        state: DashboardItemState.CRITICAL,
      },
      width: 1,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Valmont Loading Zone',
        index: 'F',
        value: 18,
        icon_type: DashboardItemIconType.LOADING,
        state: DashboardItemState.SUCCESS,
      },
      width: 1,
    },
  ],
  bottom_section: [
    {
      tile_type: DashboardItemType.DISTRIBUTION_GRAPH,
      data: {
        title: 'Devices of Type A distribution per Zones',
        graph_data: [
          { name: 'Production', value: 15 },
          { name: 'Warehouse PZ', value: 10 },
          { name: 'Warehouse SHIPPING', value: 0 },
          { name: 'Galvanizing', value: 0 },
          { name: 'Warehouse Galva', value: 0 },
          { name: 'Loading Zone', value: 11 },
        ],
      },
      width: 2,
    },
    {
      tile_type: DashboardItemType.DISTRIBUTION_GRAPH,
      data: {
        title: 'Devices of Type B distribution per Zones',
        graph_data: [
          { name: 'Production', value: 15 },
          { name: 'Warehouse PZ', value: 10 },
          { name: 'Warehouse SHIPPING', value: 0 },
          { name: 'Galvanizing', value: 0 },
          { name: 'Warehouse Galva', value: 0 },
          { name: 'Loading Zone', value: 11 },
        ],
      },
      width: 2,
    },
    {
      tile_type: DashboardItemType.DISTRIBUTION_GRAPH,
      data: {
        title: 'Devices of Type C distribution per Zones',
        graph_data: [
          { name: 'Production', value: 15 },
          { name: 'Warehouse PZ', value: 10 },
          { name: 'Warehouse SHIPPING', value: 0 },
          { name: 'Galvanizing', value: 0 },
          { name: 'Warehouse Galva', value: 0 },
          { name: 'Loading Zone', value: 11 },
        ],
      },
      width: 2,
    },
  ],
};
