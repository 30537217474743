import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CommonButton } from '@bit/first-scope.common-button';
import { ErrorPageType } from '../../ErrorPage';
import { media } from '../../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 326px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  ${media.phone`
    justify-content: ${(props: any) =>
      props.type === ErrorPageType.ERROR_500 || props.type === ErrorPageType.ERROR_403 ? 'space-between' : 'center'}
  `}
`;

export const Buttons = ({
  type,
  toMainOnClick,
  reloadOnClick,
  logoutOnClick,
}: {
  type: ErrorPageType;
  toMainOnClick: () => void;
  reloadOnClick: () => void;
  logoutOnClick: (e: any) => void;
}) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);
  const { primary, secondary2, white, black } = theme.colors;

  if (type === ErrorPageType.ERROR_401 || type === ErrorPageType.ERROR_404) {
    return (
      <Wrapper type={type}>
        <CommonButton
          width={'157px'}
          height={'40px'}
          onClick={toMainOnClick}
          backgroundColor={primary}
          backgroundColorHover={secondary2}
          borderColor={primary}
          borderColorHover={secondary2}
          color={white}
        >
          {language ? language.mainPageButton : 'Main Page'}
        </CommonButton>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper type={type}>
        <CommonButton
          width={'157px'}
          height={'40px'}
          onClick={type === ErrorPageType.ERROR_403 ? logoutOnClick : reloadOnClick}
          backgroundColor={primary}
          backgroundColorHover={secondary2}
          borderColor={primary}
          borderColorHover={secondary2}
          color={white}
        >
          {type === ErrorPageType.ERROR_403
            ? language
              ? language.logoutButton
              : 'Log Out'
            : language
            ? language.reloadButton
            : 'Reload'}
        </CommonButton>
        <CommonButton
          width={'157px'}
          height={'40px'}
          onClick={toMainOnClick}
          backgroundColor={white}
          backgroundColorHover={primary}
          borderColor={primary}
          borderColorHover={primary}
          color={black}
          hoverColor={white}
        >
          {language ? language.mainPageButton : 'Main Page'}
        </CommonButton>
      </Wrapper>
    );
  }
};
