/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for Processes page
 * Highly customisable.
 *
 */

// @ts-ignore
import styled from '@xstyled/styled-components';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { deleteProcessesGlobal, updateProcess, useStagesList } from '../../../api/manageProcesses';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { API_URL } from '../../../consts';
import { useLoaderFunctions } from '../../../features/placeholderComponent/loader.context';
import { useTableFunctions } from '../../../features/universalTable/context';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { View } from '../../../routes/routeInterfaces';
import { media } from '../../../styles/media';
import { useMediaType } from '../../../styles/style.context';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { MetaDataComponent } from './components/metaDataComponent/MetaDataComponent';
import { StagesComponent } from './components/stagesComponent/StagesComponent';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;
export interface ProcessesProps {
  match: any;
  className?: string;
}

export const Processes: FC<ProcessesProps> = ({ match, ...props }) => {
  const { startAddProcess, setSearchValue, getSearchValue, resetTable } = useTableFunctions();

  const { actual: searchValue } = getSearchValue();

  const mediaType = useMediaType();

  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();

  const keyword = 'ProcessesTable';
  const offsetKey = 'offset';

  const [dataStages] = useStagesList(
    (isPlaceholder: boolean) => appendKeywordList([keyword], isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList([keyword], isPlaceholder),
  );
  const offset = match.params[offsetKey];
  const { state }: any = useLocation();
  const accessData = useSelector(selectAccess).management.processes;

  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper {...props}>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfProcessesPage : 'Processes'}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        searchInputValue={searchValue}
        addButtonOnClick={startAddProcess}
        handleChangeSearchInputValue={setSearchValue}
        accessData={accessData}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_PROCESSES}
        mainApiUrl={`${API_URL}/v1/processes/`}
        keyword={keyword}
        additionalKeywords={['ManageLayoutSearch']}
        modalColumnCount={3}
        updateItems={updateProcess}
        deleteItems={deleteProcessesGlobal}
        allowDelete={accessData.delete}
        dynamicFlags={[dataStages && !!dataStages.length]}
        idItemForViewModal={state?.state?.id}
        actionSet={[
          {
            type: ActionType.EDIT,
          },
        ]}
        inlineActionSet={[
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        accessData={accessData}
        pageSize={mediaType.phone ? 6 : 18}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter name',
            label: languageStrings ? languageStrings.labelName : 'Name',
            defaultValue: '',
          },
          {
            fieldName: 'description',
            placeholder: languageStrings ? languageStrings.enterDescriptionPlaceholder : 'Enter description',
            label: languageStrings ? languageStrings.labelDescription : 'Description',
            modalColumns: 2,
            defaultValue: '',
          },
          {
            fieldName: 'stages',
            placeholder: languageStrings ? languageStrings.selectStagePlaceholder : 'Select stage',
            label: languageStrings ? languageStrings.labelStagesInSequence : 'Stages in sequence',
            defaultValue: [],
            type: RowItemType.CUSTOM,
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
              <StagesComponent value={value} state={state} setValue={setValue} dataStages={dataStages} />
            ),
          },
          {
            fieldName: 'metadata',
            placeholder: '',
            label: languageStrings ? languageStrings.labelMetadata : 'Metadata',
            defaultValue: {},
            modalColumns: 2,
            type: RowItemType.CUSTOM,
            excludedFromView: true,
            // hide title component for View_Modal in mobile version
            excludedTitleFromMobileViewEmptyData: true,
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
              <MetaDataComponent value={value} state={state} setValue={setValue} />
            ),
          },
        ]}
      />
    </Wrapper>
  );
};
