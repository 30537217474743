/**
 * @description
 * This is component that covers
 * the container for modal component.
 *
 */

import React, { FC, ReactNode } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../styles/media';

const Wrapper = styled.div`
  max-width: ${(props: any) => (props.width ? props.width : 'auto')};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.white)};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow1};
  border-radius: ${(props: any) => props.theme.borderRadius.additional16};
  position: relative;
  padding: ${(props: any) => (props.padding ? props.padding : '24px 14px')};
  ${media.tablet`
    max-height: 650px;
    max-width: 100%;
    border-radius: ${(props: any) => {
      const { additional16 } = props.theme.borderRadius;
      return `${additional16} ${additional16} 0 0`;
    }};
      padding: 24px 16px 30px 16px;
  `}
  ${media.phone`
    max-height: 94%;
  `}
`;

export interface ModalContainerProps {
  children: ReactNode;
  backgroundColor?: string;
  padding?: string;
  width?: string;
  className?: string;
}

export const ModalContainer: FC<ModalContainerProps> = ({ children, backgroundColor, padding, width, ...props }) => {
  const theme = useTheme();
  return (
    <Wrapper width={width} backgroundColor={backgroundColor} theme={theme} {...props} padding={padding}>
      {children}
    </Wrapper>
  );
};

ModalContainer.defaultProps = {
  width: '480px',
};
