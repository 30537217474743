/**
 * @description
 * component for common use
 * used to switch filter
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../styles/media';
import { SwipeSwitch } from '../swipeSwitch/SwipeSwitch';

const ZonesText = styled(Text)`
  font-weight: 500;
  color: ${(props: any) => (props.isActive ? props.gray1 : props.gray2)};
`;
const SwipeSwitchWrapper = styled.div`
  max-width: ${(props: any) => (props.maxWidth ? props.maxWidth : '185px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border: 1px solid;
  border-color: gray3;
  width: 100%;
  height: 31px;
  box-sizing: border-box;
  background-color: ${(props: any) => (props.isActive ? 'white' : 'transparent')};
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
  ${media.desktop`
    margin-top: 10px;
  `}
`;

/* switch to enable zones */
export const SwipeSwitchFilter = ({
  isActive,
  name,
  handleStateSwitch,
  disabled,
  maxWidth,
}: {
  isActive: boolean;
  name: string;
  handleStateSwitch: () => void;
  disabled?: boolean;
  maxWidth?: string;
}) => {
  const theme = useTheme();
  const { primary, gray3, gray2, gray1 } = theme.colors;

  return (
    <SwipeSwitchWrapper isActive={isActive} max-width={maxWidth}>
      <ZonesText type={TextType.TEXT_12_BLACK} gray1={gray1} gray2={gray2} isActive={isActive}>
        {name}
      </ZonesText>
      <SwipeSwitch
        stateSwitch={isActive}
        handleStateSwitch={handleStateSwitch}
        height={20}
        width={35}
        onColor={primary}
        offColor={gray3}
        disabled={disabled}
      />
    </SwipeSwitchWrapper>
  );
};
