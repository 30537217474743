/**
 * @description
 * This is component that display levels
 * and give possibility necessary level on ZonesDetails and CampusMapInsight pages
 */
import React, { useCallback, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as ArrowIcon } from '../../assets/small_arrow.svg';
import { useEffect } from 'react';
import { media } from '../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const Wrapper = styled.div`
  width: ${(props: any) => (props.width ? props.width : '210px')};
  height: 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  border: 1px solid;
  border-color: gray3;
  position: relative;
  margin-left: 20px;
  cursor: pointer;
  ${media.tablet`
    display: ${(props: any) => (props.isShown ? 'flex' : 'none')};
  `}
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;
const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Arrow = styled(ArrowIcon)`
  transform: ${(props: any) => props.isOpenModal && 'rotate(-180deg)'};
  transition: all 0.3s ease-in-out;
  margin-left: 12px;
`;
const PopupWrapper = styled.div`
  width: calc(100% + 2px);
  height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  border: 1px solid;
  border-color: gray3;
  padding: 10px 12px;
  position: absolute;
  top: 46px;
  left: -1;
  z-index: 1;
  display: ${(props: any) => (props.isOpenModal ? 'flex' : 'none')};
  transition: all 0.2s ease-in-out;
`;
const LevelItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > span:last-child {
    width: 77px;
  }
`;
const ElementsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const MockContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 12px;
`;
const NoSelectText = styled(Text)`
  user-select: none;
`;

export const SelectLevel = ({
  currentLevel,
  setCurrentLevel,
  countOfLevels,
  countOfElementsOnTheFloor,
  width,
  levelArr,
  nameOfElements,
  isShown,
  ...props
}: {
  currentLevel: number;
  setCurrentLevel: React.Dispatch<React.SetStateAction<number>>;
  countOfElementsOnTheFloor: number;
  countOfLevels: number;
  levelArr: any[];
  nameOfElements: string;
  width?: string;
  isShown?: boolean;
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const theme = useTheme();
  const { black } = theme.colors;
  const language = useSelector(selectLanguageStrings);
  const levelRef = useRef<any>(null);

  const handleSelectOnClick = () => {
    isOpenModal ? setIsOpenModal(false) : setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const handleOutsideHandler = useCallback(
    (e: any) => {
      if (isOpenModal && !(levelRef && levelRef.current && (levelRef as any).current.contains(e.target))) {
        closeModal();
      }
    },
    [isOpenModal],
  );
  const handleChooseLevel = (e: any, index: number) => {
    e.stopPropagation();
    setCurrentLevel(index);
    closeModal();
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideHandler);
    return () => {
      window.removeEventListener('click', handleOutsideHandler);
    };
  }, [handleOutsideHandler]);

  return (
    <Wrapper width={width} ref={levelRef} isShown={isShown} {...props}>
      <Container onClick={handleSelectOnClick}>
        <NoSelectText type={TextType.TEXT_14_BLACK} color={black}>
          {language ? language.labelLevel : 'Level'}{' '}
          {levelArr && levelArr[currentLevel]
            ? levelArr[currentLevel].floor_level
            : language
            ? language.labelNA
            : 'N/A'}
        </NoSelectText>
        <ArrowWrapper>
          <NoSelectText type={TextType.TEXT_12_GRAY}>
            {countOfElementsOnTheFloor}{' '}
            {nameOfElements.length < 8
              ? countOfElementsOnTheFloor > 1
                ? `${nameOfElements}s`
                : `${nameOfElements}`
              : ''}
          </NoSelectText>
          <Arrow isOpenModal={isOpenModal} />
        </ArrowWrapper>
      </Container>
      <PopupWrapper countOfLevels={countOfLevels} isOpenModal={isOpenModal}>
        {levelArr.map((item: any, index: number) => {
          const { floor_level } = item;
          return (
            <LevelItem
              key={index}
              onClick={
                index !== currentLevel
                  ? (e: any) => {
                      handleChooseLevel(e, floor_level);
                    }
                  : () => {}
              }
            >
              <NoSelectText type={TextType.TEXT_14_BLACK} color={black}>
                {language ? language.labelLevel : 'Level'} {floor_level}
              </NoSelectText>
              <ElementsWrapper>
                <NoSelectText type={TextType.TEXT_12_GRAY}>
                  {countOfElementsOnTheFloor}{' '}
                  {nameOfElements.length < 8
                    ? countOfElementsOnTheFloor > 1
                      ? `${nameOfElements}s`
                      : `${nameOfElements}`
                    : ''}
                </NoSelectText>
                <MockContainer />
              </ElementsWrapper>
            </LevelItem>
          );
        })}
      </PopupWrapper>
    </Wrapper>
  );
};
