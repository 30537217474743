/**
 * @description
 * This is component that is used for getting description in DescriptionPopup
 */

import React, { useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const Container = styled.div`
  margin: 4px 0 0 0;
`;
const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.isEditingInProcess
      ? props.theme.colors.primary
      : props.isError
      ? props.theme.colors.red3
      : props.theme.colors.gray3};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  background-color: white;
  color: gray1;
  transition: all 0.3s linear;
`;

const TextField = styled(TextareaAutosize)`
  width: 100%;
  box-sizing: border-box;
  background-color: inherit;
  color: inherit;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  padding: 0 6px 0 6px;
  margin: 6px;
  outline: none;
  resize: none;
  border: none;
  &::-webkit-scrollbar {
    width: 3px;
    height: 100%;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
`;

export const Textarea = ({
  value,
  onChange,
  isEdit,
  isError,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  isEdit?: boolean;
  isError?: boolean;
  className?: string;
}) => {
  const language = useSelector(selectLanguageStrings);
  const [isEditingInProcess, setIsEditingInProcess] = useState(false);
  const theme = useTheme();
  const { red3 } = theme.colors;
  return (
    <Container {...props}>
      <Wrapper
        className={isEdit ? 'active' : 'disabled'}
        theme={theme}
        isEditingInProcess={isEditingInProcess}
        isError={isError}
      >
        <TextField
          value={value}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          onFocus={() => {
            setIsEditingInProcess(true);
          }}
          onBlur={() => {
            setIsEditingInProcess(false);
          }}
          placeholder={language ? language.enterDescriptionPlaceholder : 'Enter description'}
          rowsMax={3}
          spellCheck={false}
          disabled={!isEdit}
        />
      </Wrapper>
      {isError && (
        <Text type={TextType.TEXT_12_BLACK} margin={'4px 0 0 12px'} color={red3} weight={'400'}>
          {language ? language.textareaDescriptionErrorText : `Description doesn't look right`}
        </Text>
      )}
    </Container>
  );
};
