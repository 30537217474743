import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Loader as LoaderComponent, LoaderType } from '../../features/loader/Loader';

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

export const Loader = ({ isLoader }: { isLoader: boolean }) => (
  <>
    {isLoader && (
      <LoaderWrapper>
        <LoaderComponent type={LoaderType.CLASSIC} />
      </LoaderWrapper>
    )}
  </>
);
