/**
 * @description
 * This is component for popup choose parameter in select
 * all the functionalities cthings require for ChooseParameterSelectPopup
 * Highly customisable.
 *
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CheckBox } from '@bit/first-scope.controls';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchInput } from '../searchInput/SearchInput';
import { ReactComponent as NodeIndicator } from '../../../../assets/NodeIndicator.svg';
import { ReactComponent as TrackerIndicator } from '../../../../assets/TrackerIndicator.svg';
import { ReactComponent as OfflineIndicator } from '../../../../assets/OfflineIndicator.svg';
import { TypeOfDeviceParameter } from '../../../../containers/common/campusMapInsight/Content/Content';
const Wrapper = styled.div`
  max-width: ${(props: any) => (props.maxWidthSelectPopup ? props.maxWidthSelectPopup : '160px')};
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid;
  border-color: gray3;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow4};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  position: absolute;
  top: ${(props: any) => `${props.fieldHeight + 8}px`};
  left: 0;
  z-index: 2;
  cursor: auto;
`;
const ParameterListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 4px 0 12px;
`;
const ParameterList = styled.ul`
  max-height: 180px;
  margin: 0;
  padding: 0;
  overflow-y: ${(props: any) => (props.heightOfList > 220 ? 'scroll' : 'auto')};
  &::-webkit-scrollbar {
    width: 4px;
    background: gray4;
    opacity: 1;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

const ParameterItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${(props: any) => (props.isLast ? '12px' : '10px')};
  & > div {
    margin-right: 8px;
  }
  & > svg {
    margin-left: 4px;
  }
`;

export const ChooseParameterSelectPopup = ({
  popupRef,
  parameterList,
  handleCheckedItem,
  handleSelectAll,
  fieldHeight,
  isSelectAll,
  isSearchActive,
  maxWidthSelectPopup,
  language,
}: {
  popupRef: React.MutableRefObject<any>;
  parameterList: any[];
  handleCheckedItem: (type?: TypeOfDeviceParameter, id?: string) => void;
  handleSelectAll: () => void;
  fieldHeight: number;
  isSelectAll?: boolean;
  isSearchActive?: boolean;
  maxWidthSelectPopup?: string;
  language?: any;
}) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const { gray1, gray2, white, primary } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [heightOfList, setHeightOfList] = useState(0);

  const handleInputOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };
  const handleClearInput = () => {
    setSearchInputValue('');
  };

  const filterParameterList = (flag: string) => {
    if (parameterList && parameterList.length > 0) {
      const filteredValue: any = parameterList.filter((item: any) => {
        return item?.name?.toLowerCase().indexOf(flag.toLowerCase()) !== -1;
      });
      return filteredValue;
    } else {
      return [];
    }
  };

  const getIndicator = useCallback((type: TypeOfDeviceParameter) => {
    const getIndicator: any = {
      [TypeOfDeviceParameter.NODE]: <NodeIndicator />,
      [TypeOfDeviceParameter.TRACKER]: <TrackerIndicator />,
      [TypeOfDeviceParameter.OFFLINE]: <OfflineIndicator />,
    };
    return getIndicator[type];
  }, []);

  useEffect(() => {
    listRef && listRef.current && setHeightOfList(listRef.current.clientHeight);
  }, []);

  return (
    <Wrapper
      theme={theme}
      fieldHeight={fieldHeight}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      maxWidthSelectPopup={maxWidthSelectPopup}
    >
      {isSearchActive && (
        <SearchInput
          value={searchInputValue}
          handleOnChange={handleInputOnChange}
          handleClearInput={handleClearInput}
        />
      )}
      <ParameterListWrapper ref={popupRef}>
        <ParameterList ref={listRef} heightOfList={heightOfList}>
          {handleSelectAll && (
            <ParameterItem>
              <CheckBox
                label={''}
                color={gray2}
                backgroundColor={white}
                backgroundColorHover={primary}
                toggle={isSelectAll}
                disabled={false}
                handleToggle={() => {
                  handleSelectAll();
                }}
              />
              <Text type={TextType.TEXT_14_GRAY} color={gray1}>
                {language ? language.labelAll : 'All'}
              </Text>
            </ParameterItem>
          )}

          {filterParameterList(searchInputValue).map((item: any, index: number) => {
            const { id, name, isSelected, type } = item;
            return (
              <ParameterItem key={index} isLast={index === parameterList.length - 1}>
                <CheckBox
                  label={''}
                  color={gray2}
                  backgroundColor={white}
                  backgroundColorHover={primary}
                  toggle={isSelected}
                  disabled={false}
                  handleToggle={() => {
                    handleCheckedItem(type, id);
                  }}
                />
                <Text type={TextType.TEXT_12_GRAY} color={gray1}>
                  {name}
                </Text>
                {getIndicator(type)}
              </ParameterItem>
            );
          })}
        </ParameterList>
      </ParameterListWrapper>
    </Wrapper>
  );
};
