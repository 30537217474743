/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for action section of universal table require
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useState } from 'react';
import { InlineActionConfig, InlineActionType } from '../../types';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../../../styles/media';

import { DetailsButton } from '../../../../components/detailsButton/DetailsButton';
import { ArrowButton, ArrowButtonType } from '../../../../components/arrowButton/ArrowButton';

const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > .locationIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  & > .rightIcon {
    margin-left: 14px;
  }

  & > div > div > svg > path {
    stroke: ${(props: any) => props.theme.colors.gray2};
  }
`;

const StyledDetailsButton = styled(DetailsButton)`
  /* max-width: 80px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  ${media.tablet`
    display: none;
  `}
`;

const StyledArrowButton = styled(ArrowButton)`
  display: none;
  ${media.tablet`
  width:  ${(props: any) => (props.isSelectAllCheckBox ? '80px' : '20px')};
  margin-left: 12px;
    display: flex;
  `}
`;
interface ActionSectionProps {
  inlineActionSet: InlineActionConfig[];
  onDetailsClick: any;
  data: any;
  isSelectAllCheckBox?: boolean;
}

export const ActionSection: FC<ActionSectionProps> = ({
  inlineActionSet,
  onDetailsClick,
  data,
  isSelectAllCheckBox,
}) => {
  const handleCallback = (e: any, callback: any) => {
    e.preventDefault();
    e.stopPropagation();
    callback && callback(data);
  };

  const [node, setNode] = useState<any>(null);
  const wrapperRef = useCallback((currentNode) => {
    if (currentNode !== null) {
      setNode(currentNode);
    }
  }, []);

  useEffect(() => {
    if (node) {
      const pseudoFunc = (e: any) => {};
      node.addEventListener('touchstart', pseudoFunc, { passive: false });
      return () => node.removeEventListener('touchstart', pseudoFunc);
    }
  }, [node]);

  return (
    <EditWrapper>
      {inlineActionSet.map((item) => {
        return item.type === InlineActionType.DETAILS ? (
          <>
            <StyledDetailsButton onClick={onDetailsClick}>Details</StyledDetailsButton>
            <StyledArrowButton
              type={ArrowButtonType.RIGHT}
              onClick={onDetailsClick}
              isSelectAllCheckBox={isSelectAllCheckBox}
            />
          </>
        ) : !!item.component ? (
          <div
            ref={wrapperRef}
            onClick={(e) => handleCallback(e, item.callback)}
            onTouchStart={(e) => handleCallback(e, item.callback)}
            onTouchEnd={(e) => handleCallback(e, item.callback)}
          >
            {item.component}
          </div>
        ) : null;
      })}
    </EditWrapper>
  );
};
