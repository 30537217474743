/**
 * @description
 * This is component that displays details about zone
 */

import React, { useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { API_URL } from '../../../consts';
import { useSelector } from 'react-redux';
import { use100vh } from 'react-div-100vh';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { View } from '../../../routes/routeInterfaces';
import { media, mediaType } from '../../../styles/media';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { SearchBarType } from '../../../enums/SearchBarType';
import { useFloorPlanDetails } from '../../../api/locations';
import { useWindowSize } from '../../../styles/style.context';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { useNotifierFunctions } from '../../../features/notifier2';
import { SearchBar } from '../../../components/searchBar/SearchBar';
import { CopyButton } from '../../../components/copyButton/CopyButton';
import { SelectStagesState } from '../../../app/state/manageStagesSlice';
import { SelectLevel } from '../../../components/selectLevel/SelectLevel';
import { ReactComponent as SearchIcon } from '../../../assets/Search.svg';
import { ReactComponent as ListIcon } from '../../../assets/list_button.svg';
import { deleteZoneGlobal, useGetStagesList } from '../../../api/manageZones';
import { CommonButton, ButtonIconType } from '@bit/first-scope.common-button';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { DetailsButton } from '../../../components/detailsButton/DetailsButton';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { ZonesFloorplan } from '../../../features/zonesFloorplan/ZonesFloorplan';
import { LevelComponent } from '../zones/components/levelComponent/LevelComponent';
import { updateZone, useManageZonesDetails } from '../../../api/manageZonesDetails';
import { StagesComponent } from '../zones/components/stagesComponent/StagesComponent';
import { ReactComponent as LocationIcon } from '../../../assets/location-secondary.svg';
import { useTableFunctions, withTableContextProvider } from '../../../features/universalTable/context';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  overflow: hidden;
  ${media.tablet`
    padding: 173px 0 45px 0;
  `}
`;
const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: gray5;
  ${media.tablet`
    position: fixed;
    top: 42px;
    z-index: 10;
    padding: 34px 16px 26px 16px;
    border-bottom: 1px solid;
    border-color: gray3;
  `}
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Title = styled(Text)`
  user-select: none;
  ${media.tablet`
    font-size: 20px;
    margin: 0;
  `}
`;
const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 14px;
  ${media.tablet`
    justify-content: center;
  `}
`;
const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    & > svg > path {
      stroke: ${(props: any) => props.theme.colors.gray3};
    }
    & > span {
      color: ${(props: any) => props.theme.colors.gray1};
    }
  }
`;
const ListButton = styled(CommonButton)`
  min-width: 115px;
  font-size: 12px;
  & > svg {
    margin-right: 8px;
  }
  ${media.tablet`
    display: none;
  `}
`;
const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 24px 0 40px 0;
  ${media.tablet`
    flex-direction: column-reverse;
    align-items: center;
    margin: 10px 0 0 0;
  `}
`;
const InputWrapper = styled.div`
  display: flex;
  ${media.tablet`
    width: 100%;
    justify-content: space-between;
    margin-top: 14px;
  `}
`;
const AddressWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const AddressIcon = styled(LocationIcon)`
  margin-right: 6px;
  ${media.tablet`
    display: none;
  `}
`;
const RefWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    display: none;
  `};
`;
const ShowSearchButton = styled.button`
  display: none;
  ${media.tablet`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid;
    border-color: gray3;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    & > svg {
      flex-shrink: 0;
      & > path {
      stroke: ${(props: any) => props.theme.colors.primary};
    }
    }
  `}
`;
const StyledSearchBar = styled(SearchBar)`
  ${media.tablet`
    display: ${(props: any) => (props.searchIsShown ? 'inline-block' : 'none')};
    width: 100%;
    & > div > div {
      width: 100%;
    }
  `}
`;
const StyledSelectLevel = styled(SelectLevel)`
  ${media.tablet`
      width: calc(100% - 50px);
      margin-left: 0;
  `};
`;

const ZonesDetailsPlain = ({ match }: any) => {
  const { resetTable } = useTableFunctions();
  const [activeId, setActiveId] = useState<string | null>(null);
  const [searchIsShown, setSearchIsShown] = useState(false);
  const customHistory = useCustomHistory();
  const pathParams = match.params;
  const buildingId: any = pathParams.building_id;
  const keyword = 'ZonesDetails';
  const offsetKey = 'offset';
  const offset = match.params[offsetKey];
  const zoneId = match.params['zone_id'];
  const [imageSize, setImageSize] = useState({ height: 0, width: 0 });
  const fullHeight = use100vh();
  const [trigger, setTrigger] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);
  const accessData = useSelector(selectAccess).management.zones;

  const toggleTrigger = () => {
    setTrigger((val) => !val);
  };

  const buildingData = useFloorPlanDetails(buildingId);
  const floorId = buildingData && buildingData.floors[0].floor_id;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [savedZones, setSavedZones] = useState<any>(null);
  const [isUpdateZones, setIsUpdateZones] = useState(false);

  const stagesState = useSelector(SelectStagesState);
  useGetStagesList();
  const tableRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const theme = useTheme();
  const searchInputRef = useRef<any>(null);
  const { addNotification } = useNotifierFunctions();

  const languageStrings = useSelector(selectLanguageStrings);

  const handleScrollToBottom = () => {
    tableRef && tableRef.current && tableRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleScrollToTop = (id: string) => {
    setActiveId(id);
    titleRef && titleRef.current && titleRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleShowSearchInput = () => {
    setSearchIsShown(true);
  };
  const handleOutsideHandler = useCallback(
    (e: any) => {
      if (
        searchIsShown &&
        !(searchInputRef && searchInputRef.current && (searchInputRef as any).current.contains(e.target))
      ) {
        setSearchIsShown(false);
      }
    },
    [searchIsShown],
  );
  const handleChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };
  const handleResultOnClick = (elem: any) => {
    setActiveId(elem.id);
  };
  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({
      message: languageStrings ? languageStrings.notificationCopieMessage : 'Copied to clipboard',
      type: 'info',
    });
  };

  const [locations, setLocations] = useManageZonesDetails(
    floorId,
    () => {},
    () => {},
    imageSize.height,
    imageSize.width,
    activeId,
    isUpdateZones,
    setIsUpdateZones,
  );

  const windowWidth = useWindowSize()[0];

  useEffect(() => {
    if (windowWidth > 768) {
      setSearchIsShown(true);
    } else {
      setSearchIsShown(false);
    }
  }, [windowWidth]);
  useEffect(() => {
    window.addEventListener('click', handleOutsideHandler);
    return () => {
      window.removeEventListener('click', handleOutsideHandler);
    };
  }, [handleOutsideHandler]);
  useEffect(() => {
    if (zoneId !== 'noselection') {
      setActiveId(zoneId);
    }
  }, [zoneId]);
  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  const triggerUpdateZonesFloorPlan = () => {
    setIsUpdateZones(true);
  };

  const deleteGlobalZones = (id: string[], deleteZone: any, deleteCheckedZones: any) => {
    deleteZoneGlobal(id, deleteZone, deleteCheckedZones, addNotification, triggerUpdateZonesFloorPlan);
  };

  return (
    <Wrapper>
      <Header>
        <TitleWrapper ref={titleRef}>
          <CommonButton
            width={'32px'}
            height={'32px'}
            iconType={ButtonIconType.ARROW_LEFT}
            iconWithoutText
            onClick={() => {
              customHistory.goBack();
            }}
          />
          <NameWrapper>
            <CopyWrapper
              onClick={() => {
                handleCopyText(buildingData ? buildingData.building_name : '');
              }}
              theme={theme}
            >
              <Title type={TextType.TITLE_PRIMARY} margin={'0 0 0 12px'}>
                {buildingData && buildingData.building_name}
              </Title>
              <CopyButton />
            </CopyWrapper>
          </NameWrapper>
          <ListButton onClick={handleScrollToBottom} width={'126px'}>
            <ListIcon />
            {languageStrings ? languageStrings.listOfZonesButton : 'List of zones'}
          </ListButton>
        </TitleWrapper>
        <SearchBarWrapper>
          <InputWrapper ref={searchInputRef}>
            <StyledSearchBar
              placeholderProps={{
                keyword: 'SearchZoneDetails',
                placeholderOptions: { withoutLoader: true },
                style: {
                  width: windowWidth > 768 ? 'max-content' : '100%',
                  display:
                    windowWidth < 769 && searchIsShown ? 'flex' : windowWidth < 769 && !searchIsShown ? 'none' : 'flex',
                },
              }}
              type={SearchBarType.CLASSIC_VALMONT}
              countResultOfSearching={2}
              onChange={handleChange}
              onResult={handleResultOnClick}
              value={searchInputValue}
              results={
                locations &&
                locations
                  .filter((element: any) => element.name.includes(searchInputValue))
                  .map((finalElem: any, index: number) => ({
                    ...finalElem,
                    label: finalElem.name,
                    positioning_role_name: `${languageStrings ? languageStrings.labelLevel : 'Level'} ${
                      finalElem.location.floor.level
                    }`,
                    index,
                  }))
              }
              isZonesDetails
              searchIsShown={searchIsShown}
              isGlobalSearch={false}
            />
            <StyledSelectLevel
              currentLevel={currentLevel}
              setCurrentLevel={setCurrentLevel}
              countOfElementsOnTheFloor={
                locations
                  ? locations.filter((item: any) => item.location.floor.level === buildingData?.floors[0]?.floor_level)
                      .length
                  : 'N/A'
              }
              countOfLevels={locations ? locations.length : 1}
              levelArr={buildingData ? buildingData.floors : []}
              nameOfElements={languageStrings ? languageStrings.labelZone : 'Zone'}
              isShown={!searchIsShown}
            />
            {!searchIsShown && (
              <ShowSearchButton theme={theme} onClick={handleShowSearchInput}>
                <SearchIcon />
              </ShowSearchButton>
            )}
          </InputWrapper>
          <AddressWrapper>
            <AddressIcon />
            <Text type={TextType.TEXT_14_GRAY}>
              {buildingData &&
                `${buildingData.address.city}, ${buildingData.address.line1} ${buildingData.address.line2}`}
            </Text>
          </AddressWrapper>
        </SearchBarWrapper>
      </Header>
      <ZonesFloorplan
        image={{
          src: buildingData?.floors[0]?.floor_image_url,
          height: buildingData?.floors[0]?.floor_image_height,
          width: buildingData?.floors[0]?.floor_image_width,
        }}
        data={buildingData}
        setImageDimensions={setImageSize}
        id={buildingId}
        floorId={floorId}
        height={`${(fullHeight || 0) - 244}px`}
        pathParams={pathParams}
        isOpenModal={false}
        closeModal={() => {}}
        locations={locations}
        toggleTrigger={toggleTrigger}
        setLocations={(data: any) => {
          setLocations(data);
        }}
        setActiveId={setActiveId}
        activeId={activeId}
        savedZones={savedZones}
        setSavedZones={setSavedZones}
        accessData={accessData}
      />
      <RefWrapper ref={tableRef}>
        <Text type={TextType.SEMITITLE} margin={'40px 0 14px 0'}>
          {languageStrings ? languageStrings.listOfZonesButton : 'List of zones'}
        </Text>
        <UniversalTable
          placeholderProps={{
            keyword,
            placeholderOptions: { borderRadiusTable: !mediaType.tablet },
          }}
          apiTriggers={[trigger]}
          mainApiUrl={floorId && `${API_URL}/v1/zones/floor/${floorId}`}
          pathParams={pathParams}
          keyword={keyword}
          offset={offset}
          offsetKey={offsetKey}
          tablePath={View.MANAGE_ZONES_DETAILS}
          updateItems={updateZone}
          deleteItems={deleteGlobalZones}
          allowDelete={accessData.delete}
          actionSet={[{ type: ActionType.EDIT }]}
          inlineActionSet={[
            {
              type: InlineActionType.CUSTOM,
              component: <DetailsButton onClick={() => {}} />,
              callback: (data: any) => {
                const { id } = data;
                setSavedZones(locations);
                handleScrollToTop(id);
              },
            },
          ]}
          accessData={accessData}
          columnStructure={[
            {
              fieldName: 'name',
              placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter Name',
              label: languageStrings ? languageStrings.labelName : 'Name',
              defaultValue: '',
            },
            {
              fieldName: 'location',
              placeholder: languageStrings ? languageStrings.chooseLevelPlaceholder : 'Choose level',
              label: languageStrings ? languageStrings.labelLevel : 'Level',
              defaultValue: [],
              type: RowItemType.CUSTOM,
              component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
                return <LevelComponent value={value} state={state} setValue={setValue} />;
              },
            },
            {
              fieldName: 'associated_stages',
              placeholder: '',
              label: languageStrings ? languageStrings.labelAssociatedStages : 'Associated stages',
              defaultValue: [],
              type: RowItemType.CUSTOM,
              modalColumns: 2,
              component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
                return <StagesComponent value={value} state={state} setValue={setValue} stagesList={stagesState} />;
              },
            },
            {
              fieldName: 'description',
              placeholder: languageStrings ? languageStrings.enterDescriptionPlaceholder : 'Enter description',
              label: languageStrings ? languageStrings.labelDescription : 'Description',
              defaultValue: '',
              modalColumns: 2,
              rows: 4,
            },
          ]}
        />
      </RefWrapper>
    </Wrapper>
  );
};

export const ZonesDetails = withTableContextProvider(ZonesDetailsPlain);
