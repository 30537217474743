import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserType } from '../../enums/UserType';
import { UserState, UserSettings, Access } from './userInterfaces';
import languageStrings from '../../languages/lang.json';
import ls from '../../utils/ls';
import { LsValueType } from '../../enums/LsValueType';

export const emptyAccess = {
  isAccess: false,
  permission: false,
  dashboard: false,
  insight: false,
  management: {
    client: {
      edit: false,
      view: false,
      delete: false,
    },
    user: { edit: false, view: false, delete: false },
    devices: { edit: false, view: false, delete: false },
    components: { edit: false, view: false, delete: false },
    orders: { edit: false, view: false, delete: false },
    processes: { edit: false, view: false, delete: false },
    stages: { edit: false, view: false, delete: false },
    zones: { edit: false, view: false, delete: false },
    inventories: { edit: false, view: false, delete: false },
  },
  settings: false,
};

const languageSet = [
  { name: 'English', id: 'en_gb', shortName: 'EN' },
  { name: 'Polski', id: 'pl_pl', shortName: 'PL' },
];

const polCLUSP = 'valmont.intelliventory.ai';

const defaultLang = window.location.href.includes(polCLUSP)
  ? { name: 'Polski', id: 'pl_pl', shortName: 'PL' }
  : {
      name: 'English',
      id: 'en_gb',
      shortName: 'EN',
    };

const initialState: UserState = {
  userType: UserType.USER,
  id: null,
  idpId: null,
  firstName: null,
  lastName: null,
  userSettings: { status: false },
  userSettingsCompleted: false,
  access: emptyAccess,
  language: languageSet.find((el) => el.id === ls.get(LsValueType.locale)) || defaultLang,
  languages: languageSet,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id;
      state.idpId = action.payload.idpId;
      state.userType = action.payload.userType;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.userSettingsCompleted = action.payload.userSettingsCompleted;
      state.userSettings = action.payload.userSettings;
    },
    setUserType: (state, action: PayloadAction<UserType>) => {
      state.userType = action.payload;
    },
    setAccess: (state, action: PayloadAction<Access>) => {
      state.access = { ...action.payload, isAccess: true };
    },

    updateUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.userSettings = { ...state.userSettings, ...action.payload };
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      const index = state.languages.findIndex((language: any) => language.id === action.payload);
      state.language = index !== -1 ? state.languages[index] : state.language;
    },
  },
});

export const { setUser, setUserType, setAccess, setLanguage, updateUserSettings } = userSlice.actions;

export const selectUser = (state: RootState) => {
  return state.user;
};
export const selectUserType = (state: RootState) => {
  return state.user.userType;
};

export const selectUserSettingsCompleted = (state: RootState) => {
  return state.user.userSettingsCompleted;
};

export const selectAccess = (state: RootState) => {
  return state.user.access;
};

export const selectLanguageStrings = (state: RootState) => {
  return (languageStrings as any)[state.user.language.id];
};

export const selectLanguages = (state: RootState) => {
  return state.user.languages;
};

export const selectLanguage = (state: RootState) => {
  return state.user.language;
};

export default userSlice.reducer;
