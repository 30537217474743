import React, { FC } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import { ItemWrapper, StyledNavLink, TextWrapper, StyledText } from './styled';
import { TextType } from '@bit/first-scope.text';
import Tooltip from '../../../components/tooltip';
import { ItemImage } from './ItemImages';
import { MenuItemType } from '../MenuItem';
import { getAccess } from '../../../routes/paths';

export interface ItemTypes {
  disabled?: boolean;
  tooltipId?: string;
  to: any;
  type: MenuItemType;
  isOpen: boolean;
  value?: string;
  isActive?: boolean;
  appendSecondaryItems?: (val: any) => void;
  clearSecondaryItems?: () => void;
}

export const Item: FC<ItemTypes> = ({
  disabled,
  tooltipId,
  to,
  type,
  isOpen,
  value,
  isActive,
  appendSecondaryItems,
  clearSecondaryItems,
}) => {
  const theme = useTheme();

  const { additional4 } = theme.borderRadius;
  const { black, primary } = theme.colors;

  const activeOptions = isActive ? { className: 'active' } : {};

  return (
    <ItemWrapper className={isActive && 'active'}>
      <Tooltip id={!isOpen ? tooltipId ?? '' : ''} text={!isOpen ? value ?? '' : ''}>
        <StyledNavLink
          to={to}
          access={getAccess(to)}
          onClick={(e: any) => {
            disabled && e.preventDefault();
            clearSecondaryItems && clearSecondaryItems();
          }}
          borderRadius={`0 ${additional4} ${additional4} 0`}
          primary={primary}
          black={black}
          {...activeOptions}
        >
          <ItemImage type={type} />
          <TextWrapper className={isOpen ? 'open' : ''}>
            <StyledText
              type={TextType.MENU_ITEM_TEXT_PRIMARY}
              color={theme.colors.black}
              hoverColor={theme.colors.primary}
            >
              {value}
            </StyledText>
          </TextWrapper>
        </StyledNavLink>
      </Tooltip>
    </ItemWrapper>
  );
};
