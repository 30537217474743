/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for loading screen require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { SpinnerClassic } from '../animations/SpinnerClassic';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: fill-available;
  justify-content: center;
  align-items: center;
`;

export const LoadingScreen = () => {
  return (
    <Wrapper>
      <SpinnerClassic />
    </Wrapper>
  );
};
