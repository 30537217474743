/**
 * @description
 * This is component that set sizes of zone in DescriptionPopup
 */

import React, { useEffect, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { RoundedRange } from '../../../range/RoundedRange';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';

const PropertyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;
const RangeWrapper = styled.div`
  margin-bottom: ${(props: any) => props.isRange && '14px'};
`;
const NumberWrapper = styled.div`
  width: max-content;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: gray3;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  padding: 0 8px;
  margin-bottom: 3px;
`;
const TextField = styled(TextareaAutosize)`
  width: 28px;
  box-sizing: border-box;
  background-color: inherit;
  color: inherit;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  outline: none;
  resize: none;
  border: none;
`;

export const Range = ({
  index,
  name,
  localValue,
  onLocalChange,
  isEdit,
  maxVal = 20,
}: {
  index: number;
  name: string;
  localValue: number;
  onLocalChange: any;
  isEdit?: boolean;
  maxVal?: number;
}) => {
  const [size, setSize] = useState(localValue);
  const { addNotification } = useNotifierFunctions();
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  const handleSetSize = (value: number, index: number) => {
    const preparedValue = value > maxVal ? maxVal : value;
    setSize(preparedValue);
    onLocalChange([preparedValue]);
    value > maxVal &&
      addNotification({
        message: `${languageStrings ? languageStrings.maxValueNotification : 'The maximum value of'}  ${
          index === 2
            ? languageStrings
              ? languageStrings.width
              : 'width'
            : languageStrings
            ? languageStrings.height
            : 'height'
        } ${languageStrings ? languageStrings.is : 'is'} ${maxVal}`,
        type: 'error',
      });
  };

  useEffect(() => {
    setSize(localValue);
  }, [localValue]);

  return (
    <RangeWrapper key={index} isRange={(index === 2 || index === 3) && isEdit}>
      <PropertyWrapper>
        <Text type={TextType.TEXT_12_GRAY}>{name}</Text>
        {(index === 2 && isEdit) || (index === 3 && isEdit) ? (
          <NumberWrapper theme={theme}>
            <TextField
              value={size}
              onChange={(e: any) => {
                const value = isNaN(e.target.value) ? 1 : +e.target.value;
                handleSetSize(value, index);
              }}
            />
            <Text type={TextType.TEXT_12_BLACK}> m</Text>
          </NumberWrapper>
        ) : index === 2 || index === 3 ? (
          <Text type={TextType.TEXT_12_BLACK}>{`${localValue > maxVal ? maxVal : Math.floor(localValue)} m`}</Text>
        ) : (
          <Text type={TextType.TEXT_12_BLACK}>{Math.round(localValue)}</Text>
        )}
      </PropertyWrapper>
      {(index === 2 || index === 3) && isEdit && (
        <RoundedRange values={[Math.floor(localValue)]} handleOnChange={onLocalChange} min={0} max={maxVal} />
      )}
    </RangeWrapper>
  );
};
