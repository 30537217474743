/**
 * @description
 * This is container for map
 *
 */

import React, { useCallback, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { RealTimeInsight } from './components/realTimeInsight/realTimeInsight';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 45px;
  display: flex;
  flex-direction: column;
  ${media.tablet`
    padding: 10px 0 0;
  `}
`;

const StyledText = styled(Text)`
  ${media.tablet`
    margin: 20px 0 35px;
    padding: 0 16px;
  `}
`;

const MapWrapper = styled.div`
  height: 100%;
  border: 1px solid;
  border-color: gray3;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  ${media.tablet`
    border-radius: 0;
  `}
`;

export const Insights = ({ match }: { match: any }) => {
  const [boundingRect, setBoundingRect] = useState(null);
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  const mapRef = useCallback((node) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  return (
    <Wrapper>
      <StyledText type={TextType.TITLE_PRIMARY} margin={'0 0 45px 0'}>
        {languageStrings ? languageStrings.titleOfRealTimeInsightPage : 'Real Time Insight'}
      </StyledText>
      {/* <SearchBar type={SearchBarType.WITHLOCATION} countResultOfSearching={0}></SearchBar> */}
      {/*<div style={{ marginTop: '20px' }}></div>*/}
      <MapWrapper ref={mapRef} theme={theme}>
        {boundingRect && <RealTimeInsight boundingRect={boundingRect} match={match} />}
      </MapWrapper>
    </Wrapper>
  );
};
