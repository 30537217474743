import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { /*AppThunk,*/ RootState } from '../store';

import { SettingsState, NotificationCall, ModalOpen } from './settingsInterfaces';
import { ModalType } from '../../enums/ModalType';

const emptySearch = {
  city: '',
  isOpen: false,
};

const emptyModal = {
  status: false,
  search: emptySearch,
  type: ModalType.NONE,
  windowOffset: 0,
  scrolled: true,
  redirect: false,
};

const initialState: SettingsState = {
  value: 0,
  notifications: { nextKey: -1, list: [] },
  modal: emptyModal,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalOpen>) => {
      document.body.classList.add('blocked');
      const search =
        action.payload.type === ModalType.SEARCH && action.payload.search ? action.payload.search : emptySearch;
      state.modal = {
        ...action.payload,
        status: true,
        windowOffset: window.pageYOffset,
        scrolled: true,
        search,
        redirect: false,
      };
    },
    closeModal: (state, action: PayloadAction<boolean | undefined>) => {
      document.body.classList.remove('blocked');
      state.modal = {
        ...emptyModal,
        windowOffset: state.modal.windowOffset,
        scrolled: false,
        type: state.modal.type,
        search: state.modal.search,
        redirect: !!action.payload,
      };
    },
    setScrolled: (state, action: PayloadAction<boolean>) => {
      state.modal.scrolled = action.payload;
      state.modal.type = ModalType.NONE;
      state.modal.search = emptySearch;
    },
    setRedirect: (state, action: PayloadAction<boolean>) => {
      state.modal.redirect = action.payload;
    },
  },
});

export const { openModal, closeModal, setScrolled, setRedirect } = settingsSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
/*export const incrementAsync = (amount: number): AppThunk => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};*/

export const selectModal = (state: RootState) => {
  return state.settings.modal;
};

export default settingsSlice.reducer;
