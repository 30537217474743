/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for Inventories page
 * Highly customisable.
 *
 */

// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  createInventoriesTypeFunctions,
  deleteInventoriesGlobal,
  updateInventories,
  useGetDeviceUnattached,
  useGetTypes,
} from '../../../api/manageInventories';
import { selectLanguageStrings } from '../../../app/state/userSlice';
import { ReactComponent as LocationImage } from '../../../assets/locationGray.svg';
import { API_URL } from '../../../consts';
import { useTableFunctions } from '../../../features/universalTable/context';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { View } from '../../../routes/routeInterfaces';
import { media } from '../../../styles/media';
import { useMediaType } from '../../../styles/style.context';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { MetaDataComponent } from '../processes/components/metaDataComponent/MetaDataComponent';
import { useInventoryTypes } from './useInventoryTypes';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

const LocationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LocationButton = styled(LocationImage)`
  width: 24px;
  height: 24px;
  margin-right: 14px;
  cursor: pointer;
  & > path {
    stroke: ${(props: any) => props.theme.colors.gray2};
    transition: stroke 0.3s ease;
  }
  &:hover > path {
    stroke: ${(props: any) => props.theme.colors.gray3};
  }
`;

export interface InventoriesProps {
  match: any;
  className?: any;
}

export const Inventories: React.FC<InventoriesProps> = ({ match, ...props }) => {
  const { startAddProcess, setSearchValue, getSearchValue, resetTable } = useTableFunctions();

  const { actual: searchValue } = getSearchValue();

  const mediaType = useMediaType();
  const [types, setTypes] = useGetTypes();
  const unattachedDevice = useGetDeviceUnattached();

  const keyword = 'InventoriesPropsTable';
  const offsetKey = 'offset';

  const offset = match.params[offsetKey];
  const { state }: any = useLocation();
  const customHistory = useCustomHistory();
  const theme = useTheme();

  const { handleCreateInventoryType, handleDeleteInventoryType, handleUpdateInventoryType } = useInventoryTypes(
    setTypes,
  );

  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper {...props}>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfInventories : 'Inventories'}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        searchInputValue={searchValue}
        addButtonOnClick={startAddProcess}
        accessData={{ view: true, edit: true, delete: true }}
        handleChangeSearchInputValue={setSearchValue}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_INVENTORIES}
        updateItems={updateInventories}
        deleteItems={deleteInventoriesGlobal}
        allowDelete
        accessData={{ view: true, edit: true, delete: true }}
        additionalKeywords={['ManageLayoutSearch']}
        actionSet={[{ type: ActionType.EDIT }]}
        inlineActionSet={[
          {
            type: InlineActionType.CUSTOM,
            component: (
              <LocationButtonWrapper>
                <LocationButton theme={theme} />
              </LocationButtonWrapper>
            ),
            callback: (data: any) => {
              return data?.tracker
                ? customHistory.push(View.USER_INSIGHTS_DETAILS, {
                    pathParams: { id: data?.tracker?.location?.building_id, name: data?.tracker?.name },
                  })
                : null;
            },
          },
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        idItemForViewModal={state?.state?.id}
        mainApiUrl={`${API_URL}/v1/inventories/`}
        keyword={keyword}
        pageSize={mediaType.phone ? 6 : 18}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter name',
            label: languageStrings ? languageStrings.labelName : 'Name',
            defaultValue: '',
          },
          {
            fieldName: 'inventory_type',
            type: RowItemType.JOINED_SELECT,
            placeholder: languageStrings ? languageStrings.chooseTypePlaceholder : 'Choose type',
            label: languageStrings ? languageStrings.labelType : 'Type',
            getTextDisplay: (type: any) => type?.name,
            defaultValue: { name: '', id: '' },
            selectItems: types,
            createItem: handleCreateInventoryType,
            updateItem: handleUpdateInventoryType,
            deleteItem: handleDeleteInventoryType,
          },
          {
            fieldName: 'tracker',
            placeholder: languageStrings ? languageStrings.enterLocationPlaceholder : 'Enter location',
            label: languageStrings ? languageStrings.labelLocation : 'Location',
            getTextDisplay: (tracker: any) =>
              tracker?.location ? `${tracker?.location?.address?.city}, ${tracker?.location?.address?.country}` : 'N/A',
            excludedFromAdd: true,
            defaultValue: '',
            disabled: true,
          },
          {
            fieldName: 'tracker',
            placeholder: languageStrings ? languageStrings.enterZonePlaceholder : 'Enter zone',
            label: languageStrings ? languageStrings.labelZone : 'Zone',
            getTextDisplay: (tracker: any) => tracker?.location?.zone || 'N/A',
            excludedFromAdd: true,
            defaultValue: '',
            disabled: true,
          },
          {
            fieldName: 'tracker',
            placeholder: languageStrings ? languageStrings.chooseDevicePlaceholder : 'Choose device',
            label: languageStrings ? languageStrings.labelAttachedTo : 'Attached to',
            getTextDisplay: (tracker: any) => (tracker?.name ? tracker?.name : 'N/A'),
            defaultValue: { name: '', id: '' },
            type: RowItemType.JOINED_SELECT,
            selectItems: unattachedDevice,
            conditionForValidationInCustom: languageStrings
              ? languageStrings.conditionForValidationInCustom
              : 'not valid',
            isUnassigned: true,
          },
          {
            fieldName: 'metadata',
            placeholder: '',
            label: languageStrings ? languageStrings.labelMetadata : 'Metadata',
            defaultValue: {},
            modalColumns: 2,
            type: RowItemType.CUSTOM,
            excludedFromView: true,
            // hide title component for View_Modal in mobile version
            excludedTitleFromMobileViewEmptyData: true,
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
              <MetaDataComponent value={value} state={state} setValue={setValue} />
            ),
            conditionForValidationInCustom: languageStrings
              ? languageStrings.conditionForValidationInCustom
              : 'not valid',
          },
        ]}
      />
    </Wrapper>
  );
};
