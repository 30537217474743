import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserType } from '../app/state/userSlice';
import { UserType } from '../enums/UserType';
import { withRouter } from 'react-router-dom';
import { injectPathParams, PATHS } from '../routes/paths';
import { LoadingScreen } from '../features/loadingScreen/LoadingScreen';

const UserSwitcher = ({ history }: { history: any }) => {
  const type = useSelector(selectUserType);

  if (type !== UserType.NONE) {
    switch (type) {
      case UserType.USER:
        history.push(PATHS.USER_SEARCH);
        break;
      default:
        history.push(PATHS.ERROR_403);
    }
  }

  return <LoadingScreen />;
};

export default withRouter(UserSwitcher);
