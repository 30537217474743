/**
 * @description
 * This is component that displays list of levels on zone
 * and user can choose necessary level
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { useTableContext, useTableFunctions } from '../../../../../features/universalTable/context';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { LevelFunctional } from './components/levelFunctional/LevelFunctional';
import { LevelView } from './components/levelView/LevelView';

export const LevelComponent = ({
  value,
  state,
  fieldName,
  setValue,
  dataStages,
}: {
  value: any;
  state?: TableCustomComponentState;
  fieldName?: string;
  setValue?: any;
  dataStages?: any;
}) => {
  const { setField } = useTableFunctions();
  const [{ openIndex }] = useTableContext();
  const language = useSelector(selectLanguageStrings);
  const selectData = { id: value?.floor?.floor_id, name: `${value?.floor?.level}` };

  const handleSelectChange = (value: any, fieldName: any) => {
    setField(openIndex, value.id, fieldName.id);
    setField(openIndex, value.name, fieldName.name);
  };

  const mockData = [selectData];
  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <LevelView value={0} />
      ) : state === TableCustomComponentState.VIEW_MODAL ? (
        <LevelFunctional value={selectData} isDisabled selectList={mockData} />
      ) : state === TableCustomComponentState.EDIT ? (
        <LevelFunctional
          value={selectData}
          selectList={mockData}
          handleSelectChange={handleSelectChange}
          fieldName={fieldName}
          placeholder={language ? language.chooseLevelPlaceholder : 'Choose level'}
        />
      ) : null}
    </>
  );
};
