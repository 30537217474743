/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings manhole button on map require
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { ManholeInfo } from '../../components/manholeInfo/ManholeInfo';

// import { ReactComponent as ManholeImage } from '../../assets/greenManhole.svg';
import { ReactComponent as HouseImage } from '../../assets/Warehouse.svg';
import { ReactComponent as WarehouseHovered } from '../../assets/Warehouse_hovered.svg';

export enum ManholesType {
  GREEN = 'GREEN',
  RED = 'RED',
  BLUE = 'BLUE',
  NONE = 'NONE',
}

const Wrapper = styled.div`
  transform: translate(-28px, -28px);
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  width: 56px;
  background-color: inherit;
  border: none;
  position: relative;
  padding: 0px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${(props: any) => (props.isOpen ? props.firstLayerColor : 'initial')};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: ${(props: any) => props.theme.borderRadius.round};
  transition: background-color 0.3s ease;
  & > svg > circle {
    fill: ${(props: any) => props.circleFill};
    stroke: ${(props: any) => props.pathFill};
  }
  & > svg > path {
    fill: ${(props: any) => props.pathFill};
  }
`;
const HouseWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  & > .common {
    position: absolute;
    left: 0;
    top: 0;
  }
  & > .hovered {
    opacity: ${(props: any) => (props.isHovered ? '1' : '0')};
    transition: all 0.3s linear;
    position: absolute;
    left: -8px;
    top: -7px;
  }
`;

const ImageExtraWrapper = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: any) => (props.isOpen ? props.secondLayerColor : 'initial')};
  box-sizing: border-box;
  border-radius: ${(props: any) => props.theme.borderRadius.round};
  transition: background-color 0.3s ease;
  &:hover,
  &:hover > ${ImageWrapper} {
    background-color: ${(props: any) => props.secondLayerColor};
  }
`;

const CircleInfo = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -10%);
  border: 2px solid;
  border-color: white2;
  background-color: rgba(0, 95, 131, 0.9);
  border-radius: ${(props: any) => props.theme.borderRadius.round};
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: white;
`;

const StyledManholeInfo = styled(ManholeInfo)`
  left: -23px;
  top: 11px;
  z-index: 10001;
  position: relative;
`;

export interface ManholeButtonProps {
  type: ManholesType;
  staticDisplay?: boolean;
  values?: any[];
  className?: string;
  isReq?: boolean;
  handleClick?: any;
}

export const ManholeButton: FC<ManholeButtonProps> = ({
  type,
  staticDisplay,
  values,
  isReq = false,
  handleClick,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    isReq ? setIsHovered(true) : setIsHovered(false);
  }, [isReq]);

  let circleFill, pathFill, firstLayerColor, secondLayerColor;

  if (type === ManholesType.GREEN) {
    circleFill = theme.colors.primary;
    pathFill = theme.colors.secondary1;
    firstLayerColor = 'rgb(19, 107, 47, 0.25)';
    secondLayerColor = 'rgb(19, 107, 47, 0.2)';
  } else if (type === ManholesType.RED) {
    circleFill = theme.colors.red3;
    pathFill = theme.colors.red1;
    firstLayerColor = 'rgb(244, 68, 80, 0.25)';
    secondLayerColor = 'rgb(244, 68, 80, 0.2)';
  } else if (type === ManholesType.BLUE) {
    // circleFill = theme.colors.blue1;
    // pathFill = theme.colors.secondary1;
    firstLayerColor = 'transparent';
    secondLayerColor = 'transparent';
  }

  const isInfoOpen = !staticDisplay && isReq;

  return (
    <Wrapper {...props}>
      <Button>
        <ImageExtraWrapper theme={theme} secondLayerColor={secondLayerColor} isOpen={isReq}>
          <ImageWrapper
            theme={theme}
            circleFill={circleFill}
            pathFill={pathFill}
            firstLayerColor={firstLayerColor}
            isOpen={isReq}
            onClick={handleClick}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              if (!isReq) {
                setIsHovered(false);
              }
            }}
          >
            <HouseWrapper isHovered={isHovered}>
              <HouseImage className="common" />
              <WarehouseHovered className="hovered" />
            </HouseWrapper>
          </ImageWrapper>
        </ImageExtraWrapper>
      </Button>
      {isReq && (
        <div
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <StyledManholeInfo isOpen={isReq} isClicked={!staticDisplay && isInfoOpen} {...(values as any)[0]} />
        </div>
      )}
    </Wrapper>
  );
};

ManholeButton.defaultProps = {
  type: ManholesType.NONE,
  values: [
    {
      address: 'Brest, Belarus',
      coordinates: '5551.4444 7895.5854',
      temperature: '54',
      waterLevel: '5',
      date: '21.01.2020',
      time: '9:54',
    },
  ],
};
