/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for modal system of universal table require
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTableContext, useTableFunctions } from '../../context';
import {
  ActionConfig,
  ActionType,
  ModalColumnCount,
  RowItemType,
  TableCustomComponentState,
  UpdateUserType,
} from '../../types';
import { DeletePopup } from '../../../../components/deletePopup/DeletePopup';
import { CommonButton } from '@bit/first-scope.common-button';
import { media, sizes } from '../../../../styles/media';
import { InputModal } from '../../../../components/inputModal/InputModal';
import { Select } from '../../../../components/select/Select';
import { usePrevious } from '../../../../utils/usePrevious';
import { ModalContainer } from '../../../../components/newModals/ModalContainer';
import { Modal } from '../../../../components/newModals/Modal';
import { getValFromObj, setValInObj } from '../../../../utils/objectDeconstruct';
import { MobilePortalContainer } from '../../../../components/mobilePortalContainer/MobilePortalContainer';
import { useBlockBodyScroll } from '../../../../utils/useBlockBodyScroll';
import { useNotifierFunctions } from '../../../notifier2';
import { CloseWhiteRoundButton } from '../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { useWindowSize } from '../../../../styles/style.context';

const StyledCloseWhiteRoundButton = styled(CloseWhiteRoundButton)`
  display: none;
  ${media.tablet`
    display: flex;
  `}
`;

const MobileSelectActionButton = styled(Text)`
  cursor: pointer;
  margin: 10px 16px;
  font-size: 16px;
  line-height: 24px;
` as typeof Text;

const MobileSelectActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MobileCancelActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const MobileSelectActionBlock = styled.div`
  display: flex;
`;

const StyledButton = styled(CommonButton)`
  width: 108px;
  margin: 5px 0;
  height: auto;
  padding: 4px 0;
  line-height: 22px;
  pointer-events: ${(props: any) => (props.isBlocked ? 'none' : 'auto')};
  ${media.tablet`
      width: 100%;  
      height: 40px; 
      pointer-events: ${(props: any) => props.isBlocked && 'none'};
  `};
`;

const StyledInput = styled(InputModal)`
  width: ${(props: any) => (props.isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;

  ${media.tablet`
      width: 100%;   
  `};
`;

const StyledSelect = styled(Select)`
  width: ${(props: any) => (props.isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
  }
  ${media.tablet`
      width: 100%;   
  `};
`;

const CustomBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: any) => (props.isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;
  &.custom-select-view {
    & > div > div {
      & > div.select {
        height: 40px;
        background-color: ${(props: any) => (props.disabled ? props.theme.colors.gray5 : 'transparent')};
        border: 1px solid
          ${(props: any) =>
            props.disabled
              ? props.theme.colors.gray5
              : props.isFocused
              ? props.theme.colors.primary
              : props.isError
              ? props.theme.colors.red3
              : props.theme.colors.gray3};
        & > svg {
          width: 26px;
          height: 26px;
          & > path {
            stroke: ${(props: any) =>
              props.disabled
                ? props.theme.colors.gray5
                : props.isFocused
                ? props.theme.colors.primary
                : props.isError
                ? props.theme.colors.red3
                : props.theme.colors.gray2};
          }
        }
      }
      & > span {
        display: ${(props: any) => (props.isShowMessage ? 'flex' : 'none')} !important;
      }
    }
  }

  ${media.tablet`
      width: 100%; 
      grid-area: ${(props: any) => (props.isWide === 3 ? 'auto/span 2' : '')};  
  `};
  ${media.phone`
      grid-area: auto;  
  `};
`;

const CustomInnerBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props: any) => (props.disabled ? props.theme.colors.gray5 : 'transparent')};
  padding: 0;
  transition: all 0.3s linear;
  ${media.tablet`
    & > div {
      width: 100%;
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
      width: 100%;   
  `};
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  & > * {
    margin-right: 10px;
    margin-left: 10px;
    ${media.tablet`
      margin-right: 0;   
      margin-left: 0;
      `};
  }
  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    margin: 0;
    max-height: 520px;
    overflow-y: scroll;
   `};
  ${media.phone`
    max-height: 56vh;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `};
  ${media.phoneS`
    max-height: 51vh;
  `};
  ${media.phoneXS`
    max-height: 49vh;
  `};
`;

const StyledModalContainer = styled(ModalContainer)`
  padding: 24px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > button:nth-child(odd) {
    margin-right: 14px;
  }
  ${media.tablet`
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%; 
    grid-gap: 15px;
    & > button:nth-child(odd) {
      margin-right: 0px;
    }  
  `};
  ${media.phone`
    flex-direction: column-reverse;
    grid-gap: 0;
  `}
`;

const CustomButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${media.tablet`
    & > button {
      margin-right: 15px;
    }
  `};
  ${media.phone`
    & > button {
      margin-right: 0;
    }
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  min-height: 32px;
  width: 100%;
  ${media.tablet`
    grid-area: auto/span 2;
    grid-gap: 15px;
  `};
  ${media.phone`
    flex-direction: column-reverse; 
     grid-area: auto;
     grid-gap: 0;
  `};
`;
const DeletePopupContainer = styled(ModalContainer)`
  max-width: 384px;
  ${media.tablet`
    max-width: 100%;
  `}
`;

export interface ModalSystemProps {
  actionSet: ActionConfig[];
  deleteItems: any;
  allowDelete: boolean;
  modalColumnCount: ModalColumnCount;

  updateItems: UpdateUserType;
  banAutoFocus?: boolean;
  resetIsAddModalFromGlobalSearch?: () => void;
  keywordAddModalGlobalSearch: string | undefined;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  isPendingRequestFromModalApi?: boolean;
  triggerColumnStructure: () => void;
  languageStrings?: any;
}

export const ModalSystem: FC<ModalSystemProps> = ({
  actionSet,
  deleteItems,
  allowDelete,
  updateItems,
  modalColumnCount,
  banAutoFocus,
  resetIsAddModalFromGlobalSearch = () => {},
  keywordAddModalGlobalSearch,
  accessData,
  isPendingRequestFromModalApi,
  triggerColumnStructure,
  languageStrings,
}) => {
  const inputWidth = 230;
  const flexGap = 20;
  const outerPadding = 24;
  const width = (modalColumnCount - 1) * (inputWidth + flexGap) + inputWidth;
  const containerWidth = width + outerPadding * 2;
  const theme = useTheme();
  const widthView = useWindowSize()[0];
  const isMobileSize = widthView < sizes.phone;
  const { primary, black, gray1, white, black1, red3 } = theme.colors;

  const {
    deleteItem,
    deleteCheckedItems,
    getSelectedItems,
    toggleView,
    getViewedData,
    setField,
    toggleEdit,
    toggleDelete,
    toggleAdd,
    updateLocalItem,
    setLocalDeleteIndex,
    getLocalDeleteName,
    getLocalDeleteId,
    createItem,
    uncheckAll,
    getModalItems,
    getRequiredData,
    toggleMobileSelectMode,
    setValidatingIsStarted,
  } = useTableFunctions();

  const arr = getModalItems();
  const requiredData = getRequiredData();

  const viewedData = getViewedData();
  const prevViewedData = usePrevious(viewedData);
  const [savedData, setSavedData] = useState(viewedData);
  const [blockButtons, setBlockButtons] = useState(false);

  const { addNotification } = useNotifierFunctions();

  const [
    {
      isViewInProcess,
      openIndex,
      isEditInProcess,
      isAddInProcess,
      openId,
      currentModalApiId,
      isDeleteInProcess,
      localDeleteIndex,
      mobileSelectMode,
    },
  ] = useTableContext();

  const markedArr = getSelectedItems();

  const nameList = markedArr.map(
    (item: any) => item.name || `${languageStrings ? languageStrings.labelId : 'id:'} ${item.id}`,
  );
  const idList = markedArr.map((item: any) => item.id);
  const validatedFields = arr.filter(
    (item: any) => !item.disabled && !item.excludedFromAdd && !item.excludedFromModalView,
  );

  const isAllFieldsValidated = validatedFields.length === validatedFields.filter((item: any) => !item.isError).length;
  const isLocalDeleteInProcess = localDeleteIndex !== -1;
  const localDeleteId = getLocalDeleteId();
  const disabled = !isEditInProcess && !isAddInProcess;
  const title = isEditInProcess
    ? languageStrings
      ? languageStrings.labelEdit
      : 'Edit'
    : isAddInProcess
    ? languageStrings
      ? languageStrings.labelAdd
      : 'Add'
    : languageStrings
    ? languageStrings.labelDetails
    : 'Details';
  const localDeleteName = getLocalDeleteName();
  const customComponentState =
    isEditInProcess || isAddInProcess ? TableCustomComponentState.EDIT : TableCustomComponentState.VIEW_MODAL;

  const handleGlobalDelete = useCallback(() => {
    const deleteItemRow = () => {
      deleteItems && deleteItems([localDeleteId], deleteItem, deleteCheckedItems, addNotification);
      isViewInProcess && toggleView(null, null);
      setLocalDeleteIndex(-1);
    };
    const deleteMultipleRows = () => {
      deleteItems && deleteItems(idList, deleteItem, deleteCheckedItems, addNotification);
    };
    isDeleteInProcess ? deleteMultipleRows() : deleteItemRow();
    isDeleteInProcess && toggleDelete();
  }, [
    isDeleteInProcess,
    deleteCheckedItems,
    deleteItem,
    isViewInProcess,
    toggleView,
    deleteItems,
    idList,
    toggleDelete,
    setLocalDeleteIndex,
    localDeleteId,
  ]);
  const handleCallback = (callback: any) => {
    callback && callback(viewedData);
  };
  const handleInputChange = (e: any, fieldName: any) => {
    setField(openIndex, e.target.value, fieldName);
  };
  const handleSingleValueChange = useCallback(
    (value: any, fieldName: any) => {
      setField(openIndex, value, fieldName);
    },
    [openIndex, setField],
  );
  const handleLocalUpdate = useCallback(
    (index: number, value: any) => {
      updateLocalItem(index, value);
      toggleEdit();
    },
    [updateLocalItem, toggleEdit],
  );
  const handleLocalCreate = useCallback(
    (id: string, value: any) => {
      createItem(id, value);
      toggleAdd();
    },
    [createItem, toggleAdd],
  );
  const handleSave = useCallback(() => {
    setValidatingIsStarted(true);
    if (openId && updateItems) {
      if (isAllFieldsValidated) {
        // addNotification({ message: 'Saved successfully', type: 'success' });
        setBlockButtons(true);
        updateItems(
          openId,
          requiredData,
          openIndex,
          !isAddInProcess ? handleLocalUpdate : handleLocalCreate,
          addNotification,
        );
        setValidatingIsStarted(false);
        setBlockButtons(false);
      }
    }
  }, [openId, requiredData, openIndex, handleLocalUpdate, updateItems, handleLocalCreate, isAddInProcess]);
  const handleRevert = useCallback(() => {
    if (!isAddInProcess) {
      setTimeout(() => {
        handleLocalUpdate(openIndex, savedData);
      }, 10);
    } else {
      setTimeout(() => {
        deleteItem(openId);
        toggleAdd();
      }, 10);
    }
  }, [openIndex, savedData, handleLocalUpdate, isAddInProcess, deleteItem, openId, toggleAdd]);
  const handleDelete = () => {
    setLocalDeleteIndex(openIndex);
  };
  const handleCloseDeleteModal = useCallback(() => {
    isLocalDeleteInProcess && setLocalDeleteIndex(-1);
    isDeleteInProcess && toggleDelete();
  }, [isLocalDeleteInProcess, isDeleteInProcess, toggleDelete, setLocalDeleteIndex]);
  // @TODO Alex work on handleSetValue
  const handleSetValue = useCallback(
    (value: any, fieldName: any) => {
      const deconstructObject: any = (fieldName: any, value: any, path: string[] = []) => {
        const isObj = typeof fieldName === 'object' && fieldName !== null;
        if (isObj) {
          return Object.keys(fieldName).reduce((accumulator: any[], newKey: any) => {
            return [...accumulator, ...deconstructObject(fieldName[newKey], value && value[newKey], [...path, newKey])];
          }, []);
        } else {
          return [{ fieldName, value: value || null, path }];
        }
      };
      const mapping = deconstructObject(fieldName, value);
      mapping.forEach((mappingItem: any) => {
        setField(openIndex, (prevValue: any) => {
          const modifiedValue = setValInObj(mappingItem.path, prevValue, mappingItem.value);
          return modifiedValue;
        });
      });
    },
    [openIndex, setField],
  );
  const handleSelectChange = (value: any, fieldName: any, onlyIdForOnChangeSelect?: boolean) => {
    if (onlyIdForOnChangeSelect) {
      setField(openIndex, value.id, fieldName.id);
    } else {
      setField(openIndex, value.id, fieldName.id);
      setField(openIndex, value.name, fieldName.name);
    }
  };
  const handleJoinedSelectChange = (value: any, fieldName: any) => {
    setField(openIndex, value, fieldName);
  };
  const handleCancel = () => {
    setTimeout(() => {
      uncheckAll();
      toggleMobileSelectMode();
    }, 10);
  };

  useEffect(() => {
    !prevViewedData && viewedData && setSavedData(viewedData);
  }, [viewedData, prevViewedData]);
  useEffect(() => {
    currentModalApiId && currentModalApiId === openId && setSavedData(viewedData);
  }, [currentModalApiId]);
  useEffect(() => {
    setBlockButtons(!isViewInProcess && !isAddInProcess);
  }, [isViewInProcess, isAddInProcess]);

  //will block body scroll when modal is open
  useBlockBodyScroll(isViewInProcess || isAddInProcess);

  return (
    <>
      <Modal isOpenModal={isViewInProcess || isAddInProcess}>
        <StyledModalContainer width={`${containerWidth}px`} padding={'24px 16px 30px 16px'}>
          <StyledCloseWhiteRoundButton
            onClick={() => {
              if (isAddInProcess) {
                handleRevert();
                setValidatingIsStarted(false);
              } else if (isEditInProcess) {
                handleRevert();
                setValidatingIsStarted(false);
                toggleView(null, null);
              } else {
                toggleView(null, null);
                setValidatingIsStarted(false);
              }
            }}
          />
          <Wrapper>
            <Text type={TextType.TITLE_H3} color={black} margin="0 0 14px 0">
              {title}
            </Text>
            <BodyWrapper>
              {arr
                .filter(
                  (elem: any) =>
                    (!isAddInProcess && !elem.excludedFromModalView) || !(elem.disabled || elem.excludedFromAdd),
                )
                .map((item: any, index: any) => {
                  const {
                    value,
                    fieldName,
                    label,
                    placeholder,
                    type,
                    selectItems,
                    component,
                    getTextDisplay,
                    modalColumns,
                    pathToSelectItems,
                    defaultValue,
                    disabled: isSingleDisabled,
                    rows,
                    selectItemsModifier,
                    isError,
                    tooltipText,
                    validatingIsStarted,
                    customComponentHasSelectView,
                    excludedTitleFromMobileViewEmptyData,
                    updateItem,
                    createItem,
                    deleteItem,
                    isUnassigned,
                    onlyIdForOnChangeSelect,
                  } = item;

                  let selectVal = value || defaultValue;
                  let selectList = selectItems;
                  if (type === RowItemType.SELECT || type === RowItemType.JOINED_SELECT) {
                    selectList =
                      selectItems ||
                      (pathToSelectItems && pathToSelectItems.length > 0
                        ? getValFromObj(pathToSelectItems, viewedData) || []
                        : []);
                    if (selectItemsModifier) {
                      selectList = selectItemsModifier(selectList);
                    }
                    const isIncluded = selectList.findIndex((val: any) => val.id === selectVal.id) !== -1;
                    selectList = isIncluded
                      ? selectList
                      : // isUnassigned value used for detach functionality in edit inventory modal
                      isUnassigned
                      ? [...selectList, selectVal]
                      : [selectVal, ...selectList];
                  }

                  const isEmptyValue = Object.keys(value ? value : {}).length === 0;
                  //is hide title component for View_Modal type(details view)in mobile version
                  const isHideViewModalMobile =
                    customComponentState === TableCustomComponentState.VIEW_MODAL &&
                    isMobileSize &&
                    // prop from column structure
                    excludedTitleFromMobileViewEmptyData;

                  return !type ||
                    type === RowItemType.INPUT ||
                    type === RowItemType.INPUT_NUMBER ||
                    type === RowItemType.INPUT_PHONE_NUMBER ||
                    type === RowItemType.INPUT_EMAIL ||
                    type === RowItemType.INPUT_CUSTOM ? (
                    <StyledInput
                      key={index}
                      type={type === RowItemType.INPUT_NUMBER ? 'number' : 'text'}
                      name={label}
                      value={getTextDisplay ? getTextDisplay(value) : value}
                      disabled={disabled || isSingleDisabled}
                      placeholder={placeholder}
                      onChange={(e: any) => handleInputChange(e, fieldName)}
                      isWide={modalColumns === 2}
                      rows={rows}
                      startedValidation={validatingIsStarted}
                      isError={isError && (isEditInProcess || isAddInProcess)}
                      isTooltip
                      tooltipText={tooltipText}
                    />
                  ) : type === RowItemType.SELECT ? (
                    <StyledSelect
                      value={selectVal}
                      label={label}
                      disabled={disabled || isSingleDisabled}
                      values={selectList}
                      onChange={(value: any) => handleSelectChange(value, fieldName, onlyIdForOnChangeSelect)}
                      isWide={modalColumns === 2}
                      placeholder={isEditInProcess || isAddInProcess ? placeholder : 'N/A'}
                      banAutoFocus={banAutoFocus}
                      startedValidation={validatingIsStarted}
                      isError={isError && (isEditInProcess || isAddInProcess)}
                      isPendingRequest={isPendingRequestFromModalApi}
                      updateItem={updateItem}
                      createItem={createItem}
                      deleteItem={deleteItem}
                      triggerColumnStructure={triggerColumnStructure}
                    />
                  ) : type === RowItemType.JOINED_SELECT ? (
                    <StyledSelect
                      value={value}
                      label={label}
                      disabled={disabled || isSingleDisabled}
                      values={selectList}
                      onChange={(value: any) => handleJoinedSelectChange(value, fieldName)}
                      isWide={modalColumns === 2}
                      placeholder={isEditInProcess || isAddInProcess ? placeholder : 'N/A'}
                      banAutoFocus={banAutoFocus}
                      startedValidation={validatingIsStarted}
                      isError={isError && (isEditInProcess || isAddInProcess)}
                      isPendingRequest={isPendingRequestFromModalApi}
                      updateItem={updateItem}
                      createItem={createItem}
                      deleteItem={deleteItem}
                      triggerColumnStructure={triggerColumnStructure}
                    />
                  ) : type === RowItemType.CUSTOM ? (
                    <CustomBlock
                      isWide={modalColumns}
                      className={customComponentHasSelectView ? 'custom-select-view' : ''}
                      startedValidation={validatingIsStarted}
                      isError={
                        (customComponentHasSelectView ? isError && validatingIsStarted : isError) &&
                        (isEditInProcess || isAddInProcess)
                      }
                      disabled={disabled || isSingleDisabled}
                      isShowMessage={validatingIsStarted && isError && !disabled}
                    >
                      {isHideViewModalMobile && isEmptyValue ? null : (
                        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 4px 6px">
                          {label}
                        </Text>
                      )}
                      <CustomInnerBlock disabled={disabled || isSingleDisabled}>
                        {isHideViewModalMobile && isEmptyValue
                          ? null
                          : component &&
                            component(value, customComponentState, (val: any) =>
                              handleSingleValueChange(val, fieldName),
                            )}
                      </CustomInnerBlock>
                    </CustomBlock>
                  ) : null;
                })}
            </BodyWrapper>
            <ButtonWrapper>
              {!isEditInProcess && !isAddInProcess ? (
                <>
                  <StyledButton
                    borderColor={primary}
                    borderColorHover={primary}
                    onClick={() => {
                      toggleView(null, null);
                      setValidatingIsStarted(false);
                    }}
                    isBlocked={blockButtons}
                  >
                    {languageStrings ? languageStrings.closeButton : 'Close'}
                  </StyledButton>
                  <ActionsWrapper>
                    {actionSet.map((item) => {
                      return item.type === ActionType.EDIT && accessData?.edit ? (
                        <StyledButton
                          onClick={() => {
                            setSavedData(viewedData);
                            toggleEdit();
                          }}
                          backgroundColor={white}
                          borderColor={primary}
                          backgroundColorHover={primary}
                          borderColorHover={primary}
                          color={black1}
                          isBlocked={blockButtons}
                        >
                          {languageStrings ? languageStrings.editButton : 'Edit'}
                        </StyledButton>
                      ) : !!item.component ? (
                        <CustomButtonWrapper
                          onClick={() => {
                            handleCallback(item.callback);
                          }}
                        >
                          {item.component}
                        </CustomButtonWrapper>
                      ) : null;
                    })}
                    {allowDelete && accessData?.delete && (
                      <StyledButton
                        onClick={handleDelete}
                        backgroundColor={white}
                        borderColor={red3}
                        backgroundColorHover={red3}
                        borderColorHover={red3}
                        color={black1}
                        isBlocked={blockButtons}
                      >
                        {languageStrings ? languageStrings.deleteButton : 'Delete'}
                      </StyledButton>
                    )}
                  </ActionsWrapper>
                </>
              ) : (
                <>
                  <ActionsWrapper>
                    <StyledButton
                      onClick={() => {
                        handleRevert();
                        setValidatingIsStarted(false);
                        keywordAddModalGlobalSearch && resetIsAddModalFromGlobalSearch();
                      }}
                      backgroundColor={white}
                      borderColor={primary}
                      backgroundColorHover={primary}
                      borderColorHover={primary}
                      color={black1}
                      isBlocked={blockButtons}
                    >
                      {languageStrings ? languageStrings.cancelButton : 'Cancel'}
                    </StyledButton>
                    <StyledButton
                      borderColor={primary}
                      borderColorHover={primary}
                      onClick={() => {
                        handleSave();
                        setSavedData(viewedData);
                        keywordAddModalGlobalSearch && resetIsAddModalFromGlobalSearch();
                      }}
                      isBlocked={blockButtons}
                    >
                      {languageStrings ? languageStrings.saveButton : 'Save'}
                    </StyledButton>
                  </ActionsWrapper>
                </>
              )}
            </ButtonWrapper>
          </Wrapper>
        </StyledModalContainer>
      </Modal>
      <Modal isOpenModal={isLocalDeleteInProcess || isDeleteInProcess}>
        <DeletePopupContainer padding="24px">
          <DeletePopup
            title={languageStrings ? languageStrings.confirmDelete : 'Are you sure?'}
            semiTitle={languageStrings ? languageStrings.entitiesDeleted : 'This entities will be permanently deleted:'}
            isOpenModal
            deletedElementsList={!isDeleteInProcess ? [localDeleteName] : nameList}
            handleOnClose={handleCloseDeleteModal}
            handleOnDelete={handleGlobalDelete}
          />
        </DeletePopupContainer>
      </Modal>
      {markedArr.length > 0 && !isDeleteInProcess && (
        <MobilePortalContainer zIndex={'999999999'}>
          <MobileSelectActionWrapper>
            <MobileSelectActionBlock onClick={toggleDelete}>
              <MobileSelectActionButton type={TextType.TEXT_14_RED}>
                {languageStrings ? languageStrings.deleteButton : 'Delete'}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
            <MobileSelectActionBlock onClick={handleCancel}>
              <MobileSelectActionButton type={TextType.TEXT_14_GRAY}>
                {languageStrings ? languageStrings.cancelButton : 'Cancel'}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
          </MobileSelectActionWrapper>
        </MobilePortalContainer>
      )}
      {markedArr.length === 0 && !isDeleteInProcess && mobileSelectMode && (
        <MobilePortalContainer zIndex={'999999999'}>
          <MobileCancelActionWrapper>
            <MobileSelectActionBlock onClick={handleCancel}>
              <MobileSelectActionButton type={TextType.TEXT_14_GRAY}>
                {languageStrings ? languageStrings.cancelButton : 'Cancel'}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
          </MobileCancelActionWrapper>
        </MobilePortalContainer>
      )}
    </>
  );
};
