import React from 'react';
import { ChooseParameterSelect } from '../../../../../../components/chooseParameterSelect/ChooseParameterSelect';
import { useFilters } from '../../../Content/filterContext/сontext';
import { getIsAllEntitySelect, getIsDisabledEntity } from '../../../Content/filterContext/selectors';

const FilterEntity = ({ languageStrings }: any) => {
  const { state, dispatch } = useFilters();
  const { entityState } = state;
  const { checkedEntityCount, isOpenEntityList, entityFilter } = entityState;

  const isSelectAllEntity = getIsAllEntitySelect(state);
  const isDisabledEntity = getIsDisabledEntity(state);

  const handleSelectAllAttach = () => {
    dispatch({ type: 'SELECT_ALL_ENTITY', payload: isSelectAllEntity });
  };

  const handleSelectOneAttach = (type: string) => {
    dispatch({ type: 'SELECT_ONE_ENTITY', payload: type });
  };

  const handleEntityList = (state: boolean) => {
    dispatch({ type: 'HANDLE_ENTITY_LIST', payload: state });
  };

  return (
    <ChooseParameterSelect
      name={languageStrings ? languageStrings.labelObjectType : 'Object type'}
      parameters={entityFilter}
      //@ts-ignore
      handleCheckedItem={handleSelectOneAttach}
      handleSelectAll={handleSelectAllAttach}
      setIsOpenSelectList={handleEntityList}
      isSelectAll={isSelectAllEntity}
      isOpenSelectList={isOpenEntityList}
      isSearchActive={false}
      isDisabled={isDisabledEntity}
      checkedCount={checkedEntityCount}
      maxWidth={'185px'}
    />
  );
};

export { FilterEntity };
