/**
 * @description
 * This component is used to search in management pages
 * Highly customisable.
 *
 */

import React, { useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ReactComponent as Icon } from '../../assets/Search.svg';
import { CloseButton } from '@bit/first-scope.close-button';
import { media } from '../../styles/media';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: ${(props: any) => (props.width ? props.width : '536px')};
  height: ${(props: any) => (props.height ? props.height : '40px')};
  position: relative;
  ${media.phone`
    width: 100%;
  `}
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.isActive ? (props.iconColor ? props.iconColor : props.theme.colors.primary) : props.theme.colors.gray3};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  outline: none;
  padding: 12px 12px 12px 36px;
  transition: all 0.3s linear;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: gray1;
  ::placeholder {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: gray2;
  }
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */
`;
const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: ${(props: any) => (props.right ? props.right : '')};
  left: ${(props: any) => (props.left ? props.left : '')};
`;
const SearchIcon = styled(Icon)`
  & > path {
    stroke: ${(props: any) => (props.iconColor ? props.iconColor : props.theme.colors.primary)};
  }
`;
const ResetButton = styled(CloseButton)`
  width: 18px;
  height: 18px;
  stroke-width: 2px;
`;

const ManagementSearchInputPlain = ({
  value,
  handleOnChange,
  placeholder,
  width,
  height,
  disabled,
  iconColor,
  ...props
}: {
  value: string;
  handleOnChange: (value: string) => void;
  placeholder: string;
  width?: string;
  height?: string;
  iconColor?: string;
  className?: string;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);
  const handleResetOnClick = () => {
    handleOnChange('');
  };

  return (
    <Wrapper {...props}>
      <Input
        theme={theme}
        value={value}
        onChange={(e: any) => handleOnChange(e.target.value)}
        placeholder={placeholder}
        onFocus={() => {
          setIsActive(true);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        isActive={isActive}
        iconColor={iconColor}
        disabled={disabled}
      />
      <IconWrapper left={'8px'}>
        <SearchIcon theme={theme} iconColor={iconColor} />
      </IconWrapper>
      {value && (
        <IconWrapper right={'8px'}>
          <ResetButton onClick={handleResetOnClick} />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

export const ManagementSearchInput = withLoader(undefined, PlaceholderType.SEARCH)(ManagementSearchInputPlain);
