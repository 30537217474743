import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../../app/state/userSlice';

const StyledContainer = styled(ModalContainer)`
  padding: 24px;
`;

export const EditModal = ({ isOpenModal, closeModal }: { isOpenModal: boolean; closeModal: () => void }) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  const { gray1, white, black } = theme.colors;

  return (
    <Modal isOpenModal={isOpenModal}>
      <StyledContainer width={'343px'}>
        <Text type={TextType.TEXT_14_GRAY} weight={'400'} color={gray1} margin={'0 0 15px 0'}>
          {languageStrings
            ? languageStrings.editingProhibited
            : 'Zone editing is available only in the desktop version'}
        </Text>
        <CommonButton onClick={closeModal} width={'104px'} height={'32px'} backgroundColor={white} color={black}>
          {languageStrings ? languageStrings.gotItButton : 'Got it'}
        </CommonButton>
      </StyledContainer>
    </Modal>
  );
};
