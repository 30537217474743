/**
 * @description
 * This is component for choose parameter in select
 * all the functionalities cthings require for chooseParameterSelect
 * Highly customisable.
 *
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ChooseParameterSelectPopup } from './components/chooseParameterSelectPopup/ChooseParameterSelectPopup';
import { ReactComponent as ArrowIcon } from '../../assets/small_arrow.svg';
import { TypeOfDeviceParameter } from '../../containers/common/campusMapInsight/Content/Content';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { media } from '../../styles/media';

const Wrapper = styled.div`
  max-width: ${(props: any) => (props.maxWidth ? props.maxWidth : '160px')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
  position: relative;
  ${media.desktop`
    margin-top: 10px;
  `}
`;
const FieldWrapper = styled.div`
  width: 100%;
  height: max-content;
  min-height: ${(props: any) => (props.minHeight ? props.minHeight : '31px')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 7px;
  border: 1px solid;
  border-color: ${(props: any) => (props.isOpenSelectList ? props.theme.colors.primary : props.theme.colors.gray3)};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  background-color: ${(props: any) =>
    props.isOpenSelectList || props.checkedCount > 0 || props.isSelectAll ? props.theme.colors.white : 'transparent'};
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${(props: any) => (props.isPopupDescription ? 'auto' : 'pointer')};
  &.disabled {
    cursor: auto;
    opacity: 0.4;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SelectExtraWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-right: 10px;
  }
`;

const Arrow = styled(ArrowIcon)`
  transform: ${(props: any) => props.isOpenSelectList && 'rotate(-180deg)'};
  transition: all 0.3s ease-in-out;
`;

const CheckedCount = styled(Text)`
  font-size: ${(props: any) => (props.fontSizeSelectNameCount ? props.fontSizeSelectNameCount : '12px')};
`;

const SelectName = styled(Text)`
  font-size: ${(props: any) => (props.fontSizeSelectNameCount ? props.fontSizeSelectNameCount : '12px')};
`;

const Field = styled.div`
  width: 100%;
  height: ${(props: any) => (props.fieldHeight < 116 ? 'max-content' : '100%')};
  max-height: 112px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow-y: ${(props: any) => (props.fieldHeight < 116 ? 'auto' : 'scroll')};

  &::-webkit-scrollbar {
    width: 4px;
    background: gray4;
    opacity: 1;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

export const ChooseParameterSelect = ({
  type,
  name,
  parameters,
  handleCheckedItem,
  handleSelectAll,
  setIsOpenSelectList,
  isSelectAll,
  isOpenSelectList,
  isDisabled,
  isPopupDescription,
  isSearchActive,
  checkedCount,
  maxWidth,
  minHeight,
  fontSizeSelectNameCount,
  maxWidthSelectPopup,
}: {
  type?: TypeOfDeviceParameter;
  name?: string;
  parameters: any[];
  handleCheckedItem: (type?: TypeOfDeviceParameter, id?: string) => void;
  handleSelectAll: () => void;
  setIsOpenSelectList: (value: boolean) => void;
  isSelectAll?: boolean;
  isOpenSelectList?: boolean;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
  isSearchActive?: boolean;
  checkedCount?: number;
  maxWidth?: string;
  minHeight?: string;
  fontSizeSelectNameCount?: string;
  maxWidthSelectPopup?: string;
}) => {
  const theme = useTheme();
  const [fieldHeight, setFieldHeight] = useState(40);

  const language = useSelector(selectLanguageStrings);

  const { gray1, gray2, black } = theme.colors;
  const popupRef = useRef<any>(null);

  const openSelectList = useCallback(() => {
    setIsOpenSelectList(true);
  }, []);
  const closeSelectList = useCallback(() => {
    setIsOpenSelectList(false);
  }, []);

  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpenSelectList && !(popupRef && popupRef.current && (popupRef as any).current.contains(e.target))) {
        closeSelectList();
      }
    },
    [isOpenSelectList, closeSelectList],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const parametersArr = parameters ? Object.keys(parameters).map((key: any) => parameters[key]) : [];

  return (
    <Wrapper name={name} maxWidth={maxWidth}>
      <FieldWrapper
        className={isDisabled && !isPopupDescription ? 'disabled' : ''}
        ref={(el: any) => {
          if (!el) return;
          setFieldHeight(el.getBoundingClientRect().height);
        }}
        theme={theme}
        isOpenSelectList={isOpenSelectList}
        checkedCount={checkedCount}
        isSelectAll={isSelectAll}
        onClick={isDisabled ? () => {} : isOpenSelectList ? closeSelectList : openSelectList}
        isPopupDescription={isPopupDescription}
        minHeight={minHeight}
      >
        <SelectWrapper>
          <SelectName
            type={TextType.TEXT_12_BLACK}
            color={isOpenSelectList || (!isSelectAll && checkedCount && checkedCount > 0) ? gray1 : gray2}
            fontSizeSelectNameCount={fontSizeSelectNameCount}
          >
            {name}
          </SelectName>
          <SelectExtraWrapper>
            <CheckedCount
              type={TextType.TEXT_12_BLACK}
              color={isOpenSelectList || (!isSelectAll && checkedCount && checkedCount > 0) ? black : gray1}
              fontSizeSelectNameCount={fontSizeSelectNameCount}
            >
              {isSelectAll ? (language ? language.labelAll : 'All') : checkedCount === 0 ? '0' : checkedCount}
            </CheckedCount>
            <Arrow isOpenSelectList={isOpenSelectList} />
          </SelectExtraWrapper>
        </SelectWrapper>
        {isOpenSelectList && (
          <ChooseParameterSelectPopup
            handleCheckedItem={handleCheckedItem}
            handleSelectAll={handleSelectAll}
            popupRef={popupRef}
            parameterList={parametersArr}
            fieldHeight={fieldHeight}
            isSelectAll={isSelectAll}
            isSearchActive={isSearchActive}
            maxWidthSelectPopup={maxWidthSelectPopup}
            language={language}
          />
        )}
      </FieldWrapper>
    </Wrapper>
  );
};
