import React from 'react';
import { ManageTitle } from '../../../manageTitle/ManageTitle';
import { useTableContext, useTableFunctions } from '../../context';

export const MobileInput = () => {
  const [data] = useTableContext();
  const { getSearchValue, setSearchValue, startAddProcess } = useTableFunctions();
  const { globalProps } = data;
  const { keyword } = globalProps;
  const { actual: searchValue } = getSearchValue();
  return (
    <ManageTitle
      keyword={keyword}
      searchInputValue={searchValue}
      handleChangeSearchInputValue={setSearchValue}
      addButtonOnClick={startAddProcess}
      hideTitle={true}
      paddingDevice={'64px 0 12px 0'}
    />
  );
};
