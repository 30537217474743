import React from 'react';
import { MenuItemType } from '../MenuItem';
//images
import { ReactComponent as SearchIcon } from '../../../assets/Menu/search.svg';
import { ReactComponent as DashboardsIcon } from '../../../assets/Menu/dashboards.svg';
import { ReactComponent as SitesIcon } from '../../../assets/Menu/sites.svg';
import { ReactComponent as InventoriesIcon } from '../../../assets/Menu/inventories.svg';
import { ReactComponent as UsersIcon } from '../../../assets/Menu/users.svg';
import { ReactComponent as DevicesIcon } from '../../../assets/Menu/devices.svg';
import { ReactComponent as OperationsIcon } from '../../../assets/Menu/operations.svg';
import { ReactComponent as ServicesIcon } from '../../../assets/Menu/services.svg';
import { ReactComponent as DocumentsIcon } from '../../../assets/Menu/documents.svg';

import { ReactComponent as SettingsImage } from '../../../assets/Settings.svg';
import { ReactComponent as LogOutImage } from '../../../assets/Logout.svg';

export const ItemImage = ({ type }: { type: MenuItemType }) => {
  switch (type) {
    case MenuItemType.SEARCH:
      return <SearchIcon />;
    case MenuItemType.DASHBOARDS:
      return <DashboardsIcon />;
    case MenuItemType.SITES:
      return <SitesIcon />;
    case MenuItemType.INVENTORIES:
      return <InventoriesIcon />;
    case MenuItemType.USERS:
      return <UsersIcon />;
    case MenuItemType.DEVICES:
      return <DevicesIcon />;
    case MenuItemType.OPERATIONS:
      return <OperationsIcon />;
    case MenuItemType.SERVICES:
      return <ServicesIcon />;
    case MenuItemType.DOCUMENTS:
      return <DocumentsIcon />;
    case MenuItemType.SETTINGS:
      return <SettingsImage />;
    case MenuItemType.LOGOUT:
      return <LogOutImage />;
    default:
      return <></>;
  }
};
