/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings search select require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CloseButton } from '@bit/first-scope.close-button';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 14px 14px 8px;
  border-bottom: 1px solid !important;
  border-color: gray3 !important;
  border-radius: ${(props: any) => `${props.theme.borderRadius.primary} ${props.theme.borderRadius.primary} 0 0`};
  background-color: white;
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.primary};
  }
`;
const Input = styled.input`
  width: calc(100% - 36px);
  margin-left: 2px;
  border: none;
  padding: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  font-weight: 400;
  color: gray1;
  &::placeholder {
    color: gray2;
  }
`;

export const SearchInputSelect = ({
  value,
  handleOnChange,
  handleClearInput,
  setIsEditing,
  languageStrings,
  isFocused,
  banAutoFocus,
}: {
  value: string;
  handleOnChange: (value: string) => void;
  handleClearInput: () => void;
  setIsEditing?: (e: any) => void;
  languageStrings: any;
  isFocused?: boolean;
  banAutoFocus?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Wrapper
      onClick={(e: any) => {
        e.stopPropagation();
        setIsEditing && setIsEditing(null);
      }}
      theme={theme}
    >
      <SearchIcon />
      <Input
        value={value}
        onChange={handleOnChange}
        placeholder={languageStrings ? languageStrings.searchBarPlaceholder : 'Choose or create one...'}
        autoFocus={banAutoFocus ? false : isFocused}
      />
      {value && <CloseButton onClick={handleClearInput} width={'16px'} height={'16px'} strokeWidth={'2px'} />}
    </Wrapper>
  );
};
