import React, { createContext, useContext, useReducer } from 'react';
import { initialFilterState } from './initialState';
import { reducer } from './reducer';

const FiltersContext = createContext<any | null>(null);

const FiltersProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialFilterState);
  const value = { state, dispatch };
  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

const useFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within the Filters Provider');
  }
  return context;
};

export { FiltersProvider, useFilters };
