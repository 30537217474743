import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../../../../../styles/media';
import { View } from '../../../../../../../routes/routeInterfaces';
import { useCustomHistory } from '../../../../../../../utils/react-router-dom-abstraction';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  height: max-content;
  grid-template-columns: ${(props: any) => (props.gridTemplate ? props.gridTemplate : '1.25fr 1.5fr')};
  grid-gap: 24px;
  padding: 11px 22px 10px 22px;
  box-sizing: border-box;
  background-color: white;
  border-top: ${(props: any) => (props.isFirst ? 'none' : '1px solid')};
  border-color: gray4;
  border-radius: ${(props: any) => (props.isLast ? '0 0 8px 8px' : 'none')};
  ${media.phone`
    height: max-content;
    padding: 11px 18px 11px 16px;
`}
`;

const WrapperText = styled(Text)`
  color: gray1;
  &.underline {
    text-decoration: underline;
    color: primary;
    cursor: pointer;
    &:hover {
      color: blue12;
    }
  }
`;

export const TableRow = ({
  property,
  value,
  isFirst,
  isLast,
  gridTemplate,
  orderId,
  packageId,
  locationId,
  zoneId,
  ...props
}: {
  property: string;
  value: string;
  isFirst?: boolean;
  isLast?: boolean;
  gridTemplate?: string;
  className?: string;
  orderId?: string;
  packageId?: string;
  locationId?: string;
  zoneId?: string;
}) => {
  const customHistory = useCustomHistory();

  const redirectToOrder = () => {
    const to = View.MANAGE_ORDERS_DETAILS;
    to &&
      customHistory.push(to, {
        state: { package_id: packageId },
        pathParams: { id: orderId, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
      });
  };

  const redirectToLocation = () => {
    const to = View.MANAGE_ZONES_DETAILS;
    to &&
      customHistory.push(to, {
        state: { zone_id: zoneId },
        pathParams: { building_id: locationId, floor: 0, zone_id: zoneId ?? 'noselect', offset: 0 },
      });
  };

  return (
    <Wrapper isFirst={isFirst} isLast={isLast} gridTemplate={gridTemplate} {...props}>
      <WrapperText type={TextType.TEXT_14_BLACK} weight={'400'}>
        {property}
      </WrapperText>
      <WrapperText
        onClick={orderId || packageId ? redirectToOrder : locationId || zoneId ? redirectToLocation : null}
        type={TextType.TEXT_14_BLACK}
        className={(orderId || locationId) && 'underline'}
        weight={'500'}
      >
        {value || 'N/A'}
      </WrapperText>
    </Wrapper>
  );
};
