import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../../styles/media';
import { useTableFunctions } from '../../../features/universalTable/context';
import { deleteComponentsGlobal, updateComponent, useGetProcess } from '../../../api/manageComponents';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { MetaDataComponent } from '../processes/components/metaDataComponent/MetaDataComponent';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { API_URL } from '../../../consts';
import { useMediaType } from '../../../styles/style.context';
import { View } from '../../../routes/routeInterfaces';
import { useLocation } from 'react-router-dom';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

export interface ComponentsPageProps {
  match: any;
  className?: string;
}

export const ComponentsPage: FC<ComponentsPageProps> = ({ match, ...props }) => {
  const { startAddProcess, setSearchValue, getSearchValue, resetTable } = useTableFunctions();
  const { actual: searchValue } = getSearchValue();
  const mediaType = useMediaType();
  const keyword = 'ComponentsTable';
  const offsetKey = 'offset';
  const accessData = useSelector(selectAccess).management.components;
  const conditionForIndex = /^\s*$|\d|./;

  const offset = match.params[offsetKey];
  const { state }: any = useLocation();
  const processes = useGetProcess();

  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper {...props}>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfComponentsPage : 'Components'}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        searchInputValue={searchValue}
        addButtonOnClick={startAddProcess}
        handleChangeSearchInputValue={setSearchValue}
        accessData={accessData}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_COMPONENTS}
        updateItems={updateComponent}
        deleteItems={deleteComponentsGlobal}
        allowDelete={accessData.delete}
        additionalKeywords={['ManageLayoutSearch']}
        actionSet={[{ type: ActionType.EDIT }]}
        inlineActionSet={[{ type: InlineActionType.DETAILS }]}
        idItemForViewModal={state?.state?.id}
        mainApiUrl={`${API_URL}/v1/components/`}
        keyword={keyword}
        accessData={accessData}
        pageSize={mediaType.phone ? 6 : 18}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings ? languageStrings.namePlaceholder : 'Name',
            label: languageStrings ? languageStrings.labelName : 'Name',
            defaultValue: '',
            type: RowItemType.INPUT,
          },
          {
            fieldName: 'index',
            placeholder: languageStrings ? languageStrings.indexPlaceholder : 'Index',
            label: languageStrings ? languageStrings.labelIndex : 'Index',
            defaultValue: '',
            type: RowItemType.INPUT,
          },
          {
            fieldName: 'index_desc',
            placeholder: languageStrings ? languageStrings.indexDescriptionPlaceholder : 'Index description',
            label: languageStrings ? languageStrings.labelIndexDescription : 'Index description',
            defaultValue: '',
            type: RowItemType.INPUT,
          },
          {
            fieldName: 'max_pckg_vol',
            placeholder: languageStrings ? languageStrings.MaximumValuePlaceholder : 'Maximum package value',
            label: languageStrings ? languageStrings.labelMaxValue : 'Max package value',
            conditionForValidationInCustom: conditionForIndex,
            defaultValue: 0,
            type: RowItemType.INPUT_NUMBER,
          },
          {
            fieldName: 'process_name',
            type: RowItemType.JOINED_SELECT,
            placeholder: languageStrings ? languageStrings.processNamePlaceholder : 'Process Name',
            label: languageStrings ? languageStrings.labelProcess : 'Process',
            defaultValue: { name: '', id: '' },
            selectItems: processes,
          },
          {
            fieldName: 'metadata',
            placeholder: '',
            label: languageStrings ? languageStrings.labelMetadata : 'Metadata',
            defaultValue: {},
            modalColumns: 3,
            type: RowItemType.CUSTOM,
            excludedFromView: true,
            // hide title component for View_Modal in mobile version
            excludedTitleFromMobileViewEmptyData: true,
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
              return <MetaDataComponent value={value} state={state} setValue={setValue} />;
            },
          },
        ]}
      />
    </Wrapper>
  );
};
