/**
 * @description
 * This is component for search CampusMapInsight
 * all the functionalities cthings require for CampusMapInsight
 * Highly customisable.
 *
 */

import React, { useEffect } from 'react';
import { useState } from 'react';
import { useFloorplanLocationLive } from '../../../api/floorsLocationPlan';
import { usePrevious } from '../../../utils/usePrevious';
import { Content } from './Content/Content';
import { useFloorPlanDetails } from '../../../api/locations';

export const CampusMapInsight = ({ ...props }: any) => {
  const id = props.match?.params.id || '';

  const [imageSize, setImageSize] = useState({ height: 0, width: 0 });
  const data = useFloorPlanDetails(id);

  const prevId = usePrevious(id);

  const pathParams = props.match.params;

  const [dataLocationsLive] = useFloorplanLocationLive(
    id,
    data?.floors[0]?.floor_id,
    Math.floor(imageSize.height),
    Math.floor(imageSize.width),
  );

  useEffect(() => {
    if (prevId && prevId !== id) {
      window.location.reload();
    }
  }, [id]);

  return (
    <Content
      data={data}
      dataLocationsLive={dataLocationsLive}
      pathParams={pathParams}
      setImageSize={setImageSize}
      id={id}
    />
  );
};
