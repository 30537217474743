/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings tables require.
 * Highly customisable.
 * Background logic is contained in context.tsx
 *
 */

import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Head } from './components/head/Head';
import { useTableContext, useTableFunctions } from './context';
import { Row } from './components/row/Row';
import { ActionConfig, ColumnStructure, InlineActionConfig, ModalApi, ModalColumnCount, RowItemType } from './types';
import { ModalSystem } from './components/modalSystem/ModalSystem';
import { withLoader } from '../placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../components/placeholders/typePlaceholders/placeholdersType';
import { ManagePagination } from './components/managePagination';
import { View } from '../../routes/routeInterfaces';
import { NoDataPlaceholder } from './components/noDataPlaceholder/NoDataPlaceholder';
import { UNIVERSAL_TABLE_ANCHOR_ID } from './components/mobileInputAnchor/MobileInputAnchor';
import { MobileInput } from './components/mobileInput/MobileInput';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../app/state/userSlice';
import { useWindowSize } from '../../styles/style.context';

const MainWrapper = styled.div`
  position: relative;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow2};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
`;

const BackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  z-index: 1;
`;

export interface UniversalTableProps {
  withMobileSearch?: boolean;
  updateItems?: any;
  mobileHeadless?: boolean;
  deleteItems?: any;
  onPageChange?: any;
  pageSize?: number;
  columnStructure: ColumnStructure[];
  allowDelete?: boolean;
  allowSelect?: boolean;
  selectComponent?: (selectedList: any[]) => ReactNode;
  titleActionComponent?: ReactNode;
  actionSet?: ActionConfig[];
  inlineActionSet?: InlineActionConfig[];
  mobileLimit?: number;
  modalColumnCount?: ModalColumnCount;
  customRowClick?: (value: any) => void;
  modalApi?: ModalApi;
  indexOfItemInPayload?: string;
  withoutQuestionMarkBeforeLimit?: boolean;
  dynamicFlags?: boolean[];
  keyword?: string;
  additionalKeywords?: string[];
  mainApiUrl?: string;
  page?: number;
  eventNum?: any;
  offset?: number;
  tablePath?: typeof View[keyof typeof View];
  pathParams?: any;
  offsetKey?: any;
  apiTriggers?: any[];
  columnStructureTriggers?: any[];
  idItemForViewModal?: string;
  banAutoFocus?: boolean;
  withHeaderInMobile?: boolean;
  tableAddToggle?: any[];
  withHeaderGridTemplate?: string;
  withRowGridTemplate?: string;
  keywordAddModalGlobalSearch?: string | undefined;
  resetIsAddModalFromGlobalSearch?: () => void;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}

const UniversalTablePlain: FC<UniversalTableProps> = ({
  withMobileSearch = false,
  updateItems,
  mobileHeadless,
  deleteItems,
  onPageChange,
  pageSize = 6,
  columnStructure,
  allowDelete,
  allowSelect,
  selectComponent,
  titleActionComponent,
  actionSet = [],
  inlineActionSet = [],
  mobileLimit = 4,
  modalColumnCount,
  customRowClick,
  modalApi,
  indexOfItemInPayload,
  withoutQuestionMarkBeforeLimit,
  dynamicFlags,
  keyword,
  additionalKeywords = [],
  mainApiUrl,
  offset,
  tablePath,
  pathParams = {},
  offsetKey,
  apiTriggers = [],
  columnStructureTriggers = [],
  idItemForViewModal,
  banAutoFocus,
  withHeaderInMobile,
  withHeaderGridTemplate,
  withRowGridTemplate,
  keywordAddModalGlobalSearch,
  accessData,
  // this method to reset the status "addModal" from the global search
  resetIsAddModalFromGlobalSearch,
}) => {
  const theme = useTheme();

  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage).shortName;
  const windowWidth = useWindowSize()[0];

  const [isSelectAllCheckBox, setIsSelectAllCheckBox] = useState(false);
  const [isPendingRequestFromModalApi, setIsPendingRequestFromModalApi] = useState(false);
  const [columnStructureTriggerCounter, setColumnStructureTriggerCounter] = useState(1);
  const triggerColumnStructure = () => setColumnStructureTriggerCounter((val) => val + 1);

  const [{ highlightedIndex, list, isAddInProcess }] = useTableContext();

  const { setGlobalProps, setHighlightedIndex, setPage, startAddProcess, getReadyState } = useTableFunctions();

  const pathArray = Object.keys(pathParams).reduce((acc: any[], key: any) => [...acc, pathParams[key]], []);

  const dataReady = getReadyState();

  // @NOTE this condition can be improved later to check if all the dynamic data in
  // column structure is ready
  // For this checks only the select items as they can be missing in some cases
  const columnStructureReady =
    columnStructure.findIndex(
      (column) =>
        (column.type === RowItemType.SELECT || column.type === RowItemType.JOINED_SELECT) &&
        !(column.selectItems || column.pathToSelectItems),
    ) === -1 &&
    (!dynamicFlags || dynamicFlags?.findIndex((flag) => !flag) === -1);

  const selectItemsTrigger = columnStructure.reduce((acc, currentItem) => {
    const hasSelectItems =
      (currentItem.type === RowItemType.SELECT || currentItem.type === RowItemType.JOINED_SELECT) &&
      currentItem.selectItems;
    const increment = hasSelectItems ? currentItem?.selectItems?.length || 0 : 0;
    return acc + increment;
  }, 0);

  useEffect(() => {
    setGlobalProps({ apiTriggers });
  }, [...apiTriggers]);

  useEffect(() => {
    const hasActions = allowDelete || allowSelect || inlineActionSet.length > 0 || !!customRowClick;

    setGlobalProps({
      allowDelete,
      allowSelect,
      mobileLimit,
      modalApi,
      mainApiUrl,
      indexOfItemInPayload,
      withoutQuestionMarkBeforeLimit,
      keywordList: [keyword, ...additionalKeywords],
      keyword,
      pageSize,
      offsetKey,
      tablePath,
      withHeaderInMobile,
      setIsPendingRequestFromModalApi,
      hasActions,
    });
  }, [
    mainApiUrl,
    indexOfItemInPayload,
    withoutQuestionMarkBeforeLimit,
    allowDelete,
    allowSelect,
    mobileLimit,
    pageSize,
    offsetKey,
    tablePath,
    withHeaderInMobile,
  ]);

  useEffect(() => {
    columnStructureReady && setGlobalProps({ columnStructure });
  }, [columnStructureReady, columnStructureTriggerCounter, selectItemsTrigger, ...columnStructureTriggers]);

  useEffect(() => {
    offset && setPage(Math.floor(offset / pageSize));
  }, [offset, pageSize]);

  useEffect(() => {
    setGlobalProps({ pathParams });
  }, [...pathArray]);

  useEffect(() => {
    if (
      dataReady &&
      !isAddInProcess &&
      startAddProcess &&
      keywordAddModalGlobalSearch &&
      keywordAddModalGlobalSearch === keyword
    ) {
      startAddProcess();
    }
  }, [dataReady, keywordAddModalGlobalSearch, startAddProcess]);

  useEffect(() => {
    if (windowWidth < 1024 || language !== 'EN' || language === 'EN' || isAddInProcess) {
      setGlobalProps({ columnStructure });
    }
  }, [language, windowWidth, isAddInProcess]);

  const isHighlighted = highlightedIndex !== -1;

  const node = useRef<any>(null);
  node.current = document.getElementById(UNIVERSAL_TABLE_ANCHOR_ID);

  const createInput = useCallback(() => {
    return node && node.current ? ReactDOM.createPortal(<MobileInput />, node.current) : null;
  }, [node]);

  return (
    <MainWrapper theme={theme}>
      {createInput()}
      <Head
        titleActionComponent={titleActionComponent}
        allowDelete={!!allowDelete}
        allowSelect={!!allowSelect}
        selectComponent={selectComponent}
        withHeaderInMobile={withHeaderInMobile}
        withHeaderGridTemplate={withHeaderGridTemplate}
        isSelectAllCheckBox={isSelectAllCheckBox}
        languageStrings={languageStrings}
      />
      {list.length > 0 ? (
        list
          .slice(0, pageSize)
          .map((item: any, index: number) => (
            <Row
              data={item}
              index={index}
              setLastListItem={list.length - 1}
              key={index}
              allowDelete={!!allowDelete}
              allowSelect={!!allowSelect}
              inlineActionSet={inlineActionSet}
              customRowClick={customRowClick}
              isFirst={index === 0}
              idItemForViewModal={idItemForViewModal}
              rounded={index === 0 && !!mobileHeadless}
              withHeaderInMobile={withHeaderInMobile}
              withRowGridTemplate={withRowGridTemplate}
              setIsSelectAllCheckBox={setIsSelectAllCheckBox}
              isSelectAllCheckBox={isSelectAllCheckBox}
              languageStrings={languageStrings}
            />
          ))
      ) : (
        <NoDataPlaceholder languageStrings={languageStrings} />
      )}

      <ManagePagination
        onPageChange={
          !mainApiUrl
            ? onPageChange
            : (page: number, isOffset: boolean) => setPage(page, { isOffset, replacePath: true })
        }
        editInProcess={false}
      />
      <ModalSystem
        actionSet={actionSet}
        deleteItems={deleteItems}
        allowDelete={!!allowDelete}
        modalColumnCount={modalColumnCount || 2}
        updateItems={updateItems}
        banAutoFocus={banAutoFocus}
        keywordAddModalGlobalSearch={keywordAddModalGlobalSearch}
        resetIsAddModalFromGlobalSearch={resetIsAddModalFromGlobalSearch}
        accessData={accessData}
        isPendingRequestFromModalApi={isPendingRequestFromModalApi}
        triggerColumnStructure={triggerColumnStructure}
        languageStrings={languageStrings}
      />
      {isHighlighted && <BackgroundBlur theme={theme} onClick={() => setHighlightedIndex(-1)} />}
    </MainWrapper>
  );
};

export const UniversalTable = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(UniversalTablePlain);
