/**
 * @description
 * This is component that displays info about zone on zonesFloorplan
 */
import React from 'react';

import { DesktopView } from './components/desktopView/DesktopView';
import { MobileView } from './components/mobileView/MobileView';

export const DescriptionPopup = ({
  handleCancelOnClick,
  handleDeleteOnClick,
  handleApplyOnClick,
  handleEditOnClick,
  isEdit,
  value,
  onChange,
  onRevert,
  closeModal,
  pixel_ratio,
  pictureSize,
  isRight,
  accessData,
}: {
  handleCancelOnClick: () => void;
  handleDeleteOnClick: () => void;
  handleApplyOnClick: any;
  handleEditOnClick: () => void;
  closeModal: any;
  value: any;
  onChange: any;
  onRevert: any;
  isEdit?: boolean;
  pixel_ratio: number;
  pictureSize: { height: number; width: number };
  isRight: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}) => {
  return (
    <>
      <DesktopView
        handleCancelOnClick={handleCancelOnClick}
        handleDeleteOnClick={handleDeleteOnClick}
        handleApplyOnClick={handleApplyOnClick}
        handleEditOnClick={handleEditOnClick}
        pictureSize={pictureSize}
        isEdit={isEdit}
        value={value}
        onChange={onChange}
        onRevert={onRevert}
        closeModal={closeModal}
        pixel_ratio={pixel_ratio}
        isRight={isRight}
        accessData={accessData}
      />
      <MobileView
        handleDeleteOnClick={handleDeleteOnClick}
        closeModal={closeModal}
        isEdit={isEdit}
        value={value}
        accessData={accessData}
      />
    </>
  );
};
