/**
 * @description
 * This is component for animation SinnerBounce
 *
 */

import React from 'react';
// @ts-ignore
import styled, { keyframes } from '@xstyled/styled-components';

import { theme } from '../../styles/theme';

const scaleout = keyframes`
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
`;

const Wrapper = styled.div`
  width: 40px;
  height: 40px;

  background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : theme.colors.primary)};

  border-radius: 100%;

  position: relative;

  margin: 100px auto;

  -webkit-animation: ${scaleout} 1s infinite ease-in-out;
  animation: ${scaleout} 1s infinite ease-in-out;
`;

export const SpinnerBounce = ({ className, backgroundColor }: { className?: string; backgroundColor?: string }) => {
  return <Wrapper className={`spinner ${className}`} backgroundColor={backgroundColor} />;
};
