import { useEffect, useState } from 'react';

import { API_URL, NEW_ID } from '../consts';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { HttpService } from '../utils/http';

export const useStagesList = (setPlaceholder: any, stopPlaceholder: any) => {
  const [stages, setStages] = useState([]);
  const trigger = [] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/stages/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data;
        setStages(responseData.payload);
      } catch (e) {
        //
      }
    })();
  }, [...trigger, loaderOptions]);

  return [stages];
};

export const updateProcess = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/processes/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  } else {
    const url = `${API_URL}/v1/processes/`;
    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        const { payload } = responseData;
        const { id, ...responseItems } = payload;
        updateLocalItem(id, responseItems);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  }
};

export const deleteProcessesGlobal = (
  id: string[],
  deleteProcess: any,
  deteleCheckedProcesses: any,
  handleAddNotification: Function,
) => {
  const data = { process_ids: id };
  const url = `${API_URL}/v1/processes/delete`;
  const deleteOneProcess = () => deleteProcess(id[0]);

  (async () => {
    try {
      let responseData = (await HttpService.delete(url, data)).data;
      handleAddNotification({
        message: 'Successfully deleted',
        type: 'success',
      });
    } catch (e) {
      const error = e as any;
      handleAddNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
  if (id.length === 1) {
    deleteOneProcess();
  } else {
    deteleCheckedProcesses();
  }
};
