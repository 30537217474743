import React from 'react';
import { ChooseParameterSelect } from '../../../../../../components/chooseParameterSelect/ChooseParameterSelect';
import { useFilters } from '../../../Content/filterContext/сontext';
import { getIsAllAttachSelect, getIsDisabledAttach } from '../../../Content/filterContext/selectors';

const FilterAttach = ({ languageStrings }: any) => {
  const { state, dispatch } = useFilters();
  const { attachState } = state;
  const { checkedAttachCount, isOpenAttachList, attachFilter } = attachState;

  const isSelectAllAttach = getIsAllAttachSelect(state);
  const isDisabledAttach = getIsDisabledAttach(state);

  const handleSelectAllAttach = () => {
    dispatch({ type: 'SELECT_ALL_ATTACH', payload: isSelectAllAttach });
  };

  const handleSelectOneAttach = (typeAttach: string) => {
    dispatch({ type: 'SELECT_ONE_ATTACH', payload: { typeAttach } });
  };

  const handleAttachList = (state: boolean) => {
    dispatch({ type: 'HANDLE_ATTACH_LIST', payload: state });
  };

  return (
    <ChooseParameterSelect
      name={languageStrings ? languageStrings.labelAttach : 'Attach'}
      parameters={attachFilter}
      //@ts-ignore
      handleCheckedItem={handleSelectOneAttach}
      handleSelectAll={handleSelectAllAttach}
      setIsOpenSelectList={handleAttachList}
      isSelectAll={isSelectAllAttach}
      isOpenSelectList={isOpenAttachList}
      isSearchActive={false}
      isDisabled={isDisabledAttach}
      checkedCount={checkedAttachCount}
      maxWidth={'185px'}
    />
  );
};

export { FilterAttach };
