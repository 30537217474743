import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { Text, TextType } from '@bit/first-scope.text';
import { CloseWhiteRoundButton } from '../../../../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { CommonButton } from '@bit/first-scope.common-button';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: ${(props: any) =>
    `${props.theme.borderRadius.additional16} ${props.theme.borderRadius.additional16} 0 0`};
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled(Text)`
  font-size: 18px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DeleteModal = ({
  isOpenModal,
  closeModal,
  deleteZone,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  deleteZone: () => void;
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  const { gray1, white, black, red3, red2 } = theme.colors;
  return (
    <Modal isOpenModal={isOpenModal}>
      <Wrapper>
        <TitleWrapper>
          <Title type={TextType.TITLE_PRIMARY}>
            {languageStrings ? languageStrings.confirmDelete : 'Are you sure?'}
          </Title>
          <CloseWhiteRoundButton onClick={closeModal} />
        </TitleWrapper>
        <Text type={TextType.TEXT_14_GRAY} weight={'400'} color={gray1} margin={'0 0 25px 0'}>
          {languageStrings ? languageStrings.mobileEntitiesDeleted : 'This zone will be permanently deleted!'}
        </Text>
        <ButtonsWrapper>
          <CommonButton
            onClick={deleteZone}
            width={'100%'}
            height={'40px'}
            backgroundColor={red3}
            borderColor={red3}
            color={white}
            backgroundColorHover={red2}
            borderColorHover={red2}
          >
            {languageStrings ? languageStrings.confirmDeleteButton : 'Yes, do it'}
          </CommonButton>
          <CommonButton onClick={closeModal} width={'100%'} height={'40px'} backgroundColor={white} color={black}>
            {languageStrings ? languageStrings.canselDeleteButton : 'No, cancel'}
          </CommonButton>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};
