import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';

const Level = styled.span`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  color: gray1;
`;

export const LevelView = ({ value }: { value: any }) => {
  return <Level>{value || value === 0 ? value : 'N/A'}</Level>;
};
