/**
 * @description
 * This is container for graphs in dashboard
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { Wrapper } from '../zonesCapacityGraph/ZonesCapacityGraph';
import { Graph } from './graph/Graph';
import { withLoader } from '../../../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { NoDataPlaceholder } from '../../../../../components/nodataPlaceholder/NoDataPlaceholder';

export interface DistributionGraphProps {
  data: any[];
  title: string;
}

const Container = styled(Wrapper)`
  padding: 22px;
`;

const DistributionGraphPlain: FC<DistributionGraphProps> = ({ data, title, ...props }) => {
  return (
    <Container height={'max-content'} {...props}>
      <Text type={TextType.TEXT_12_GRAY} weight={'500'} margin={'0 0 23px 0'}>
        {title}
      </Text>
      {data.length > 0 ? <Graph data={data} /> : <NoDataPlaceholder />}
    </Container>
  );
};

export const DistributionGraph = withLoader(undefined, PlaceholderType.GRAPH)(DistributionGraphPlain);
