/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for textarea with autosize require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { media } from '../../styles/media';

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;

  box-sizing: border-box;
  background-color: white;
  color: gray1;
  ::placeholder {
    font-family: 'Poppins', sans-serif;
    color: gray2;
  }
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid
    ${(props: any) =>
      props.disabled
        ? props.theme.colors.gray5
        : props.isFocused
        ? props.theme.colors.primary
        : props.isError
        ? props.theme.colors.red3
        : props.theme.colors.gray3};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  padding: ${(props: any) => (props.image ? '8px 36px 8px 38px' : '8px 36px 8px 12px')};
  outline: none;
  resize: none;
  transition: border 0.3s linear;

  &.disabled {
    background-color: gray5 !important;
    -webkit-safari-color: ${(props: any) => props.theme.colors.gray1};
    color: gray1 !important;
    -webkit-text-fill-color: ${(props: any) => props.theme.colors.gray1};
    &::placeholder {
      -webkit-safari-color: ${(props: any) => props.theme.colors.gray1};
      color: gray1;
    }
  }
  -webkit-text-fill-color: gray1;
  opacity: 1;
  ${media.tablet`
    -webkit-appearance: caret;
    -moz-appearance: caret; /* mobile firefox too! */
`}
`;

export const TextareaModal = ({
  value,
  placeholder,
  onChange,
  onClick,
  onFocus,
  onBlur,
  isFocused,
  isError,
  disabled,
  isImage,
  className,
  rows,
}: {
  value: string;
  placeholder: string;
  onChange: () => void;
  onClick: () => void;
  onFocus: () => void;
  onBlur: () => void;
  isFocused?: boolean;
  isError?: boolean;
  disabled?: boolean;
  isImage?: boolean;
  className?: string;
  rows?: number;
}) => {
  const theme = useTheme();
  const { black } = theme.colors;

  return (
    <Textarea
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      isFocused={isFocused}
      isError={isError}
      disabled={disabled}
      isImage={isImage}
      className={disabled ? 'disabled' : className ? className : ''}
      theme={theme}
      color={black}
      maxRows={rows}
    />
  );
};
