import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../../../../styles/media';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
// import { InfoContentTable } from './infoContentTable/InfoContentTable';
import { ButtonsContainer } from './components/buttonsContainer/ButtonsContainer';
import { DeviceDetailsType } from '../../../../../enums/View';
import { PlaceholderType } from '../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { withLoader } from '../../../../../features/placeholderComponent/loaderFunctions';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../routes/routeInterfaces';
import { useMediaType } from '../../../../../styles/style.context';
import { InfoContentTable } from '../secondTableInfo/infoContentTable/InfoContentTable';

const Wrapper = styled.div`
  min-width: 980px;
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  ${media.monitor`
    min-width: 588px;
  `}
  ${media.tablet`
    min-width: 288px;
  `}
`;

const DeviceFirstTableInfoPlain = ({
  setIsAttach,
  isAttach,
  data = [],
  headerLabels,
  headerGridTemplate,
  type,
  handleDeAttachDeviceApi,
  setShowAttachModal,
  languageStrings,
}: {
  setIsAttach: any;
  isAttach: any;
  data: any;
  headerLabels: any;
  headerGridTemplate: any;
  type: any;
  setShowAttachModal: any;
  handleDeAttachDeviceApi: any;
  languageStrings?: any;
}) => {
  const { ts_installed, location, id, name, mac_id } = data;

  const customHistory = useCustomHistory();
  const mediaType = useMediaType();

  const arrTracker = [
    { property: languageStrings ? languageStrings.labelName : 'Name', value: `${name} ` },
    {
      property: languageStrings ? languageStrings.labelLocation : 'Location',
      value: location?.address?.city === '' ? 'N/A' : `${location?.address?.city}, ${location?.address?.zip} `,
      locationId: location?.building_id,
    },
    {
      property: languageStrings ? languageStrings.labelZone : 'Zone',
      value: location?.zone,
      locationId: location?.building_id,
      zoneId: location?.zone_id,
    },
    {
      property: languageStrings ? languageStrings.labelCoordinates : 'Coordinates',
      value:
        location?.address?.geotag?.lat === '0'
          ? 'N/A'
          : `${location?.address?.geotag?.lat}, ${location?.address?.geotag?.lng}`,
    },
    { property: languageStrings ? languageStrings.labelMacAddress : 'Mac address', value: `${mac_id}` },
    {
      property: languageStrings ? languageStrings.labelInstalledAt : 'Installed at',
      value: getRequiredDateFormat(ts_installed, 'DD.MM.YYYY [at] HH:mm'),
    },
  ];

  return (
    <Wrapper>
      <InfoContentTable
        type={type}
        headerLabels={headerLabels}
        headerGridTemplate={headerGridTemplate}
        arr={arrTracker}
        placeholderProps={{
          keyword: 'OrderInfoTable',
          style: { width: '100%', padding: '3px', boxSizing: 'border-box' },
          placeholderOptions: { countRows: 4, withoutPagination: true },
        }}
      />
      {mediaType.tablet && (
        <ButtonsContainer
          setShowAttachModal={setShowAttachModal}
          data={data}
          handleDeAttachDeviceApi={handleDeAttachDeviceApi}
          setIsAttach={setIsAttach}
          handleShowOnTheMap={() => {
            customHistory.push(View.USER_INSIGHTS_DETAILS, {
              pathParams: { id: location.building_id, name: name },
            });
          }}
          isAttach={isAttach}
          isAttachDeAttachButton={type === DeviceDetailsType.TRACKER}
        />
      )}
    </Wrapper>
  );
};

export const FirstTableInfo = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(DeviceFirstTableInfoPlain);
