/**
 * @description
 * This is component that covers
 * all the functionalities cthings for background of modals require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';

import { createPortal } from 'react-dom';
// @ts-ignore
import styled from '@xstyled/styled-components';

import { media } from '../../styles/media';

const ExtraWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 1000000;
  background-color: rgb(0, 0, 0, 0.6);
  ${media.tablet`
    align-items: flex-end;
`};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  ${media.ultramonitor`
    max-width: 1920px;
`};
`;

export interface ModalProps {
  children: any;
  isOpenModal: boolean;

  className?: string;
}
export const Modal: FC<ModalProps> = ({ children, isOpenModal, ...props }) => {
  if (isOpenModal) {
    return createPortal(
      <ExtraWrapper {...props}>
        <Wrapper>{children}</Wrapper>
      </ExtraWrapper>,
      document.getElementById('modal') || document.body,
    );
  }
  return null;
};
