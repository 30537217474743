/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for placeholder if 'no data' of universal table require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as NoDataImage } from '../../../../assets/No_Data.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const NoDataPlaceholder = ({ ...props }: { className?: string; languageStrings?: any }) => {
  return (
    <Wrapper {...props}>
      <NoDataImage />
      <Text type={TextType.TEXT_14_GRAY}>{props.languageStrings ? props.languageStrings.labelNoData : 'No data'}</Text>
    </Wrapper>
  );
};
