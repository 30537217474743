/**
 * @description
 * component for displaying cluster entity
 *
 */

import React, { FC } from 'react';
import { LocationPoint } from '../locationPoint/LocationPoint';

export interface ClusterEntityProps {
  scale: number;
  pointData: any;
  dimensionRatio: number;
  containerDimensions: { height: number; width: any };
  imageDimensions: { height: number; width: any };
  handleDragEnd: any;
  handleDragStart: any;
  onClick: any;
  innerOffset: any;
  activeId: string | null;
  pixel_ratio: number;
  ratio: number;
  color?: any;
  isDraggable?: boolean;
  withPoint?: boolean;
}

export const ClusterEntity: FC<ClusterEntityProps> = ({
  scale,
  pointData,
  dimensionRatio,
  containerDimensions,
  imageDimensions,
  handleDragEnd,
  handleDragStart,
  innerOffset,
  onClick,
  activeId,
  pixel_ratio,
  ratio,
  color,
  isDraggable,
  withPoint = false,
}) => {
  const ratioCoordX = (pointData.coordinates.center.x / imageDimensions.width) * ratio;
  const ratioCoordY = (pointData.coordinates.center.y / imageDimensions.height) * ratio;
  const imageHeight = (containerDimensions.width * imageDimensions.height) / imageDimensions.width;
  const imageWidth = (containerDimensions.height * imageDimensions.width) / imageDimensions.height;
  const left =
    dimensionRatio < 1
      ? ratioCoordX * imageWidth + (containerDimensions.width - imageWidth) / 2
      : ratioCoordX * containerDimensions.width;
  const top =
    dimensionRatio < 1
      ? ratioCoordY * containerDimensions.height
      : ratioCoordY * imageHeight + (containerDimensions.height - imageHeight) / 2;

  return (
    <LocationPoint
      scale={1 / scale}
      scaleZoom={scale}
      color={color}
      ratio={ratio}
      top={top}
      left={left}
      pixel_ratio={pixel_ratio}
      width={pointData.coordinates.width}
      height={pointData.coordinates.height}
      onClick={onClick}
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      innerOffset={innerOffset}
      activeId={activeId}
      pointData={pointData}
      isDraggable={isDraggable}
      withPoint={withPoint}
    />
  );

  /*requiredCluster.index === i && requiredCluster.type === type && (
            <PulsationAnimation theme={theme} type={type} isRequired={requiredCluster.index === i} isCluster />
          )*/

  /*requiredCluster.index === i && requiredCluster.type === type && (
            <PulsationAnimation theme={theme} type={type} isRequired={requiredCluster.index === i} />
          )*/
};
