import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';
import ls from '../../utils/ls';
import { LsValueType } from '../../enums/LsValueType';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import { ErrorImage } from './components/errorImage/ErrorImage';
import { Text, TextType } from '@bit/first-scope.text';
import { Buttons } from './components/buttons/Buttons';
import { View } from '../../routes/routeInterfaces';
import { media } from '../../styles/media';
import { use100vh } from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

export enum ErrorPageType {
  ERROR_401 = 'ERROR_401',
  ERROR_403 = 'ERROR_403',
  ERROR_404 = 'ERROR_404',
  ERROR_500 = 'ERROR_500',
}

const Wrapper = styled.div`
  width: 100%;
  min-height: ${(props: any) => (props.minHeight ? props.minHeight + 'px' : '100vh')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray5;
  ${media.phone`
    flex-direction: column;
`}
`;
const MessageWrapper = styled.div`
  width: 345px;
  display: flex;
  flex-direction: column;
  ${media.phone`
    align-items: center;
  `}
`;
const Title = styled(Text)`
  font-size: 49px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: ${(props: any) => (props.pageType === ErrorPageType.ERROR_500 ? '16px' : '0')};
  ${media.phone`
    text-align: center;
`}
`;
const Description = styled(Text)`
  font-weight: 400;
  ${media.phone`
    text-align: center;
`}
`;

export const ErrorPage = ({ type }: { type: ErrorPageType }) => {
  const theme = useTheme();
  const customHistory = useCustomHistory();
  const language = useSelector(selectLanguageStrings);
  const { black } = theme.colors;
  const message =
    type === ErrorPageType.ERROR_401
      ? language
        ? language.errorPageMessage401
        : 'Unauthorised'
      : type === ErrorPageType.ERROR_403
      ? language
        ? language.errorPageMessage403
        : 'Forbidden'
      : type === ErrorPageType.ERROR_404
      ? language
        ? language.errorPageMessage404
        : 'Oooops!'
      : type === ErrorPageType.ERROR_500 && language
      ? language.errorPageMessage500
      : 'Something went wrong';
  const description =
    type === ErrorPageType.ERROR_401
      ? language
        ? language.errorPageDescription401
        : 'Access is denied due to invalid credentials'
      : type === ErrorPageType.ERROR_403
      ? language
        ? language.errorPageDescription403
        : 'Sorry, you don’t have permission to view this directory. You can return to main page if you have the access rights.'
      : type === ErrorPageType.ERROR_404
      ? language
        ? language.errorPageDescription404
        : 'Sorry, we could not find the page'
      : type === ErrorPageType.ERROR_500 && language
      ? language.errorPageDescription500
      : 'We are working on fixing the problem. Please try again.';

  const toMainOnClick = () => {
    customHistory.push(View.USER_DASHBOARD);
  };
  const reloadOnClick = () => {
    customHistory.go(0);
  };
  const handleLogout = (e: any) => {
    e.preventDefault();
    ls.remove(LsValueType.token);
    oauthRedirect();
  };

  const minHeight = use100vh();

  return (
    <Wrapper minHeight={minHeight}>
      <ErrorImage type={type} />
      <MessageWrapper>
        <Title type={TextType.TITLE_H1} color={black} pageType={type}>
          {message}
        </Title>
        <Description type={TextType.SEMITITLE} color={black}>
          {description}
        </Description>
        <Buttons type={type} toMainOnClick={toMainOnClick} reloadOnClick={reloadOnClick} logoutOnClick={handleLogout} />
      </MessageWrapper>
    </Wrapper>
  );
};
