import React from 'react';
import { renderRoutes } from 'react-router-config';
import { LsValueType } from '../../enums/LsValueType';
import { UserType } from '../../enums/UserType';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import withUserType from '../../routes/withUserType';
import ls from '../../utils/ls';
import { ManageTitle } from '../../features/manageTitle/ManageTitle';
import { useTableFunctions, withTableContextProvider } from '../../features/universalTable/context';
import { Wrapper, Body } from '../elements/wrappers';
import { Navigation } from '../../features/navigation/Navigation';
import { useUserAccess } from '../../api/user';
import { useSelector } from 'react-redux';
import { selectAccess } from '../../app/state/userSlice';
import { PATHS } from '../../routes';
import { MobileInputAnchor } from '../../features/universalTable/components/mobileInputAnchor/MobileInputAnchor';

const ManagementMobilePlain = (props: any) => {
  const { startAddProcess, getSearchValue, setSearchValue } = useTableFunctions();
  const keyword = 'ManageLayoutSearch';

  useUserAccess();
  const globalAccess = useSelector(selectAccess);
  const { role_name }: any = globalAccess;

  const handleLogout = (e: any) => {
    e.preventDefault();
    ls.remove(LsValueType.token);
    oauthRedirect();
  };

  const { actual: searchValue } = getSearchValue();

  const route = window.location.pathname;

  const reg = /^\/manage\/orders\/\w+\/\d+\/\d+\/\d+$/;

  const title_inclusion = !route.includes(PATHS.USER_SITES_REDIRECT) || route.includes('zones');

  return (
    <Wrapper>
      <Navigation onLogout={handleLogout} userStatus={role_name} withLanguages />
      {title_inclusion && !reg.test(route) && <MobileInputAnchor />}
      <Body>{renderRoutes(props.route.routes)}</Body>
    </Wrapper>
  );
};
const ManagementMobileContext = withTableContextProvider(ManagementMobilePlain);

export const ManagementMobile = withUserType(UserType.USER)(ManagementMobileContext);
