/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for add button require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ReactComponent as AddIcon } from '../../assets/Add.svg';

const Button = styled.button`
  max-width: ${(props: any) => props.width};
  width: 100%;
  height: ${(props: any) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.white)};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  border: 1px solid;
  border-color: ${(props: any) => (props.borderColor ? props.borderColor : props.theme.colors.primary)};
  transition: all 0.3s linear;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin: ${(props: any) => (props.marginButton ? props.marginButton : '')};
  & > span {
    color: ${(props: any) => (props.color ? props.color : props.theme.colors.primary)};
  }
  & > svg > path {
    stroke: ${(props: any) => (props.color ? props.color : props.theme.colors.primary)};
  }
  &:hover {
    background-color: ${(props: any) =>
      props.backgroundColorHover ? props.backgroundColorHover : props.theme.colors.primary};
    border-color: ${(props: any) => (props.borderColorHover ? props.borderColorHover : props.theme.colors.primary)};
    & > span {
      color: ${(props: any) => (props.colorHover ? props.colorHover : props.theme.colors.white)};
    }
    & > svg > path {
      stroke: ${(props: any) => (props.colorHover ? props.colorHover : props.theme.colors.white)};
    }
  }
`;
const Title = styled.span`
  max-width: calc(100% - 14px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2px;
  font-size: ${(props: any) => (props.fontSize ? props.fontSize : '')};
`;

export interface AddButtonProps {
  onClick: (...props: any[]) => void;
  title: string;
  width: string;
  height: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  colorHover?: string;
  backgroundColorHover?: string;
  borderColorHover?: string;
  fontSize?: string;
  marginButton?: string;
}

export const AddButton: FC<AddButtonProps> = ({
  onClick,
  title,
  width,
  height,
  color,
  backgroundColor,
  borderColor,
  colorHover,
  backgroundColorHover,
  borderColorHover,
  fontSize,
  marginButton,
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      width={width}
      height={height}
      theme={theme}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      colorHover={colorHover}
      backgroundColorHover={backgroundColorHover}
      borderColorHover={borderColorHover}
      marginButton={marginButton}
    >
      <AddIcon />
      <Title fontSize={fontSize}>{title}</Title>
    </Button>
  );
};
