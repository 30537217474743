import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ReactComponent as SearchIcon } from '../../../../assets/Search_placeholder_icon.svg';
import { Text, TextType } from '@bit/first-scope.text';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 14px 0;
`;

export const PopupPlaceholder = ({ languageStrings }: { languageStrings?: any }) => {
  const theme = useTheme();
  const { gray3 } = theme.colors;

  return (
    <Wrapper>
      <SearchIcon />
      <Text type={TextType.TEXT_14_GRAY}>
        {languageStrings ? languageStrings.searchBarPlaceholderLabel : 'No results found'}
      </Text>
      <Text type={TextType.TEXT_10_GRAY} color={gray3} weight={'400'}>
        {languageStrings ? languageStrings.searchBarPlaceholderTerm : 'Please try searching with another term'}
      </Text>
    </Wrapper>
  );
};
