import { Text, TextType } from '@bit/first-scope.text';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';
//images
import { ReactComponent as LogOutImage } from '../../../assets/Logout.svg';
import { Line, LogoutButton, StyledLogoutText, TextWrapper, UserNameWrapper, WrapperLogout } from './styled';

export interface LogoutItemTypes {
  userName: string | undefined;
  onLogout: any;
  isOpen: boolean;
}

export const LogoutItem: FC<LogoutItemTypes> = ({ userName, onLogout, isOpen }) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);

  const { black, gray2 } = theme.colors;
  return (
    <WrapperLogout>
      <UserNameWrapper className={isOpen ? 'open' : ''}>
        <Text type={TextType.TEXT_12_BLACK} color={gray2}>
          {userName}
        </Text>
      </UserNameWrapper>
      <Line className={isOpen ? 'open' : ''} />
      <LogoutButton onClick={onLogout}>
        <LogOutImage />
        <TextWrapper className={isOpen ? 'open' : ''}>
          <StyledLogoutText type={TextType.TEXT_12_BLACK} color={black}>
            {language ? language.logoutButton : 'Logout'}
          </StyledLogoutText>
        </TextWrapper>
      </LogoutButton>
    </WrapperLogout>
  );
};
