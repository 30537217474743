import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStages } from '../app/state/manageStagesSlice';

import { API_URL } from '../consts';
import { HttpService } from '../utils/http';

export const deleteZoneGlobal = (
  id: string[],
  deleteZone: any,
  deleteCheckedZones: any,
  handleAddNotification: Function,
  triggerUpdateZonesFloorPlan?: any,
) => {
  const data = { zone_ids: id };
  const url = `${API_URL}/v1/zones/delete`;
  const deleteOneZone = () => deleteZone(id[0]);
  (async () => {
    try {
      let responseData = (await HttpService.post(url, data)).data;
      triggerUpdateZonesFloorPlan && triggerUpdateZonesFloorPlan();
      handleAddNotification({ message: 'Successfully deleted', type: 'success' });
    } catch (e) {
      handleAddNotification({ message: 'An unexpected error has occurred. Please, try again', type: 'error' });
    }
  })();
  if (id.length === 1) {
    deleteOneZone();
  } else {
    deleteCheckedZones();
  }
};

export const useGetStagesList = () => {
  const [localStages, setLocalStages] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const url = `${API_URL}/v1/stages/?limit=0&offset=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id, isSelected: false }));
        setLocalStages(result);
      } catch (e) {
        //
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(setStages({ list: localStages }));
  }, [localStages]);
  return localStages;
};
