/**
 * @description
 * This is container for dashboard card
 *
 */

import React, { useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { useDashboardData, useFakeDashboard, useNewDashboards } from '../../../api/dashboard';
import { media } from '../../../styles/media';
import { DashboardCard } from '../../../components/dashboardCard/DashboardCard';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { CardType } from '../../../enums/CardType';
import { useLoaderFunctions } from '../../../features/placeholderComponent/loader.context';
import { CalendarWithPlaceholder } from './components/calendarWithPlaceholder/CalendarWithPlaceholder';
import { CalendarType } from '@bit/first-scope.calendar';
import { ZonesCapacityGraph } from './components/zonesCapacityGraph/ZonesCapacityGraph';
import { DistributionGraph } from './components/distributionGraph/DistributionGraph';
import { useWindowSize } from '../../../styles/style.context';
import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { ReactComponent as CurrentDateIcon } from '../../../assets/corner-down-left.svg';
import { DashboardItemType, DashboardType } from '../../../enums/Dashboard';
import { useParams } from 'react-router-dom';
import { useLiteralNumbering } from '../../../utils/useLiteralNumbering';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px;
  box-sizing: border-box;
  ${media.tablet`
    padding: 38px 16px 0 16px;
  `}
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
`;
const ToCurrentDateButton = styled(CommonButton)`
  height: 32px;
  font-size: 12px;
  & > svg {
    margin-right: 8px;
    & > path {
      stroke: ${(props: any) => props.color};
    }
  }
  ${media.tablet`
    display: none;
  `}
`;
const MobileToCurrentDateButton = styled(ToCurrentDateButton)`
  display: none;
  ${media.tablet`
    height: 40px;
    font-size: 15px;
    display: flex;
    margin-bottom: 20px;
  `}
`;
const SemiWrapper = styled.div``;
const GlobalRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  ${media.tablet`
    flex-wrap: wrap-reverse;
    & > div {
      width: 100%;
      margin-bottom: 10px;
    }
  `}
`;
const CardsWrapper = styled.div`
  max-width: 896px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  box-sizing: border-box;
  margin: 0 20px 20px 0;
  ${media.desktop`
    width: max-content;
    grid-template-columns: 1fr 1fr;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 0 0 20px 0;
  `}
  ${media.phone`
    grid-template-columns: 1fr;
  `}
`;
const GraphsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
`;

interface Params {
  type: DashboardType;
}

export const Dashboard = () => {
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = ['DashboardCard', 'CalendarWithPlaceholder', 'DashboardGraph'];
  const [date, setDate] = useState(new Date());
  const currentDate = new Date();
  const theme = useTheme();
  const { primary, white } = theme.colors;
  const windowSize = useWindowSize();

  const params = useParams<Params>();

  const languageStrings = useSelector(selectLanguageStrings);

  // OLEG example
  const fakeData: any = useFakeDashboard(params.type || DashboardType.OVERALL) ?? [];

  const newData: any = useNewDashboards(
    params.type || DashboardType.OVERALL,
    (isPlaceholder: boolean) => appendKeywordList([...keywordList, 'DashboardCardFiller'], isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );
  const upperSection = newData.upper_section ?? [];

  const bottomSection = newData.bottom_section.map((item: any) => {
    return {
      ...item,
      title: item.data.title,
      data: item.data.graph_data.map((graphItem: any) => ({
        title: graphItem.name,
        distributionProportion: graphItem.value,
      })),
    };
  });

  /*const [data] = useDashboardData(
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
    getRequiredDateFormat(date, 'YYYY-MM-DDTHH:mm:ss'),
  );*/
  //const { no_of_nodes, no_of_trackers, total_devices_offline, total_devices_online, dynamic_tiles } = data;
  /*const infoArr = [
    { type: CardType.DEVICES, title: 'Total Devices', nodes: no_of_nodes, trackers: no_of_trackers },
    { type: CardType.ONLINE_DEVICES, title: 'Online', count: total_devices_online },
    { type: CardType.OFFLINE_DEVICES, title: 'Offline', count: total_devices_offline },
  ];*/

  const literals = useLiteralNumbering(10);

  const getPointValue = (index: number) => {
    return literals[index] ? literals[index] : 'X';
  };

  /*const zonesCards = dynamic_tiles.map((item: any, index: number) => {
    const { name, no_of_devices, zone_capacity, package_distribution, value, max_capacity } = item;
    return {
      type: CardType.WAREHOUSE_PZ,
      title: name,
      count: no_of_devices,
      zoneCapacityProportion: zone_capacity,
      packageDistributionProportion: package_distribution,
      zoneValue: value,
      zoneMaxCapacity: max_capacity,
      pointValue: getPointValue(index),
      iconColor: primary,
    };
  });*/
  //const cardsArr = [...infoArr, ...zonesCards];

  /*const cardsForGraph =
    zonesCards.length > 0
      ? cardsArr.filter(
          (item: any) => item.type !== 'DEVICES' && item.type !== 'ONLINE_DEVICES' && item.type !== 'OFFLINE_DEVICES',
        )
      : [];*/
  const graphPlaceholderStyle =
    windowSize[0] > 768 ? { margin: '0 0 20px 0' } : { width: '100%', marginBottom: '20px' };

  const handleSetCurrentDate = () => {
    setDate(currentDate);
  };
  const isShowButton = () => {
    if (
      new Date(currentDate).getDay() === new Date(date).getDay() &&
      new Date(currentDate).getMonth() === new Date(date).getMonth() &&
      new Date(currentDate).getFullYear() === new Date(date).getFullYear() &&
      new Date(currentDate).getDate() === new Date(date).getDate()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const title = {
    [DashboardType.OVERALL]: languageStrings ? languageStrings.dashboardOverallTitle : 'Overall',
    [DashboardType.ORDERS]: languageStrings ? languageStrings.dashboardOrdersTitle : 'Orders',
    [DashboardType.INVENTORIES]: languageStrings ? languageStrings.dashboardInventoriesTitle : 'Inventories',
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Text type={TextType.TITLE_H1}>{title[params.type || DashboardType.OVERALL]}</Text>
        {isShowButton() ? (
          <ToCurrentDateButton onClick={handleSetCurrentDate} width={'150px'} color={white}>
            <CurrentDateIcon />
            {languageStrings ? languageStrings.toCurrentDateButton : 'To current date'}
          </ToCurrentDateButton>
        ) : null}
      </TitleWrapper>
      <SemiWrapper>
        <GlobalRow>
          <CardsWrapper>
            {/* {cardsArr.map((item: any, index: number) => {
              const { type, title, nodes, trackers, count, pointValue, iconColor } = item;
              return (
                <DashboardCard
                  key={index}
                  placeholderProps={{ keyword: 'DashboardCard' }}
                  type={type}
                  title={title}
                  nodes={nodes}
                  trackers={trackers}
                  count={count}
                  iconColor={iconColor}
                  pointValue={pointValue}
                />
              );
            })} */}
            {upperSection.map((item: any, index: number) => {
              const { tile_type, data } = item;

              return (
                <DashboardCard
                  key={index}
                  type={tile_type}
                  data={data}
                  placeholderProps={{
                    keyword: 'DashboardCard',
                  }}
                />
              );
            })}
          </CardsWrapper>
          {isShowButton() ? (
            <MobileToCurrentDateButton onClick={handleSetCurrentDate} width={'100%'} height={'32px'} color={white}>
              <CurrentDateIcon />
              {languageStrings ? languageStrings.toCurrentDateButton : 'To current date'}
            </MobileToCurrentDateButton>
          ) : null}
          {/*<CalendarWithPlaceholder
            type={CalendarType.DATE_SELECTION}
            inline={windowSize[0] > 768}
            startDate={date}
            setStartDate={setDate}
            placeholderProps={windowSize[0] > 769 ? { keyword: 'CalendarWithPlaceholder' } : {}}
            maxDate={currentDate}
          />*/}
        </GlobalRow>
        <GraphsWrapper>
          {bottomSection.map((item: any, index: number) => {
            const { tile_type, data, title } = item;

            return (
              <>
                {tile_type === DashboardItemType.DISTRIBUTION_GRAPH && (
                  <DistributionGraph
                    title={title}
                    data={data}
                    placeholderProps={{
                      keyword: 'DashboardGraph',
                      style: graphPlaceholderStyle,
                    }}
                  />
                )}
              </>
            );
          })}
          {/*<ZonesCapacityGraph
            data={cardsForGraph}
            placeholderProps={{
              keyword: 'DashboardGraph',
              style: graphPlaceholderStyle,
            }}
          />
          <PackagesDistributionGraph
            data={cardsForGraph}
            placeholderProps={{
              keyword: 'DashboardGraph',
              style: graphPlaceholderStyle,
            }}
          />*/}
        </GraphsWrapper>
      </SemiWrapper>
    </Wrapper>
  );
};
