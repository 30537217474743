/**
 * @description
 * This is component for cheap
 * all the functionalities cthings require for cheap
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CloseButton } from '@bit/first-scope.close-button';

const Wrapper = styled.div`
  width: max-content;
  height: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${(props: any) => props.backgroundColor};
  padding: 4px 8px;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  margin: 6px 6px 0 0;
`;

export const Cheap = ({
  value,
  closeOnClick,
  isDisabled,
  isPopupDescription,
}: {
  value: string;
  closeOnClick: () => void;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
}) => {
  const theme = useTheme();
  const { gray1, gray2, gray5, white } = theme.colors;

  return (
    <Wrapper
      theme={theme}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      backgroundColor={isPopupDescription ? gray5 : isDisabled ? white : gray5}
      isDisabled={isDisabled}
    >
      <Text type={TextType.TEXT_12_GRAY} color={gray2} margin={'0 6px 0 0'}>
        {value}
      </Text>
      {!isDisabled && (
        <CloseButton onClick={closeOnClick} color={gray1} width={'12px'} height={'12px'} strokeWidth={'2px'} />
      )}
    </Wrapper>
  );
};
