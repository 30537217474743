export const isClasp = !(
  window.location.href.includes('valmont.intelliventory.ai') ||
  window.location.href.includes('valmont.dev.intelliventory.ai') ||
  window.location.href.includes('valmont.staging.intelliventory.ai')
);

export const AUTH_URL = (window as any)._env_
  ? (window as any)._env_.AUTH_URL
  : process.env.AUTH_URL || 'https://identity.cthings.co/auth';

export const SOCKET_URL = (window as any)._env_
  ? (window as any)._env_.SOCKET_URL
  : process.env.SOCKET_URL || 'wss://119-review-develop-3zknud.ena.kube.cthings.co';

export const OLD_SOCKET_URL = 'wss://api.valmont.dev.intelliventory.ai';

export const API_URL = (window as any)._env_
  ? (window as any)._env_.API_URL
  : process.env.API_URL || 'https://119-review-develop-3zknud.ena.kube.cthings.co';

export const OLD_API_URL = 'https://api.valmont.dev.intelliventory.ai';

export const UNIQUE_PREFIX = (window as any)._env_
  ? (window as any)._env_.UNIQUE_PREFIX
  : process.env.UNIQUE_PREFIX || 'CthingsCo.1.';
export const MAPBOX_API = (window as any)._env_
  ? (window as any)._env_.MAPBOX_API
  : process.env.MAPBOX_API || 'https://api.mapbox.com/geocoding/v5';
export const MAPBOX_TOKEN = (window as any)._env_
  ? (window as any)._env_.MAPBOX_TOKEN
  : process.env.MAPBOX_TOKEN ||
    'pk.eyJ1IjoicHJhdGlrLWd1cnVkYXR0IiwiYSI6ImNrZjU3dTh6dzBrN3kyc25vZ3ZqMjd1eXAifQ.CQXH7MSvem6BjKNCcGDmvA';
export const NEW_ID = (window as any)._env_ ? (window as any)._env_.NEW_ID : process.env.NEW_ID || 'NEW_ID';
export const CLIENT_ID = isClasp
  ? (window as any)._env_
    ? (window as any)._env_.CLIENT_ID
    : process.env.CLIENT_ID || 'clasp'
  : 'valmont';

export const roleList = [
  { name: 'ADMIN', id: 1 },
  { name: 'USER', id: 0 },
];

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
