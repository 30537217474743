import React, { useEffect, useState } from 'react';
// @ts-ignore
import { useOrderList, getPackageList } from '../../../../../api/manageDevicesDetails';
import { FieldsType, isValidField } from '../../../../../utils/validation';
import { useGetInventories } from '../../../../../api/manageInventories';
import { AttachInventoryModal } from './AttachInventoryModal/AttachInventoryModal';
import { AttachPackageModal } from './AttachPackageModal/AttachPackageModal';
import { AttachToModal } from './AttachToModal/AttachToModal';
import { setInventoriesAttach } from '../../../../../api/manageInventories';
import { Loader } from '../../../../../components/loader/Loader';
import { useNotifierFunctions } from '../../../../../features/notifier2';

export enum InputType {
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  INPUT = 'INPUT',
}

export enum RowItemType {
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  JOINED_SELECT = 'JOINED_SELECT',
}

export interface SelectType {
  name: any;
  id: any;
}

const mapOrders = (orders: object[]) => {
  if (orders) {
    return orders.map((item: any) => {
      return { name: item.order_id, id: item.id };
    });
  }
};

const defaultPackageState = {
  name: '',
  component: { id: '', name: '' },
  tracker: { id: '', name: '' },
  zone: '',
  quantity: '',
  stage: { id: '', name: '' },
  process: { id: '', name: '' },
  status: { id: '', name: '' },
  id: '',
};

export const AttachModal = ({
  idDevice,
  title,
  isOpenModal,
  handleCloseAttachModal,
  data,
  setIsAttach,
  isLoader,
  setIsLoader,
  languageStrings,
}: {
  title: string;
  isOpenModal: boolean;
  handleCloseAttachModal: (props: boolean) => void;
  data: any;
  setIsAttach: any;
  idDevice: string;
  isLoader: boolean;
  setIsLoader: any;
  languageStrings?: any;
}) => {
  const { addNotification } = useNotifierFunctions();
  const inventoriesList = useGetInventories();
  const listOrders = useOrderList();

  const [packages, setPackages] = useState([]);
  const [onePackage, setOnePackage] = useState(defaultPackageState);
  const [isLoaderPackageModal, setIsLoaderPackageModal] = useState(false);
  const [order, setOrder] = useState({ id: '', name: '' });
  const [dataAttachPackage, setDataAttachPackage] = useState({ trackable_id: onePackage.id });

  const [dataAttachInventory, setDataAttachInventory] = useState({ trackable_id: '', metaData: {} });
  const [inventory, setInventory] = useState({ id: '', name: '' });
  const [inventoryType, setInventoryType] = useState('');
  const [validationIsTarted, setValidationIsStarted] = useState(false);
  const [attachedToPackage, setAttachedToPackage] = useState(false);
  const [attachedToInventory, setAttachedToInventory] = useState(false);

  useEffect(() => {
    setDataAttachPackage((prev) => {
      return { ...prev, trackable_id: onePackage.id };
    });
  }, [onePackage]);

  useEffect(() => {}, [inventoryType]);

  const handleAttachPackageAPI = () => {
    setInventoriesAttach(idDevice, dataAttachPackage, setIsLoader, setIsAttach, addNotification);
    setOnePackage(defaultPackageState);
    setPackages(() => []);
    setOrder({ id: '', name: '' });
    handleCloseAttachModal(false);
    setAttachedToPackage(false);
  };

  const handleChangeOrder = (e: any) => {
    setOrder(e);
    setIsLoaderPackageModal(true);
    setOnePackage(defaultPackageState);
    getPackageList(e.id, setPackages, setIsLoaderPackageModal, addNotification);
  };

  const handleChangePackage = (e: any) => {
    setOnePackage(() => e);
  };

  const handleClosePackageModal = () => {
    setOnePackage(() => defaultPackageState);
    setPackages(() => []);
    setOrder({ id: '', name: '' });
    setAttachedToPackage(false);
    setIsLoaderPackageModal(false);
  };

  const closeAttachModal = () => {
    handleCloseAttachModal(false);
    setIsAttach(false);
    setOnePackage(defaultPackageState);
    setValidationIsStarted(false);
  };

  const handleAttachInventoryApi = () => {
    setAttachedToInventory(false);
    handleCloseAttachModal(false);
    setIsLoader(true);
    setInventoriesAttach(idDevice, dataAttachInventory, setIsLoader, setIsAttach, addNotification);
  };

  const handleChooseInventories = (value: any) => {
    setInventoryType(() => value?.inventory_type?.name);
    setDataAttachInventory((prev) => {
      return { ...prev, trackable_id: value.id };
    });
  };

  const handleCloseInventoriesModal = () => {
    setAttachedToInventory(false);
    setDataAttachInventory({ trackable_id: '', metaData: {} });
    setInventoryType('');
  };

  const packageModalFields = [
    {
      name: languageStrings.labelOrder || 'Order',
      placeholder: languageStrings.selectOrderPlaceholder || 'Select order',
      values: mapOrders(listOrders || []),
      value: order ? order : { id: '', name: '' },
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleChangeOrder,
      isPendingRequest: listOrders.length ? false : true,
    },
    {
      name: languageStrings.labelPackageName || 'Package name',
      placeholder: packages.length ? languageStrings.selectPackagePlaceholder || 'Select package' : 'N/A',
      values: packages.length ? packages : [],
      value: onePackage ? onePackage : 'N/A',
      disabled: packages.length ? false : true,
      type: RowItemType.JOINED_SELECT,
      onChange: handleChangePackage,
      isPendingRequest: false,
    },
    {
      name: languageStrings.labelComponent || 'Component',
      values: [],
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: onePackage?.component?.name ? onePackage?.component?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelTrackerID || 'Tracker ID',
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: onePackage?.tracker?.id ? onePackage?.tracker?.id : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelQuantityInPackage || 'Quantity in the package',
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: onePackage?.quantity ? onePackage?.quantity : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelStage || 'Stage',
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: onePackage?.stage?.name ? onePackage?.stage?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelProcess || 'Process',
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: onePackage?.process?.name ? onePackage?.process?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelZone || 'Zone',
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: onePackage?.zone ? onePackage?.zone : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelStatus || 'Status',
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: onePackage?.status?.name ? onePackage?.status?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
  ];

  const inventoryModalFields = [
    {
      name: languageStrings.labelName || 'Name',
      placeholder: languageStrings.chooseNamePlaceholder || 'Choose name',
      values: inventoriesList || [],
      value: inventory,
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleChooseInventories,
      isPendingRequest: inventoriesList.length ? false : true,
    },
    {
      name: languageStrings.labelType || 'Type',
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: inventoryType ? inventoryType : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
  ];

  const isAllFieldsValidated =
    packageModalFields.length === packageModalFields.filter((item: any) => !item.isError).length;
  const isAllFieldFilled =
    packageModalFields.length ===
    packageModalFields.filter(
      (item: any) =>
        (item.value && item.value.length > 3) ||
        (item.value && item.value.name && item.value.name.length > 0) ||
        (item.value && item.value > 0),
    ).length;

  useEffect(() => {
    if (isAllFieldFilled) {
      setValidationIsStarted(true);
    } else {
      setValidationIsStarted(false);
    }
  }, [isAllFieldFilled]);

  return (
    <>
      <AttachToModal
        isOpenModal={isOpenModal}
        closeModal={closeAttachModal}
        setAttachedToPackage={setAttachedToPackage}
        setAttachedToInventory={setAttachedToInventory}
        languageStrings={languageStrings}
      />

      <AttachPackageModal
        attachedToPackage={attachedToPackage}
        packageArr={packageModalFields}
        setValidationIsStarted={setValidationIsStarted}
        handleAttachPackageAPI={handleAttachPackageAPI}
        title={title}
        packages={packages}
        onePackage={onePackage}
        isLoaderPackageModal={isLoaderPackageModal}
        handleClosePackageModal={handleClosePackageModal}
        validationIsTarted={validationIsTarted}
        isAllFieldFilled={isAllFieldFilled}
        isAllFieldsValidated={isAllFieldsValidated}
        languageStrings={languageStrings}
      />

      <AttachInventoryModal
        attachedToInventory={attachedToInventory}
        inventoryArr={inventoryModalFields}
        handleAttachInventoryApi={handleAttachInventoryApi}
        handleCloseInventoriesModal={handleCloseInventoriesModal}
        dataAttachInventory={dataAttachInventory}
        languageStrings={languageStrings}
      />

      <Loader isLoader={isLoader} />
    </>
  );
};
