// @ts-ignore
import styled from '@xstyled/styled-components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguageIdentity } from '../../../api/language';
import { selectLanguageStrings, setLanguage } from '../../../app/state/userSlice';
import { isSafari } from '../../../consts';
import { LsValueType } from '../../../enums/LsValueType';
import { useLanguagePicker } from '../../../languages/useLanguagePicker';
import { media } from '../../../styles/media';
import ls from '../../../utils/ls';
import { useBlockBodyScroll } from '../../../utils/useBlockBodyScroll';
import { ReactComponent as ClaspIcon } from '../assets/clasp_icon.svg';
import { MenuItem, MenuItemType } from '../MenuItem';
import { useNavigationContext } from './navigation.context';
import {
  ItemLang,
  ItemWrapper,
  LineForModal,
  MobileItemLang,
  StyledManageArrowImage,
  StyledManageNavlink,
} from './styled';

const Header = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BurgerButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  & div {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: black;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  & .first {
    transform: translateY(calc(-50% - 6px));
  }
  & .third {
    width: 70%;
    transform: translateY(calc(-50% + 6px));
  }
  &.open {
    & .second {
      opacity: 0;
    }
    & .first {
      transform: rotate(45deg) translate(0, 0);
    }
    & .third {
      width: 18px;
      transform: rotate(-45deg) translate(0, 0);
    }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: black;
    opacity: 0;
    transition: opacity 0.3s;
  }
  &.open {
    pointer-events: auto;
    &:after {
      opacity: 0.4;
    }
  }
`;

const Menu = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 270px;
  background-color: white;
  transform: translateX(-100%);
  transition: transform 0.3s;
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.1);
  &.open {
    transform: translateX(0);
  }
  ${media.tabletNavigation`
    width: 100%;
  `}
`;

const ManageModal = styled.div`
  position: absolute;
  top: 0;
  left: 270px;
  bottom: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
  background-color: white;
  transform: translateX(-200%);
  transition: 0.3s;
  z-index: 1;
  &.open {
    transform: translateX(0);
    transition: 0.3s;
  }
  ${media.tabletNavigation`
    z-index: 10;
    box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.1);
    width: 100%;
    left: 0;
    transform: translateX(-100%);
  `}
`;

const BackButton = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  padding: 10px 16px;
  margin: 0;
  height: 45px;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;
    height: 0.5px;
    background-color: gray3;
  }
`;

const BackText = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: gray2;
  margin-left: 10px;
`;

export const MobileNavigation = ({ menuItemArray, userStatus, onLogout, withLanguages }: any) => {
  const [{ secondaryMenuItems }, , { clearSecondaryItems, appendSecondaryItems }] = useNavigationContext();
  const [mobileModalIsOpen, setMobileModalIsOpen] = useState(false);

  const { changeLanguageHandler, languageIndex } = useLanguagePicker();

  const language = useSelector(selectLanguageStrings);

  useBlockBodyScroll(mobileModalIsOpen);
  // @TODO Alex add access check for navigation
  return (
    <>
      <Header>
        <BurgerButton
          className={mobileModalIsOpen && 'open'}
          onClick={() => {
            setMobileModalIsOpen(!mobileModalIsOpen);
            clearSecondaryItems();
          }}
        >
          <div className="first" />
          <div className="second" />
          <div className="third" />
        </BurgerButton>
        <ClaspIcon />
      </Header>

      <Modal className={mobileModalIsOpen && 'open'}>
        <Menu className={mobileModalIsOpen && 'open'}>
          {menuItemArray.map((item: any, index: number) => {
            const { value, route, type, childRoutes, pathForActive } = item;
            return (
              <MenuItem
                key={index}
                type={type}
                value={value}
                to={route}
                tooltipId={''}
                childRoutes={childRoutes ? childRoutes : null}
                pathForActive={pathForActive ? pathForActive : null}
                appendSecondaryItems={appendSecondaryItems}
                clearSecondaryItems={clearSecondaryItems}
                isOpen
              />
            );
          })}
          <div style={{ flex: 1, minHeight: '50px' }}></div>
          {withLanguages && (
            <MenuItem
              type={MenuItemType.LANGUAGES}
              isOpen={true}
              appendSecondaryItems={appendSecondaryItems}
              clearSecondaryItems={clearSecondaryItems}
            />
          )}
          <MenuItem type={MenuItemType.LOGOUT} isOpen={true} userName={userStatus} onLogout={onLogout} />
        </Menu>

        <ManageModal className={secondaryMenuItems && secondaryMenuItems.length && 'open'}>
          <BackButton onClick={clearSecondaryItems}>
            <StyledManageArrowImage className={'back_button'} />
            <BackText>{language ? language.backButton : 'Back'}</BackText>
          </BackButton>
          {secondaryMenuItems &&
            secondaryMenuItems.map((item, i) => {
              const { name, to, view } = item;
              return to ? (
                view === 'LINE' ? (
                  <LineForModal className="mobile" />
                ) : (
                  <ItemWrapper>
                    <StyledManageNavlink
                      style={{ marginLeft: 0, marginTop: 0 }}
                      to={to}
                      onClick={() => {
                        clearSecondaryItems();
                        setMobileModalIsOpen(false);
                      }}
                    >
                      {name}
                    </StyledManageNavlink>
                  </ItemWrapper>
                )
              ) : (
                <ItemWrapper
                  onClick={() => {
                    changeLanguageHandler(secondaryMenuItems[i].id!);
                    clearSecondaryItems();
                  }}
                >
                  <MobileItemLang className={i === languageIndex && 'active'}>{name}</MobileItemLang>
                </ItemWrapper>
              );
            })}
        </ManageModal>
      </Modal>
    </>
  );
};
