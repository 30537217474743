import { API_URL } from '../consts';
import { HttpService } from '../utils/http';

export const uploadOrder = (data: any, addNotification: any) => {
  const url = `${API_URL}/v1/orders/file`;
  const bodyFormData = new FormData();
  bodyFormData.append('file', data);
  (async () => {
    try {
      let responseData = (
        await HttpService.post(url, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      ).data;

      const message = 'The order is being processed!';
      addNotification({
        message: message,
        type: 'success',
      });
    } catch (e) {
      const message = e?.response?.data?.detail;
      addNotification({
        message: message || 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
};
