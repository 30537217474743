/**
 * @description
 * This is component for visualisation Tracker in floorPlan popupModal.
 *
 */

import React, { useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { View } from '../../../../../../routes/routeInterfaces';
import { useCustomHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { getRequiredDateFormat } from '../../../../../../utils/date/date-format';
import { ContentType } from '../../../../../../components/insightBadges/InsightBadges';

const Wrapper = styled.div`
  width: ${(props: any) => (props.isFullInfoView ? props.maxWidth : '240px')};
  box-sizing: border-box;
  padding: ${(props: any) => (props.isFullInfoView ? '12px 18px' : '12px 18px 8px 18px')};
  transition: width 0.3s ease;
`;
const FieldsWrapper = styled.div`
  width: 100%;
  display: ${(props: any) => (props.isHidden ? 'none' : 'flex')};
  flex-direction: column;
  border-bottom: ${(props: any) => (props.isWithBorder ? '1px solid' : 'none')};
  padding: ${(props: any) => (props.isWithBorder ? '0 0 5px 0' : '0')};
  margin: ${(props: any) => (props.isWithBorder ? '0 0 5px 0' : '0')};
  opacity: ${(props: any) => (props.isHidden ? '0' : '1')};
  transition: opacity 0.3s ease-out;
  border-color: gray4;
`;
const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;
const Button = styled(CommonButton)`
  font-size: 14px;
`;
const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white1;
  padding: 4px 0;
  border-radius: ${(props: any) =>
    `0 0 ${props.theme.borderRadius.primary} ${props.theme.borderRadius.primary}`} !important;
  border-top: 1px solid;
  border-color: gray4;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    background-color: gray7;
    & > span {
      color: gray1;
    }
  }
`;
const TransitionWrapper = styled.div`
  width: ${(props: any) => (props.withTransition ? (props.isFullInfoView ? props.width : '0px') : 'max-content')};
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  transition: width 0.5s linear;
`;
const StyledText = styled(Text)`
  white-space: nowrap;
`;

const StyledTextOverflow = styled(Text)`
  max-width: 88px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tracker = ({
  popupInfo,
  infoArr,
  roleName,
  languageStrings,
}: {
  popupInfo: any;
  infoArr: any;
  roleName: string;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const [isFullInfoView, setIsFullInfoView] = useState(false);
  const { black, gray1, primary, white } = theme.colors;
  const customHistory = useCustomHistory();
  const {
    name,
    packageName,
    deviceId,
    lastUpdate,
    order_id,
    package_id,
    order_name,
    component_index,
    production_id,
    inventoryName,
    inventory_id,
    inventoryType,
  } = popupInfo.content;

  const findLongestStringInArr = (arr: any[]) => {
    var longest = arr.reduce(function (a, b) {
      return a.length > b.length ? a : b;
    });

    return longest.length;
  };
  const theLongestValue = findLongestStringInArr([
    name ?? 'N/A',
    packageName ?? 'N/A',
    order_name ?? 'N/A',
    deviceId ?? 'N/A',
    lastUpdate ? getRequiredDateFormat(lastUpdate, 'DD.MM.YYYY, HH:mm') : 'N/A',
    component_index ?? 'N/A',
    production_id ?? 'N/A',
    inventoryName ?? 'N/A',
    inventoryType ?? 'N/A',
    inventory_id ?? 'N/A',
  ]);

  const maxWidth = `${theLongestValue * 7 + (108 + 16 + 36)}px`;

  const handleOnClickDetailsButton = () => {
    if (isFullInfoView) {
      setIsFullInfoView(false);
    } else {
      setIsFullInfoView(true);
    }
  };

  const newInfoArr = infoArr ? Object.values(infoArr) : [];

  return (
    <>
      <Wrapper isFullInfoView={isFullInfoView} maxWidth={maxWidth}>
        <Text type={TextType.TEXT_14_BLACK} color={black} weight={'600'} margin={'0 0 8px 0'}>
          {name}
        </Text>
        {newInfoArr?.map((item: any) => {
          return item?.map((elem: any, index: number) => {
            return (
              <FieldsWrapper
                isWithBorder={
                  isFullInfoView && roleName === ContentType.TAG_PACKAGE ? index === 0 || index === 1 : index === 0
                }
                isHidden={!isFullInfoView && index === 2}
              >
                {elem.values.map((val: any) => {
                  return (
                    <ItemWrapper>
                      <StyledText
                        type={TextType.TEXT_12_BLACK}
                        weight={'400'}
                        color={elem.blurred ? gray1 : black}
                        lineHeight={'24px'}
                        margin={'0 16px 0 0'}
                      >
                        {val.label}
                      </StyledText>
                      <TransitionWrapper
                        withTransition={index === 2}
                        width={`${val.value.length * 9}px`}
                        isFullInfoView={isFullInfoView}
                      >
                        {val.label === 'Inventory Name' && val.value.length > 13 && !isFullInfoView ? (
                          <StyledTextOverflow
                            type={TextType.TEXT_12_BLACK}
                            weight={'500'}
                            color={elem.elem ? gray1 : black}
                            lineHeight={'24px'}
                          >
                            {val.value}
                          </StyledTextOverflow>
                        ) : (
                          <StyledText
                            type={TextType.TEXT_12_BLACK}
                            weight={'500'}
                            color={elem.elem ? gray1 : black}
                            lineHeight={'24px'}
                          >
                            {val.value}
                          </StyledText>
                        )}
                      </TransitionWrapper>
                    </ItemWrapper>
                  );
                })}
              </FieldsWrapper>
            );
          });
        })}
        {isFullInfoView && (
          <ButtonsWrapper isPackage={popupInfo?.content?.package}>
            <Button
              width={'calc(50% - 8px)'}
              height={'26px'}
              color={black}
              backgroundColor={white}
              backgroundColorHover={primary}
              borderColorHover={primary}
              onClick={() => {
                const to = popupInfo?.content?.package ? View.MANAGE_ORDERS_DETAILS : View.MANAGE_INVENTORIES;
                popupInfo?.content?.package
                  ? to &&
                    customHistory.push(to, {
                      state: { package_id },
                      pathParams: { id: order_id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
                    })
                  : to &&
                    customHistory.push(to, {
                      state: { id: inventory_id },
                      pathParams: { offset: 0 },
                    });
              }}
            >
              {roleName === ContentType.TAG_PACKAGE
                ? languageStrings
                  ? languageStrings.packageButton
                  : 'Package'
                : languageStrings
                ? languageStrings.inventoriesButton
                : 'Inventories'}
            </Button>

            <Button
              width={'calc(50% - 8px)'}
              height={'26px'}
              onClick={() => {
                const { id, buildingId } = popupInfo.content;
                const to =
                  roleName !== ContentType.UNKNOWN
                    ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER
                    : View.USER_MANAGE_DEVICES_DETAILS_NODE;
                to &&
                  customHistory.push(to, {
                    pathParams: { id: id, buildingId: buildingId, offset: 0 },
                  });
              }}
            >
              {languageStrings ? languageStrings.labelDevice : 'Device'}
            </Button>
          </ButtonsWrapper>
        )}
      </Wrapper>
      <DetailsWrapper theme={theme} onClick={handleOnClickDetailsButton}>
        <Text type={TextType.TEXT_12_GRAY} lineHeight={'24px'}>
          {isFullInfoView
            ? languageStrings
              ? languageStrings.lessDetailsButton
              : 'Less details'
            : languageStrings
            ? languageStrings.moreDetailsButton
            : 'More details'}
        </Text>
      </DetailsWrapper>
    </>
  );
};
