import React, { FC, useState } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../../../../styles/media';
import { deletePackageGlobal, updatePackage, useOrderPackageDetails } from '../../../../../api/manageOrderDetails';
import { UniversalTable } from '../../../../../features/universalTable/UniversalTable';
import { EXTRA_KEY, useTableFunctions, withTableContextProvider } from '../../../../../features/universalTable/context';
import {
  ActionType,
  InlineActionType,
  ModalApiType,
  RowItemType,
  TableCustomComponentState,
} from '../../../../../features/universalTable/types';
import { AddButton } from '../../../../../components/addButton/AddButton';
import { Text, TextType } from '@bit/first-scope.text';
import { setOrderPackageComment } from '../../../../../api/manageOrderDetails';
import { Comments } from './components/comments/Comments';
import { API_URL, NEW_ID } from '../../../../../consts';
import { View } from '../../../../../routes/routeInterfaces';
import { useWindowSize } from '../../../../../styles/style.context';
import { useLocation } from 'react-router-dom';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  ${media.desktop`
    max-width: 1024px;
    margin-top: 30px;
  `}
`;
const WrapperText = styled(Text)`
  margin-bottom: 15px;
`;

export interface PackagesInfoPlainProps {
  id: string;
  pathParams: any;
  toggleTrigger: any;
  idItemForViewModal?: string;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  offsetFromSmartSearch?: number;
}

const PackagesInfoPlain: FC<PackagesInfoPlainProps> = ({
  id,
  pathParams,
  toggleTrigger,
  idItemForViewModal,
  accessData,
  //this offset need for show certain page during from smart search redirect
  offsetFromSmartSearch,
}) => {
  const offsetKey = 'packagesOffset';
  const keyword = 'PackagesInfo';
  const { startAddProcess } = useTableFunctions();
  const width = useWindowSize()[0];
  const { addNotification } = useNotifierFunctions();
  const language = useSelector(selectLanguageStrings);

  const handleUpdateItems = (package_id: string, data: any, index: number, updateLocalItem: any) => {
    updatePackage(package_id, data, index, updateLocalItem, id, toggleTrigger, addNotification);
  };

  const handleDeletePackages = (packageIds: string[], deleteItem: any, deleteCheckedItems: any) => {
    deletePackageGlobal(packageIds, deleteItem, deleteCheckedItems, id, addNotification);
  };

  const offset = pathParams[offsetKey];
  const { state }: any = useLocation();

  const [isAddModalFromGlobalSearch, setIsAddModalFromGlobalSearch] = useState(
    state?.state?.isAddModalFromGlobalSearch,
  );

  const resetIsAddModalFromGlobalSearch = () => {
    setIsAddModalFromGlobalSearch(false);
  };

  return (
    <Wrapper>
      <WrapperText type={TextType.SEMITITLE}>{language ? language.titlePackagesInfo : 'Packages info'}</WrapperText>
      <UniversalTable
        placeholderProps={{
          keyword,
          style: { height: 'max-content' },
          conditionalStyle: { overflow: 'hidden' },
          placeholderOptions: { countRows: 6 },
        }}
        allowDelete={accessData?.delete}
        deleteItems={handleDeletePackages}
        mainApiUrl={`${API_URL}/v1/order/details/${id}/packages`}
        pathParams={pathParams}
        tablePath={View.MANAGE_ORDERS_DETAILS}
        offset={offsetFromSmartSearch ? offsetFromSmartSearch : offset}
        offsetKey={offsetKey}
        keyword={keyword}
        updateItems={handleUpdateItems}
        accessData={accessData}
        titleActionComponent={
          accessData?.edit && (
            <AddButton
              onClick={() => {
                startAddProcess();
              }}
              title={width < 769 ? 'Add' : language ? language.addPackageButton : 'Add package'}
              width={width < 769 ? '72px' : '120px'}
              height="32px"
            />
          )
        }
        actionSet={[
          {
            type: ActionType.EDIT,
          },
        ]}
        inlineActionSet={[
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        modalApi={{
          type: ModalApiType.GET,
          url: (package_id: string) =>
            `${API_URL}/v1/order/details/${id}/package/${package_id === NEW_ID ? '' : package_id}`,
        }}
        idItemForViewModal={idItemForViewModal}
        keywordAddModalGlobalSearch={isAddModalFromGlobalSearch && keyword}
        resetIsAddModalFromGlobalSearch={resetIsAddModalFromGlobalSearch}
        withHeaderInMobile={width < 769}
        withHeaderGridTemplate={'1fr 1fr 1fr'}
        withRowGridTemplate={'1fr 1fr 1fr'}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: language ? language.enterNamePlaceholder : 'Enter name',
            label: language ? language.labelName : 'Name',
            defaultValue: '',
            type: RowItemType.INPUT,
            shownInHeader: width < 769,
          },
          {
            fieldName: 'component',
            placeholder: language ? language.selectComponentPlaceholder : 'Select component',
            type: RowItemType.JOINED_SELECT,
            label: language ? language.labelComponent : 'Component',
            defaultValue: { name: '', id: '' },
            pathToSelectItems: [`${EXTRA_KEY}available`, 'components'],
            shownInHeader: width < 769,
          },
          {
            fieldName: 'quantity',
            type: RowItemType.INPUT_NUMBER,
            placeholder: language ? language.enterQuantityPlaceholder : 'Enter quantity',
            label: language ? language.labelQuantity : 'Quantity',
            defaultValue: 0,
          },
          {
            fieldName: 'stage',
            placeholder: language ? language.selectStagePlaceholder : 'Select stage',
            label: language ? language.labelStage : 'Stage',
            type: RowItemType.JOINED_SELECT,
            defaultValue: { name: '', id: '' },
            pathToSelectItems: [`${EXTRA_KEY}available`, 'stages'],
            excludedFromAdd: true,
          },
          {
            fieldName: 'process',
            placeholder: language ? language.enterProcessPlaceholder : 'Enter process',
            label: language ? language.labelProcess : 'Process',
            type: RowItemType.JOINED_SELECT,
            selectItems: [],
            defaultValue: { name: '', id: '' },
            excludedFromAdd: true,
          },
          {
            fieldName: 'zone',
            placeholder: 'N/A',
            label: language ? language.labelZone : 'Zone',
            type: RowItemType.JOINED_SELECT,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'zone'],
            defaultValue: { name: '', id: '' },
            disabled: true,
            excludedFromAdd: true,
          },
          {
            fieldName: 'status',
            placeholder: language ? language.selectStatusPlaceholder : 'Select status',
            label: language ? language.labelStatus : 'Status',
            type: RowItemType.JOINED_SELECT,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'status'],
            defaultValue: { id: '', name: '' },
          },
          {
            fieldName: 'tracker',
            placeholder: language ? language.selectTrackerPlaceholder : 'Select tracker',
            label: language ? language.clusteredContentTracker : 'Tracker',
            type: RowItemType.JOINED_SELECT,
            excludedFromView: true,
            // isFromRequest: true,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'devices'],
            defaultValue: { name: '', id: '' },
          },
          {
            fieldName: 'comments',
            placeholder: '',
            label: language ? language.labelRemarks : 'Remarks',
            defaultValue: [],
            modalColumns: 3,
            type: RowItemType.CUSTOM,
            excludedFromView: true,
            excludedFromAdd: true,
            component: (value: any, state?: TableCustomComponentState) => (
              <Comments
                state={state}
                setOrderPackageComment={(commentData: any, packageId: string) =>
                  setOrderPackageComment(id, commentData, packageId)
                }
                useDetails={useOrderPackageDetails}
                idOrder={id}
              />
            ),
          },
        ]}
      />
    </Wrapper>
  );
};

export const PackagesInfo = withTableContextProvider(PackagesInfoPlain);
