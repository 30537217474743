import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API_URL, NEW_ID } from '../consts';
import { HttpService } from '../utils/http';
import { setOrderDetails, selectOrderDetails, setPackageModalComments } from '../app/state/manageOrderDetailsSlice';
import { store } from '../app/store';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { selectLanguage, selectLanguageStrings } from '../app/state/userSlice';

//@REVIEW dont use Redux for tables anymore
export const useManageOrderDetails = (id: string, setPlaceholder?: any, stopPlaceholder?: any) => {
  const manageOrderDetails = useSelector(selectOrderDetails);
  const dispatch = useDispatch();
  const [localOrderDetails, setLocalOrderDetails] = useState(manageOrderDetails);
  const trigger = [dispatch, id] as const;
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/order/details/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
        dispatch(setOrderDetails(responseData));
        setLocalOrderDetails(responseData);
      } catch (e) {
        //
      }
    })();
  }, [...trigger, loaderOptions]);

  useEffect(() => {
    setOrderDetails(manageOrderDetails);
  });
  return localOrderDetails;
};

export const useOrderPackageDetails = (idOrder: any, id: any) => {
  const [packageData, setPackageData] = useState<any>({});
  // const language = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage);
  useEffect(() => {
    if (id) {
      // const url = `${API_URL}/v1/order/details/62ea8f32fef81b5f007c621f/package/62f11c5f854aa073277e6774`;
      const url = `${API_URL}/v1/order/details/${idOrder}/package/${id}`;
      (async () => {
        try {
          let responseData = (await HttpService.get(url)).data;
          const { payload } = responseData;
          setPackageData(payload);
          //dispatch(setPackageModalComments(payload));
        } catch (e) {
          //
        }
      })();
    }
  }, [id, idOrder, language]);
  return [packageData];
};

export const setOrderPackageComment = (order_id: string, commentData: string, package_id: string) => {
  const url = `${API_URL}/v1/order/details/${order_id}/package/${package_id}/remark`;
  (async () => {
    try {
      let responseData = (await HttpService.patch(url, commentData)).data;
      const { payload } = responseData;
      store.dispatch(setPackageModalComments(payload));
    } catch (e) {
      //
    }
  })();
};

export const updatePackage = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  orderId: string,
  toggleTrigger: any,
  handleAddNotification: Function,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/order/details/${orderId}/package/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        toggleTrigger();
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  } else {
    const url = `${API_URL}/v1/order/details/${orderId}/package/addpackage/`;

    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        toggleTrigger();
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  }
};

export const updateEvents = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  orderId: string,
  handleAddNotification: Function,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/order/details/${orderId}/action/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  } else {
    const url = `${API_URL}/v1/order/details/${orderId}/action`;

    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
      } catch (e) {
        const error = e as any;
        handleAddNotification({
          message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
          type: 'error',
        });
      }
    })();
  }
};

export const deleteEventGlobal = (
  id: string[],
  deleteItem: any,
  deleteCheckedItems: any,
  handleAddNotification: Function,
) => {
  const data = { ids: id };
  const url = `${API_URL}/v1/actions/`;
  const deleteOneItem = () => deleteItem(id[0]);
  (async () => {
    try {
      await HttpService.delete(url, data);
      handleAddNotification({
        message: 'Successfully deleted',
        type: 'success',
      });
    } catch (e) {
      const error = e as any;
      handleAddNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
  if (id.length === 1) {
    deleteOneItem();
  } else {
    deleteCheckedItems();
  }
};

export const deletePackageGlobal = (
  packageIds: string[],
  deleteItem: any,
  deleteCheckedItems: any,
  orderId: string,
  handleAddNotification: Function,
) => {
  const data = { package_ids: packageIds };
  const url = `${API_URL}/v1/order/details/${orderId}/package/`;
  const deleteOnePackage = () => deleteItem(packageIds[0]);
  (async () => {
    try {
      await HttpService.delete(url, data);
      handleAddNotification({
        message: 'Successfully deleted',
        type: 'success',
      });
    } catch (e) {
      const error = e as any;
      handleAddNotification({
        message: error?.response?.data?.detail ?? 'An unexpected error has occurred. Please, try again',
        type: 'error',
      });
    }
  })();
  if (packageIds.length === 1) {
    deleteOnePackage();
  } else {
    deleteCheckedItems();
  }
};
