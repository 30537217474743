import { Text } from '@bit/first-scope.text';
// @ts-ignore
import styled from '@xstyled/styled-components';
// @ts-ignore
import { borderRadius, compose, height } from '@xstyled/system';
//images
import { ReactComponent as ArrowImage } from '../../../assets/SmallArrow.svg';
import { media } from '../../../styles/media';
import { NavLink } from '../../../utils/react-router-dom-abstraction';

export const ItemWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-left: 18px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  ${media.tablet`
    display: flex;
    &.active {
      background-color: white1;
    }
    padding: 10px 16px;
    margin: 0;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 0.5px;
      background-color: gray3;
    }
  `}
`;

export const navlinkLineStyle = compose(borderRadius);

export const LineForModal = styled.div`
  width: 100%;
  height: 1px;
  background-color: gray4;
  margin: 0 0 15px 30px;
  &.modal {
    margin-left: 0;
  }
  &.mobile {
    width: 100%;
    height: 0;
    margin: 0;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 1px;
      background-color: gray3;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-decoration: none;
  & > svg,
  & > svg > path {
    width: 20px;
    height: 20px;
    stroke: ${(props: any) => props.black};
    transition: 0.3s;
  }
  &:hover {
    & > svg,
    & > svg > path {
      stroke: ${(props: any) => props.primary};
    }
    ${media.tablet`
      & > svg,
      & > svg > path {
        stroke: ${(props: any) => props.black};
      }
    `}
  }
  &.active {
    position: relative;
    & div span {
      color: primary;
    }
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: -18px;
      bottom: 0;
      width: 2px;
      background-color: primary;
      ${navlinkLineStyle}
      ${media.tablet`
        display: none;
      `}
    }
    & > svg,
    & > svg > path {
      stroke: ${(props: any) => props.primary};
    }
  }
`;

export const TextWrapper = styled.div`
  box-sizing: border-box;
  flex: 0;
  overflow: hidden;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
`;

export const TextWrapperManage = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1px;
  flex: 0;
  overflow: hidden;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
`;

export const listHeight = compose(height);

export const ManageList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 0;
  width: 100%;
  transition: all 0.3s ease;
  &.open {
    ${listHeight}
  }
`;

export const StyledManageNavlink = styled(NavLink)`
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 15px 30px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: gray1;
  text-decoration: none;
  transition: 0.3s;
  word-wrap: break-word;
  &:first-child {
    margin: 10px 0 15px 30px;
  }
  &:last-child {
    margin: 0 0 0 30px;
  }
  &.active {
    font-weight: 600;
  }
  &:hover {
    color: primary;
    ${media.tablet`
      color: gray1;
    `}
  }
  ${media.tablet`
    width: 100%;
    font-weight: 500;
    &.active {
      font-weight: 500;
      color: primary;
    }
  `}
`;

export const modalHeight = compose(height);

export const ManageModal = styled.div`
  box-sizing: border-box;
  padding: 12px 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 20px), -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  &.open {
    min-width: 135px;
    opacity: 1;
    pointer-events: auto;
  }
`;

export const StyledText = styled(Text)`
  margin-left: 10px;
  text-decoration: none;
  &:hover {
    ${media.tablet`
      color: black;
    `}
  }
`;

export const StyledManageArrowImage = styled(ArrowImage)`
  transform: rotate(0deg);
  transition: all 0.3s ease;
  &.open {
    transform: rotate(-180deg);
  }
  ${media.tablet`
    transform: rotate(-90deg);
    &.open {
      transform: rotate(-270deg);
    }
    &.back_button {
      transform: rotate(-270deg);
    }
  `}
`;

export const StyledLangArrowImage = styled(ArrowImage)`
  transition: all 0.3s ease;
  &.open {
    transform: rotate(-180deg);
  }
  ${media.tablet`
    transform: rotate(-90deg);
    &.open {
      transform: rotate(-270deg);
    }
  `}
`;

export const WrapperLanguages = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-left: 18px;
  margin-bottom: 50px;
  ${media.tablet`
    padding: 0 16px;
  `}
`;

export const SelectedLang = styled.div`
  box-sizing: border-box;
  transform: translateY(100%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  &.openNav {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const SelectedLangSec = styled.div`
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  &.openNav {
    opacity: 0;
  }
`;

export const ListLanguages = styled.div`
  box-sizing: border-box;
  width: 0;
  padding: 12px 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 20px), calc(-50% + 12px));
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
  overflow: hidden;
  &.open {
    width: 110px;
    opacity: 1;
    pointer-events: auto;
  }
`;

export const ItemLang = styled.div`
  cursor: pointer;
  color: gray1;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 14px;
  transition: all 0.3s ease;
  &:hover {
    font-weight: 600;
  }
  &.selected {
    font-weight: 600;
  }
  &.last {
    margin: 0;
  }
`;

export const MobileItemLang = styled.div`
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: gray1;
  text-decoration: none;
  transition: 0.3s;
  word-wrap: break-word;
  &.active {
    font-weight: 600;
  }
  &:hover {
    color: primary;
    ${media.tablet`
      color: gray1;
    `}
  }
  ${media.tablet`
    width: 100%;
    font-weight: 500;
    &.active {
      font-weight: 500;
      color: primary;
    }
  `}
`;

export const WrapperLogout = styled.div`
  box-sizing: border-box;
  padding-left: 18px;
  margin-bottom: 20px;
  ${media.tablet`
    padding: 10px 16px 20px;
    margin: 0;
    /* &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 0.5px;
      background-color: gray3;
    } */
  `}
`;

export const UserNameWrapper = styled.div`
  box-sizing: border-box;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  &.open {
    width: 100%;
  }
  ${media.tablet`
    margin-left: 4px;
  `}
`;

export const Line = styled.div`
  box-sizing: border-box;
  margin-bottom: 15px;
  height: 1px;
  width: 0;
  background-color: gray5;
  transition: width 0.3s ease;
  &.open {
    width: 100%;
  }
`;

export const LogoutButton = styled.button`
  box-sizing: border-box;
  border: none;
  outline: transparent;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledLogoutText = styled(Text)`
  margin-left: 10px;
`;
