/**
 * @description
 * This is a component of the delete button
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';

import { theme } from '../../styles/theme';

import { ReactComponent as DeleteIcon } from '../../assets/Delete_button.svg';

const Button = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props: any) => (props.editInProcess ? 'auto' : 'pointer')};

  & > svg > path {
    stroke: ${(props: any) => props.color};
    transition: stroke 0.3s ease;
  }

  &:hover > svg > path {
    stroke: ${(props: any) => props.colorHover};
  }
`;

export interface DeleteButtonProps {
  onClick: any;
  color?: string;
  colorHover?: string;
  editInProcess?: boolean;
  className?: string;
}

export const DeleteButton: FC<DeleteButtonProps> = ({ onClick, color, colorHover, editInProcess, ...props }) => {
  return (
    <Button
      onClick={onClick}
      color={color}
      colorHover={editInProcess ? color : colorHover}
      editInProcess={editInProcess}
      {...props}
    >
      <DeleteIcon />
    </Button>
  );
};

DeleteButton.defaultProps = {
  color: theme.colors.gray3,
  colorHover: theme.colors.gray2,
};
