/**
 * @description
 * This is component that contain list of filters for Inventories
 */
import React, { useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as ArrowIcon } from '../../assets/arrow_right.svg';
import { Modal } from './components/modal/Modal';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const Wrapper = styled.div`
  max-width: ${(props: any) => (props.maxWidth ? props.maxWidth : '160px')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
  margin-bottom: 10px;
  position: relative;
`;

const FieldWrapper = styled.div`
  width: 100%;
  height: max-content;
  min-height: ${(props: any) => (props.minHeight ? props.minHeight : '31px')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 7px;
  border: 1px solid;
  border-color: ${(props: any) => (props.isOpenModal ? props.theme.colors.primary : props.theme.colors.gray3)};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  background-color: ${(props: any) =>
    props.isOpenModal || props.checkedCount > 0 || props.isCheckedAll ? props.theme.colors.white : 'transparent'};
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${(props: any) => (props.isPopupDescription ? 'auto' : 'pointer')};
  &.disabled {
    cursor: auto;
    opacity: 0.4;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectExtraWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-right: 10px;
  }
`;

const CheckedCount = styled(Text)`
  font-size: ${(props: any) => (props.fontSizeSelectNameCount ? props.fontSizeSelectNameCount : '12px')};
`;

const ArrowRight = styled(ArrowIcon)`
  transform: ${(props: any) => props.isOpenModal && 'rotate(-180deg)'};
  transition: all 0.3s ease-in-out;
`;

const SelectName = styled(Text)`
  font-size: ${(props: any) => (props.fontSizeSelectNameCount ? props.fontSizeSelectNameCount : '12px')};
`;

export const SelectWithModal = ({
  name,
  maxWidth,
  minHeight,
  isDisabled,
  isPopupDescription,
  checkedCount = 0,
  fontSizeCount,
  parameterList,
  handleCheckedItem,
  handleSelectAll,
  isSearchActive = false,
  isDynamicData = false,
  isCheckedAll,
  handleClearAll,
  closeModal,
  handleDone,
  isSelectAllFirstModal,
}: {
  name?: string;
  maxWidth?: string;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
  checkedCount?: number;
  minHeight?: string;
  fontSizeCount?: string;
  parameterList: any;
  handleCheckedItem: any;
  handleSelectAll: any;
  isSearchActive?: boolean;
  isDynamicData?: boolean;
  isCheckedAll: boolean;
  handleClearAll: any;
  closeModal: any;
  handleDone: any;
  isSelectAllFirstModal?: boolean;
}) => {
  const theme = useTheme();
  const [fieldHeight, setFieldHeight] = useState(40);
  const { gray1, gray2, black } = theme.colors;
  const language = useSelector(selectLanguageStrings);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.stopPropagation();
    setIsOpenModal(false);
    closeModal();
  };

  const handleDoneModal = (e: any) => {
    e.stopPropagation();
    handleDone();
    setIsOpenModal(false);
  };

  return (
    <Wrapper name={name} maxWidth={maxWidth}>
      <FieldWrapper
        className={isDisabled && !isPopupDescription ? 'disabled' : ''}
        ref={(el: any) => {
          if (!el) return;
          setFieldHeight(el.getBoundingClientRect().height);
        }}
        theme={theme}
        isOpenSelectList={isOpenModal}
        checkedCount={checkedCount}
        isSelectAll={isCheckedAll}
        onClick={isDisabled ? () => {} : openModal}
        isPopupDescription={isPopupDescription}
        minHeight={minHeight}
      >
        <SelectWrapper>
          <SelectName
            type={TextType.TEXT_12_BLACK}
            color={isOpenModal || (!isCheckedAll && checkedCount && checkedCount > 0) ? gray1 : gray2}
            fontSizeSelectNameCount={fontSizeCount}
          >
            {name}
          </SelectName>
          <SelectExtraWrapper>
            <CheckedCount
              type={TextType.TEXT_12_BLACK}
              color={isOpenModal || (!isCheckedAll && checkedCount && checkedCount > 0) ? black : gray1}
              fontSizeSelectNameCount={fontSizeCount}
            >
              {isSelectAllFirstModal ? (language ? language.labelAll : 'All') : checkedCount === 0 ? '0' : checkedCount}
            </CheckedCount>
            <ArrowRight isOpenModal={isOpenModal} />
          </SelectExtraWrapper>
        </SelectWrapper>
        {isOpenModal && (
          <Modal
            parameterList={parameterList}
            isOpenModal={isOpenModal}
            closeModal={handleCloseModal}
            name={name}
            handleCheckedItem={handleCheckedItem}
            handleSelectAll={handleSelectAll}
            isSearchActive={isSearchActive}
            isDynamicData={isDynamicData}
            isCheckedAll={isCheckedAll}
            handleClearAll={handleClearAll}
            handleDone={handleDoneModal}
            languageStrings={language}
          />
        )}
      </FieldWrapper>
    </Wrapper>
  );
};
