/**
 * @description
 * This is an advanced component that covers titles
 * in mobile version of manage layout
 * Highly customisable.
 *
 */

import { ButtonIconType, CommonButton } from '@bit/first-scope.common-button';
// @ts-ignore
import styled from '@xstyled/styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ManagementSearchInput } from '../../components/managementSearchInput/ManagementSearchInput';
import { injectPathParams, PATHS } from '../../routes/paths';
import { useStyleContext } from '../../styles/style.context';
import { media } from '../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const Wrapper = styled.div`
  display: none;
  transition: all 0.3s ease;
  ${media.tablet`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    top: 0;
    background-color: gray5;
    border-bottom: 1px solid;
    border-color: gray3;
    padding: ${(props: any) =>
      props.isRoutingWithDetailsPage ? '0' : props.paddingDevice ? props.paddingDevice : '88px 0 12px 0'};
    margin-bottom: 0;
  `}

  &.up {
    ${media.tablet`
    padding-top: ${(props: any) => (props.isRoutingWithDetailsPage ? 0 : '12px')};
    transition: all 0.3s ease;
  `}
  }
`;

const FixedWrapper = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: ${(props: any) => (props.hideTitle ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const ManageItem = styled(NavLink)`
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: gray2;
  margin-right: 20px;
  &.first {
    margin: 0 20px 0 16px;
  }
  &.last {
    margin-right: 16px;
  }
  &.selected {
    font-size: 20px;
    font-weight: 600;
    color: black;
  }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  & > button {
    margin-left: 10px;
  }
`;
const SearchInput = styled(ManagementSearchInput)`
  width: 100%;
  height: 32px;
  & > input {
    border-color: white;
  }
`;

export const ManageTitle = ({
  keyword,
  searchInputValue,
  handleChangeSearchInputValue,
  addButtonOnClick,
  addButtonValue,
  hideTitle,
  paddingDevice,
}: {
  keyword: string;
  searchInputValue: string;
  handleChangeSearchInputValue: (value: string) => void;
  addButtonValue?: string;
  addButtonOnClick?: () => void;
  hideTitle?: boolean;
  paddingDevice?: string;
}) => {
  const search: any = useRef();
  const [isHidden, setIsHidden] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [media] = useStyleContext();
  const titleWrapperRef = useRef<any>(null);
  const location = useLocation();
  const language = useSelector(selectLanguageStrings);
  const currentRoute = location.pathname;
  const manageItems = [
    {
      name: language ? language.titleOfDevicespage : 'Devices',
      to: injectPathParams(PATHS.MANAGE_DEVICES, { offset: 0 }),
    },
    { name: language ? language.titleOfUsersPage : 'Users', to: injectPathParams(PATHS.MANAGE_USERS, { offset: 0 }) },
    {
      name: language ? language.titleOfOrdersPage : 'Orders',
      to: injectPathParams(PATHS.MANAGE_ORDERS, { offset: 0 }),
    },
    {
      name: language ? language.titleOfStagesPage : 'Stages',
      to: injectPathParams(PATHS.MANAGE_STAGES, { offset: 0 }),
    },
    {
      name: language ? language.titleOfProcessesPage : 'Processes',
      to: injectPathParams(PATHS.MANAGE_PROCESSES, { offset: 0 }),
    },
    { name: language ? language.zonesTitle : 'Zones', to: injectPathParams(PATHS.MANAGE_ZONES, { offset: 0 }) },
    {
      name: language ? language.titleOfComponentsPage : 'Components',
      to: injectPathParams(PATHS.MANAGE_COMPONENTS, { offset: 0 }),
    },
    {
      name: language ? language.titleOfInventories : 'Inventories',
      to: injectPathParams(PATHS.MANAGE_INVENTORIES, { offset: 0 }),
    },
  ];

  useEffect(() => {
    const scrollRef = titleWrapperRef.current;
    if (
      currentRoute.includes(injectPathParams(PATHS.MANAGE_COMPONENTS, { offset: '' })) ||
      currentRoute.includes(injectPathParams(PATHS.MANAGE_PROCESSES, { offset: '' })) ||
      currentRoute.includes(injectPathParams(PATHS.MANAGE_ZONES, { offset: '' }))
    ) {
      scrollRef.scrollLeft = 302;
    } else if (currentRoute.includes(injectPathParams(PATHS.MANAGE_STAGES, { offset: '' }))) {
      scrollRef.scrollLeft = 120;
    } else {
      scrollRef.scrollLeft = 0;
    }
  }, [currentRoute]);

  const isRoutingWithDetailsPage =
    currentRoute.includes('/devices_details') ||
    currentRoute.includes('/insights_details') ||
    currentRoute.includes('/zones_details') ||
    currentRoute.includes('/orders_details');

  useEffect(() => {
    const handleScroll = () => {
      if (!isHidden && window.scrollY > scrollY) {
        setIsHidden(true);
        setScrollY(window.scrollY);
      } else if (isHidden && window.scrollY < scrollY) {
        setIsHidden(false);
        setScrollY(window.scrollY);
      } else if (window.scrollY < 0) {
        setIsHidden(false);
      } else {
        setScrollY(window.scrollY);
      }
    };

    const addEventScroll = () => {
      if (search) {
        window.addEventListener('scroll', handleScroll);
      }
      window.addEventListener('touchmove', handleScroll);
    };

    const removeEventListener = () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };

    media.mediaType.phone ? addEventScroll() : removeEventListener();
    return () => {
      removeEventListener();
    };
  }, [isHidden, scrollY]);

  const isShowAddButtonForMobile = !currentRoute.includes(injectPathParams(PATHS.MANAGE_DEVICES, { offset: '' }));

  return (
    <Wrapper
      paddingDevice={paddingDevice}
      className={`${media.mediaType.phone && isHidden && 'up'}`}
      isRoutingWithDetailsPage={isRoutingWithDetailsPage}
    >
      <TitleWrapper hideTitle={hideTitle} ref={titleWrapperRef}>
        <FixedWrapper>
          {manageItems.map((item: any, index: number) => {
            const { name, to } = item;
            return (
              <ManageItem
                className={index === 0 ? 'first' : manageItems.length - 1 === index ? 'last' : ''}
                activeClassName={'selected'}
                isActive={(match: any, location: any) => {
                  return location.pathname.includes(to.slice(0, -1));
                }}
                to={to}
                isLast={manageItems.length - 1 === index}
              >
                {name}
              </ManageItem>
            );
          })}
        </FixedWrapper>
      </TitleWrapper>
      <SearchInputWrapper>
        {!isRoutingWithDetailsPage && (
          <SearchInput
            value={searchInputValue}
            handleOnChange={handleChangeSearchInputValue}
            placeholder={language ? language.whatAreYouLookingForPlaceholder : 'What are you looking for?'}
            placeholderProps={{
              keyword: keyword,
              style: { width: addButtonValue && addButtonOnClick ? 'calc(100% - 40px)' : '100%', maxWidth: '536px' },
              placeholderOptions: { withoutLoader: true },
            }}
          />
        )}
        {isShowAddButtonForMobile ? (
          <CommonButton
            onClick={addButtonOnClick}
            width={'30px'}
            height={'30px'}
            iconType={ButtonIconType.ADD}
            iconWithoutText
          />
        ) : null}
      </SearchInputWrapper>
    </Wrapper>
  );
};
