/**
 * @description
 * This is component for rows of orders table
 * all the functionalities cthings require for orders table
 * good customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
// @ts-ignore
import styled from '@xstyled/styled-components';
import React from 'react';
import { media } from '../../../../styles/media';

const Wrapper = styled.div`
  height: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props: any) => (props.gridTemplate ? props.gridTemplate : '1.25fr 1.5fr')};
  grid-gap: 24px;
  padding: 13px 22px;
  box-sizing: border-box;
  background-color: white;
  border-top: ${(props: any) => (props.isFirst ? 'none' : '1px solid')};
  border-color: gray4;
  border-radius: ${(props: any) => (props.isLast ? '0 0 8px 8px' : 'none')};
  ${media.phone`
    height: max-content;
`}
`;

export const TableRow = ({
  property,
  value,
  isFirst,
  isLast,
  gridTemplate,
  ...props
}: {
  property: string;
  value: string;
  isFirst?: boolean;
  isLast?: boolean;
  gridTemplate?: string;
  className?: string;
}) => {
  return (
    <Wrapper isFirst={isFirst} isLast={isLast} gridTemplate={gridTemplate} {...props}>
      <Text type={TextType.TEXT_14_BLACK} weight={'400'}>
        {property}
      </Text>
      <Text type={TextType.TEXT_14_BLACK} weight={'400'}>
        {value || 'N/A'}
      </Text>
    </Wrapper>
  );
};
