/**
 * @description
 * This is component that displays desktop version of DescriptionPopup
 */

import { CommonButton } from '@bit/first-scope.common-button';
import { Text, TextType } from '@bit/first-scope.text';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetStagesList } from '../../../../../../../api/manageZones';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';
import { ReactComponent as CrossIcon } from '../../../../../../../assets/Cross.svg';
import { DeletePopup } from '../../../../../../../components/deletePopup/DeletePopup';
import EditButton from '../../../../../../../components/editButton/EditButton';
import { Modal } from '../../../../../../../components/newModals/Modal';
import { ModalContainer } from '../../../../../../../components/newModals/ModalContainer';
import { media } from '../../../../../../../styles/media';
import { Textarea } from '../../../textarea/Textarea';
import { Range } from '../../components/range/Range';

const Wrapper = styled.div`
  width: 240px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 14px 18px 18px 18px;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow1};
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  &.right {
    left: initial;
    right: 16px;
  }
  ${media.phone`
    display: none;
  `}
`;
const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 33px;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  & > div {
    width: auto !important;
  }
`;
const Title = styled(TextareaAutosize)`
  color: ${(props: any) => props.theme.colors.black};
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  margin-bottom: 6px;
  &:disabled {
    background-color: white;
  }
  &::placeholder {
    color: ${(props: any) => (props.isError ? props.theme.colors.red3 : props.theme.colors.gray3)};
    font-weight: 400;
  }
`;
const StyledEditButton = styled(EditButton)`
  margin-top: 4px;
  cursor: pointer !important;
`;
const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${(props: any) => (props.isEditable ? '6px 0 18px 0' : '6px 0 0 0')};
  &.stages {
    margin: 6px 0 3px 0;
  }
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 14;
  right: 14;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray5;
  transition: 0.3s;
  &:hover {
    background-color: white1;
  }
`;

const DeletePopupContainer = styled(ModalContainer)`
  max-width: 384px;
  ${media.phone`
    max-width: auto !important;
  `}
`;

export const DesktopView = ({
  handleCancelOnClick,
  handleDeleteOnClick,
  handleApplyOnClick,
  handleEditOnClick,
  isEdit,
  value,
  onChange,
  onRevert,
  closeModal,
  pixel_ratio,
  pictureSize,
  isRight,
  accessData,
}: {
  handleCancelOnClick: () => void;
  handleDeleteOnClick: () => void;
  handleApplyOnClick: () => void;
  handleEditOnClick: () => void;
  closeModal: any;
  value: any;
  onChange: any;
  onRevert: any;
  isEdit?: boolean;
  pixel_ratio: number;
  pictureSize: { height: number; width: number };
  isRight: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}) => {
  const theme = useTheme();
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const { black, gray1, white, primary, red3 } = theme.colors;
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [titleIsEdit, setIsTitleEdit] = useState(false);
  const [validatingIsStarted, setValidatingIsStarted] = useState(false);
  //const [stagesList, setStagesList] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);

  const stagesList = useGetStagesList();
  const { name, description } = value;

  const languageStrings = useSelector(selectLanguageStrings);

  const handleChangeWidth = (val: number[]) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      coordinates: { ...currentVal.coordinates, width: val[0] * pixel_ratio },
    }));
  };
  const handleChangeHeight = (val: number[]) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      coordinates: { ...currentVal.coordinates, height: val[0] * pixel_ratio },
    }));
  };
  const handleChangeDescription = (val: string) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      description: val,
    }));
  };
  const handleChangeName = (val: string) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      name: val,
    }));
  };
  const handleChangeAssosiatedStage = (val: any[]) => {
    /*onChange((currentVal: any) => ({
      ...currentVal,
      associated_stages: val,
    }));*/
  };

  const openDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const propertiesArray = [
    {
      name: languageStrings ? languageStrings.labelPositionX : 'Position X',
      value: Math.ceil(value?.coordinates?.center?.x / pixel_ratio),
    },
    {
      name: languageStrings ? languageStrings.labelPositionY : 'Position Y',
      value: Math.ceil(value?.coordinates?.center?.y / pixel_ratio),
    },
    {
      name: languageStrings ? languageStrings.labelWidth : 'Width',
      value: Math.ceil(value?.coordinates?.width / pixel_ratio),
      onChange: handleChangeWidth,
      maxValue: Math.ceil(
        value?.coordinates?.center?.x > pictureSize.width / 2
          ? (pictureSize.width - value?.coordinates?.center?.x / 2) / pixel_ratio
          : (pictureSize.width / 2 + value?.coordinates?.center?.x / 2) / pixel_ratio,
      ),
    },
    {
      name: languageStrings ? languageStrings.labelHeight : 'Height',
      value: Math.ceil(value?.coordinates?.height / pixel_ratio),
      onChange: handleChangeHeight,
      maxValue: Math.ceil(
        value?.coordinates?.center?.y > pictureSize.height / 2
          ? (pictureSize.height - value?.coordinates?.center?.y / 2) / pixel_ratio
          : (pictureSize.height / 2 + value?.coordinates?.center?.y / 2) / pixel_ratio,
      ),
    },
  ];

  const handleTitleEditOnClick = () => {
    setIsTitleEdit(true);
    let temp_value = titleRef.current && titleRef.current.value;
    if (titleRef.current) {
      titleRef.current.value = '';
      titleRef.current.value = temp_value as string;
    }
  };

  useEffect(() => {
    if (titleIsEdit && titleRef && titleRef.current) {
      titleRef.current.focus();
    }
  }, [titleIsEdit]);
  useEffect(() => {
    //setStagesList(value.associated_stages.list);
    setSelectedStages(value.associated_stages);
  }, [value.associated_stages]);

  return (
    <Wrapper
      className={isRight && 'right'}
      theme={theme}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {!isEdit && (
        <CloseButton onClick={closeModal}>
          <CrossIcon />
        </CloseButton>
      )}
      <TitleWrapper>
        <Title
          onChange={(e: any) => {
            handleChangeName(e.target.value);
          }}
          onBlur={() => {
            if (name) {
              setIsTitleEdit(false);
            }
          }}
          value={name}
          placeholder={languageStrings ? languageStrings.enterNamePlaceholder : 'Enter name'}
          theme={theme}
          disabled={!titleIsEdit}
          rowsMax={2}
          ref={titleRef}
          isError={!name && validatingIsStarted}
        />
        {isEdit && !titleIsEdit && (
          <StyledEditButton
            onClick={handleTitleEditOnClick}
            tooltipText={languageStrings ? languageStrings.editTitleTooltip : 'Edit title'}
          />
        )}
      </TitleWrapper>
      {propertiesArray.map((item: any, index: number) => {
        const { name, value: localValue, onChange: onLocalChange, maxValue } = item;
        return (
          <Range
            index={index}
            name={name}
            localValue={localValue}
            onLocalChange={onLocalChange}
            isEdit={isEdit}
            maxVal={maxValue}
          />
        );
      })}
      {/*<DescriptionWrapper className={'stages'}>
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin={'0 0 4px 12px'}>
          Assosiated stages
        </Text>
        <StagesComponent
          value={value.associated_stages}
          state={isEdit ? TableCustomComponentState.EDIT : TableCustomComponentState.VIEW_MODAL}
          stagesList={stagesList}
          setValue={handleChangeAssosiatedStage}
          isPopupDescription
        />
    </DescriptionWrapper>*/}
      <DescriptionWrapper isEditable={accessData?.edit}>
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin={'0 0 0 12px'}>
          {languageStrings ? languageStrings.labelDescription : 'Description'}
        </Text>
        <Textarea
          value={description}
          onChange={handleChangeDescription}
          isEdit={isEdit}
          isError={!description && validatingIsStarted}
        />
      </DescriptionWrapper>
      <ButtonsWrapper>
        {accessData?.delete && (
          <CommonButton
            width={'94px'}
            height={'26px'}
            backgroundColor={white}
            borderColor={isEdit ? primary : red3}
            backgroundColorHover={isEdit ? primary : red3}
            borderColorHover={isEdit ? primary : red3}
            color={black}
            onClick={
              isEdit
                ? () => {
                    handleCancelOnClick();
                    setValidatingIsStarted(false);
                    setIsTitleEdit(false);
                  }
                : openDeleteModal
            }
          >
            {isEdit
              ? languageStrings
                ? languageStrings.cancelButton
                : 'Cancel'
              : languageStrings
              ? languageStrings.deleteButton
              : 'Delete'}
          </CommonButton>
        )}
        {accessData?.edit && (
          <CommonButton
            width={'94px'}
            height={'26px'}
            onClick={
              isEdit
                ? () => {
                    if (!!name && !!description) {
                      handleApplyOnClick();
                      setValidatingIsStarted(false);
                      setIsTitleEdit(false);
                    } else {
                      setValidatingIsStarted(true);
                    }
                  }
                : handleEditOnClick
            }
          >
            {isEdit
              ? languageStrings
                ? languageStrings.applyButton
                : 'Apply'
              : languageStrings
              ? languageStrings.editButton
              : 'Edit'}
          </CommonButton>
        )}
      </ButtonsWrapper>
      {accessData?.delete && (
        <Modal isOpenModal={isOpenDeleteModal}>
          <DeletePopupContainer padding="24px">
            <DeletePopup
              title={languageStrings ? languageStrings.confirmDelete : 'Are you sure?'}
              semiTitle={
                languageStrings ? languageStrings.entitiesDeleted : 'This entities will be permanently deleted:'
              }
              isOpenModal
              handleOnClose={closeDeleteModal}
              handleOnDelete={handleDeleteOnClick}
            />
          </DeletePopupContainer>
        </Modal>
      )}
    </Wrapper>
  );
};
