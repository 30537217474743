/**
 * @description
 * This is component for popup choose parameter
 * all the functionalities cthings require for chooseParameterPopup
 * Highly customisable.
 *
 */

import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CheckBox } from '@bit/first-scope.controls';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchInput } from '../searchInput/SearchInput';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid;
  border-color: gray3;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow3};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  position: absolute;
  top: ${(props: any) => `${props.fieldHeight + 8}px`};
  left: 0;
  z-index: 1;
  cursor: auto;
`;
const ParameterListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 6px 4px 6px 14px;
`;
const ParameterList = styled.ul`
  max-height: 210px;
  margin: 0;
  padding: 0;
  overflow-y: ${(props: any) => (props.heightOfList > 220 ? 'scroll' : 'auto')};
  &::-webkit-scrollbar {
    width: 4px;
    background: gray4;
    opacity: 1;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;
const ParameterItem = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr 1.5fr;
  grid-gap: 10px;
  margin-bottom: ${(props: any) => (props.isLast ? '0' : '13px')};
  & > div {
    display: flex;
    align-items: center;
  }
`;

export const ChooseParameterPopup = ({
  popupRef,
  parameterList,
  handleCheckedItem,
  handleSelectAll,
  fieldHeight,
  isSelectAll,
}: {
  popupRef: React.MutableRefObject<any>;
  parameterList: any[];
  handleCheckedItem: (id: string) => void;
  handleSelectAll?: () => void;
  fieldHeight: number;
  isSelectAll?: boolean;
}) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const { gray1, gray2, white, primary } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [heightOfList, setHeightOfList] = useState(0);

  const language = useSelector(selectLanguageStrings);

  const handleInutOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };
  const handleClearInput = () => {
    setSearchInputValue('');
  };
  const filterParameterList = (flag: string) => {
    if (parameterList && parameterList.length > 0) {
      return parameterList.filter((item: any) => {
        return item.name.toLowerCase().indexOf(flag.toLowerCase()) !== -1;
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    listRef && listRef.current && setHeightOfList(listRef.current.clientHeight);
  }, []);

  return (
    <Wrapper
      theme={theme}
      fieldHeight={fieldHeight}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <SearchInput value={searchInputValue} handleOnChange={handleInutOnChange} handleClearInput={handleClearInput} />
      <ParameterListWrapper ref={popupRef}>
        <ParameterList ref={listRef} heightOfList={heightOfList}>
          {handleSelectAll && (
            <ParameterItem>
              <CheckBox
                label={''}
                color={gray2}
                backgroundColor={white}
                backgroundColorHover={primary}
                toggle={isSelectAll}
                disabled={false}
                handleToggle={() => {
                  handleSelectAll();
                }}
              />
              <Text type={TextType.TEXT_14_GRAY} color={gray1}>
                {language ? language.labelAll : 'All'}
              </Text>
            </ParameterItem>
          )}

          {filterParameterList(searchInputValue).map((item: any, index: number) => {
            const { name, secondary_name, isSelected, id } = item;
            return (
              <ParameterItem key={index} isLast={index === parameterList.length - 1}>
                <CheckBox
                  label={''}
                  color={gray2}
                  backgroundColor={white}
                  backgroundColorHover={primary}
                  toggle={isSelected}
                  disabled={false}
                  handleToggle={() => {
                    handleCheckedItem(id);
                  }}
                />
                <Text type={TextType.TEXT_14_GRAY} color={isSelected ? primary : gray1}>
                  {name}
                </Text>
                {secondary_name && (
                  <Text type={TextType.TEXT_14_GRAY} color={isSelected ? primary : gray1}>
                    {secondary_name}
                  </Text>
                )}
              </ParameterItem>
            );
          })}
        </ParameterList>
      </ParameterListWrapper>
    </Wrapper>
  );
};
