import React, { useCallback, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Placeholder } from '../../components/placeholders/Placeholders';
import { useLoaderContext, useLoaderFunctions } from './loader.context';
import { Loader } from '../../components/placeholders/Loader';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import { LoaderOptions, TriggerPlaceholder } from './types';

const Wrapper = styled.div`
  position: relative;
`;

const StyledPlaceholder = styled(Placeholder)`
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface PlaceholderProps {
  placeholderProps?: {
    keyword?: string;
    style?: any;
    conditionalStyle?: any;
    placeholderOptions?: any;
  };
}

export const withLoader = (keyword?: any, type?: any, style?: any, conditionalStyle: any = {}, options?: any) => <
  P extends object
>(
  Component: React.ComponentType<P>,
): React.FC<P & PlaceholderProps> => ({ placeholderProps, ...props }) => {
  const [{ loaderList, placeholderList }] = useLoaderContext();
  const placeholderOptions = placeholderProps ? placeholderProps.placeholderOptions : {};
  const primaryOptions = { ...options, ...placeholderOptions };

  const primaryKeyword = placeholderProps && placeholderProps.keyword ? placeholderProps.keyword : keyword;

  const loaderCondition = loaderList.includes(primaryKeyword);
  const placeholderCondition = placeholderList.includes(primaryKeyword);

  const finalConditionalStyle = placeholderProps?.conditionalStyle
    ? placeholderProps.conditionalStyle
    : conditionalStyle;

  const primaryStyle =
    placeholderProps?.keyword === 'PackagesInfoTable' && !placeholderList.includes(primaryKeyword)
      ? (placeholderProps.style = undefined)
      : placeholderProps && placeholderProps.style
      ? placeholderProps.style
      : style;

  useBlockBodyScroll(placeholderCondition && !primaryOptions.noScrollPrevention);

  return (
    <Wrapper style={!placeholderCondition ? primaryStyle : { ...primaryStyle, ...finalConditionalStyle }}>
      <Component {...(props as P)} />
      {loaderCondition && <Loader withoutLoader={primaryOptions.withoutLoader} />}
      {placeholderCondition && <StyledPlaceholder type={type} options={primaryOptions} />}
    </Wrapper>
  );
};

export const triggerPlaceholderEmpty = (val: boolean) => {};

export const useLoaderOptions = (
  setPlaceholder: TriggerPlaceholder,
  stopPlaceholder: TriggerPlaceholder,
  trigger: any[],
  notRendered?: boolean,
): LoaderOptions => {
  const [isInit, setIsInit] = useState(false);
  // @NOTE the functions related to placeholders are const, but they trigger the rerender, they should not bew included

  // in useEffect callback
  const handleLoader = useCallback(
    (flag: boolean) => {
      !flag ? setPlaceholder(false) : stopPlaceholder(false);
    },
    [setPlaceholder, stopPlaceholder],
  );
  const handleSkeleton = useCallback(
    (flag: boolean) => {
      !flag ? setPlaceholder(true) : stopPlaceholder(true);
    },
    [setPlaceholder, stopPlaceholder],
  );
  useEffect(() => {
    !isInit && !notRendered && setIsInit(true);
  }, [...trigger, notRendered]);

  const placeholderAction = useMemo(() => {
    return !isInit ? { setPlaceholder: handleSkeleton } : { setPlaceholder: handleLoader };
  }, [isInit]);

  return placeholderAction;
};

export const generateLoaderOptions = (
  setPlaceholder: TriggerPlaceholder,
  stopPlaceholder: TriggerPlaceholder,
  onlyPlaceholder?: boolean,
) => {
  const isInit = !onlyPlaceholder;
  const handleLoader = (flag: boolean) => {
    !flag ? setPlaceholder(false) : stopPlaceholder(false);
  };
  const handleSkeleton = (flag: boolean) => {
    !flag ? setPlaceholder(true) : stopPlaceholder(true);
  };
  return !isInit ? { setPlaceholder: handleSkeleton } : { setPlaceholder: handleLoader };
};

export const useNewLoaderOptions = (keywordList: string[], trigger: any[], notRendered?: boolean) => {
  const [isInit, setIsInit] = useState(false);
  const [placeholderHappened, setPlaceholderHappened] = useState(false);
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  // @NOTE the functions related to placeholders are const, but they trigger the rerender, they should not bew included
  // in useEffect callback
  const handleLoader = useCallback(
    (flag: boolean) => {
      !flag ? appendKeywordList(keywordList, false) : deleteFromKeywordList(keywordList, false);
    },
    [keywordList],
  );
  const handleSkeleton = useCallback(
    (flag: boolean) => {
      keywordList.length > 0 && setPlaceholderHappened(true);
      !flag ? appendKeywordList(keywordList, true) : deleteFromKeywordList(keywordList, true);
    },
    [keywordList],
  );
  useEffect(() => {
    !isInit && !notRendered && keywordList.length > 0 && placeholderHappened && setIsInit(true);
  }, [...trigger, notRendered, keywordList, placeholderHappened]);

  const placeholderAction = useMemo(() => {
    return !isInit ? { setPlaceholder: handleSkeleton } : { setPlaceholder: handleLoader };
  }, [isInit, handleSkeleton, handleLoader]);

  return placeholderAction;
};
