/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings select require
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { SmallLoader } from '../../features/smallLoader/SmallLoader';
import { media } from '../../styles/media';
import { useMediaType } from '../../styles/style.context';
import { usePrevious } from '../../utils/usePrevious';
import { SearchInputSelect } from '../searchInputSelect/SearchInputSelect';
import { CommonButton } from '@bit/first-scope.common-button';
import { Text, TextType } from '@bit/first-scope.text';
//images
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Delete } from './assets/delete.svg';
import { ReactComponent as Edit } from './assets/edit.svg';
import { ReactComponent as Accept } from './assets/accept.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const SelectBlock = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SelectItem = styled.div`
  padding: ${(props: any) =>
    props.paddingInput ? props.paddingInput : props.disabled ? '8px 12px' : '6px 6px 6px 12px'};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props: any) => (props.disabled ? props.theme.colors.gray5 : 'transparent')};
  pointer-events: ${(props: any) => props.disabled && 'none'};
  width: 100%;
  border: 1px solid
    ${(props: any) =>
      props.disabled
        ? props.theme.colors.gray5
        : props.isFocused
        ? props.theme.colors.primary
        : props.isError
        ? props.theme.colors.red3
        : props.theme.colors.gray3};
  &.borderless {
    border: 0;
  }
  display: flex;
  outline: none;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props: any) => (props.isPendingRequest ? 'auto' : 'pointer')};
  transition: all 0.3s linear;
`;

const TextItem = styled.span`
  display: block;
  font-size: 14px;
  line-height: 22px;
  font-family: Poppins, sans-serif;
  color: ${(props: any) => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  &.stageType {
    ${media.megaMonitor`
      width: 118px;
    `}
    ${media.tablet`
      width: 100%;
    `}
  }
  -webkit-text-fill-color: ${(props: any) => props.color};
  opacity: 1; /* required on iOS */
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */
`;

const ArrowButton = styled.button`
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${(props: any) => (props.isPendingRequest ? '0' : '1')};
  transition: all 0.3s linear;
  & svg {
    transition: all 0.3s;
    transform: ${(props: any) => (props.isOpen ? 'rotate(-180deg)' : '')};
  }
`;

const SelectModal = styled.div`
  width: ${(props: any) => (props.width ? props.width : '100%')};
  max-height: ${(props: any) => props.height && props.height};
  /* max-height: 316px; */
  left: ${(props: any) => (props.left ? props.left : '0')};
  box-sizing: border-box;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  position: absolute;
  z-index: 20;
  top: ${(props: any) => (props.top ? props.top : '62px')};
`;

const SelectModalContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  background-color: white;
  border: 1px solid;
  border-color: gray3;
  &.borderless {
    width: calc(100% - 8px);
  }
`;

const ScrolledWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex: 1;
`;

const ScrolledList = styled.ul`
  width: 100%;
  max-height: 250px;
  background-color: white;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  padding: 6px 6px 6px 0;
  margin: 2px 2px 2px 0;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray3;
    border-radius: 9px;
    width: 4px;
  }
`;

const SelectModalItem = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 4px 5px 12px;
  cursor: pointer;
  color: black;
  overflow: hidden;
  transition: all 0.3s linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span {
    flex: 1;
    /* word-break: break-all; */
    hyphens: auto;
  }
  &.selected > span {
    color: primary;
    font-weight: 500;
  }
  &.create {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > span {
      margin: 8px 0 8px;
      flex: 1;
      /* word-wrap: break-word; */
      hyphens: auto;
      /* -moz-hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto; */
    }
    &:hover > span {
      color: black;
      font-weight: normal;
      cursor: default;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 4px;
      left: 12px;
      height: 1px;
      background-color: gray4;
      opacity: 1;
      transition: opacity 0.3s linear;
    }
    &.single {
      margin: 0;
      & > span {
        margin-top: 0;
      }
      &::after {
        opacity: 0;
      }
    }
  }
  &:hover > span {
    color: primary;
    font-weight: 500;
  }
  &:hover .buttons .edit,
  &:hover .buttons .delete {
    opacity: 1;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const EditInput = styled.input`
  margin-right: 8px;
  padding: 0 6px;
  width: 100%;
  height: 100%;
  border: none;
  padding: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  color: black;
  border: 1px solid;
  border-color: primary;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
`;

const Buttons = styled.div`
  width: 45px;
  display: flex;
  & .button {
    transition: all 0.3s linear;
    & path {
      stroke: ${({ gray2 }: any) => gray2};
    }
    &:hover path {
      transition: all 0.3s linear;
      stroke: ${({ gray3 }: any) => gray3};
    }
  }
  & .edit,
  & .delete {
    opacity: 0;
  }
`;

const StyledAccept = styled(Accept)`
  margin-right: 5px;
`;

const StyledClose = styled(Close)``;

const StyledEdit = styled(Edit)`
  margin-right: 5px;
`;

const StyledDelete = styled(Delete)``;

const StyledButton = styled(CommonButton)`
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: primary;
  background-color: white;
`;

const Message = styled(Text)`
  width: 100%;
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > span {
    font-size: 14px;
    line-height: 24px;
  }
`;

interface valueType {
  id: any;
  name: string;
}

export interface ComponentProps {
  value: valueType;
  values: any[];
  disabled?: boolean;
  onChange: (value: valueType) => any;
  className?: any;
  label?: any;
  width?: any;
  placeholder?: string;
  positionLeft?: any;
  paddingInput?: any;
  positionTop?: any;
  handleClick?: any;
  borderLess?: boolean;
  openingListener?: (isOpen: boolean) => void;
  stageType?: boolean;
  banAutoFocus?: boolean;
  startedValidation?: boolean;
  isError?: boolean;
  fromAttach?: boolean;
  isPendingRequest?: boolean;
  updateItem?: Function;
  deleteItem?: Function;
  createItem?: Function;
  triggerColumnStructure?: () => void;
}

export const Select: FC<ComponentProps> = ({
  value,
  values,
  disabled = false,
  onChange,
  label,
  width,
  placeholder,
  positionLeft,
  paddingInput,
  positionTop,
  handleClick,
  borderLess,
  openingListener,
  stageType,
  banAutoFocus,
  startedValidation,
  isError,
  fromAttach,
  isPendingRequest,
  updateItem,
  deleteItem,
  createItem,
  triggerColumnStructure,
  ...props
}) => {
  const theme = useTheme();
  const mediaType = useMediaType();
  const withCreating = !!updateItem && !!deleteItem && !!createItem;

  const [isOpen, setIsOpen] = useState(false);
  const prevIsOpen = usePrevious(isOpen);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    values?.findIndex((item: any) => value && item.id === value.id),
  );

  const language = useSelector(selectLanguageStrings);
  const [searchInputValue, setSearchInputValue] = useState('');
  const isShowMessage = fromAttach ? !!isError : startedValidation && !!isError && !disabled;

  const { gray1, gray2, gray3, red1, primary, black } = theme.colors;

  const heightOfPopup = values ? `${values.length * 34}px` : 'max-content';

  const buttonRef = useRef<any>(null);

  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpen && !buttonRef.current?.contains(e.target)) {
        setIsOpen(false);
        setIsEditing(null);
      }
    },
    [isOpen],
  );

  const filterParameterList = (flag: string, items: any) => {
    return (
      items &&
      items.filter((item: any) => {
        return item?.name?.length > 0 && item?.name?.toLowerCase()?.indexOf(flag.toLowerCase()) !== -1;
      })
    );
  };

  const handleChange = (index: any, value: valueType) => {
    setIsOpen(false);
    setSelectedIndex(index);
    onChange(value);
  };

  const handleInutOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  useEffect(() => {
    if (stageType) {
      onChange(value);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  useEffect(() => {
    isOpen && isOpen !== prevIsOpen && handleClick && handleClick();
  }, [isOpen]);

  useEffect(() => {
    setSelectedIndex(
      filterParameterList(searchInputValue, values)?.findIndex((item: any) => value && item.id === value.id),
    );
  }, [value, values]);

  useEffect(() => {
    openingListener && openingListener(isOpen);
  }, [isOpen]);

  const selectValue = typeof value === 'string' ? value : values && selectedIndex !== -1 && values[selectedIndex]?.name;

  const [isEditing, setIsEditing] = useState(null);
  const [valueInEdit, setValueInEdit] = useState('');

  const handleCreate = (name: string) => {
    handleClearInput();
    createItem && createItem({ body: { name }, callback: onChange });
  };

  const handleUpdate = (name: string, id: string) => {
    updateItem && updateItem({ body: { name, id }, callback: triggerColumnStructure });
  };

  const handleDelete = (id: string) => {
    deleteItem && deleteItem({ body: { id } });
  };

  return (
    <SelectBlock {...props}>
      {label && (
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 4px 6px">
          {label}
        </Text>
      )}
      <SelectItem
        disabled={disabled}
        isError={isShowMessage}
        className={!!borderLess ? 'borderless' : ''}
        isFocused={isOpen}
        theme={theme}
        onClick={
          isPendingRequest
            ? () => {}
            : () => {
                setIsOpen(!isOpen);
                !isOpen && handleClearInput();
              }
        }
        paddingInput={paddingInput}
        isPendingRequest={isPendingRequest}
      >
        <TextItem color={disabled ? gray1 : selectValue ? gray1 : gray2} className={stageType ? 'stageType' : ''}>
          {selectValue ? selectValue : placeholder}
        </TextItem>
        {!disabled &&
          (isPendingRequest ? (
            <SmallLoader style={{ width: '24px', height: '24px' }} />
          ) : (
            <ArrowButton isOpen={isOpen} ref={buttonRef}>
              <Arrow />
            </ArrowButton>
          ))}
      </SelectItem>
      {isOpen && (
        <SelectModal
          theme={theme}
          width={width}
          height={values.length > 5 && heightOfPopup}
          left={positionLeft}
          top={positionTop}
        >
          <SelectModalContainer theme={theme} className={!!borderLess && 'borderless'}>
            <SearchInputSelect
              value={searchInputValue}
              handleOnChange={handleInutOnChange}
              handleClearInput={handleClearInput}
              setIsEditing={setIsEditing}
              languageStrings={language}
              isFocused={isOpen && !mediaType.tablet}
              banAutoFocus={banAutoFocus}
            />
            <ScrolledWrapper>
              <ScrolledList theme={theme}>
                {(!withCreating && filterParameterList(searchInputValue, values).length) || withCreating ? (
                  <>
                    {filterParameterList(searchInputValue, values).map((el: any, i: number) => (
                      <SelectModalItem
                        key={el.id}
                        className={selectedIndex !== -1 && values[selectedIndex].id === el.id && 'selected'}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          isEditing !== el.id && handleChange(i, el);
                        }}
                        // onClick={() => isEditing !== i && console.log('HEll', isEditing, i)}
                        // onBlur={() => setIsEditing(-1)}
                      >
                        {withCreating && isEditing === el.id ? (
                          <EditInput
                            theme={theme}
                            value={valueInEdit}
                            onClick={(e: any) => e.stopPropagation()}
                            onChange={(e: any) => setValueInEdit(e.currentTarget.value)}
                            autoFocus
                          />
                        ) : (
                          <Text type={TextType.TEXT_14_BLACK} color={black} weight={'400'}>
                            {el.name}
                          </Text>
                        )}
                        {withCreating && (
                          <Buttons className={`buttons`} gray3={gray3} gray2={gray2}>
                            {isEditing === el.id ? (
                              <>
                                <StyledAccept
                                  className={'button'}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleUpdate(valueInEdit, el.id);
                                    setIsEditing(null);
                                  }}
                                />
                                <StyledClose
                                  className={'button'}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    setIsEditing(null);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <StyledEdit
                                  className={'button edit'}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    setIsEditing(el.id);
                                    setValueInEdit(el.name);
                                  }}
                                />
                                {!(selectedIndex !== -1 && values[selectedIndex].id === el.id) && (
                                  <StyledDelete
                                    className={'button delete'}
                                    onClick={(e: any) => {
                                      handleDelete(el.id);
                                      e.stopPropagation();
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Buttons>
                        )}
                      </SelectModalItem>
                    ))}
                    {withCreating &&
                      searchInputValue &&
                      values.findIndex((e: any) => e.name === searchInputValue) === -1 && (
                        <SelectModalItem
                          className={`create ${filterParameterList(searchInputValue, values).length === 0 && 'single'}`}
                        >
                          <Text type={TextType.TEXT_14_BLACK} color={black} weight={'400'}>
                            {searchInputValue}
                          </Text>
                          <StyledButton
                            borderColor={primary}
                            borderColorHover={primary}
                            onClick={() => handleCreate(searchInputValue)}
                            isBlocked={false}
                          >
                            {language ? language.createButton : 'Create'}
                          </StyledButton>
                        </SelectModalItem>
                      )}
                  </>
                ) : (
                  <PlaceholderWrapper>
                    <Text type={TextType.TEXT_12_BLACK} color={gray2} weight={'400'} margin={'0 0 0 10px'}>
                      {language ? language.emptyPlaceholder : 'Data is empty'}
                    </Text>
                  </PlaceholderWrapper>
                )}
              </ScrolledList>
            </ScrolledWrapper>
          </SelectModalContainer>
        </SelectModal>
      )}
      {isShowMessage && (
        <Message type={TextType.TEXT_12_GRAY} color={red1} margin={'4px 0 0 12px'}>
          {label + language ? language.inputErrorText : 'doesnt look right'}
        </Message>
      )}
    </SelectBlock>
  );
};
