/**
 * @description
 * This is component is orders table
 * all the functionalities cthings require for orders table
 * good customisable.
 *
 */

// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { FC } from 'react';
import { withLoader } from '../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../placeholders/typePlaceholders/placeholdersType';
import { TableHeader } from '../components/tableHeader/TableHeader';
import { TableRow } from '../components/tableRow/TableRow';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  & > div:nth-child(1) {
    height: 56px;
  }
`;
const StyledTableRow = styled(TableRow)`
  & > span:nth-child(2) {
    color: ${(props: any) =>
      props.completion === 0
        ? props.theme.colors.green10
        : props.completion === 1
        ? props.theme.colors.primary
        : props.completion === 2
        ? props.theme.colors.red3
        : ''};
  }
`;

export interface HeaderLabelsProps {
  name: string;
  isSorted: boolean;
}

export interface ComponentProps {
  headerLabels: any;
  headerGridTemplate: any;
  arr: any;
  completionStatus: any;
  updated_at: any;
  shipping_deadline: any;
}

const ContentTablePlain: FC<ComponentProps> = ({
  headerLabels,
  headerGridTemplate,
  arr,
  completionStatus,
  updated_at,
  shipping_deadline,
}) => {
  const theme = useTheme();

  return (
    <TableWrapper theme={theme}>
      <TableHeader
        labels={headerLabels}
        gridTemplateDesktop={headerGridTemplate.desktop}
        gridTemplateTablet={headerGridTemplate.tablet}
        gridTemplatePhone={headerGridTemplate.phone}
      />
      {arr &&
        arr.map((item: any, index: number) => {
          const { value, property } = item;

          return index === arr.length - 1 ? (
            <StyledTableRow
              key={index}
              property={property}
              value={value}
              isLast
              completion={completionStatus}
              theme={theme}
            />
          ) : (
            <TableRow key={index} property={property} value={value} isFirst={index === 0} />
          );
        })}
    </TableWrapper>
  );
};

export const ContentTable = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(ContentTablePlain);
