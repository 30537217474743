import React, { createContext, useContext, useReducer } from 'react';
import { initialStateMobile } from './initialStateMobile';
import { mobileReducer } from './mobileReducer';

const FiltersContext = createContext<any | null>(null);

const MobileFiltersProvider = ({ children }: any) => {
  const [stateMobile, dispatchMobile] = useReducer(mobileReducer, initialStateMobile);
  const value = { stateMobile, dispatchMobile };
  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

const useMobileFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within the Filters Provider');
  }
  return context;
};

export { MobileFiltersProvider, useMobileFilters };
