/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings pagination pages of tables require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { media } from '../../../styles/media';

import { PaginationNumbering } from '../paginationNumbering/PaginationNumbering';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: white;
  box-sizing: border-box;
  padding: 13px 24px;
  border-radius: 0 0 8px 8px;
  border-top: 2px solid;
  border-color: gray3;
  ${media.phone`
    padding: 11px 18px 11px 16px;
  `};
`;

const Mark = styled.span`
  display: inline-block;
  color: ${(props: any) => (props.isOpenRow ? props.theme.colors.gray2 : props.theme.colors.primary)};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  user-select: none;
  &.small {
    display: none;
  }
  &.full {
    display: inline-block;
  }
  &.sliced {
    display: none;
  }
  ${media.tablet`
  &.sliced {
    display: inline-block;
  }
  &:hover.sliced {
    cursor: pointer;
    color: gray2;
    transition: color 0.3s ease;
  }
  `}
  ${media.phoneS`
    display: none;
    display: inline-block;
  `}
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const ShowingSpan = styled.span`
  display: inline;
  ${media.phone`
    display: none;
  `}
`;

export const Pagination = ({
  onPageChange,
  currentPage,
  pageSize,
  eventNum,
  ...props
}: {
  onPageChange: any;
  currentPage: number;
  pageSize: number;
  eventNum: number;
  className?: string;
}) => {
  const pagesNum = Math.ceil(eventNum / pageSize);
  const startRange = currentPage * pageSize + 1;
  const endRange = (currentPage + 1) * pageSize;
  //page size added in mod part to both sides for case where: event num < pageSize
  const groundDelta = Math.floor(endRange / eventNum) ? (endRange + pageSize) % (eventNum + pageSize) : 0;
  const limitedEndRange = endRange - groundDelta;
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper {...props}>
      <Mark theme={theme}>
        <ShowingSpan>Showing</ShowingSpan> <BoldSpan>{`${startRange}-${limitedEndRange}`}</BoldSpan>
        {language ? language.paginationOf : 'of'}
        <BoldSpan>{eventNum}</BoldSpan>
      </Mark>
      <Mark className="small" theme={theme}>
        <BoldSpan>{`${startRange}-${limitedEndRange}`}</BoldSpan> {language ? language.paginationOf : 'of'}
        <BoldSpan>{eventNum}</BoldSpan>
      </Mark>
      <PaginationNumbering currentPage={currentPage} onPageChange={onPageChange} pagesNum={pagesNum} />
    </Wrapper>
  );
};
