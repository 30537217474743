// @ts-ignore
import styled from '@xstyled/styled-components';
// @ts-ignore
import { compose, top, left, backgroundColor, width, height, borderColor } from '@xstyled/system';
import { media } from '../../styles/media';

export const Image = styled.img`
  height: ${(props: any) => (props.ratio <= 1 ? '100%' : '')};
  width: ${(props: any) => (props.ratio <= 1 ? '' : '100%')};
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > .droptarget {
    height: ${(props: any) => (props.ratio <= 1 ? '100%' : '')};
    width: ${(props: any) => (props.ratio <= 1 ? '' : '100%')};
  }
`;

export const ImageOuterContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const MapInteraction = styled.div`
  height: ${(props: any) => props.height};
  width: 100%;
  box-sizing: border-box;
  background-color: gray4;
  position: relative;
  border: 1px solid;
  border-color: gray3;
  border-radius: 8px;
  padding: 5px;
  & > div > div > div {
    width: 100%;
    height: 100%;
    cursor: default;
  }
  ${media.tablet`
    border: none;
    border-radius: 0;
  `}
`;

export const ZoomToolsContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 15px;
  display: flex;
  flex-direction: column;
  z-index: 99;
`;

export const ToolsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0;
  background-color: white;
  outline: transparent;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 3px 6px rgba(96, 97, 112, 0.16);
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.additional6};
  cursor: pointer;
  transition: all 0.3s linear;
  &:nth-child(1) {
    border-bottom: 1px solid;
    border-color: gray4;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:nth-child(3) {
    margin-top: 6px;
  }
  &:hover {
    background-color: gray6;
  }
`;

export const MapLegendLeft = styled.div`
  position: absolute;
  bottom: 0px;
  left: 15px;
  display: flex;
  align-items: center;
  z-index: 99;
  ${media.phone`
    display: none;
  `}
`;
export const MapLegendRight = styled.div`
  position: absolute;
  bottom: 0px;
  right: 75px;
  display: flex;
  align-items: center;
  z-index: 99;
  ${media.desktop`
    display: none;
  `}
  ${media.tablet`
    display: none;
  `}
  ${media.phone`
    display: none;
  `}
`;

const pointWrapperStyles = compose(top, left);

export const PointWrapper = styled.div`
  width: ${(props: any) => (props.isActive ? '20px' : '15px')};
  height: ${(props: any) => (props.isActive ? '20px' : '15px')};
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props: any) => props.transform};
  ${pointWrapperStyles};
  & > div > .ddcontainerghost {
    transform: ${(props: any) => props.ghostTransform};
  }
`;

const areaStyles = compose(backgroundColor, width, height, borderColor, top, left);

export const Area = styled.div`
  border: ${(props: any) => (props.isActive ? '2px solid' : '1px solid')};
  z-index: 1;
  pointer-events: none;
  position: absolute;
  ${areaStyles}
`;

const pointStyles = compose(backgroundColor);
export const Point = styled.div`
  width: ${(props: any) => (props.isActive ? '14px' : '10px')};
  height: ${(props: any) => (props.isActive ? '14px' : '10px')};
  border-radius: 50%;
  position: relative;
  box-shadow: ${(props: any) => (props.isActive ? `0 0 0 6px ${props.shadowColor}` : 'none')};
  ${pointStyles};
`;
