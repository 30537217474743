/**
 * @description
 * component for displaying point location
 *
 */

import React, { FC, useCallback, useState } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';

import { Area, Point, PointWrapper } from '../../styled';
import { hexToRGBA } from '../../../../utils/hexToRgb';
// @ts-ignore
import { DragDropContainer } from 'react-drag-drop-container';
import { useDragEnd } from '../../../../utils/longTap';
import Tooltip, { useTooltip } from '../../../../components/tooltip/Tooltip';

export interface LocationPointProps {
  top: number;
  left: number;
  scale: number;
  onClick: any;
  handleDragEnd: any;
  innerOffset: any;
  handleDragStart: any;
  width: number;
  height: number;
  activeId: string | null;
  pointData: any;
  pixel_ratio: number;
  ratio: number;
  color?: any;
  isDraggable?: boolean;
  withPoint?: boolean;
  scaleZoom: number;
}

export const LocationPoint: FC<LocationPointProps> = ({
  top,
  left,
  scale,
  onClick,
  handleDragEnd,
  handleDragStart,
  innerOffset,
  width,
  height,
  activeId,
  pointData,
  pixel_ratio,
  ratio,
  color,
  isDraggable,
  withPoint = false,
  scaleZoom,
}) => {
  const theme = useTheme();
  const [node, setNode] = useState<any>();
  const ref = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const dotColor = color ? color.dotColor : theme.colors.primary;
  const borderColor = color ? color.borderColor : theme.colors.primary;
  const backgroundColor = color ? color.backgroundColor : hexToRGBA(dotColor, 0.35);
  const shadowColor = color ? color.shadowColor : '#b3dae9';

  const actualWidth = width * ratio * scaleZoom;
  const actualHeight = height * ratio * scaleZoom;
  const handleDragEndFinal = (e: any, c: any, x: number, y: number) => {
    handleDragEnd(e, c, x, y, node);
  };
  const [onDragStart, onDragEnd] = useDragEnd(handleDragStart, handleDragEndFinal, 300, true);
  const [tooltipIds, , hideTooltip] = useTooltip();
  const { id, name } = pointData;

  const activeOffset = id === activeId ? 6.5 : 5;

  const activeInnerOffset = id === activeId ? 5 : 4.5;

  return (
    <PointWrapper
      top={`${top}px`}
      left={`${left}px`}
      transform={`scale(${scale}) translateX(-${activeOffset}px) translateY(-${activeOffset}px)`}
      ghostTransform={`translateX(${-left - innerOffset.x + 8}px) translateY(${-top - innerOffset.y + 8}px)`}
      onClick={(e: any) => onClick(e, left)}
      isActive={id === activeId}
      onTouchStart={isDraggable ? () => {} : onClick}
    >
      {isDraggable ? (
        <DragDropContainer targetKey="foo" onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Tooltip width="unset" id={tooltipIds[0]} text={name}>
            {withPoint ? (
              <Point backgroundColor={dotColor} isActive={id === activeId} shadowColor={shadowColor} ref={ref}>
                <Area
                  width={`${actualWidth}px`}
                  height={`${actualHeight}px`}
                  top={`${-actualHeight / 2 + activeInnerOffset}px`}
                  left={`${-actualWidth / 2 + activeInnerOffset}px`}
                  backgroundColor={backgroundColor}
                  borderColor={borderColor}
                  isActive={id === activeId}
                />
              </Point>
            ) : (
              <Area
                width={`${actualWidth}px`}
                height={`${actualHeight}px`}
                top={`${-actualHeight / 2 + activeInnerOffset}px`}
                left={`${-actualWidth / 2 + activeInnerOffset}px`}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                isActive={id === activeId}
              />
            )}
          </Tooltip>
        </DragDropContainer>
      ) : (
        <Tooltip width="unset" id={tooltipIds[0]} text={name}>
          {withPoint ? (
            <Point backgroundColor={dotColor} isActive={id === activeId} shadowColor={shadowColor} ref={ref}>
              <Area
                width={`${actualWidth}px`}
                height={`${actualHeight}px`}
                top={`${-actualHeight / 2 + activeInnerOffset}px`}
                left={`${-actualWidth / 2 + activeInnerOffset}px`}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                isActive={id === activeId}
              />
            </Point>
          ) : (
            <Area
              width={`${actualWidth}px`}
              height={`${actualHeight}px`}
              top={`${-actualHeight / 2 + activeInnerOffset}px`}
              left={`${-actualWidth / 2 + activeInnerOffset}px`}
              backgroundColor={backgroundColor}
              borderColor={borderColor}
              isActive={id === activeId}
            />
          )}
        </Tooltip>
      )}
    </PointWrapper>
  );
};
