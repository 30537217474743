/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings inputs in modals in tables require
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { TextareaModal } from '../textareaModal/TextareaModal';
import { ReactComponent as Info } from '../../assets/infoButton.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const ExtraWrapper = styled.div`
  width: ${(props: any) => (props.width ? props.width : '100%')};
  display: flex;
  flex-direction: column;
  margin: 0 0 14px 0;
`;
const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  position: relative;
`;
const InputElement = styled.input`
  width: 100%;
  height: 40px;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  background-color: white;
  color: ${(props: any) => props.theme.colors.gray1};
  &::placeholder {
    font-family: 'Poppins', sans-serif;
    -webkit-safari-color: gray2;
    color: gray2;
  }
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid
    ${(props: any) =>
      props.disabled
        ? props.theme.colors.gray5
        : props.isError
        ? props.theme.colors.red3
        : props.isFocused
        ? props.theme.colors.primary
        : props.theme.colors.gray3};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  padding: ${(props: any) => (props.image ? '8px 36px 8px 38px' : '8px 12px')};
  outline: none;
  transition: all 0.3s linear;

  &.disabled {
    background-color: gray5 !important;
    -webkit-safari-color: ${(props: any) => props.theme.colors.gray1};
    color: gray1 !important;
    -webkit-text-fill-color: ${(props: any) => props.theme.colors.gray1};
    &::placeholder {
      -webkit-safari-color: ${(props: any) => props.theme.colors.gray1};
      color: gray1;
    }
  }
  -webkit-text-fill-color: gray1;
  opacity: 1; /* required on iOS */
  ${media.tablet`
    -webkit-appearance: caret;
    -moz-appearance: caret; /* mobile firefox too! */
`}
`;
const ImageWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.black};
  }
`;
const AdditionalImage = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;
const Message = styled(Text)`
  width: 100%;
`;
const TooltipWrapper = styled.div`
  display: flex;
  margin: 0 0 4px 6px;
`;
const TooltipBlock = styled.div`
  position: absolute;
  background: #28293d;
  bottom: calc(100% + 10px);
  min-width: 200px;
  width: 100%;
  text-align: center;
  z-index: 3;
  line-height: 15px;
  padding: 6px;
  border-radius: 12px;
  left: 50%;
  -webkit-transform: translate(-51%, 0);
  -ms-transform: translate(-51%, 0);
  transform: translate(-51%, 0);
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden;
  opacity: 0;
  &::after {
    position: absolute;
    content: '';
    border-bottom: 15px solid #28293d;
    border-left: 15px solid transparent;
    bottom: -6px;
    transform: rotate(45deg);
    left: calc(50% - 5px);
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  height: max-content;
  margin-left: 4px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:hover ${TooltipBlock} {
    visibility: visible;
    opacity: 1;
  }
`;
const InfoIcon = styled(Info)`
  & > path {
    stroke: ${(props: any) => props.theme.colors.primary};
  }
  &:hover > path {
    transition: all 0.3s linear;
    stroke: ${(props: any) => props.theme.colors.secondary2};
  }
`;
const TooltipText = styled(Text)`
  color: white;
  font-size: 10px;
  line-height: 15px;
`;

export interface InputProps {
  value: string;
  onChange: any;
  name: string;
  placeholder: string;
  onClick?: any;
  setIsError?: any;
  type?: string;
  image?: any;
  additionalImage?: any;
  additionalImageOnClick?: any;
  disabled?: boolean;
  width?: string;
  rows?: number;
  startedValidation?: boolean;
  isError?: boolean;
  fromAttach?: boolean;
  className?: string;
  isTooltip?: boolean;
  tooltipText?: string;
}

export const InputModal: FC<InputProps> = ({
  value,
  onChange,
  onClick,
  setIsError,
  name,
  placeholder,
  image,
  type,
  width,
  additionalImage,
  additionalImageOnClick,
  rows,
  disabled,
  isError,
  startedValidation,
  fromAttach,
  isTooltip,
  tooltipText,
  ...props
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const language = useSelector(selectLanguageStrings);
  const { gray1, black, red1 } = theme.colors;
  const isShowMessage = fromAttach ? !!isError : startedValidation && !!isError && !disabled;
  return (
    <ExtraWrapper {...props}>
      <TooltipWrapper>
        <Text type={TextType.TEXT_12_BLACK} color={gray1}>
          {name}
        </Text>
        {tooltipText && (
          <InfoWrapper>
            <InfoIcon theme={theme} />
            <TooltipBlock>
              <TooltipText>{tooltipText ?? ''}</TooltipText>
            </TooltipBlock>
          </InfoWrapper>
        )}
      </TooltipWrapper>
      <Wrapper width={width}>
        {image ? (
          <ImageWrapper color={gray1} theme={theme}>
            {image}
          </ImageWrapper>
        ) : null}
        {rows && rows > 1 ? (
          <TextareaModal
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            isFocused={isFocused}
            isError={startedValidation && isError}
            disabled={disabled}
            isImage={image}
            rows={rows}
          />
        ) : (
          <InputElement
            type={type ? type : 'text'}
            onClick={onClick}
            value={value ?? 'N/A'}
            placeholder={placeholder}
            className={disabled ? 'disabled' : ''}
            theme={theme}
            disabled={disabled}
            color={black}
            isFocused={isFocused}
            isError={fromAttach ? isError : startedValidation && isError}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            onChange={onChange}
            image={image}
          />
        )}

        {additionalImage ? (
          <AdditionalImage color={gray1} theme={theme} onClick={additionalImageOnClick}>
            {additionalImage}
          </AdditionalImage>
        ) : null}
      </Wrapper>
      {isShowMessage && (
        <Message type={TextType.TEXT_12_GRAY} color={red1} margin={'4px 0 0 12px'}>
          {name + language ? language.inputErrorText : " doesn't look right"}
        </Message>
      )}
    </ExtraWrapper>
  );
};
