/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholder for cards require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';

const Svg = styled.svg`
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export interface ComponentProps {
  // type: PlaceholderType;
}

export const PlaceholderCard: FC<ComponentProps> = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 280 120"
      fill="none"
      preserveAspectRatio={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="280" height="120" rx="10" fill="#E4E4EB" />
      <rect x="23" y="23" width="119" height="9" rx="4.5" fill="#F2F2F5" />
      <rect x="23" y="66" width="82" height="31" rx="6" fill="#F2F2F5" />
      <rect x="197" y="37" width="60" height="60" rx="6" fill="#F2F2F5" />
    </Svg>
  );
};
