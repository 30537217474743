import React, { useEffect } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../../../../styles/media';
import { FilterDevice } from './filterDevice/FilterDevice';
import { FilterEntity } from './filterEntity/FilterEntity';
import { FilterAttach } from './filterAttach/FilterAttach';
import { View } from '../../../../../routes/routeInterfaces';
import { CommonButton } from '@bit/first-scope.common-button';
import { useFilters } from '../../Content/filterContext/сontext';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { FilteredButton } from '../../../../../components/filteredButton/FilteredButton';
import { SwipeSwitchFilter } from '../../../../../components/swipeSwitchFilter/SwipeSwitchFilter';
import { getIsZones, getIsAllDeviceSelect, getIsActiveSwitchZones } from '../../Content/filterContext/selectors';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const Wrapper = styled.div`
  display: flex;
`;

const FilteredWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  ${media.desktop`
    flex-wrap: wrap;
  `}
  ${media.phone`
    display: none;
  `}
`;

const ZonesButton = styled(CommonButton)`
  max-width: 126px;
  margin-top: 20px;
  ${media.desktop`
    margin-top: 30px;
  `}
  ${media.phone`
    display: none;
  `} /* ${media.monitorS`
  grid-area: 1/5;
  `}
  ${media.desktop`
    max-width: 100%;
    width: 100%;
    grid-area: 1/5;
  `}
  ${media.tablet`
    grid-area: 1/4;
  `} 
  ${media.tabletS`
    grid-area: 1/3;
  `} */
`;

export const Filters = ({ dataFloorMap, data, pathParams }: { dataFloorMap: any; data: any; pathParams: any }) => {
  const { state, dispatch } = useFilters();
  const { zonesState } = state;
  const { isZones } = zonesState;
  const isDisabledZones = !getIsZones(state);
  const isSelectAllDevice = getIsAllDeviceSelect(state);
  const isActiveSwitchZones = getIsActiveSwitchZones(state);
  const customHistory = useCustomHistory();
  const { id, name } = pathParams;

  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    dispatch({ type: 'ADD_NODES', payload: dataFloorMap });
  }, [dataFloorMap]);

  const handleSwitch = () => {
    dispatch({ type: 'SELECT_ZONES' });
  };

  const toggleFilterHandler = (isShowAllFilters: boolean) => {
    isShowAllFilters
      ? dispatch({
          type: 'SELECT_CLEAR_ALL',
        })
      : dispatch({
          type: 'SELECT_SHOW_ALL',
        });
  };

  return (
    <Wrapper>
      <FilteredWrapper>
        <FilterDevice languageStrings={languageStrings} />
        <FilterAttach languageStrings={languageStrings} />
        <FilterEntity languageStrings={languageStrings} />
        <SwipeSwitchFilter
          disabled={isDisabledZones}
          name={languageStrings ? languageStrings.labelZones : 'Zones'}
          isActive={isZones}
          handleStateSwitch={handleSwitch}
        />
        <FilteredButton
          toggleFilterHandler={toggleFilterHandler}
          isCheckedAllDevices={isSelectAllDevice || isActiveSwitchZones}
          languageStrings={languageStrings}
        />
      </FilteredWrapper>
      <ZonesButton
        onClick={() =>
          customHistory.push(View.MANAGE_ZONES_DETAILS, {
            pathParams: { building_id: id, floor: data.floors[0].floor_level, zone_id: name, offset: 0 },
          })
        }
      >
        {languageStrings ? languageStrings.labelZones : 'Zones'}
      </ZonesButton>
    </Wrapper>
  );
};
