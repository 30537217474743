import { useLoaderOptions } from './../features/placeholderComponent/loaderFunctions';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectDevices, setDevices } from '../app/state/insightSlice';

import { API_URL } from '../consts';
// import { usePlaceholderOptions } from '../features/placeholderComponent/withPlaceholderComponent';
import { HttpService } from '../utils/http';
import { usePrevious } from '../utils/usePrevious';
import { selectLanguage } from '../app/state/userSlice';

export const useDevices = (dimensions: any, setPlaceholder?: any, stopPlaceholder?: any) => {
  const devices = useSelector(selectDevices);
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState(devices);
  const prevDimensions = usePrevious(dimensions);
  const dimensionsChanged = prevDimensions
    ? dimensions.width !== (prevDimensions as any).width && dimensions.height !== (prevDimensions as any).height
    : dimensions && !!dimensions.width;
  const trigger = [dispatch, dimensions, dimensionsChanged, setPlaceholder] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    if (dimensions && dimensions.width && dimensions.height && dimensionsChanged) {
      const url = `${API_URL}/v1/locations/geopivoted?map_pwidth=${Math.floor(
        dimensions.width,
      )}&map_pheight=${Math.floor(dimensions.height)}`;
      (async () => {
        try {
          let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
          dispatch(setDevices(responseData));
          setLocalData(responseData);
        } catch (e) {
          //
        }
      })();
    }
  }, [...trigger]);

  useEffect(() => {
    setLocalData(devices);
  }, [devices]);

  return localData;
};

export const useFloorPlanDetails = (buildingId: string) => {
  const [localFloorPlanDetails, setLocalFloorPlanDetails] = useState();
  const language = useSelector(selectLanguage);
  useEffect(() => {
    const url = `${API_URL}/v1/locations/floorplan/details?building_id=${buildingId}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setLocalFloorPlanDetails(responseData);
      } catch (e) {
        //
      }
    })();
  }, [language]);

  return localFloorPlanDetails as any;
};

export const useDevicesForNav = () => {
  const devices = useSelector(selectDevices);
  const [localData, setLocalData] = useState(devices);
  const boundingRect = { height: 1094, width: 1645 };

  useEffect(() => {
    const url = `${API_URL}/v1/locations/geopivoted?map_pwidth=${Math.floor(
      boundingRect.width,
    )}&map_pheight=${Math.floor(boundingRect.height)}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setLocalData(responseData);
      } catch (e) {}
    })();
  }, []);

  return localData;
};
