/**
 * @description
 * This is component that displays all inforamtion about Zones
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../../styles/media';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { useMediaType } from '../../../styles/style.context';
import { View } from '../../../routes/routeInterfaces';
import { API_URL } from '../../../consts';
import { InlineActionType, RowItemType, TableCustomComponentState } from '../../../features/universalTable/types';
import { StagesComponent } from './components/stagesComponent/StagesComponent';
import { SelectStagesState } from '../../../app/state/manageStagesSlice';
import { LevelComponent } from './components/levelComponent/LevelComponent';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { useTableFunctions } from '../../../features/universalTable/context';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { MapWithZones } from './components/mapWithZones/MapWithZones';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { deleteZoneGlobal } from '../../../api/manageZones';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px;
  box-sizing: border-box;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;
const MapWrapper = styled.div`
  height: 100%;
  border: 1px solid;
  border-color: gray3;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  ${media.tablet`
    border-radius: 0;
  `}
`;

export const Zones = ({ match }: { match: any }) => {
  const accessData = useSelector(selectAccess).management.zones;

  const { setSearchValue, getSearchValue, resetTable } = useTableFunctions();
  const [isMapView, setIsMapView] = useState(false);
  const [boundingRect, setBoundingRect] = useState(null);

  const { actual: searchValue } = getSearchValue();
  const theme = useTheme();
  const mediaType = useMediaType();
  const keyword = 'ZonesTable';
  const offsetKey = 'offset';
  const customHistory = useCustomHistory();
  const offset = match.params[offsetKey];
  const stagesState = useSelector(SelectStagesState);

  const languageStrings = useSelector(selectLanguageStrings);

  const mapRef = useCallback((node) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.labelZones : 'Zones'}
        searchInputValue={searchValue}
        handleChangeSearchInputValue={setSearchValue}
        switcherState={isMapView}
        setSwitcherState={setIsMapView}
      />
      {isMapView ? (
        <MapWrapper ref={mapRef} theme={theme}>
          {boundingRect && <MapWithZones boundingRect={boundingRect} />}
        </MapWrapper>
      ) : (
        <UniversalTable
          placeholderProps={{
            keyword,
            placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: 6 },
          }}
          mainApiUrl={`${API_URL}/v1/zones/`}
          keyword={keyword}
          additionalKeywords={['ManageLayoutSearch']}
          offset={offset}
          offsetKey={offsetKey}
          tablePath={View.MANAGE_ZONES}
          updateItems={() => {}}
          deleteItems={deleteZoneGlobal}
          allowDelete={accessData.delete}
          inlineActionSet={[{ type: InlineActionType.DETAILS }]}
          customRowClick={(value: any) => {
            customHistory.push(View.MANAGE_ZONES_DETAILS, {
              pathParams: {
                building_id: value.location.building_id,
                floor: value.location.floor.level,
                zone_id: 'noselection',
                offset: 0,
              },
            });
          }}
          pageSize={mediaType.phone ? 6 : 18}
          columnStructure={[
            {
              fieldName: 'name',
              placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter Name',
              label: languageStrings ? languageStrings.labelName : 'Name',
              defaultValue: '',
            },
            {
              fieldName: 'location',
              placeholder: languageStrings ? languageStrings.enterLocationPlaceholder : 'Enter location',
              label: languageStrings ? languageStrings.labelLocation : 'Location',
              defaultValue: '',
              getTextDisplay: (value: any) =>
                value ? `${value.address.city} ${value.address.line1} ${value.address.line2}` : 'N/A',
            },
            {
              fieldName: 'location',
              placeholder: languageStrings ? languageStrings.chooseLevelPlaceholder : 'Choose level',
              label: languageStrings ? languageStrings.labelLevel : 'Level',
              defaultValue: [],
              type: RowItemType.CUSTOM,
              component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
                return <LevelComponent value={value} state={state} setValue={setValue} />;
              },
            },
            {
              fieldName: 'associated_stages',
              placeholder: '',
              label: languageStrings ? languageStrings.labelAssociatedStages : 'Associated stages',
              defaultValue: [],
              type: RowItemType.CUSTOM,
              modalColumns: 2,
              component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
                <StagesComponent value={value} state={state} setValue={setValue} stagesList={stagesState} />
              ),
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
