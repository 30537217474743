export enum GeneralView {
  USER = 'USER',
  MANAGE = 'MANAGE',
  REDIRECT = 'REDIRECT',
  PLACEHOLDER = 'PLACEHOLDER',
  ERROR = 'ERROR',
}

export enum UserView {
  USER_INSIGHTS = 'USER_INSIGHTS',
  USER_INSIGHTS_DETAILS = 'USER_INSIGHTS_DETAILS',
  USER_DASHBOARD = 'USER_DASHBOARD',
  USER_DASHBOARD_REDIRECT = 'USER_DASHBOARD_REDIRECT',
  USER_PROBLEMS = 'USER_PROBLEMS',
  USER_SEARCH = 'USER_SEARCH',
  USER_DOCUMENTS = 'USER_DOCUMENTS',
  USER_SETTINGS = 'USER_SETTINGS',
  USER_MANAGE_DEVICES_DETAILS_TRACKER = 'USER_MANAGE_DEVICES_DETAILS_TRACKER',
  USER_MANAGE_DEVICES_DETAILS_NODE = 'USER_MANAGE_DEVICES_DETAILS_NODE',
  USER_SITES = 'USER_SITES',
  USER_SITES_REDIRECT = 'USER_SITES_REDIRECT',

  USER_OPERATIONS_REDIRECT = 'USER_OPERATIONS_REDIRECT',

  USER_SERVICES_REDIRECT = 'USER_SERVICES_REDIRECT',
  USER_INVENTORIES_REDIRECT = 'USER_INVENTORIES_REDIRECT',
  USER_DEVICES_REDIRECT = 'USER_DEVICES_REDIRECT',
  USER_USERS_REDIRECT = 'USER_USERS_REDIRECT',
}

export enum ManageView {
  MANAGE_DEVICES = 'MANAGE_DEVICES',
  MANAGE_DEVICES_DETAILS = 'MANAGE_DEVICES_DETAILS',
  MANAGE_USERS = 'MANAGE_USERS',
  MANAGE_ORDERS = 'MANAGE_ORDERS',
  MANAGE_ORDERS_DETAILS = 'MANAGE_ORDERS_DETAILS',
  MANAGE_STAGES = 'MANAGE_STAGES',
  MANAGE_PROCESSES = 'MANAGE_PROCESSES',
  MANAGE_COMPONENTS = 'MANAGE_COMPONENTS',
  MANAGE_ZONES = 'MANAGE_ZONES',
  MANAGE_ZONES_DETAILS = 'MANAGE_ZONES_DETAILS',
  MANAGE_INVENTORIES = 'MANAGE_INVENTORIES',
}

export enum ErrorView {
  ERROR_403 = 'ERROR_403',
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
  ERROR_401 = 'ERROR_401',
}

export enum DeviceDetailsType {
  TRACKER = 'TRACKER',
  NODE = 'NODE',
}
