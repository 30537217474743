/**
 * @description
 * This is row for draggable table
 * all the functionalities cthings require for TableRow
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { media } from '../../../styles/media';

import { ReactComponent as ArrowImage } from '../../../assets/Shape.svg';
import { getRequiredDateFormat } from '../../../utils/date/date-format';

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  padding: 13px 24px;
  border-top: ${(props: any) => (!props.first ? `1px solid` : 'none')};
  border-color: gray3;
  ${media.phone`
    padding: 11px 18px 11px 16px;
  `};
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 76px 170px 1fr;
  grid-column-gap: 26px;
  grid-template-rows: 1fr;
  ${media.tablet`
    grid-template-columns: 76px 130px 1fr;
  `};
`;

const MarkWrapper = styled.div``;

const Mark = styled.p`
  color: gray1;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  ${media.tablet`
    &.problem {
      display: none;
    }
  `}
  &.isOpen {
    color: black;
  }
  &.problem {
    color: red !important;
  }
`;

const ArrowContainer = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transform: ${(props: any) => (props.isOpenRow ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  cursor: pointer;
  & > svg > path {
    fill: ${(props: any) => props.theme.colors.black};
  }
  & > svg:hover > path {
    fill: ${(props: any) => props.theme.colors.black};
    transition: fill 0.3s ease;
  }
  ${media.tablet`
    display: flex;
  `}
`;

const EventWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
  box-sizing: border-box;
  display: ${(props: any) => (props.isOpenRow ? 'flex' : 'none')};
  background-color: white1;
  color: red;
  padding: 10px 16px;
  border-top: 0.5px solid;
  border-color: gray3;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

export interface TableRowProps {
  value: any;

  first?: boolean;

  className?: string;
}

export const TableRow: FC<TableRowProps> = ({
  value,
  first,

  ...props
}) => {
  const [isOpenRow, setIsOpenRow] = useState(false);
  const place = `${value.address.city}, ${value.address.line1}`;
  const date = getRequiredDateFormat(value.ts, 'DD.MM.YYYY HH:mm');
  const theme = useTheme();
  return (
    <>
      <Wrapper first={first} {...props}>
        <Container>
          <MarkWrapper>
            <Mark isOpenRow={isOpenRow}>{date}</Mark>
          </MarkWrapper>
          <MarkWrapper>
            <Mark isOpenRow={isOpenRow}>{place}</Mark>
          </MarkWrapper>
          <MarkWrapper>
            <Mark className="problem" isOpenRow={isOpenRow}>
              {value.event_message}
            </Mark>
          </MarkWrapper>
          <ArrowContainer onClick={() => setIsOpenRow(!isOpenRow)} isOpenRow={isOpenRow} theme={theme}>
            <ArrowImage />
          </ArrowContainer>
        </Container>
      </Wrapper>
      <EventWrapper isOpenRow={isOpenRow}>{value.event_message}</EventWrapper>
    </>
  );
};

TableRow.defaultProps = {};
