/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for row of universal table require
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CheckBox } from '@bit/first-scope.controls';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../../styles/media';
import { useTableContext, useTableFunctions } from '../../context';
import { InlineActionConfig, InlineActionType, RowItemType, TableCustomComponentState } from '../../types';
import { ActionSection } from '../actionSection/ActionSection';
import { useLongTap } from '../../../../utils/longTap';
//images
import { ReactComponent as SelectImg } from '../../assets/select.svg';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import { useWindowSize } from '../../../../styles/style.context';
import { sizes } from '../../../../styles/media';

const StyledText = styled(Text)`
  display: block;
  word-wrap: break-word;
  & > span {
    color: gray1;
    width: 100%;
    &.normal {
      font-weight: normal;
    }
  }
` as typeof Text;

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  background-color: white;
  display: grid;
  gap: ${(props: any) => (props.withHeaderInMobile ? '0' : '12px 0')};
  grid-template-columns: ${(props: any) => props.gridTemplate};
  align-items: center;
  border-bottom: 1px solid;
  border-color: gray4;
  padding: ${(props: any) => (props.withCheckBox ? '12px 24px' : '12px 24px 12px 12px')};
  opacity: 1;
  transition: opacity 0.3s ease;
  position: static;
  z-index: auto;

  ${media.tablet`
    grid-template-columns: ${(props: any) => props.mobileGridTemplate};
    grid-template-rows: ${(props: any) => (props.withHeaderInMobile ? 'none' : 'auto')};
    grid-template-areas: ${(props: any) => props.mobileGridArea};
    align-items: start;
    padding: 13px 16px 13px 4px;
    &:hover {
      background-color: white1;
    }
    &.isCheckbox {
      padding: 24px 24px 24px 16px;
    }
  `}

  &.highlighted {
    filter: drop-shadow(0px 4px 6px rgba(96, 97, 112, 0.3));
    position: relative;
    z-index: 2;
  }
  &.blurred {
    opacity: 0.5;
    user-select: none;
  }
  &.chosen {
    position: relative;
    background-color: white1;
    border-left: 2px solid;
    border-left-color: primary;
    padding: 12px 24px 12px 22px;
    ${media.tablet`
      padding: 24px 24px 24px 14px;
    `}
  }
  &.rounded {
    ${media.tablet`
      border-radius: 8px 8px 0 0;
    `}
  }
`;

const GridItem = styled.div`
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  &.field {
    padding: 0 6px;
    ${media.tablet`
    padding: 0 12px;
    `}
  }
  &.select {
    margin-right: 0 12px;
    align-self: center;
    ${media.tablet`
      padding: 0;
    `}
  }
  &.actions {
    align-self: center;
  }
  ${media.tablet`  
    grid-area: ${(props: any) => props.gridArea};
    display: ${(props: any) => (props.mobileDisplay ? 'block' : 'none')};
    align-self: ${(props: any) => (props.mobileAlignSelf ? props.mobileAlignSelf : 'start')};
    user-select: none;
  `}
`;

const Modal = styled.div`
  background-color: white;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow4};
  position: absolute;
  left: 16px;
  z-index: 5;
  width: 128px;
  display: none;
  ${media.tablet`
    display: block;
    top: ${(props: any) => (props.isLast ? '-90px' : 'calc(100% + 10px)')};
  `}
`;

const SelectButton = styled.button`
  height: 40px;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: gray4;
  padding: 0 15px;
  user-select: none;
`;

const DeleteButton = styled.button`
  height: 40px;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  user-select: none;
`;

const TextSelect = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: black;
  margin: 0;
`;

const DeleteText = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: red3;
  margin: 0;
`;

const SelectWrapper = styled.div`
  padding: 0;
`;

export interface ManageTableRowProps {
  data: any;
  index: number;
  isFirst: boolean;
  allowDelete: boolean;
  allowSelect: boolean;
  rounded?: boolean;
  inlineActionSet: InlineActionConfig[];
  customRowClick?: (value: any) => void;
  idItemForViewModal?: string;
  setLastListItem?: any;
  withHeaderInMobile?: boolean;
  withRowGridTemplate?: string;
  setIsSelectAllCheckBox: (value: any) => void;
  isSelectAllCheckBox: boolean;
  languageStrings?: any;
}

const checkIncludesType = (typeArray: any[]) => {
  let isDetailsType = false;
  typeArray.forEach((item) => {
    if (item.type === InlineActionType.DETAILS) {
      isDetailsType = true;
    }
  });
  return isDetailsType;
};

export const Row: FC<ManageTableRowProps> = ({
  data,
  index,
  allowDelete,
  allowSelect,
  customRowClick,
  isFirst,
  rounded,
  inlineActionSet,
  idItemForViewModal,
  withHeaderInMobile,
  withRowGridTemplate,
  setLastListItem,
  setIsSelectAllCheckBox,
  isSelectAllCheckBox,
  languageStrings,
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const {
    toggleIsChecked,
    toggleView,
    setHighlightedIndex,
    toggleMobileSelectMode,
    setLocalDeleteIndex,
    getPresentedItems,
    getGridStructure,
    uncheckAll,
  } = useTableFunctions();

  const { id, isChecked } = data;

  const [{ highlightedIndex, mobileSelectMode, globalProps }] = useTableContext();
  const isExtraModal = highlightedIndex === index;
  const { mobileLimit } = globalProps;
  const windowWidth = useWindowSize()[0];

  const handleClickDetails = useCallback(() => {
    if (!!customRowClick) {
      customRowClick(data);
    } else {
      setHighlightedIndex(-1);
      toggleView(index, id);
    }
  }, [index, id, data, customRowClick, setHighlightedIndex, toggleView]);

  useEffect(() => {
    if (id && id === idItemForViewModal && checkIncludesType(inlineActionSet)) {
      toggleView(index, id);
    }
  }, []);

  const presentedItems = getPresentedItems(index, windowWidth);
  const presentedItemsFiltered =
    windowWidth > sizes.tablet && windowWidth < sizes.monitor && presentedItems.length > 3
      ? presentedItems.slice(0, 3)
      : presentedItems;

  const { gridTemplate, mobileGridTemplate, mobileGridArea } = getGridStructure(index, windowWidth);

  const handleSelectToggle = useCallback(
    (index: number) => {
      toggleIsChecked(index);
    },
    [toggleIsChecked],
  );
  const [onTouchStart, onTouchEnd, clearTouch] = useLongTap(
    (e: any) => setHighlightedIndex(index),
    (e: any) => handleClickDetails(),
    700,
    !mobileSelectMode && (allowDelete || allowSelect),
  );
  const switchMobileSelect = (e: any) => {
    toggleMobileSelectMode();
    handleSelectToggle(index);
    setHighlightedIndex(-1);
    setIsSelectAllCheckBox(true);
  };
  const clearEvents = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDelete = useCallback(() => {
    setLocalDeleteIndex(index);
  }, [index]);
  const handleSelect = useCallback(
    (e: any) => {
      handleSelectToggle(index);
      clearTouch(e);
    },
    [index, handleSelectToggle, clearTouch],
  );

  return (
    <Wrapper
      className={`${isChecked ? 'chosen' : ''} ${mobileSelectMode ? 'isCheckbox' : ''} ${
        isExtraModal ? 'highlighted' : ''
      } 
      ${isFirst ? 'first' : ''} ${rounded ? 'rounded' : ''}`}
      gridTemplate={gridTemplate}
      mobileGridTemplate={
        mobileSelectMode
          ? '30px 2fr 2fr 1fr'
          : withRowGridTemplate
          ? isSelectAllCheckBox && mobileSelectMode
            ? '30px 1fr 1fr'
            : withRowGridTemplate
          : mobileGridTemplate
      }
      mobileGridArea={mobileSelectMode ? " 'item0 item1 item2 action' ' item5 item3 item4 action'" : mobileGridArea}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={clearTouch}
      withCheckBox={allowDelete || allowSelect}
      withHeaderInMobile={withHeaderInMobile}
    >
      {(allowDelete || allowSelect) && (
        <GridItem
          gridArea={mobileSelectMode ? 'item0' : withHeaderInMobile ? '' : 'select'}
          mobileDisplay={mobileSelectMode}
          mobileAlignSelf={'center'}
          onTouchStart={clearEvents}
          onTouchEnd={clearEvents}
          className={'select'}
        >
          <SelectWrapper onClick={handleSelect}>
            <CheckBox
              editInProcess={false}
              toggle={isChecked}
              label=""
              handleToggle={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelect(e);
              }}
            />
          </SelectWrapper>
        </GridItem>
      )}
      <>
        {presentedItemsFiltered.map((item: any, columnIndex: number) => {
          const { getTextDisplay, value, component, type } = item;
          return (
            <GridItem
              key={columnIndex}
              mobileDisplay={mobileLimit === 0 || columnIndex < mobileLimit}
              gridArea={`item${mobileSelectMode ? columnIndex + 1 : columnIndex}`}
              className={'field'}
            >
              {type === RowItemType.CUSTOM ? (
                <>{component && component(value, TableCustomComponentState.VIEW)}</>
              ) : (
                <StyledText key={index} type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                  {getTextDisplay
                    ? getTextDisplay(value)
                    : value
                    ? value.id === undefined && value.name === undefined
                      ? value
                      : value.name
                    : 'N/A'}
                </StyledText>
              )}
            </GridItem>
          );
        })}
      </>
      <GridItem
        gridArea={withHeaderInMobile ? '' : 'action'}
        mobileDisplay={true}
        mobileAlignSelf={'center'}
        className={'actions'}
      >
        <ActionSection
          inlineActionSet={inlineActionSet}
          data={data}
          onDetailsClick={handleClickDetails}
          isSelectAllCheckBox={isSelectAllCheckBox}
        />
      </GridItem>
      {isExtraModal && (
        <Modal
          theme={theme}
          onTouchStart={clearEvents}
          onTouchEnd={clearEvents}
          isLast={highlightedIndex === setLastListItem}
        >
          <SelectButton onTouchStart={switchMobileSelect}>
            <TextSelect>{languageStrings ? languageStrings.selectButton : 'Select'}</TextSelect>
            <SelectImg />
          </SelectButton>
          <DeleteButton onTouchStart={handleDelete}>
            <DeleteText>{languageStrings ? languageStrings.deleteButton : 'Delete'}</DeleteText>
            <Delete />
          </DeleteButton>
        </Modal>
      )}
    </Wrapper>
  );
};
