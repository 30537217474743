/**
 * @description
 * This is component for choose parameter
 * all the functionalities cthings require for parameter
 * Highly customisable.
 *
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { Cheap } from './components/cheap/Cheap';
import { ChooseParameterPopup } from './components/chooseParameterPopup/ChooseParameterPopup';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props: any) => (props.name ? '18px' : '0')};
  position: relative;
`;
const FieldWrapper = styled.div`
  width: 100%;
  height: max-content;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: ${(props: any) => (props.fieldHeight > 129 ? '8px 6px 8px 12px' : '2px 6px 8px 12px')};
  border: 1px solid;
  border-color: ${(props: any) => (props.isOpenSelectList ? props.theme.colors.primary : props.theme.colors.gray3)};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  background-color: white;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${(props: any) => (props.isPopupDescription ? 'auto' : 'pointer')};
  &.disabled {
    background-color: gray5;
    border-color: gray5;
    cursor: auto;
  }
`;
const Field = styled.div`
  width: 100%;
  height: ${(props: any) => (props.fieldHeight < 116 ? 'max-content' : '100%')};
  max-height: 112px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow-y: ${(props: any) => (props.fieldHeight < 116 ? 'auto' : 'scroll')};

  &::-webkit-scrollbar {
    width: 4px;
    background: gray4;
    opacity: 1;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

export const ChooseParameter = ({
  name,
  parameters,
  selectedParameters,
  placeholder,
  handleCheckedItem,
  handleRemoveSelectedItem,
  handleSelectAll,
  setIsOpenSelectList,
  isSelectAll,
  isOpenSelectList,
  isDisabled,
  isPopupDescription,
}: {
  name?: string;
  parameters: any[];
  selectedParameters: any[];
  placeholder: string;
  handleCheckedItem: (id: string) => void;
  handleRemoveSelectedItem: (item: any) => void;
  handleSelectAll?: () => void;
  setIsOpenSelectList: (value: boolean) => void;
  isSelectAll?: boolean;
  isOpenSelectList?: boolean;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
}) => {
  const theme = useTheme();
  const [fieldHeight, setFieldHeight] = useState(40);

  const { gray1, gray2 } = theme.colors;
  const popupRef = useRef<any>(null);

  const openSelectList = useCallback(() => {
    setIsOpenSelectList(true);
  }, []);
  const closeSelectList = useCallback(() => {
    setIsOpenSelectList(false);
  }, []);
  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpenSelectList && !(popupRef && popupRef.current && (popupRef as any).current.contains(e.target))) {
        closeSelectList();
      }
    },
    [isOpenSelectList, closeSelectList],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  return (
    <Wrapper name={name}>
      {name && (
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin={'0 0 2px 8px'}>
          {name}
        </Text>
      )}
      <FieldWrapper
        className={isDisabled && !isPopupDescription ? 'disabled' : ''}
        ref={(el: any) => {
          if (!el) return;
          setFieldHeight(el.getBoundingClientRect().height);
        }}
        theme={theme}
        isOpenSelectList={isOpenSelectList}
        onClick={isDisabled ? () => {} : isOpenSelectList ? closeSelectList : openSelectList}
        isPopupDescription={isPopupDescription}
      >
        <Field fieldHeight={fieldHeight}>
          {selectedParameters.length > 0 ? (
            selectedParameters.map((item: any, index: number) => {
              const { name } = item;
              const value = name;
              return (
                <Cheap
                  key={index}
                  value={value}
                  closeOnClick={() => {
                    handleRemoveSelectedItem(item);
                  }}
                  isDisabled={isDisabled}
                  isPopupDescription={isPopupDescription}
                />
              );
            })
          ) : (
            <Text type={TextType.TEXT_12_GRAY} color={gray2} margin={'7px 0 0 0'}>
              {placeholder}
            </Text>
          )}
        </Field>
        {isOpenSelectList && (
          <ChooseParameterPopup
            handleCheckedItem={handleCheckedItem}
            handleSelectAll={handleSelectAll}
            popupRef={popupRef}
            parameterList={parameters}
            fieldHeight={fieldHeight}
            isSelectAll={isSelectAll}
          />
        )}
      </FieldWrapper>
    </Wrapper>
  );
};
