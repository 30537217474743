/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for close button require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { ReactComponent as CrossIcon } from '../../assets/Stroke30.svg';

const Round = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
  appearance: none;
  background-color: gray5;
  transition: 0.3s;
  &:hover,
  &:focus {
    background-color: white1;
  }
`;

type CloseWhiteRoundButtonProps = {
  onClick: () => void;
  className?: string;
};

export const CloseWhiteRoundButton: React.FC<CloseWhiteRoundButtonProps> = ({ onClick, ...props }) => {
  return (
    <Round onClick={onClick} {...props}>
      <CrossIcon />
    </Round>
  );
};
