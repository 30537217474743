/**
 * @description
 * component for displaying zone locations
 *
 */

import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
// @ts-ignore
import { MapInteractionCSS } from 'react-map-interaction';
import useResizeObserver from 'use-resize-observer';
import { ImageContainer, MapInteraction, Image, ImageOuterContainer } from './styled';
// @ts-ignore
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { usePrevious } from '../../utils/usePrevious';

import { ClusterEntity } from './components/clusterEntity/ClusterEntity';
import { updateZone } from '../../api/manageZonesDetails';
import { NEW_ID } from '../../consts';
import { DescriptionPopup } from '../../containers/management/zones/components/descriptionPopup/DescriptionPopup';
import { deleteZoneGlobal } from '../../api/manageZones';
import { useDispatch, useSelector } from 'react-redux';
import { createZoneColors, selectZoneColors } from '../../app/state/colorsSlice';
import { useNotifierFunctions } from '../notifier2';
import { useWindowSize } from '../../styles/style.context';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';
import { View } from '../../routes/routeInterfaces';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import { selectLanguageStrings } from '../../app/state/userSlice';

const DragImage = styled.div`
  height: 32px;
  width: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 1.68421px rgba(40, 41, 61, 0.04), 0px 3px 6px rgba(96, 97, 112, 0.16);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const OuterCircle = styled.div`
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-color: primary;
`;

const InnerCircle = styled.div`
  border-radius: 50%;
  width: 4px !important;
  height: 4px !important;

  border: 2px solid;
  border-color: primary;
`;

const DragContainer = styled.div`
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
`;

const DragText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
  color: gray1;
`;

const DEFAULT_VIEWPORT = { scale: 1, translation: { x: 0, y: 0 } };
export interface ZonesFloorplanType {
  image: { src: string; width: number; height: number };
  id: string;
  data: any;
  locations: any;
  setImageDimensions: any;
  height: string;
  pathParams: any;
  isOpenModal: boolean;
  closeModal: any;
  setLocations: any;
  setActiveId: any;
  floorId: string;
  toggleTrigger: any;
  activeId: string | null;
  savedZones: any;
  setSavedZones: React.Dispatch<any>;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}

export interface Viewport {
  scale: number;
  translation: { x: number; y: number };
}

export const ZonesFloorplan: FC<ZonesFloorplanType> = ({
  image,
  locations,
  setImageDimensions,
  height,
  setActiveId,
  isOpenModal,
  closeModal,
  data,
  setLocations,
  floorId,
  id,
  toggleTrigger,
  activeId,
  savedZones,
  setSavedZones,
  pathParams,
  accessData,
}) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const [viewport, setViewport] = useState<Viewport>(DEFAULT_VIEWPORT);
  const dispatch = useDispatch();
  const { scale } = viewport;
  const [isEdit, setIsEdit] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const description = languageStrings
    ? languageStrings.zonesFloorplanDescriptions
    : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor';
  const ref: any = useRef<any>(null);
  const imageRef: any = useRef<any>(null);

  const containerSize: any = useResizeObserver({ ref });
  const imageSize: any = useResizeObserver({ ref: imageRef });
  const prevImageSize = usePrevious(imageSize);
  const history = useCustomHistory();
  const windowWidth = useWindowSize()[0];
  useBlockBodyScroll(windowWidth < 427 && !!activeId);

  const ratio = imageSize?.width / data?.floors[0]?.floor_image_width;
  const pixel_ratio = (data?.floors[0]?.floor_pixel_per_meter | 35) * ratio;
  const { building_id, floor, offset } = pathParams;

  const [onLoadImage, setOnLoadImage] = useState<boolean>(false);
  const [mainRatio, setMainRatio] = useState<number>(0);

  const [innerOffset, setInnerOffset] = useState({ x: 0, y: 0 });

  const offsetRef = useRef<any>();

  useEffect(() => {
    if (offsetRef.current) {
      setInnerOffset(offsetRef.current.getBoundingClientRect());
    }
  }, [offsetRef.current]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (offsetRef.current) {
        setInnerOffset(offsetRef.current.getBoundingClientRect());
      }
    });
  }, []);

  useEffect(() => {
    const { width, height } = imageSize;
    if (width && height && (width !== prevImageSize?.width || height !== prevImageSize?.height)) {
      setImageDimensions({ width, height });
    }
  }, [imageSize.width, imageSize.height]);

  useEffect(() => {
    image.width &&
      image.height &&
      containerSize.width &&
      containerSize.height &&
      setMainRatio(image.width / image.height / (containerSize.width / containerSize.height));
  }, [image.width, image.height, containerSize.width, containerSize.height]);

  const { addNotification } = useNotifierFunctions();

  const triggerNotification = (success: boolean, message?: string) => {
    success
      ? addNotification({
          message: languageStrings ? languageStrings.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        })
      : addNotification({
          message: message
            ? message
            : languageStrings
            ? languageStrings.zonesConflictNotification
            : 'Zones conflict with each other',
          type: 'error',
        });
  };

  const handleDragEnd = (e: any, c: any, x: number, y: number) => {
    const rect = c.getBoundingClientRect();

    const ratio = imageSize.width / data.floors[0].floor_image_width;
    const coordinates = { center: { x: x - rect.x, y: y - rect.y }, height: 50 / ratio, width: 50 / ratio };
    const { center, height, width } = coordinates;

    const newCoordinates = {
      center: { x: center.x / ratio, y: center.y / ratio },
      height,
      width,
    };

    if (ref.current.contains(c)) {
      const newVal = {
        description,
        coordinates,
        name: languageStrings ? languageStrings.newZoneTitle : 'New zone',
        location: {
          floor_id: floorId,
          building_id: id,
          floor: {
            floor_id: floorId,
            floor_name: data.floors[0].floor_name,
            image_height: data.floors[0].floor_image_height,
            image_width: data.floors[0].floor_image_width,
            level: data.floors[0].floor_level,
            pixel_per_meter: data.floors[0].floor_pixel_per_meter,
            image_id: locations.length > 0 ? locations[0].location?.floor?.image_id : '',
          },
        },
      };
      const newValApi = {
        ...newVal,
        coordinates: newCoordinates,
      };
      const localUpdate = (id: string, data: any) => {
        setLocations((value: any[]) => [
          ...value,
          {
            id,
            ...data,
          },
        ]);
        setTimeout(() => toggleTrigger(), 150);
      };
      updateZone(NEW_ID, newValApi, { localUpdate, triggerNotification });
    }
  };

  const handleRelocate = (e: any, c: any, x: number, y: number, id: string, sourceNode: any) => {
    let rect = c.getBoundingClientRect();

    if (!ref.current.contains(c) || (sourceNode && c.contains(sourceNode))) {
      const imageWidth = (containerSize.height * imageSize.width) / imageSize.height;
      const imageHeight = (containerSize.width * imageSize.height) / imageSize.width;
      const rectX = mainRatio < 1 ? innerOffset.x + (containerSize.width - imageWidth) / 2 : innerOffset.x;
      const rectY = mainRatio > 1 ? innerOffset.y + (containerSize.height - imageHeight) / 2 : innerOffset.y;
      rect = { y: rectY, x: rectX };
    }

    setLocations((value: any[]) => {
      const index = value.findIndex((el: any) => el.id === id);
      const oldVal = index !== -1 ? value[index] : { coordinates: { pxPerM: 5 } };
      const coordinates = {
        center: { x: (x - rect.x) / ratio, y: (y - rect.y) / ratio },

        height: oldVal.coordinates.height,
        width: oldVal.coordinates.width,
      };

      const newVal = {
        ...oldVal,
        description: oldVal.description,
        coordinates,
        name: oldVal.name,
        id,
        location: {
          floor_id: oldVal?.location?.floor_id,
          building_id: oldVal?.location?.building_id,
          floor: {
            floor_id: oldVal?.location?.floor?.floor_id,
            image_height: imageSize.height,
            image_width: imageSize.width,
            level: oldVal?.location?.floor?.level,
            floor_name: oldVal?.location?.floor?.floor_name,
            image_id: oldVal?.location?.floor?.image_id,
            pixel_per_meter: oldVal?.location?.floor?.pixel_per_meter || 5,
          },
        },
      };

      const result = index !== -1 ? [...value.slice(0, index), newVal, ...value.slice(index + 1, value.length)] : value;

      return result;
    });
    setActiveId(id);
  };

  const activeDataIndex = locations.findIndex((el: any) => el.active);
  const activeData = activeDataIndex !== -1 ? locations[activeDataIndex] : null;

  const handleRevert = useCallback(() => {
    setLocations(savedZones);
  }, [savedZones, setLocations]);

  const handleApply = useCallback(() => {
    const { coordinates, id } = activeData;

    const { center, height, width } = coordinates;
    const newCoordinates = {
      center: { x: center.x, y: center.y },
      height,
      width,
    };

    const newVal = {
      ...activeData,
      coordinates,
      /*associated_stages: activeData.associated_stages.selected_stages,*/
      id,
      location: {
        floor_id: activeData?.location?.floor_id,
        building_id: activeData?.location?.building_id,
        floor: {
          floor_id: activeData?.location?.floor?.floor_id,
          image_height: imageSize.height,
          image_width: imageSize.width,
          level: 0,
          floor_name: activeData?.location?.floor?.floor_name,
          image_id: activeData?.location?.floor?.image_id,
          pixel_per_meter: activeData?.location?.floor?.pixel_per_meter || 5,
        },
      },
    };

    const newValApi = {
      ...newVal,
      coordinates: newCoordinates,
    };

    updateZone(id, newValApi, { triggerNotification, handleRevert });
    setSavedZones(locations);
    setTimeout(() => toggleTrigger(), 150);
  }, [activeData, imageSize?.height, imageSize?.width, data?.floors]);

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (activeDataIndex !== -1 && !isEdit && !dragActive) {
        setActiveId('');
        setIsEdit(false);
        history.replace(View.MANAGE_ZONES_DETAILS, {
          pathParams: { building_id, floor, zone_id: 'noselection', offset },
        });
      }
    },
    [activeDataIndex, setActiveId, isEdit, dragActive],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  // @TODO Alex - this is a HUGE HACK - remove when possible
  useEffect(() => {
    activeDataIndex !== -1 && dragActive && setTimeout(() => setDragActive(false), 150);
  }, [activeDataIndex, dragActive]);

  useEffect(() => {
    dispatch(createZoneColors(locations.length));
  }, [locations.length]);

  const colors = useSelector(selectZoneColors);

  const [modalIsRight, setModalIsRight] = useState(false);

  return (
    <MapInteraction height={height}>
      <MapInteractionCSS
        disableZoom
        disablePan
        value={viewport}
        onChange={(value: Viewport) => setViewport(value)}
        maxScale={20}
      >
        <ImageOuterContainer ref={offsetRef}>
          <ImageContainer ref={ref} ratio={mainRatio}>
            <DropTarget targetKey="foo">
              {image.src && (
                <Image
                  src={image.src}
                  alt={'Floorplan'}
                  ref={imageRef}
                  onLoad={() => setOnLoadImage(true)}
                  ratio={mainRatio}
                />
              )}
            </DropTarget>
            {onLoadImage &&
              locations.map((value: any, index: number) => (
                <ClusterEntity
                  color={colors[index]}
                  pointData={value}
                  pixel_ratio={pixel_ratio}
                  ratio={ratio}
                  scale={scale}
                  dimensionRatio={mainRatio}
                  containerDimensions={containerSize}
                  imageDimensions={imageSize}
                  handleDragEnd={(e: any, c: any, x: number, y: number, sourceRef: any) => {
                    handleRelocate(e, c, x, y, value.id, sourceRef);
                  }}
                  handleDragStart={(e: any, ...props: any[]) => {
                    setActiveId('');
                    setDragActive(true);
                  }}
                  innerOffset={innerOffset}
                  onClick={(e: any, left: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setActiveId(value.id);
                    history.replace(View.MANAGE_ZONES_DETAILS, {
                      pathParams: { building_id, floor, zone_id: value.id, offset },
                    });
                    left <= imageSize.width / 2 ? setModalIsRight(true) : setModalIsRight(false);
                  }}
                  activeId={activeId}
                  isDraggable={windowWidth > 768 && isEdit}
                  withPoint
                />
              ))}
          </ImageContainer>
        </ImageOuterContainer>
      </MapInteractionCSS>
      {activeData && (
        <DescriptionPopup
          value={activeData}
          closeModal={() => {
            setActiveId('');
            history.replace(View.MANAGE_ZONES_DETAILS, {
              pathParams: { building_id, floor, zone_id: 'noselection', offset },
            });
          }}
          pixel_ratio={pixel_ratio}
          pictureSize={{ width: data.floors[0].floor_image_width, height: data.floors[0].floor_image_height }}
          onChange={(getValue: (currentValue: any) => any) => {
            setLocations((values: any[]) => {
              const currentValue = values[activeDataIndex];
              const resultValue = getValue(currentValue);
              const result =
                activeDataIndex !== -1
                  ? [
                      ...values.slice(0, activeDataIndex),
                      resultValue,
                      ...values.slice(activeDataIndex + 1, values.length),
                    ]
                  : values;

              return result;
            });
          }}
          onRevert={() => {
            handleRevert();
          }}
          handleCancelOnClick={() => {
            handleRevert();
            setIsEdit(false);
          }}
          handleEditOnClick={() => {
            setSavedZones(locations);
            setIsEdit(true);
          }}
          handleDeleteOnClick={() => {
            const currentId = activeData.id;
            deleteZoneGlobal(
              [currentId],
              () => {
                setLocations((values: any[]) => {
                  const result =
                    activeDataIndex !== -1
                      ? [...values.slice(0, activeDataIndex), ...values.slice(activeDataIndex + 1, values.length)]
                      : values;
                  return result;
                });
              },
              addNotification,
              () => {},
            );
          }}
          handleApplyOnClick={() => {
            handleApply();
            setIsEdit(false);
          }}
          isEdit={isEdit}
          isRight={modalIsRight}
          accessData={accessData}
        />
      )}
      {windowWidth > 768 && accessData?.edit && (
        <DragContainer>
          <DragDropContainer targetKey="foo" dragClone onDragEnd={handleDragEnd}>
            <DragImage>
              <OuterCircle>
                <InnerCircle />
              </OuterCircle>
            </DragImage>
          </DragDropContainer>
          <DragText>
            - {languageStrings ? languageStrings.dragAndDropButton : 'Drag & Drop to make a new zone'}
          </DragText>
        </DragContainer>
      )}
    </MapInteraction>
  );
};
