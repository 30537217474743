import { Dispatch, SetStateAction } from 'react';
import { ApiCallType } from '../../utils/http';
import { LoaderOptions, TriggerPlaceholder } from '../placeholderComponent/types';

export enum RequestType {
  HOOK = 'HOOK',
  CALL = 'CALL',
}

export interface ApiBuilderOptions {
  data?: any;
  transformPayload?: (payload: any) => any;
  fakeApiExtender?: any;
  url: string;
  body?: Object;
  callType?: ApiCallType;
  condition?: boolean;
  requestType?: RequestType;
  triggers?: any[];
  setPlaceholder?: TriggerPlaceholder;
  stopPlaceholder?: TriggerPlaceholder;
}

export interface ApiGenerateOptions {
  transformPayload?: (payload: any) => any;
  fakeApiExtender?: any;
  url: string;
  body?: Object;
  callType?: ApiCallType;
  setPlaceholder?: TriggerPlaceholder;
  stopPlaceholder?: TriggerPlaceholder;
}

export interface ApiHookBuilderOptions {
  url: string;
  setLocalData: Dispatch<SetStateAction<any>>;
  triggers: any[];
  loaderOptions: LoaderOptions;
  body?: Object;
  callType: ApiCallType;
  transformPayload?: (payload: any) => any;
  fakeApiExtender?: any;
  condition?: boolean;
}

export interface ApiCallBuilderOptions {
  url: string;
  loaderOptions: LoaderOptions;
  body?: Object;
  callType: ApiCallType;
  transformPayload?: (payload: any) => any;
  fakeApiExtender?: any;
}

export interface ApiCallOptions {
  url: string;
  setLocalData?: Dispatch<SetStateAction<any>>;
  loaderOptions: LoaderOptions;
  body?: Object;
  callType: ApiCallType;
  handleSuccess?: (data: any) => void;
  handleError?: (error: any) => void;
  transformPayload?: (payload: any) => any;
  fakeApiExtender?: any;
}

export interface PreparedApiCallOptions {
  handleSuccess?: (data: any) => void;
  handleError?: (error: any) => void;
}

export type ApiCall = (options?: PreparedApiCallOptions) => void;
