/**
 * @description
 * This is used in Range component for DescriptionPopup
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Range, getTrackBackground } from 'react-range';

const Wrapper = styled.div`
  height: 12px;
  position: relative;
  margin-top: 4px;
`;

export const RoundedRange = ({
  values,
  handleOnChange,
  min,
  max,
}: {
  values: number[];
  handleOnChange: (values: any) => void;
  min: number;
  max: number;
}) => {
  const theme = useTheme();
  const { primary, secondary3, white } = theme.colors;

  const step = 1;

  return (
    <Wrapper>
      <Range
        values={values[0] > max ? [max] : values}
        step={step}
        min={min}
        max={max}
        onChange={(vals) => handleOnChange(vals)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '0px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '2px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values[0] > max ? [max] : values,
                  colors: [primary, secondary3],
                  min: min,
                  max: max,
                }),
                alignSelf: 'right',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '12px',
              width: '12px',
              borderRadius: '50%',
              border: '2px solid',
              borderColor: primary,
              outline: 'none',
              backgroundColor: white,
              boxShadow: theme.shadows.additionalShadow6,
            }}
          />
        )}
      />
    </Wrapper>
  );
};
