import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { AddButton } from '../../../../../components/addButton/AddButton';
import { InlineActionType, RowItemType } from '../../../../../features/universalTable/types';
import { updateEvents } from '../../../../../api/manageOrderDetails';
import moment from 'moment';
import { media } from '../../../../../styles/media';
import { useTableFunctions, withTableContextProvider } from '../../../../../features/universalTable/context';
import { UniversalTable } from '../../../../../features/universalTable/UniversalTable';
import { API_URL } from '../../../../../consts';
import { View } from '../../../../../routes/routeInterfaces';
import { useWindowSize } from '../../../../../styles/style.context';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  ${media.desktop`
    max-width: 1024px;
    margin-top: 30px;
  `}
`;

const WrapperText = styled(Text)`
  margin-bottom: 15px;
`;

export interface EventsTableProps {
  id: string;
  pathParams: any;
  trigger: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}

const EventsTablePlain: FC<EventsTableProps> = ({ id, pathParams, trigger, accessData }) => {
  const keyword = 'EventsActionsTable';
  const offsetKey = 'eventsOffset';
  const functions = useTableFunctions();
  const { startAddProcess } = functions;
  const width = useWindowSize()[0];
  const { addNotification } = useNotifierFunctions();
  const language = useSelector(selectLanguageStrings);

  const handleUpdateItems = (event_id: string, data: any, index: number, updateLocalItem: any) => {
    updateEvents(event_id, data, index, updateLocalItem, id, addNotification);
  };

  const offset = pathParams[offsetKey];

  return (
    <Wrapper>
      <WrapperText type={TextType.SEMITITLE}>{language ? language.titleOfEventsTable : 'Events/Actions'}</WrapperText>
      <UniversalTable
        apiTriggers={[trigger]}
        placeholderProps={{ keyword, placeholderOptions: { countRows: 6 } }}
        offset={offset}
        offsetKey={offsetKey}
        pathParams={pathParams}
        tablePath={View.MANAGE_ORDERS_DETAILS}
        mainApiUrl={`${API_URL}/v1/order/details/${id}/actions`}
        keyword={keyword}
        updateItems={handleUpdateItems}
        pageSize={10}
        titleActionComponent={
          accessData?.edit && (
            <AddButton
              onClick={() => {
                startAddProcess();
              }}
              title={width < 769 ? 'Add' : language ? language.addEventButton : 'Add event'}
              width={width < 769 ? '72px' : '100px'}
              height="32px"
            />
          )
        }
        inlineActionSet={[
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        withHeaderInMobile={width < 769}
        withHeaderGridTemplate={'1fr 1fr 1fr'}
        withRowGridTemplate={'1fr 1fr 1fr'}
        accessData={accessData}
        columnStructure={[
          {
            fieldName: 'ts',
            placeholder: '',
            label: language ? language.labelDate : 'Date',
            defaultValue: new Date(),
            disabled: true,
            getTextDisplay: (value: any) => moment(value).format('DD.MM.YYYY') || 'N/A',
            shownInHeader: width < 769,
          },
          {
            fieldName: 'ts',
            placeholder: '',
            label: language ? language.labelTime : 'Time',
            defaultValue: new Date(),
            disabled: true,
            getTextDisplay: (value: any) => moment(value).format('HH:mm') || 'N/A',
          },
          {
            fieldName: 'name',
            placeholder: language ? language.enterNamePlaceholder : 'Enter name',
            label: language ? language.labelName : 'Name',
            defaultValue: '',
            modalColumns: 2,
            type: RowItemType.INPUT,
            shownInHeader: width < 769,
          },
          {
            fieldName: 'desc',
            placeholder: language ? language.enterDescriptionPlaceholder : 'Enter description',
            label: language ? language.labelDescription : 'Description',
            defaultValue: '',
            modalColumns: 2,
            rows: 3,
            type: RowItemType.INPUT,
          },
        ]}
      />
    </Wrapper>
  );
};

export const EventsTable = withTableContextProvider(EventsTablePlain);
