import { useEffect, useMemo, useState } from 'react';
import { ApiCallType, getHttpService } from '../../utils/http';
import {
  generateLoaderOptions,
  triggerPlaceholderEmpty,
  useLoaderOptions,
} from '../placeholderComponent/loaderFunctions';
import { fakeApiExtend } from './fakeApiEtxendFunctions';
import {
  ApiBuilderOptions,
  ApiHookBuilderOptions,
  ApiCallBuilderOptions,
  PreparedApiCallOptions,
  ApiCallOptions,
  RequestType,
  ApiCall,
  ApiGenerateOptions,
} from './types';

const callApi = ({
  url,
  setLocalData,
  loaderOptions,
  body,
  callType,
  handleSuccess,
  handleError,
  transformPayload,
  fakeApiExtender,
}: ApiCallOptions) => {
  const httpServiceCall = getHttpService({ callType, url, body, loaderOptions });

  const prepareData = (data: any) => {
    const transformedData = transformPayload ? transformPayload(data) : data;
    const preparedData = fakeApiExtender ? fakeApiExtend(fakeApiExtender, transformedData) : transformedData;
    return preparedData;
  };
  (async () => {
    try {
      let responseData = (await httpServiceCall()).data.payload;
      const preparedData = prepareData(responseData);
      setLocalData && setLocalData(preparedData);
      handleSuccess && handleSuccess(preparedData);
    } catch (e) {
      handleError && handleError(e);
    }
  })();
};

const buildApiHook = ({
  url,
  setLocalData,
  triggers,
  loaderOptions,
  body,
  callType,
  transformPayload,
  fakeApiExtender,
  condition,
}: ApiHookBuilderOptions) => (callbacks: PreparedApiCallOptions = {}) => {
  const { handleSuccess, handleError } = callbacks;
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (condition) {
      callApi({
        url,
        setLocalData,
        loaderOptions,
        body,
        callType,
        handleSuccess,
        handleError,
        transformPayload,
        fakeApiExtender,
      });
    }
  }, [...triggers]);
};

const buildApiCall = ({
  url,
  loaderOptions,
  body,
  callType,
  transformPayload,
  fakeApiExtender,
}: ApiCallBuilderOptions) => (callbacks: PreparedApiCallOptions = {}) => {
  const { handleSuccess, handleError } = callbacks;
  callApi({ url, loaderOptions, body, callType, handleSuccess, handleError, transformPayload, fakeApiExtender });
};

export const useApiBuilder = ({
  // use data if you want localData to be outside controlled (good if needed to pair with REDUX)
  data,
  // use transformPayload function if you need to modify
  // request payload before setting it to local state
  transformPayload,
  url,
  body,
  callType = ApiCallType.GET,
  requestType = RequestType.HOOK,
  triggers: rawTriggers,
  setPlaceholder,
  stopPlaceholder,
  fakeApiExtender,
  condition = true,
}: ApiBuilderOptions) => {
  const controlledData = data;
  const [localData, setLocalData] = useState(controlledData);

  const triggers = [...(rawTriggers || []), url, condition];

  const loaderOptions = useLoaderOptions(
    setPlaceholder || triggerPlaceholderEmpty,
    stopPlaceholder || triggerPlaceholderEmpty,
    [...triggers],
  );

  useEffect(() => {
    setLocalData(controlledData);
  }, [controlledData]);

  const apiCall = useMemo((): ApiCall => {
    switch (requestType) {
      case RequestType.HOOK:
        return buildApiHook({
          setLocalData,
          url,
          body,
          triggers,
          loaderOptions,
          callType,
          transformPayload,
          fakeApiExtender,
          condition,
        });
      case RequestType.CALL:
        return buildApiCall({ url, loaderOptions, body, callType, transformPayload, fakeApiExtender });
    }
  }, [...triggers]);

  // Note that apiCall can be either hook or regular call, make sure
  // to destruct it with 'use' prefix to make react engine understand it as a hook
  return [localData, apiCall] as const;
};

export const generateApi = ({
  // use transformPayload function if you need to modify
  // request payload before setting it to local state
  transformPayload,
  url,
  body,
  callType = ApiCallType.GET,
  setPlaceholder,
  stopPlaceholder,
  fakeApiExtender,
}: ApiGenerateOptions) => {
  const loaderOptions = generateLoaderOptions(
    setPlaceholder || triggerPlaceholderEmpty,
    stopPlaceholder || triggerPlaceholderEmpty,
  );
  return buildApiCall({ url, loaderOptions, body, callType, transformPayload, fakeApiExtender });
};
