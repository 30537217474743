/**
 * @description
 * This is component for header of orders table
 * all the functionalities cthings require for orders table
 * good customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { FC } from 'react';
import { ReactComponent as ArrowImage } from '../../../../assets/Shape.svg';
import { media } from '../../../../styles/media';
import { SelectPeriod } from '../../../selectPeriod/SelectPeriod';

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  background-color: white;
  box-sizing: border-box;
  padding: 13px 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid;
  border-color: gray4;
  ${media.phone`
    padding: 11px 18px 11px 16px;
  `};
`;

const TitleBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.gridTemplateDesktop ? props.gridTemplateDesktop : '190px 280px 10fr'};
  grid-gap: 24px;
  ${media.tablet`
    grid-template-columns: ${(props: any) =>
      props.gridTemplateTablet ? props.gridTemplateTablet : '130px 130px 10fr'};
  `};
  ${media.phone`
    grid-template-columns: ${(props: any) => (props.gridTemplatePhone ? props.gridTemplatePhone : '86px 60px 10fr')};
  `};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-weight: 500;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 4px;
  & > svg:nth-child(2n + 1) {
    transform: rotate(180deg);
    margin: 0px 0px 2px 0px;
  }

  & > svg {
    cursor: pointer;
  }

  & > svg > path {
    fill: ${(props: any) => props.theme.gray2};
    transition: fill 0.3s ease;
  }

  & > svg:hover > path {
    fill: ${(props: any) => props.theme.gray1};
  }
`;

const StyledSelect = styled(SelectPeriod)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

export interface TableRowProps {
  labels: any;
  isSelectHere?: boolean;
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
  className?: string;
}

export const TableHeader: FC<TableRowProps> = ({
  labels,
  isSelectHere,
  gridTemplateDesktop,
  gridTemplateTablet,
  gridTemplatePhone,
  ...props
}) => {
  const theme = useTheme();
  const selectProps = {
    values: ['All'],
  };
  return (
    <Wrapper {...props}>
      <TitleBox
        gridTemplateDesktop={gridTemplateDesktop}
        gridTemplateTablet={gridTemplateTablet}
        gridTemplatePhone={gridTemplatePhone}
      >
        {labels.map((item: any, index: number) => {
          return (
            <TitleWrapper key={index} index={index}>
              <Text type={TextType.TITLE_H4} color={theme.colors.black}>
                {item.name}
              </Text>
              {item.isSorted ? (
                <ArrowWrapper theme={theme}>
                  <ArrowImage />
                  <ArrowImage />
                </ArrowWrapper>
              ) : null}
            </TitleWrapper>
          );
        })}
      </TitleBox>
      {isSelectHere ? <StyledSelect {...selectProps} /> : null}
    </Wrapper>
  );
};

TableHeader.defaultProps = {
  labels: ['Date', 'Time', 'Event'],
};
