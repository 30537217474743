import { Dispatch, ReactNode, SetStateAction } from 'react';
import { View } from '../../routes/routeInterfaces';

export enum RowItemType {
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  CUSTOM = 'CUSTOM',
  INPUT_CUSTOM = 'INPUT_CUSTOM',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  INPUT_EMAIL = 'INPUT_EMAIL',
  JOINED_SELECT = 'JOINED_SELECT',
}

export enum TableItemState {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  VIEW_MODAL = 'VIEW_MODAL',
}

export enum TableCustomComponentState {
  VIEW = 'VIEW',
  VIEW_MODAL = 'VIEW_MODAL',
  EDIT = 'EDIT',
}

export type ModalColumnCount = 2 | 3;

export type TableState = {
  data: TableData;
  functions: TableFunctions;
};

export type MetadataItem = {
  sortable: boolean;
  field_name: string;
  object_key: string;
  isRequired: boolean;
};

export type TableData = {
  list: any[];
  page: number;
  isSelectAll: boolean;
  openIndex: number;
  highlightedIndex: number;
  localDeleteIndex: number;
  openId: string | null;
  currentModalApiId: string | null;
  isEditInProcess: boolean;
  isViewInProcess: boolean;
  isAddInProcess: boolean;
  isDeleteInProcess: boolean;
  mobileSelectMode: boolean;
  pagination: Pagination;
  metadata?: MetadataItem[];
  sorting: SortingData;
  searchValue: { actual: string; slow: string };
  globalProps: {
    columnStructure: ColumnStructure[];
    allowDelete: boolean;
    allowSelect: boolean;
    mobileLimit: number;
    modalApi?: ModalApi;
    mainApiUrl?: string;
    indexOfItemInPayload?: string;
    withoutQuestionMarkBeforeLimit?: boolean;
    keywordList: string[];
    pageSize: number;
    pathParams: any;
    offsetKey: string;
    tablePath?: typeof View[keyof typeof View];
    apiTriggers: any[];
    setIsPendingRequestFromModalApi?: React.Dispatch<React.SetStateAction<boolean>>;
    withHeaderInMobile?: boolean;
    hasActions: boolean;
    keyword: string;
  };
};

export type TableFunctions = {
  setTableData: any;
  deleteItem: any;
  deleteCheckedItems: any;
  toggleIsChecked: any;
  toggleIsCheckedAll: any;
  toggleIsEditInProcess: any;
  addEmptyItem: any;
  increaseItemCount: any;
  setField: any;
  updateLocalItem: any;
  setGlobalProps: any;
  setSortingData: any;
  putItem: any;
  getSelectedItems: any;
  createItem: any;
  toggleView: any;
  toggleEdit: any;
  toggleAdd: any;
  toggleDelete: any;
  getReadyState: any;
  getViewedData: any;
  getLocalDeleteName: any;
  getLocalDeleteId: any;
  setHighlightedIndex: any;
  setLocalDeleteIndex: any;
  toggleMobileSelectMode: any;
  getPresentedItems: any;
  getModalItems: any;
  getGridStructure: any;
  startAddProcess: any;
  uncheckAll: any;
  enrichModalData: any;
  getRequiredData: any;
  getHeaderData: any;
  getSortingData: any;
  setPage: any;
  getSearchValue: any;
  setSearchValue: any;
  setOpenIndex: any;
  setValidatingIsStarted: any;
  resetTable: any;
};

export type ColumnStructure = {
  type?: RowItemType;
  rows?: number;
  label: string;
  fieldName: string | { name: string; id: string };
  viewFieldName?: string | { name: string; id: string };
  isError?: boolean;
  validatingIsStarted?: boolean;
  placeholder: any;
  defaultValue: any;
  width?: string;
  modalColumns?: 1 | 2 | 3;
  getTextDisplay?: (value: any) => void;
  component?: (value: any, state?: TableCustomComponentState, setValue?: any) => ReactNode;
  bold?: boolean;
  selectItems?: any[];
  pathToSelectItems?: string[];
  selectItemsModifier?: (items: [any]) => { id: string; name: string }[];
  disabled?: boolean;
  isFromRequest?: boolean;
  excludedFromView?: boolean; //@NOTE huge disclaimer !! if isFromRequest === true, make excludedFromView === true
  excludedFromAdd?: boolean;
  shownInHeader?: boolean;
  conditionForValidationInCustom?: any;
  isTooltip?: boolean;
  excludedTitleFromMobileViewEmptyData?: boolean;
  tooltipText?: string;
  customComponentHasSelectView?: boolean;
  updateItem?: Function;
  deleteItem?: Function;
  createItem?: Function;
  isUnassigned?: boolean;
  onlyIdForOnChangeSelect?: boolean;
};

export type UpdateUserType = (
  id: string,
  data: any,
  index: number,
  setField: any,
  handleAddNotification: Function,
) => void;

export type ModalApi = {
  url: (id: string) => string;
  type: ModalApiType;
  customFunction?: (id: string, data: any, enrichFunction: (payload: any) => void) => any;
};

export enum ModalApiType {
  GET = 'GET',
  POST = 'POST',
  CUSTOM = 'CUSTOM',
}

export enum ActionType {
  EDIT = 'EDIT',
  CUSTOM = 'CUSTOM',
}

export enum InlineActionType {
  DETAILS = 'DETAILS',
  CUSTOM = 'CUSTOM',
}

export type ActionConfig = {
  type: ActionType;
  callback?: (data: any) => void;
  component?: ReactNode;
};

export type SortingData = { sort_type: string; sort_field: string };

export type InlineActionConfig = {
  type: InlineActionType;
  callback?: (data: any) => void;
  component?: ReactNode;
  isDeleteWithZeroNodeCount?: boolean;
};

export type Pagination = {
  total_items: number;
  total_pages: number;
  page_number: number;
  limit: number;
  offset: number;
};

export type SelectValue = {
  name: string;
  id: string;
};

export type TableContextProviderType = [TableData, Dispatch<SetStateAction<TableData>> | null, TableFunctions];
