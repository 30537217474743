/**
 * @description
 * This is component for close button
 * all the functionalities cthings require for close button
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { ReactComponent as CloseImage } from '../../assets/Close.svg';

const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;

  border: none;

  outline: none;
  cursor: pointer;

  margin: ${(props: any) => props.margin};

  & > svg {
    width: ${(props: any) => (props.width ? props.width : 'auto')};
    height: ${(props: any) => (props.height ? props.height : 'auto')};

    stroke-width: ${(props: any) => (props.strokeWidth ? props.strokeWidth : 'auto')};
  }

  & > svg > path {
    stroke: ${(props: any) => (props.color ? props.color : props.theme.colors.gray1)};
    transition: stroke 0.3s ease;
  }

  &:hover > svg > path {
    stroke: ${(props: any) => (props.hoverColor ? props.hoverColor : props.theme.colors.gray2)};
  }
`;

export interface CloseButtonProps {
  onClick: any;

  width?: string;
  height?: string;
  strokeWidth?: string;
  color?: string;
  hoverColor?: string;
  margin?: string;
  className?: string;
}

export const CloseButton: FC<CloseButtonProps> = ({
  width,
  height,
  strokeWidth,
  color,
  hoverColor,
  onClick,
  margin,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      width={width}
      height={height}
      strokeWidth={strokeWidth}
      color={color}
      hoverColor={hoverColor}
      onClick={onClick}
      margin={margin}
      theme={theme}
      {...props}
    >
      <CloseImage />
    </Button>
  );
};
