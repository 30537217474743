/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for Orders page
 * Highly customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { API_URL } from '../../../consts';
import { useTableFunctions } from '../../../features/universalTable/context';
import { InlineActionType, RowItemType } from '../../../features/universalTable/types';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { injectPathParams, PATHS } from '../../../routes/paths';
import { View } from '../../../routes/routeInterfaces';
import { media } from '../../../styles/media';
import { useMediaType } from '../../../styles/style.context';
import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { UploadOrderModal } from './components/uploadOrderModal/UploadOrderModal';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

const StyledText = styled(Text)`
  & > span {
    color: gray1;
    &.normal {
      font-weight: normal;
    }
  }
  &.completion {
    color: ${(props: any) =>
      props.completion === 0
        ? props.theme.colors.green10
        : props.completion === 1
        ? props.theme.colors.primary
        : props.completion === 2
        ? props.theme.colors.red3
        : ''};
  }
`;
export interface OrdersProps {
  match: any;
  setPlaceholder?: any;
  className?: string;
}

export const Orders: FC<OrdersProps> = ({ setPlaceholder, match, ...props }: any) => {
  const history = useHistory();
  const keyword = 'OrdersTable';
  const offsetKey = 'offset';
  const theme = useTheme();
  const accessData = useSelector(selectAccess).management.orders;

  const { gray1 } = theme.colors;
  const { setSearchValue, getSearchValue, resetTable } = useTableFunctions();
  const { actual: searchValue } = getSearchValue();
  const mediaType = useMediaType();
  const offset = match.params[offsetKey];
  const [isOpenUloadModal, setIsOpenUloadModal] = useState(false);

  const languageStrings = useSelector(selectLanguageStrings);

  const handleOpenUploadModal = () => {
    setIsOpenUloadModal(true);
  };
  const handleCloseUploadModal = () => {
    setIsOpenUloadModal(false);
  };

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper {...props}>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfOrdersPage : 'Orders'}
        searchInputValue={searchValue}
        handleChangeSearchInputValue={setSearchValue}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        addButtonOnClick={handleOpenUploadModal}
        accessData={accessData}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_ORDERS}
        mainApiUrl={`${API_URL}/v1/orders/`}
        keyword={keyword}
        additionalKeywords={['ManageLayoutSearch']}
        updateItems={() => {}}
        customRowClick={(value: any) => {
          const to = injectPathParams(PATHS.MANAGE_ORDERS_DETAILS, {
            id: value.id,
            componentsOffset: 0,
            packagesOffset: 0,
            eventsOffset: 0,
          });
          to && history.push(to);
        }}
        inlineActionSet={[
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        pageSize={mediaType.phone ? 6 : 18}
        columnStructure={[
          {
            fieldName: 'order_id',
            placeholder: '',
            label: languageStrings ? languageStrings.labelOrderID : 'Order ID',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'500'}>
                {value}
              </Text>
            ),
          },
          {
            fieldName: 'created_at',
            placeholder: '',
            label: languageStrings ? languageStrings.labelСreatedAt : 'Сreated at',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {getRequiredDateFormat(value, 'DD.MM.YYYY')}
              </StyledText>
            ),
          },
          {
            fieldName: 'updated_at',
            placeholder: '',
            label: languageStrings ? languageStrings.labelLastUpdate : 'Last update',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {value ? getRequiredDateFormat(value, 'DD.MM.YYYY, HH:mm') : 'N/A'}
              </StyledText>
            ),
          },
          {
            fieldName: 'metadata',
            placeholder: '',
            label: languageStrings ? languageStrings.labelShippingDeadline : 'Shipping Deadline',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {getRequiredDateFormat(value.dt_deadline, 'DD.MM.YYYY')}
              </StyledText>
            ),
          },
          {
            fieldName: 'completion',
            placeholder: '',
            label: languageStrings ? languageStrings.labelCompletion : 'Completion',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText
                className="completion"
                completion={value.status}
                type={TextType.TEXT_14_GRAY}
                color={gray1}
                weight={'400'}
              >
                {`${value.value}%`}
              </StyledText>
            ),
          },
        ]}
      />
      <UploadOrderModal isOpenModal={isOpenUloadModal} handleCloseUploadModal={handleCloseUploadModal} />
    </Wrapper>
  );
};
