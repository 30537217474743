import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectDashboardData, setDashboardData } from '../app/state/dashboardSlice';
import { API_URL } from '../consts';
import { DashboardItemIconType, DashboardItemState, DashboardItemType, DashboardType } from '../enums/Dashboard';
import { useFakeApiGet } from '../features/apiBuilder/fakeApi';
import { triggerPlaceholderEmpty, useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { TriggerPlaceholder } from '../features/placeholderComponent/types';
import { HttpService } from '../utils/http';
import { defaultData, inventoriesData, ordersData, overallData } from './dashboardData';

export const useDashboardData = (setPlaceholder?: any, stopPlaceholder?: any, ts?: any) => {
  const data = useSelector(selectDashboardData);
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState(data);
  const trigger = [dispatch, ts] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/nodes/summary?ts=${ts}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload.data;
        dispatch(setDashboardData(responseData));
        setLocalData(responseData);
      } catch (e) {
        //
      }
    })();
  }, [...trigger, loaderOptions]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  return [localData];
};

// @OLEG example of use

const getDashboardData = (type: DashboardType) => {
  const data = {
    [DashboardType.OVERALL]: overallData,
    [DashboardType.ORDERS]: ordersData,
    [DashboardType.INVENTORIES]: inventoriesData,
  };
  return data[type];
};

export const useFakeDashboard = (
  type: DashboardType,
  setPlaceholder?: TriggerPlaceholder,
  stopPlaceholder?: TriggerPlaceholder,
) =>
  useFakeApiGet({
    defaultData,
    result: getDashboardData,
    options: type,
    setPlaceholder: setPlaceholder ? setPlaceholder : () => {},
    stopPlaceholder: stopPlaceholder ? stopPlaceholder : () => {},
  });

// End of example

export const useNewDashboards = (
  type: DashboardType,
  setPlaceholder?: TriggerPlaceholder,
  stopPlaceholder?: TriggerPlaceholder,
) => {
  const [data, setData] = useState(defaultData);
  const trigger = [type] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(
    setPlaceholder || triggerPlaceholderEmpty,
    stopPlaceholder || triggerPlaceholderEmpty,
    [...trigger],
  );
  useEffect(() => {
    const url = `${API_URL}/v1/dashboards/static/${type}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload.data;
        setData(responseData);
      } catch (e) {}
    })();
  }, [...trigger, loaderOptions]);

  return data;
};
