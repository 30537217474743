import React, { useCallback, useEffect, useRef, useState } from 'react';
import { media } from '../../../styles/media';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { searchText } from '../../../api/smartSearch';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TextType } from '@bit/first-scope.text';
import { View } from '../../../routes/routeInterfaces';
import { SearchBarType } from '../../../enums/SearchBarType';
import { SearchListType } from '../../../enums/SearchListType';
import { SearchBar } from '../../../components/searchBar/SearchBar';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { selectSmartSearchList, setSmartSearchList } from '../../../app/state/smartSearchSlice';
import { selectLanguageStrings } from '../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 186px 45px 45px 45px;
  box-sizing: border-box;
  ${media.tablet`
    padding: 92px 16px 38px 16px;
  `}
`;
const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 100%;
    max-width: 536px;
    & > div {
      width: max-content;
      ${media.tablet`
        width: 100%;
        & > div > div {
            width: 100%;
        }
    `}
    }
  }
`;
const Title = styled(Text)`
  ${media.phone`
    line-height: 40px;
    width: 80%;
    text-align: center;`}
`;

export type SmartSearchStateType = 'CURRENT_LIST' | 'OLD_LIST';

export const SmartSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const [slowSearchValue, setSlowSearchValue] = useState('');
  const [isLoadingInProcess, setIsLoadingInProcess] = useState(false);
  const [searchType, setSearchType] = useState<SmartSearchStateType>(SearchListType.OLD_LIST);
  const dispatch = useDispatch();
  const searchList = useSelector(selectSmartSearchList);
  const customHistory = useCustomHistory();
  const language = useSelector(selectLanguageStrings);
  const timerId = useRef(null as any);
  const handleSetSmartSearchList = (list: any[]) => {
    dispatch(setSmartSearchList(list));
    setSearchType(SearchListType.CURRENT_LIST);
  };
  const handleMoveToItem = (
    type: string,
    id: string,
    offset: number,
    role?: string,
    buildingId?: string,
    floor?: number,
    leadToMap?: boolean,
    name?: string,
    ordersId?: string,
  ) => {
    const view =
      type === 'users'
        ? View.MANAGE_USERS
        : type === 'stages'
        ? View.MANAGE_STAGES
        : type === 'processes'
        ? View.MANAGE_PROCESSES
        : type === 'zones'
        ? View.MANAGE_ZONES
        : type === 'components'
        ? View.MANAGE_COMPONENTS
        : type === 'inventories'
        ? View.MANAGE_INVENTORIES
        : View.PLACEHOLDER;

    if (
      type === 'users' ||
      type === 'stages' ||
      type === 'processes' ||
      type === 'components' ||
      type === 'inventories'
    ) {
      customHistory.push(view, { pathParams: { offset: offset }, state: { id: id } });
    } else if (type === 'devices' && leadToMap) {
      customHistory.push(View.USER_INSIGHTS_DETAILS, { pathParams: { id: buildingId, name: name } });
    } else if (type === 'devices') {
      const to = role === 'tracker' ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER : View.USER_MANAGE_DEVICES_DETAILS_NODE;
      to && customHistory.push(to, { pathParams: { id: id, buildingId: buildingId, offset: 0 } });
    } else if (type === 'orders') {
      customHistory.push(View.MANAGE_ORDERS_DETAILS, {
        pathParams: { id: id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
      });
    } else if (type === 'packages') {
      customHistory.push(View.MANAGE_ORDERS_DETAILS, {
        pathParams: { id: ordersId, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
        state: { id, offset },
      });
    } else if (type === 'zones') {
      customHistory.push(View.MANAGE_ZONES_DETAILS, {
        pathParams: { building_id: buildingId, floor: floor, zone_id: id, offset: 0 },
      });
    }
  };
  const handleChangeSearchBarValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      timerId.current && clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setSlowSearchValue(value);
      }, 1500);
    },
    [timerId],
  );

  useEffect(() => {
    searchText(slowSearchValue, handleSetSmartSearchList, setIsLoadingInProcess);
  }, [slowSearchValue]);

  return (
    <Wrapper>
      <SearchWrapper>
        <Title type={TextType.TITLE_H1} margin={'0 0 32px 0'}>
          {language ? language.titleOfSearchPage : 'What are you looking for?'}
        </Title>
        <SearchBar
          placeholderProps={{
            keyword: 'SmartSearch',
            placeholderOptions: { withoutLoader: true },
          }}
          type={SearchBarType.SMART_SEARCH}
          countResultOfSearching={2}
          onChange={(e: any) => {
            handleChangeSearchBarValue(e.target.value);
          }}
          onResult={() => {}}
          value={searchValue}
          results={searchList}
          listType={searchType}
          setListType={setSearchType}
          handleItemOnClick={handleMoveToItem}
          placeholder={language ? language.typeSomethingPlaceholder : 'Type something...'}
          isLoadingInProcess={isLoadingInProcess}
          isGlobalSearch
        />
      </SearchWrapper>
    </Wrapper>
  );
};
