// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../styles/media';

export const ModalSearch = styled.div`
  position: absolute;
  z-index: 9999999999;
  bottom: -8px;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: white;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow4};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  min-height: 65px;
  max-height: 440px;
  padding: 20px 8px 20px 20px;
  box-sizing: border-box;
  ${media.tablet`
    max-height: 380px;
  `}
  ${media.phone`
    max-height: 342px;
  `}
`;
