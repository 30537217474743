import React from 'react';
import { use100vh } from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { useUserAccess } from '../../api/user';
import { selectAccess, selectLanguageStrings } from '../../app/state/userSlice';
import { LsValueType } from '../../enums/LsValueType';
import { UserType } from '../../enums/UserType';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import { Navigation } from '../../features/navigation/Navigation';
import withUserType from '../../routes/withUserType';
import ls from '../../utils/ls';
import { Body, Wrapper } from '../elements/wrappers';

const User = (props: any) => {
  const language = useSelector(selectLanguageStrings);
  useUserAccess();
  const globalAccess = useSelector(selectAccess);
  const { role_name }: any = globalAccess;

  const handleLogout = (e: any) => {
    e.preventDefault();
    ls.remove(LsValueType.token);
    oauthRedirect();
  };

  const minHeight = use100vh();

  return (
    <Wrapper minHeight={minHeight}>
      <Navigation
        onLogout={handleLogout}
        userStatus={role_name ? role_name : language ? language.lableUser : 'User'}
        withLanguages
      />
      <Body>{renderRoutes(props.route.routes)}</Body>
    </Wrapper>
  );
};

export default withUserType(UserType.USER)(User);
