/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for orders details page
 * Highly customisable.
 *
 */

import { ButtonIconType, CommonButton } from '@bit/first-scope.common-button';
import { Text, TextType } from '@bit/first-scope.text';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useManageOrderDetails } from '../../../api/manageOrderDetails';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { CopyButton } from '../../../components/copyButton/CopyButton';
import { OrderTableInfo } from '../../../components/orderTableInfo/OrderTableInfo';
import { useNotifierFunctions } from '../../../features/notifier2';
import { useLoaderFunctions } from '../../../features/placeholderComponent/loader.context';
import { media } from '../../../styles/media';
import { ComponentsTable } from './components/componentsTable/ComponentsTable';
import { EventsTable } from './components/eventsTable/EventsTable';
import { PackagesInfo } from './components/packagesInfoTable/PackagesInfo';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 34px 16px 10px 16px;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 45px;
  ${media.tablet`
    margin-bottom: 30px;
  `}
`;

const Title = styled(Text)`
  user-select: none;
  ${media.phone`
    font-size: 20px;
  `}
`;

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-left: 14px;
  ${media.tablet`
    width: 100%;
    justify-content: center;
  `}
`;

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    & > svg > path {
      stroke: ${(props: any) => props.theme.colors.gray3};
    }
    & > span {
      color: ${(props: any) => props.theme.colors.gray1};
    }
  }
`;

const OrderInfoWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 20px;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    grid: none;
    grid-gap: 0;
  `}
  ${media.tablet`
    display: flex;
    flex-direction: column;
  `}
`;

export interface OrderDetailsProps {
  match: any;
}

export const OrderDetails: FC<OrderDetailsProps> = ({ match }) => {
  const pathParams = match.params;
  const { id } = pathParams;
  const { state }: any = useLocation();
  const accessData = useSelector(selectAccess).management.orders;
  const languageStrings = useSelector(selectLanguageStrings);
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = ['OrderInfoTable'];

  const data: any = useManageOrderDetails(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );
  const [trigger, setTrigger] = useState(false);
  const { addNotification } = useNotifierFunctions();

  const language = useSelector(selectLanguageStrings);

  const toggleTrigger = () => {
    setTrigger((value) => !value);
  };
  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({
      message: languageStrings ? languageStrings.notificationCopieMessage : 'Copied to clipboard',
      type: 'info',
    });
  };

  const theme = useTheme();
  const history = useHistory();
  const { primary, secondary2 } = theme.colors;

  const { order_id } = data;

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <CommonButton
            width={'32px'}
            height={'32px'}
            backgroundColor={primary}
            backgroundColorHover={secondary2}
            borderColorHover={secondary2}
            iconType={ButtonIconType.ARROW_LEFT}
            iconWithoutText
            onClick={() => {
              history.goBack();
            }}
          />
          <NameWrapper
            onClick={() => {
              handleCopyText(order_id);
            }}
            theme={theme}
          >
            <CopyWrapper>
              <Title type={TextType.TITLE_PRIMARY}>
                {language ? language.labelOrder : 'Order'} {order_id}
              </Title>
              <CopyButton />
            </CopyWrapper>
          </NameWrapper>
        </TitleWrapper>
        <OrderInfoWrapper>
          <OrderTableInfo
            data={data}
            title={language ? language.OrderInfoTitle : 'Order info'}
            headerLabels={[
              { name: language ? language.propertyOfTable : 'Property', isSorted: false },
              { name: language ? language.valueOfTable : 'Value', isSorted: false },
            ]}
            headerGridTemplate={{ desktop: '1.25fr 1.5fr', tablet: '1.25fr 1.5fr', phone: '1.25fr 1.5fr' }}
          />
          <ComponentsTable id={id} pathParams={pathParams} />
        </OrderInfoWrapper>
        <PackagesInfo
          id={id}
          pathParams={pathParams}
          //this offset need for show certain page during from smart search redirect
          offsetFromSmartSearch={state?.state?.offset}
          toggleTrigger={toggleTrigger}
          idItemForViewModal={state?.state?.id}
          accessData={accessData}
        />
        <EventsTable id={id} pathParams={pathParams} trigger={trigger} accessData={accessData} />
      </Wrapper>
    </>
  );
};
