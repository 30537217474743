/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings sorting arrow require
 * Highly customisable.
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ReactComponent as UpArrow } from '../../assets/Sorting_arrow_up.svg';
import { ReactComponent as DownArrow } from '../../assets/Sorting_arrow_down.svg';

const Wrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
`;

const UpArrowIcon = styled(UpArrow)`
  & > path {
    stroke: ${(props: any) => props.color};
    fill: ${(props: any) => props.color};
    transition: all 0.3s ease;
  }
  margin-bottom: 2px;
`;
const DownArrowIcon = styled(DownArrow)`
  & > path {
    stroke: ${(props: any) => props.color};
    fill: ${(props: any) => props.color};
    transition: all 0.3s ease;
  }
  cursor: pointer;
`;

export const SortingArrows = ({
  sortData,
  fieldName,
  color,
  ...props
}: {
  sortData: { sort_field: string; sort_type: string | number };
  fieldName: string;
  color?: string;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;
  const { sort_field, sort_type } = sortData;

  if (fieldName === sort_field && sort_type === 1) {
    return (
      <Wrapper {...props}>
        <DownArrowIcon color={color ? color : gray2} />
      </Wrapper>
    );
  } else if (fieldName === sort_field && sort_type === -1) {
    return (
      <Wrapper {...props}>
        <UpArrowIcon color={color ? color : gray2} />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper {...props}>
        <UpArrowIcon color={color ? color : gray2} />
        <DownArrowIcon color={color ? color : gray2} />
      </Wrapper>
    );
  }
};
