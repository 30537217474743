/**
 * @description
 * This is an advanced component that covers
 * all the functionality required by the buttons.
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

const Button = styled.button`
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};

  background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.primary)};

  color: ${(props: any) => (props.color ? props.color : props.theme.colors.white)};

  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;

  letter-spacing: 0.2px;

  border: ${(props: any) => `1px solid ${!props.borderColor ? props.theme.colors.primary : props.borderColor}`};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};

  outline: none;
  cursor: pointer;

  transition: all 0.3s ease;
  opacity: ${(props: any) => (props.disabled ? '0.3' : '1')};

  &:hover {
    background-color: ${(props: any) =>
      props.disabled
        ? 'none'
        : props.backgroundColorHover
        ? props.backgroundColorHover
        : props.theme.colors.secondary2};
    color: ${(props: any) =>
      props.disabled ? 'none' : props.hoverColor ? props.hoverColor : props.theme.colors.white};
    border-color: ${(props: any) =>
      props.disabled ? 'none' : props.borderColorHover ? props.borderColorHover : props.theme.colors.secondary2};
  }
`;

export interface CommonButtonProps {
  children: any;
  onClick: any;

  height?: string;
  width?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  color?: string;
  hoverColor?: string;
  borderColor?: string;
  borderColorHover?: string;
  noFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export const CommonButton: FC<CommonButtonProps> = ({
  children,
  onClick,
  noFocus,
  height,
  width,
  backgroundColor,
  backgroundColorHover,
  color,
  hoverColor,
  borderColor,
  disabled,
  ...props
}) => {
  const theme = useTheme();
  const handleMouseDown = (e: any) => {
    noFocus && e.preventDefault();
  };
  return (
    <Button
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      backgroundColorHover={backgroundColorHover}
      color={color}
      hoverColor={hoverColor}
      borderColor={borderColor}
      onClick={disabled ? () => {} : onClick}
      noFocus={noFocus}
      onMouseDown={handleMouseDown}
      disabled={disabled}
      theme={theme}
      {...props}
    >
      {children}
    </Button>
  );
};

CommonButton.defaultProps = {
  width: '230px',
  height: '32px',
};
