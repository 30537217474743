import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { theme } from '../../styles/theme';
import { hexToRGBA } from '../../utils/hexToRgb';

const { blue5, blue9, purple4, pink, red2, orange2, yellow3, green13, green10, blue10 } = theme.colors;

const generateColor = (color: string) => ({
  backgroundColor: hexToRGBA(color, 0.2),
  dotColor: color,
  borderColor: hexToRGBA(color, 0.5),
  shadowColor: hexToRGBA(color, 0.3),
});

const colorArray = [blue5, blue9, purple4, pink, red2, orange2, yellow3, green13, green10, blue10];

const zoneColors = colorArray.map((color: string) => generateColor(color));
const dashboardColors = [green10, blue9, red2, orange2, green13, blue5, pink, yellow3, blue10, purple4];

const getZoneColor = (index: number) => {
  const actualIndex = index % zoneColors.length;
  return zoneColors[actualIndex];
};

const getDasboardColor = (index: number) => {
  const actualIndex = index % zoneColors.length;
  return dashboardColors[actualIndex];
};

export type ZoneColorType = {
  backgroundColor: string;
  dotColor: string;
  borderColor: string;
};

export type ColorsSliceType = {
  zoneColors: ZoneColorType[];
  dashboardColors: string[];
};

const initialState: ColorsSliceType = {
  zoneColors: [],
  dashboardColors: [],
};

export const colorsSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    createZoneColors: (state, action: PayloadAction<number>) => {
      const numberOfZones = action.payload;
      let arr: ZoneColorType[] = [];
      for (let i = 0; i < numberOfZones; i++) {
        arr = [...arr, getZoneColor(i)];
      }
      state.zoneColors = arr;
    },
    createDashboardColors: (state, action: PayloadAction<number>) => {
      const numberOfZones = action.payload;
      let arr: string[] = [];
      for (let i = 0; i < numberOfZones; i++) {
        arr = [...arr, getDasboardColor(i)];
      }
      state.dashboardColors = arr;
    },
  },
});

export const { createZoneColors, createDashboardColors } = colorsSlice.actions;

export const selectZoneColors = (state: RootState) => {
  return state.colors.zoneColors;
};

export const selectDasboardColors = (state: RootState) => {
  return state.colors.dashboardColors;
};

export default colorsSlice.reducer;
