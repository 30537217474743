enum TypeDevice {
  ALL = 'ALL',
  NODE = 'NODE',
  TRACKER = 'TRACKER',
  OFFLINE = 'OFFLINE',
}

enum TypeAttach {
  ATTACHED = 'ATTACHED',
  DETACHED = 'DETACHED',
}

enum TypeEntity {
  PACKAGE = 'PACKAGE',
  INVENTORY = 'INVENTORY',
}

const initialFilterState = {
  nodes: [],
  temporaryDeviceState: {},
  temporaryAttachState: {},
  temporaryInventoriesState: {},
  zonesState: {
    zones: [],
    isZones: false,
  },
  deviceState: {
    checkedDevicesCount: 3,
    isOpenDeviceList: false,
    devicesFilter: {
      [TypeDevice.NODE]: {
        isSelected: true,
        name: 'Node',
        type: TypeDevice.NODE,
      },

      [TypeDevice.TRACKER]: {
        isSelected: true,
        name: 'Tracker',
        type: TypeDevice.TRACKER,
      },

      [TypeDevice.OFFLINE]: {
        isSelected: true,
        name: 'Offline',
        type: TypeDevice.OFFLINE,
      },
    },
  },
  attachState: {
    checkedAttachCount: 2,
    isOpenAttachList: false,
    attachFilter: {
      [TypeAttach.ATTACHED]: {
        isSelected: true,
        name: 'Attached',
        type: TypeAttach.ATTACHED,
      },

      [TypeAttach.DETACHED]: {
        isSelected: true,
        name: 'Detached',
        type: TypeAttach.DETACHED,
      },
    },
  },

  entityState: {
    checkedEntityCount: 2,
    isOpenEntityList: false,
    entityFilter: {
      [TypeEntity.PACKAGE]: {
        isSelected: true,
        name: 'Packages',
        type: TypeEntity.PACKAGE,
      },

      [TypeEntity.INVENTORY]: {
        isSelected: true,
        name: 'Inventories',
        type: TypeEntity.INVENTORY,
      },
    },
  },

  inventoriesState: {
    checkedInventoriesCount: 0,
    isOpenInventoriesList: false,
    inventoriesFilter: [],
  },
};

export { TypeDevice, TypeAttach, initialFilterState };
