/**
 * @description
 * The context contains all the background logic that is
 * required for maps to run correctly.
 * This includes custom grid layout generation, api interactions
 * and access to table state.
 *
 */

import React, { createContext, useState, useContext, ReactNode, useCallback, useEffect, useRef, useMemo } from 'react';
import { NEW_ID } from '../../consts';
import { ColumnStructure, MetadataItem, ModalApi, RowItemType, SortingData } from './types';
import { TableData, TableContextProviderType, TableFunctions, Pagination } from './types';
// @ts-ignore
import _ from 'underscore';
import { useNewLoaderOptions } from '../placeholderComponent/loaderFunctions';
import { HttpService } from '../../utils/http';
import { usePrevious } from '../../utils/usePrevious';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';
import { FieldsType, isValidField } from '../../utils/validation';
import { sizes } from '../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../app/state/userSlice';

/* @TODO Alex reuse this natively later
_.isFunction = function (obj: any) {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}; */

const DEFAULT_OPEN_INDEX = -1;
const DEFAULT_HIGHLIGHTED_INDEX = -1;
const DEFAULT_DELETE_INDEX = -1;
const DEFAULT_OPEN_ID = null;
export const EXTRA_KEY = 'extraKey_';

export const DEFAULT_TABLE_DATA = {
  list: [],
  pagination: {
    total_items: 0,
    total_pages: 1,
    page_number: 1,
    limit: 0,
    offset: 0,
  },
};

const appendFieldName = (fieldName: string, isExternal: boolean) =>
  isExternal ? `${EXTRA_KEY}${fieldName}` : fieldName;

const deconstructFieldName = (fieldName: string, isExternal: boolean) =>
  isExternal ? fieldName.slice(EXTRA_KEY.length) : fieldName;
const isColumnOfSingularValue = (type: RowItemType | undefined) =>
  !type ||
  type === RowItemType.INPUT_NUMBER ||
  type === RowItemType.INPUT ||
  type === RowItemType.JOINED_SELECT ||
  type === RowItemType.CUSTOM;

const useData = (
  url: string | undefined,
  page: number,
  pageSize: number,
  setItems: any,
  keywordList: string[],
  searchField: string,
  sortingData: SortingData,
  didRender: boolean,
  apiTriggers: any[],
  setReadyState: any,
  indexOfItemInPayload?: string,
  withoutQuestionMarkBeforeLimit?: boolean,
) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  const { sort_field, sort_type } = sortingData;
  const offset = pageSize * (page - 1);
  const language = useSelector(selectLanguage);
  const trigger = [url, pageSize, offset, searchField, sort_field, sort_type, language, ...apiTriggers] as const;
  const loaderOptions = useNewLoaderOptions(keywordList, [...trigger], !didRender);

  useEffect(() => {
    if (url) {
      const finalUrl = `${url}${withoutQuestionMarkBeforeLimit ? '' : '?'}limit=${pageSize}&offset=${offset}${
        sort_field && sort_type ? `&sort_type=${sort_type}&sort_field=${sort_field}` : ''
      }${searchField ? `&search_field=${searchField}` : ''}`;
      (async () => {
        try {
          let responseData = (await HttpService.get(finalUrl, loaderOptions)).data;
          const { pagination, payload, metadata } = responseData;
          const arr = Object.values(payload);
          const preparedPayload = indexOfItemInPayload ? arr[+indexOfItemInPayload] : payload;

          setItems &&
            setItems(
              preparedPayload.map((item: any) => ({ ...item, isChecked: false, isEdit: false })),
              pagination,
              metadata,
            );
          setReadyState(true);
        } catch (e) {
          //
        }
      })();
    }
  }, [...trigger, loaderOptions]);
};

//@TODO Alex add selector for local delete name

const DEFAULT_STATE = {
  ...DEFAULT_TABLE_DATA,
  isSelectAll: false,
  isEditInProcess: false,
  isViewInProcess: false,
  isAddInProcess: false,
  isDeleteInProcess: false,
  openIndex: DEFAULT_OPEN_INDEX,
  highlightedIndex: DEFAULT_HIGHLIGHTED_INDEX,
  localDeleteIndex: DEFAULT_DELETE_INDEX,
  page: 0,
  mobileSelectMode: false,
  openId: DEFAULT_OPEN_ID,
  currentModalApiId: DEFAULT_OPEN_ID,
  sorting: { sort_field: '', sort_type: '' },
  searchValue: { actual: '', slow: '' },
  globalProps: {
    columnStructure: [],
    allowDelete: false,
    allowSelect: false,
    mobileLimit: 4,
    keywordList: [],
    pageSize: 12,
    pathParams: {},
    offsetKey: 'offset',
    apiTriggers: [],
    keyword: '',
    hasActions: true,
  },
} as TableData;

const TableContext = createContext([DEFAULT_STATE, null as any, null as any] as TableContextProviderType);

const useTableContextCreator = (): TableContextProviderType => {
  const [tableState, setTableState] = useState(DEFAULT_STATE);
  const [mainApiReady, setMainApiReady] = useState(false);
  const [modalApiReady, setModalApiReady] = useState(false);

  const {
    mobileSelectMode,
    globalProps,
    list,
    openId,
    openIndex,
    isAddInProcess,
    metadata,
    sorting,
    searchValue,
    page,
  } = tableState;
  const {
    allowDelete,
    allowSelect,
    mobileLimit,
    columnStructure,
    modalApi,
    mainApiUrl,
    indexOfItemInPayload,
    withoutQuestionMarkBeforeLimit,
    keywordList,
    pageSize,
    tablePath,
    pathParams,
    offsetKey,
    apiTriggers,
    withHeaderInMobile,
    setIsPendingRequestFromModalApi,
    hasActions,
  } = globalProps;

  const useModalData = (
    id: string | null,
    modalApi: ModalApi | undefined,
    enrichModalData: any,
    setCurrentId: (id: string) => void,
    setReadyState: any,
  ) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    //const trigger = [id] as const;
    const prevId = usePrevious(id);
    const loaderOptions = /*useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);*/ {};

    useEffect(() => {
      if (id && modalApi && id !== prevId) {
        setIsPendingRequestFromModalApi && setIsPendingRequestFromModalApi(true);
        const url = modalApi.url(id);

        (async () => {
          try {
            let responseData = (await HttpService.get(url, loaderOptions)).data;
            const { payload } = responseData;
            enrichModalData(payload);
            setCurrentId(id);
            setReadyState(true);
            setIsPendingRequestFromModalApi && setIsPendingRequestFromModalApi(false);
          } catch (e) {
            //
          }
        })();
      }
    }, [id /*...trigger , loaderOptions*/]);
  };
  useEffect(() => {
    if (!modalApi) {
      setModalApiReady(true);
    }
  }, [mainApiUrl]);

  const timerId = useRef(null as any);
  const history = useCustomHistory();

  const setField = (index: number, newValue: any, fieldName?: string) => {
    const isFunction = _.isFunction(newValue);
    setTableState((value: TableData) => {
      const rowCopy = JSON.parse(JSON.stringify(value.list[index]));
      let resultRow = rowCopy;
      if (isFunction) {
        resultRow = newValue(rowCopy);
      } else if (fieldName) {
        resultRow[fieldName] = newValue;
      }

      return { ...value, list: [...value.list.slice(0, index), resultRow, ...value.list.slice(index + 1)] };
    });
  };

  const enrichModalData = useCallback(
    (payload: any) => {
      const addition = Object.keys(payload)
        .map((key: any) => ({
          [`${EXTRA_KEY}${key}`]: payload[key],
        }))
        .reduce(
          (acc, newVal) => ({
            ...acc,
            ...newVal,
          }),
          {},
        );

      setField(openIndex, (value: any) => ({
        ...value,
        ...addition,
      }));
    },
    [setField, openIndex],
  );

  const setCurrentModalApiId = (id: string) => {
    setTableState((value: TableData) => ({
      ...value,
      currentModalApiId: id,
    }));
  };

  const setTableData = (list: any[], pagination: Pagination, metadata?: MetadataItem[]) => {
    setTableState((value: TableData) => {
      const { list: currentList, isAddInProcess } = value;
      const listRemainder = isAddInProcess ? currentList.filter((el) => el.id === NEW_ID) : [];
      return { ...value, list: [...list, ...listRemainder], pagination, metadata };
    });
  };

  useData(
    mainApiUrl,
    page + 1,
    pageSize,
    setTableData,
    keywordList,
    searchValue.slow,
    sorting,
    !!mainApiUrl && keywordList.length > 0 && !!pageSize && columnStructure.length > 0,
    apiTriggers,
    setMainApiReady,
    indexOfItemInPayload,
    withoutQuestionMarkBeforeLimit,
  );

  useModalData(openId, modalApi, enrichModalData, setCurrentModalApiId, setModalApiReady);

  const deleteItem = (id: string, fieldNameOfId?: string) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        list: value.list.filter((elem: any) => (fieldNameOfId ? elem[fieldNameOfId] !== id : elem.id !== id)),
        pagination: { ...value.pagination, total_items: value.pagination.total_items - 1 },
        mobileSelectMode: false,
      };
    });
  };
  const deleteCheckedItems = () => {
    setTableState((value: TableData) => {
      const prevListLength = value.list.length;
      const newList = value.list.filter((item: any) => !item.isChecked);
      const listLength = newList.length;
      const difference = prevListLength - listLength;
      const { total_items } = value.pagination;
      const newPagination = { ...value.pagination, total_items: total_items - difference };
      return { ...value, list: newList, pagination: newPagination, mobileSelectMode: false };
    });
  };
  const toggleIsChecked = (index: number) => {
    setTableState((value: TableData) => {
      const rowCopy = JSON.parse(JSON.stringify(value.list[index]));
      rowCopy.isChecked = !rowCopy.isChecked;
      return { ...value, list: [...value.list.slice(0, index), rowCopy, ...value.list.slice(index + 1)] };
    });
  };
  const toggleIsCheckedAll = () => {
    setTableState((value: TableData) => {
      const newList = value.list.map((item: any, index: number) => {
        const { isChecked } = item;
        if (!isChecked && !value.isSelectAll) {
          return { ...item, isChecked: true };
        } else if (isChecked && value.isSelectAll) {
          return { ...item, isChecked: false };
        } else {
          return item;
        }
      });
      return { ...value, isSelectAll: !value.isSelectAll, list: newList };
    });
  };

  const uncheckAll = () => {
    setTableState((value: TableData) => {
      const newList = value.list.map((item: any, index: number) => ({ ...item, isChecked: false }));
      return { ...value, isSelectAll: false, list: newList };
    });
  };

  const toggleIsEditInProcess = () => {
    setTableState((value: TableData) => {
      return { ...value, isEditInProcess: !value.isEditInProcess };
    });
  };

  const putItem = (item: any) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        list: [
          {
            ...item,
            isChecked: false,
            isEdit: true,
          },
          ...value.list,
        ],
      };
    });
  };

  const addEmptyItem = () => {
    setTableState((value: TableData) => {
      const defaultValue = value.globalProps.columnStructure.reduce(
        (prevItem: any, item: any) =>
          item.type === RowItemType.SELECT
            ? {
                ...prevItem,
                [item.fieldName.id]: item.defaultValue,
              }
            : {
                ...prevItem,
                [item.fieldName]: item.defaultValue,
              },
        {},
      );

      return {
        ...value,
        list: [
          {
            id: NEW_ID,
            ...defaultValue,
            isChecked: false,
            isEdit: true,
          },
          ...value.list,
        ],
      };
    });
  };

  const createItem = (id: string, data: any) => {
    setTableState((value: TableData) => {
      const { list } = value;
      const { length } = list;
      return {
        ...value,
        list: [
          {
            ...list[length],
            id,
            ...data,
            isChecked: false,
            isEdit: false,
          },
          ...list.slice(0, length - 1),
        ],
      };
    });
  };

  const increaseItemCount = () => {
    setTableState((value: TableData) => {
      return { ...value, pagination: { ...value.pagination, total_items: value.pagination.total_items + 1 } };
    });
  };

  const updateLocalItem = (index: number, newValue: any) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        list: [...value.list.slice(0, index), { ...value.list[index], ...newValue }, ...value.list.slice(index + 1)],
      };
    });
  };
  const setGlobalProps = (props: any) => {
    setTableState((value: TableData) => {
      return { ...value, globalProps: { ...value.globalProps, ...props } };
    });
  };

  const toggleView = (index: number | null, id: string | null) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        openIndex: !value.isViewInProcess && (index || index === 0) ? index : DEFAULT_OPEN_INDEX,
        openId: !value.isViewInProcess && id ? id : DEFAULT_OPEN_ID,
        isViewInProcess: !value.isViewInProcess,
        currentModalApiId: !value.isViewInProcess ? value.currentModalApiId : DEFAULT_OPEN_ID,
      };
    });
  };

  const toggleEdit = () => {
    setTableState((value: TableData) => ({
      ...value,
      isEditInProcess: !value.isEditInProcess,
    }));
  };

  const toggleAdd = () => {
    setTableState((value: TableData) => {
      const { isAddInProcess } = value;
      const addOptions = isAddInProcess
        ? {
            openIndex: DEFAULT_OPEN_INDEX,
            openId: DEFAULT_OPEN_ID,
            currentModalApiId: DEFAULT_OPEN_ID,
          }
        : {};
      return {
        ...value,
        isAddInProcess: !isAddInProcess,
        ...addOptions,
      };
    });
  };

  const toggleDelete = () => {
    setTableState((value: TableData) => ({
      ...value,
      isDeleteInProcess: !value.isDeleteInProcess,
    }));
  };

  const setHighlightedIndex = (index: number = -1) => {
    setTableState((value: TableData) => ({
      ...value,
      highlightedIndex: index,
    }));
  };

  const setLocalDeleteIndex = (index: number = -1) => {
    setTableState((value: TableData) => ({
      ...value,
      localDeleteIndex: index,
    }));
  };

  const setSortingData = (sorting: SortingData) => {
    setTableState((value: TableData) => ({
      ...value,
      sorting,
    }));
  };

  const toggleMobileSelectMode = () => {
    setTableState((value: TableData) => ({
      ...value,
      mobileSelectMode: !value.mobileSelectMode,
    }));
  };

  const startAddProcess = () => {
    setTableState((value: TableData) => {
      const defaultValue = value.globalProps.columnStructure.reduce(
        (prevItem: any, item: any) =>
          item.type === RowItemType.SELECT
            ? {
                ...prevItem,
                [item.fieldName.id]: item.defaultValue,
              }
            : {
                ...prevItem,
                [item.fieldName]: item.defaultValue,
              },
        {},
      );

      const { list } = value;
      const openIndex = list.length;
      const openId = NEW_ID;
      const isAddInProcess = true;
      const newList = [
        ...list,
        {
          id: NEW_ID,
          ...defaultValue,
          isChecked: false,
          isEdit: true,
        },
      ];
      // tablePath && history && history.replace(tablePath, { pathParams }); это сбивает offset, когда начинаешь добавление
      return {
        ...value,
        openIndex,
        openId,
        isAddInProcess,
        list: newList,
        pagination: { ...value.pagination, total_items: value.pagination.total_items + 1 },
      };
    });
  };

  const setPage = useCallback(
    (page: number, options: { isOffset?: boolean; replacePath?: boolean } = {}) => {
      const { isOffset, replacePath } = options;
      setTableState((value: TableData) => {
        const resultPage = !!isOffset ? page + value.page : page;
        if (replacePath && offsetKey && tablePath) {
          const offset = pageSize * resultPage;
          const params = { ...pathParams, [offsetKey]: offset };
          history && history.replace(tablePath, { pathParams: params });
        }
        return { ...value, page: resultPage };
      });
    },
    [history, tablePath, pathParams, offsetKey, pageSize],
  );

  const setSearchValue = useCallback(
    (searchValue: string) => {
      setTableState((value: TableData) => ({
        ...value,
        searchValue: { ...value.searchValue, actual: searchValue },
      }));
      timerId.current && clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        if (searchValue.length > 2 || searchValue === '') {
          setTableState((value: TableData) => ({
            ...value,
            searchValue: { ...value.searchValue, slow: searchValue },
          }));
          setPage(0);
        }
      }, 700);
    },
    [timerId],
  );

  const getReadyState = useCallback(() => {
    return mainApiReady && modalApiReady;
  }, [mainApiReady, modalApiReady]);

  const getSearchValue = useCallback(() => {
    return searchValue;
  }, [searchValue]);

  const getSelectedItems = () => {
    return tableState.list.filter((elem: any) => elem.isChecked);
  };

  const getLocalDeleteName = () => {
    const localItem = tableState.localDeleteIndex !== -1 ? tableState.list[tableState.localDeleteIndex] : null;
    return localItem ? (localItem.name ? localItem.name : localItem.id) : null;
  };

  const getLocalDeleteId = () => {
    const localItem = tableState.localDeleteIndex !== -1 ? tableState.list[tableState.localDeleteIndex] : null;
    return localItem ? localItem.id : null;
  };

  const getViewedData = useCallback(() => {
    const viewedData = list[openIndex];
    let fieldNameOptions = {};
    if (viewedData && viewedData.viewFieldName) {
      fieldNameOptions = { fieldName: viewedData.viewFieldName };
    }
    return viewedData ? { ...viewedData, ...fieldNameOptions } : undefined;
  }, [list, openIndex]);

  const getPresentedItems = useCallback(
    (index: number, windowWidth: number) => {
      const data = list[index];

      const presentedItems =
        columnStructure.length > 0
          ? columnStructure
              .filter((config: ColumnStructure) => {
                if (windowWidth < 769 && withHeaderInMobile) {
                  return config.shownInHeader;
                } else {
                  return !config.excludedFromView;
                }
              })
              .map((item: any) => ({
                ...item,
                value:
                  item.type === RowItemType.SELECT
                    ? { name: data[item.fieldName.name], id: data[item.fieldName.id] }
                    : data[item.fieldName],
              }))
          : [];

      return presentedItems;
    },
    [columnStructure, list],
  );

  const getModalItems = useCallback(() => {
    const data = getViewedData();

    const modalItems =
      columnStructure.length > 0 && data
        ? columnStructure.map((item: any) => {
            const { fieldName, type, isFromRequest, conditionForValidationInCustom } = item;
            const fieldType =
              type === RowItemType.INPUT_EMAIL
                ? FieldsType.EMAIL
                : type === RowItemType.INPUT_PHONE_NUMBER
                ? FieldsType.PHONE_NUMBER
                : type === RowItemType.INPUT_NUMBER
                ? FieldsType.NUMBER
                : type === RowItemType.SELECT || type === RowItemType.JOINED_SELECT
                ? FieldsType.SELECT
                : type === RowItemType.INPUT_CUSTOM || type === RowItemType.CUSTOM
                ? FieldsType.CUSTOM
                : FieldsType.TEXT;

            const value =
              type === RowItemType.SELECT
                ? {
                    name: data[appendFieldName(fieldName.name, isFromRequest)],
                    id: data[appendFieldName(fieldName.id, isFromRequest)],
                  }
                : data[appendFieldName(fieldName, isFromRequest)];
            const valueForValidation =
              (type === RowItemType.SELECT || type === RowItemType.JOINED_SELECT) && value && typeof value !== 'string'
                ? value.name
                : value;

            return {
              ...item,
              fieldName:
                type === RowItemType.SELECT
                  ? {
                      name: appendFieldName(fieldName.name, isFromRequest),
                      id: appendFieldName(fieldName.id, isFromRequest),
                    }
                  : appendFieldName(fieldName, isFromRequest),
              value: value,
              isError: !isValidField(valueForValidation, fieldType, conditionForValidationInCustom),
            };
          })
        : [];
    return modalItems;
  }, [columnStructure, list, getViewedData]);

  const setValidatingIsStarted = (value?: boolean) => {
    const structure = columnStructure.map((item: any) => {
      return { ...item, validatingIsStarted: value };
    });
    setGlobalProps({ columnStructure: structure });
  };

  const getRequiredData = useCallback(() => {
    const data = getModalItems();
    const requiredData = data
      .filter((arrEl) => !arrEl.disabled && !(isAddInProcess && arrEl.excludedFromAdd))
      .reduce((prevItem: any, item: any) => {
        const { fieldName, type, value, isFromRequest } = item;
        return type === RowItemType.SELECT
          ? {
              ...prevItem,
              [deconstructFieldName(fieldName.id, isFromRequest)]: value.id,
            }
          : {
              ...prevItem,
              [deconstructFieldName(fieldName, isFromRequest)]: value,
            };
      }, {});
    return requiredData;
  }, [getModalItems]);

  const columnCount = useMemo(() => {
    const columnCount = hasActions ? 2 : 3;
    return columnCount;
  }, [mobileLimit, hasActions]);

  const gridLimit = useMemo(() => {
    const gridLimit = mobileLimit + (mobileLimit % columnCount);
    return gridLimit;
  }, [mobileLimit, hasActions, columnCount]);

  const formMobileGridArea = useCallback(
    (prevValue: string, index: number) => {
      //const case =
      return `${prevValue}${
        index < gridLimit
          ? ` ${index % columnCount === 0 ? (allowSelect && mobileSelectMode ? `'select ` : `'`) : ''}item${index}${
              index % columnCount === columnCount - 1 ? ` action'` : ''
            }`
          : ''
      }`;
    },
    [gridLimit, hasActions],
  );

  const encloseMobileGridArea = useCallback(
    (mobileGridArea: string, length: number) => {
      return `${mobileGridArea}${
        length % columnCount === columnCount - 1 && length < gridLimit ? ` item${length} action'` : ''
      }`;
    },
    [gridLimit, hasActions],
  );

  const getGridStructure = useCallback(
    (index: number, windowWidth: number) => {
      const presentedItems = index !== -1 ? getPresentedItems(index, windowWidth) : columnStructure;
      const preparationFunction = (array: any[], allowSelect: boolean) => {
        const length = array.length;
        const combinedData = array
          .filter((item) => {
            if (withHeaderInMobile) {
              return item.shownInHeader;
            } else {
              return !item.excludedFromView;
            }
          })
          .reduce(
            (prevItem, item: any, index) => {
              return {
                gridTemplate: `${prevItem.gridTemplate} ${item.width ? item.width : '1fr'}`,
                mobileGridTemplate: `${prevItem.mobileGridTemplate} ${index < 2 ? '1fr' : ''}`,
                mobileGridArea: formMobileGridArea(prevItem.mobileGridArea, index),
                headGridTemplate: `${prevItem.headGridTemplate} ${item.width ? item.width : '1fr'}`,
                mobileHeadGridTemplate: `${prevItem.mobileHeadGridTemplate} ${index < 2 ? '1fr' : ''}`,
              };
            },
            {
              gridTemplate: '',
              mobileGridTemplate: '',
              mobileGridArea: '',
              headGridTemplate: '',
              mobileHeadGridTemplate: '',
            },
          );
        const {
          mobileGridArea,
          gridTemplate,
          mobileGridTemplate,
          headGridTemplate,
          mobileHeadGridTemplate,
        } = combinedData;

        const functionalArea = hasActions ? '190px' : '0px';
        return {
          mobileGridArea: encloseMobileGridArea(mobileGridArea, length),
          gridTemplate: `${allowSelect ? '30px' : ''} ${gridTemplate} ${functionalArea}`,
          mobileGridTemplate: `${allowSelect && mobileSelectMode ? '25px' : ''} ${mobileGridTemplate}`,
          headGridTemplate: `${allowSelect ? '30px' : ''} ${headGridTemplate} ${functionalArea}`,
          mobileHeadGridTemplate: `${allowSelect && mobileSelectMode ? '25px' : ''} ${mobileHeadGridTemplate}`,
        };
      };
      return preparationFunction(
        windowWidth < sizes.monitor ? presentedItems.slice(0, 3) : presentedItems,
        allowDelete || allowSelect,
      );
    },
    [allowDelete, allowSelect, mobileSelectMode, mobileLimit, getPresentedItems, hasActions],
  );

  const getHeaderData = useCallback(
    (windowWidth: number) => {
      return columnStructure
        .filter((item) => {
          if (windowWidth < 769 && withHeaderInMobile) {
            return item.shownInHeader;
          } else {
            return !item.excludedFromView;
          }
        })
        .map((item, index) => {
          //let label = item.label;
          let sort_field = '';
          let sortable = false;
          if (metadata && metadata[index]) {
            const object_key = isColumnOfSingularValue(item.type)
              ? item.fieldName
              : (item.fieldName as { id: string; name: string }).name || null;
            if (object_key) {
              if (index !== -1) {
                sort_field = metadata[index].object_key;
                sortable = metadata[index].sortable;
              }
            }
          }
          return { ...item, /*label,*/ sortable, sort_field };
        });
    },
    [columnStructure, metadata],
  );

  const getSortingData = useCallback(() => {
    return sorting;
  }, [sorting]);
  const setOpenIndex = useCallback((index: number) => {
    setTableState((value: TableData) => ({ ...value, openIndex: index }));
  }, []);

  const resetTable = () => {
    setTableState(DEFAULT_STATE);
  };

  //@TODO Alex work on this object
  const functions = {
    setTableData,
    setSearchValue,
    deleteItem,
    deleteCheckedItems,
    toggleIsChecked,
    toggleIsCheckedAll,
    toggleIsEditInProcess,
    addEmptyItem,
    increaseItemCount,
    setField,
    updateLocalItem,
    setGlobalProps,
    putItem,
    getReadyState,
    getSelectedItems,
    getViewedData,
    getLocalDeleteName,
    getLocalDeleteId,
    createItem,
    toggleView,
    toggleEdit,
    toggleAdd,
    toggleDelete,
    setHighlightedIndex,
    setLocalDeleteIndex,
    setSortingData,
    toggleMobileSelectMode,
    getPresentedItems,
    getModalItems,
    getGridStructure,
    startAddProcess,
    uncheckAll,
    enrichModalData,
    getRequiredData,
    getHeaderData,
    getSortingData,
    setOpenIndex,
    getSearchValue,
    setPage,
    setValidatingIsStarted,
    resetTable,
  };

  return [tableState, setTableState, functions];
};

export const TableContextProvider = ({ children }: { children: ReactNode }) => {
  const provider = useTableContextCreator();
  //const [tableState, setTableState] = provider;

  return <TableContext.Provider value={provider}>{children}</TableContext.Provider>;
};

export const useTableContext = (): TableContextProviderType => {
  const service = useContext(TableContext);

  if (!service) {
    throw new Error('Table Context is unavailable');
  }

  return service;
};

export const useTableFunctions = (): TableFunctions => {
  const service = useContext(TableContext);

  if (!service) {
    throw new Error('Table Context is unavailable');
  }

  return service[2];
};

export const withTableContext = (Component: any) => {
  return function WithTableContext(props: any) {
    const service = useTableContext();
    return <Component {...props} tableContext={service} />;
  };
};

export const withTableContextProvider = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => ({
  ...props
}) => {
  const provider = useTableContextCreator();
  return (
    <TableContext.Provider value={provider}>
      <Component {...(props as P)} />
    </TableContext.Provider>
  );
};
