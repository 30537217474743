/**
 * @description
 * This is component that covers
 * the functionalities for modal component.
 *
 */

import React, { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../styles/media';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000001;
  background-color: rgb(0, 0, 0, 0.6);
  ${media.tablet`
    align-items: flex-end;
  `}
`;

export interface ModalProps {
  children: any;
  isOpenModal: boolean;
  bodyElem?: any;
}
export const Modal: FC<ModalProps> = ({ children, isOpenModal, bodyElem, ...props }) => {
  useEffect(() => {
    if (bodyElem) {
      bodyElem.style.overflow = isOpenModal ? 'hidden' : 'overlay';
    }
  }, [isOpenModal, bodyElem]);

  if (isOpenModal) {
    return createPortal(<Wrapper {...props}>{children}</Wrapper>, document.getElementById('modal') || document.body);
  }
  return null;
};
