/**
 * @description
 * This is component for card of dashboard
 * all the functionalities cthings require for DashboardCard
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { media } from '../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { DashboardImage } from './components/dashboardImage/DashboardImage';
import { Point } from './components/point/Point';
import { DashboardItemState } from '../../enums/Dashboard';

const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 278px;
  height: 120px;
  box-sizing: border-box;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow2};
  background-color: white;
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.additional16};
  user-select: none;
  margin: ${(props: any) => (props.margin ? props.margin : '0')};
  padding: 20px 23px 16px;
  ${media.tablet`
    width: 100%; 
  `}
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const TrackersWrapper = styled.div`
  width: 100%;
  display: flex;
`;
const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperText = styled(Text)`
  font-size: 35px;
  line-height: 38px;
`;
const Line = styled.div`
  width: 1px;
  height: 44px;
  background-color: gray4;
  margin: 0 22px;
  margin-top: -2px;
  align-self: center;
`;

type DataType = {
  title: string;
  index: string;
  icon_type: string;
  value: number;
  first_item: { title: string; value: number };
  second_item: { title: string; value: number };
  state: string;
};

enum TypeOfCard {
  COMPLEX_CARD = 'COMPLEX_CARD',
  CARD = 'CARD',
}

export interface DashboardCardProps {
  type: TypeOfCard;
  data: DataType;
  margin?: string;
}

const DashboardCardPlain: FC<DashboardCardProps> = ({ type, data, margin, ...props }) => {
  const theme = useTheme();
  const { black, gray2, primary, green10, orange2, red2 } = theme.colors;
  const { index, title, value, first_item, second_item, icon_type, state } = data;
  const valueArr = [first_item, second_item];

  const color =
    state === DashboardItemState.SUCCESS
      ? green10
      : state === DashboardItemState.WARNING
      ? orange2
      : state === DashboardItemState.CRITICAL
      ? red2
      : primary;

  return (
    <ExtraWrapper margin={margin} {...props} theme={theme}>
      <TitleWrapper>
        {index && <Point value={index} />}
        <Text type={TextType.TITLE_H5} color={gray2}>
          {title}
        </Text>
      </TitleWrapper>
      <Wrapper>
        {type === TypeOfCard.COMPLEX_CARD ? (
          <TrackersWrapper>
            {valueArr.map((item: any, index: number) => {
              const { title, value } = item;
              return (
                <>
                  <ValueWrapper>
                    <Text type={TextType.TEXT_10_GRAY} color={gray2}>
                      {title}
                    </Text>
                    <WrapperText type={TextType.PROBLEMS_BUTTON_TEXT} color={black}>
                      {value ? value : 'N/A'}
                    </WrapperText>
                  </ValueWrapper>
                  {index === 0 && <Line />}
                </>
              );
            })}
          </TrackersWrapper>
        ) : (
          <WrapperText type={TextType.PROBLEMS_BUTTON_TEXT} color={black}>
            {value && !isNaN(value) ? value : 'N/A'}
          </WrapperText>
        )}
        <DashboardImage type={icon_type} iconColor={color} />
      </Wrapper>
    </ExtraWrapper>
  );
};

export const DashboardCard = withLoader(undefined, PlaceholderType.CARD)(DashboardCardPlain);
