import React, { useState, useCallback } from 'react';
import { useProblems } from '../../../../../api/problems';
import DraggableTable from '../../../../../components/DraggableTable/DraggableTable';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { MAPBOX_TOKEN } from '../../../../../consts';
import { media } from '../../../../../styles/media';
import DeviceListMap from '../../../../../components/deviceListMap/DeviceListMap';

const MapContainer = styled.div`
  height: 600px;
  /*max-width: 580px;
  width: 100%;*/
  flex: 1;
  margin-left: 20px;
  ${media.tablet`
    margin: 0;
  `}
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TableControl = () => {
  const pageSize = 2;
  const [boundingRect, setBoundingRect] = useState(null);
  const mapRef = useCallback((node) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);
  const [page, setPage] = useState(0);
  const handlePageChange = (value: number, isOffset?: boolean) => {
    !!isOffset ? setPage((currentPage) => currentPage + value) : setPage(value);
  };
  const [payload, pagination] = useProblems(boundingRect, page + 1, pageSize);
  const devices = payload.map;
  const deviceList = devices.geopivoted;
  return (
    <Content>
      <DraggableTable
        data={payload.problems}
        page={page}
        onPageChange={handlePageChange}
        pageSize={pageSize}
        eventNum={pagination.total_items}
      />
      <MapContainer ref={mapRef}>
        {devices.zoom_level_mapbox !== -1 && (
          <DeviceListMap
            zoom={devices.zoom_level_mapbox - 1}
            latitude={deviceList[0] ? parseFloat(devices.centre_coord.lat) : 52.25603}
            longitude={deviceList[0] ? parseFloat(devices.centre_coord.lng) : 20.98765}
            staticDisplay
            deviceList={deviceList}
            mapToken={MAPBOX_TOKEN}
          />
        )}
      </MapContainer>
    </Content>
  );
};
