/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholder for graphs require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
//images
import { ReactComponent as Graphic } from '../assets/graphic.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export interface ComponentProps {
  // type: PlaceholderType;
}

export const PlaceholderGraph: FC<ComponentProps> = () => {
  return (
    <Wrapper>
      <Graphic width="100%" height="100%" preserveAspectRatio={'none'} />
    </Wrapper>
  );
};
