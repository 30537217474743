import React from 'react';
import { use100vh } from 'react-div-100vh';

import { renderRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { useUserAccess } from '../../api/user';

import { Wrapper } from '../elements/wrappers';

const Errors = (props: any) => {
  useUserAccess();
  const minHeight = use100vh();
  return <Wrapper minHeight={minHeight}>{renderRoutes(props.route.routes)}</Wrapper>;
};

export default withRouter(Errors);
