import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Modal } from '../../../../../../components/modal/Modal';
import { TextType, Text } from '@bit/first-scope.text';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CloseWhiteRoundButton } from '../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { media } from '../../../../../../styles/media';
import { useMediaType } from '../../../../../../styles/style.context';
import { Select } from '../../../../../../components/select/Select';
import { CommonButton } from '../../../../../../components/commonButton/CommonButton';
import { InputModal } from '../../../../../../components/inputModal/InputModal';

const WrapperModal = styled(Modal)`
  ${media.tablet`
    & > div {
      width: 100%;
      padding: 0;
    }
  `}
`;

const Wrapper = styled(ModalContainer)`
  align-items: flex-start;
  padding: 24px;
  ${media.tablet`
    width: 100%;
    padding: 24px 16px;
    border-radius: 16px 16px 0 0;
  `}
`;

const WrapperTitle = styled.div`
  margin-bottom: 19px;
  ${media.tablet`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `}
`;

const StyledText = styled(Text)`
  font-size: 18px;
  margin-bottom: 14px;
  ${media.tablet`
    margin-bottom: 4px;
  `}
`;

const ItemsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `}
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  width: ${(props: any) => (props.isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
  }
  ${media.tablet`
      width: 100%;   
  `};
`;

const CancelButton = styled(CommonButton)`
  width: 104px;
  height: 32px;
  margin: 10px 0 0 15px;
  ${media.tablet`
      width: 100%;
      margin: 4px 0;
  `}
`;

const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    margin-top: 8px;
  `}
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `}
`;

const StyledInput = styled(InputModal)`
  width: 230px;
  ${media.tablet`
      width: 100%;
      margin-bottom: 4px;
  `}
`;

export enum RowItemType {
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  JOINED_SELECT = 'JOINED_SELECT',
}

export const AttachInventoryModal = ({
  attachedToInventory,
  inventoryArr,
  handleAttachInventoryApi,
  handleCloseInventoriesModal,
  dataAttachInventory,
  languageStrings,
}: {
  attachedToInventory: boolean;
  inventoryArr: any;
  handleAttachInventoryApi: any;
  handleCloseInventoriesModal: any;
  dataAttachInventory: any;
  languageStrings?: any;
}) => {
  const mediaType = useMediaType();
  const theme = useTheme();

  const isDisabledAttachButton = !dataAttachInventory.trackable_id;

  return (
    <>
      <WrapperModal isOpenModal={attachedToInventory}>
        <Wrapper width="max-content">
          <WrapperTitle>
            <StyledText type={TextType.TITLE_PRIMARY}>Attach to Inventory</StyledText>
            {mediaType.tablet && <CloseWhiteRoundButton onClick={handleCloseInventoriesModal} />}
          </WrapperTitle>
          <ItemsWrapper>
            {inventoryArr.map((item: any, index: number) => {
              const {
                name,
                fieldName,
                value,
                disabled,
                type,
                onChange,
                values,
                placeholder,
                isError,
                isPendingRequest,
              } = item;
              if (type === RowItemType.JOINED_SELECT) {
                return (
                  <SelectWrapper>
                    <StyledSelect
                      value={value}
                      label={name}
                      disabled={disabled}
                      placeholder={placeholder}
                      values={values}
                      onChange={(value: any) => onChange(value, fieldName)}
                      isWide={false}
                      isError={isError}
                      fromAttach
                      isPendingRequest={isPendingRequest}
                    />
                  </SelectWrapper>
                );
              }
              if (type === RowItemType.INPUT || type === RowItemType.INPUT_NUMBER) {
                return (
                  <StyledInput
                    key={index}
                    name={name}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={disabled ? () => {} : onChange}
                    type={type === RowItemType.INPUT ? 'text' : 'number'}
                    isError={isError}
                    fromAttach
                  />
                );
              }
            })}
          </ItemsWrapper>
          <WrapperButtons>
            <CancelButton
              onClick={handleCloseInventoriesModal}
              backgroundColor={theme.colors.white}
              borderColor={theme.colors.primary}
              color={theme.colors.black2}
            >
              {languageStrings ? languageStrings.cancelButton : 'Cancel'}
            </CancelButton>
            <CancelButton
              onClick={handleAttachInventoryApi}
              backgroundColor={theme.colors.primary}
              borderColor={theme.colors.primary}
              disabled={isDisabledAttachButton}
            >
              {languageStrings ? languageStrings.attachButton : 'Attach'}
            </CancelButton>
          </WrapperButtons>
        </Wrapper>
      </WrapperModal>
    </>
  );
};
