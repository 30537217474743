// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../styles/media';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${(props: any) => (props.minHeight ? props.minHeight + 'px' : '100vh')};
  width: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const Body = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  padding: 0 0 0 54px;
  background-color: gray5;
  overflow-y: hidden;
  ${media.tablet`
    padding: 50px 0 0;
  `}
`;
