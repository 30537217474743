/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for cards of documents require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../../../../styles/media';
import { CardType } from '../../../../../enums/CardType';
import { ReactComponent as Download } from '../../../../../assets/download.svg';
import ls from '../../../../../utils/ls';
import { LsValueType } from '../../../../../enums/LsValueType';
import { API_URL } from '../../../../../consts';
import { DocumentsIcon } from '../documentsIcon/DocumentsIcon';

const ExtraWrapper = styled.div`
  width: 280px;
  /* height: 120px; */
  box-sizing: border-box;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow2};
  background-color: white;
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.additional16};
  margin: ${(props: any) => props.margin};
  padding: ${(props: any) => (props.type === CardType.CITIES ? '20px 24px 30px 24px' : '15px 24px')};
  ${media.tablet`
    width: 100%; 
  `}
`;

const WrapperTitle = styled.div`
  font-size: 21px;
  font-weight: 500;
  color: black;
`;

const DownloadLink = styled.a`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  color: gray2;
  text-decoration: none;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover {
    color: gray1;
    & > svg > path {
      transition: all 0.3s ease;
      stroke: ${(props: any) => props.hoverColor};
    }
  }
  & > svg > path {
    transition: all 0.3s ease;
    stroke: ${(props: any) => props.color};
  }
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  height: ${(props: any) => (props.type === CardType.CITIES ? 'max-content' : '64px')};
  display: ${(props: any) => (props.type === CardType.CITIES ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: flex-end;
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.primary};
  }
`;

const WrapperText = styled.div`
  color: inherit;
  font-size: 12px;
  margin-left: 5px;
`;

export interface DashboardCardProps {
  languageStrings: any;
  type?: CardType;
  title: string;
  data?: any[];
  margin?: string;
  count?: string | number;
  filename: string;
}

export const DocumentsCard: FC<DashboardCardProps> = ({
  languageStrings,
  type,
  count,
  title,
  margin,
  data,
  filename,
  ...props
}) => {
  const theme = useTheme();
  const color = theme.colors.black;
  const titleColor = theme.colors.gray2;
  const titleColorHover = theme.colors.gray1;
  const iconColor = theme.colors.primary;

  return (
    <ExtraWrapper margin={margin} type={type} {...props} theme={theme}>
      <Button type={type} color={color} titleColor={titleColor}>
        <WrapperTitle color={color}>{title}</WrapperTitle>
        <Wrapper iconColor={iconColor} type={type} theme={theme}>
          <DownloadLink
            href={`${API_URL}/v1/static/docs/${filename}?access_token=${ls.get(LsValueType.token)}`}
            target="_blank"
            rel={'noopener noreferer'}
            color={titleColor}
            hoverColor={titleColorHover}
          >
            <Download />
            <WrapperText>{languageStrings ? languageStrings.download : 'Download'}</WrapperText>
          </DownloadLink>
          <DocumentsIcon />
        </Wrapper>
      </Button>
    </ExtraWrapper>
  );
};
DocumentsCard.defaultProps = {};
