// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../styles/media';

export const ValueLevel = styled.div`
  border-radius: 50%;
  background-color: ${(props: any) => props.backgroundColor};
  transform: ${(props: any) => (!props.staticDisplay ? `translate(-50%, -50%) scale(${props.scale})` : 'none')};
  z-index: ${(props: any) => props.index === props.ITracker && '1'};
  top: ${(props: any) => props.top + 'px'};
  left: ${(props: any) => props.left + 'px'};
  position: ${(props: any) => (!props.staticDisplay ? 'absolute' : 'static')};
  margin-right: ${(props: any) => props.staticDisplay && '5px'};
  box-sizing: border-box;
  cursor: pointer;
`;

export const LocationCircle = styled(ValueLevel)`
  width: ${(props: any) => (!props.withBorder ? '8px' : '16px')};
  height: ${(props: any) => (!props.withBorder ? '8px' : '16px')};
  border: ${(props: any) => props.staticDisplay && props.withBorder && '3px solid rgba(255, 255, 255, 0.6)'};
`;

export const LocationCircleCluster = styled(ValueLevel)`
  width: 26px;
  height: 26px;
`;

export const LocationMask = styled.div`
  pointer-events: none;
  position: absolute;
  top: ${(props: any) => props.top + 'px'};
  left: ${(props: any) => props.left + 'px'};
  width: ${(props: any) => (props.isCluster ? '26px' : '16px')};
  height: ${(props: any) => (props.isCluster ? '26px' : '16px')};
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  transform: translate(-50%, -50%) ${(props: any) => `scale(${props.scale})`};

  z-index: ${(props: any) => props.index === props.ITracker && '1'};
  cursor: pointer;
`;

export const SectorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
`;

export const Sector = styled.div`
  position: absolute;
  transform-origin: 100% 100%;
  background: ${(props: any) => (props.left ? props.theme.colors.gray2 : props.theme.colors.orange7)};
  width: 100%;
  height: 100%;
  left: 50%;
  top: ${(props: any) => (props.doubleSector ? (props.left ? '0' : '100%') : '50%')};
  margin-top: -100%;
  margin-left: -100%;
  transform: ${(props: any) =>
    props.doubleSector
      ? props.left
        ? 'rotate(270deg)'
        : 'rotate(90deg)'
      : props.left
      ? 'rotate(270deg) skew(150deg)'
      : 'rotate(150deg) skew(150deg)'};
`;

export const SectorText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: white;
`;

export const Image = styled.img`
  height: ${(props: any) => (props.ratio <= 1 ? '100%' : '')};
  width: ${(props: any) => (props.ratio <= 1 ? '' : '100%')};
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MapInteraction = styled.div`
  margin-top: 15px;
  height: ${(props: any) => props.height};
  width: 100%;
  position: relative;
  border: 1px solid;
  border-radius: 8px;
  background-color: gray4;
  border-color: gray3;
  ${media.phone`
  border-radius: 0;
  `}
  & > div > div {
    border-radius: 8px;
    & > div:first-child {
      height: 100%;
      width: 100%;
    }
  }
  ${media.tablet`
    margin: 0;
    border: none;
    border-radius: 0;
  `}
`;

export const ZoomToolsContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  ${media.phoneXS`
    bottom: 40px;
  `}
`;

export const ToolsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0;
  background-color: white;
  outline: transparent;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 3px 6px rgba(96, 97, 112, 0.16);
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.additional6};
  cursor: pointer;
  transition: all 0.3s linear;
  &:nth-child(1) {
    border-bottom: 1px solid;
    border-color: gray4;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:nth-child(3) {
    margin-top: 6px;
  }
  &:hover {
    background-color: gray6;
  }
`;

export const MapLegendLeft = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 28px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 99;
  ${media.phone`
    grid-gap: 18px;
  `}
`;
export const MapLegendRight = styled.div`
  position: absolute;
  bottom: 0px;
  right: 75px;
  display: flex;
  align-items: center;
  z-index: 99;
  ${media.desktop`
    display: none;
  `}
  ${media.tablet`
    display: none;
  `}
  ${media.phone`
    display: none;
  `}
`;
