/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for loader require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { keyframes, useTheme } from '@xstyled/styled-components';

import { SpinnerClassic } from '../animations/SpinnerClassic';
import { SpinnerChaseDots } from '../animations/SpinnerChaseDots';
import { SpinnerDoubleBounce } from '../animations/SpinnerDoubleBounce';
import { SpinnerBounce } from '../animations/SpinnerBounce';
import { SpinnerRowBounce } from '../animations/SpinnerRowBounce';
import { SpinnerCircle } from '../animations/SpinnerCircle';

export enum LoaderType {
  CLASSIC = 'CLASSIC',
  CHASE_DOTS = 'CHASE_DOTS',
  DOUBLE_BOUNCE = 'DOUBLE_BOUNCE',
  BOUNCE = 'BOUNCE',
  ROW_BOUNCE = 'ROW_BOUNCE',
  CIRCLE = 'CIRCLE',
}

const loadingText = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const StyledContainer = styled.div`
  position: ${(props: any) => (props.position ? props.position : 'fixed')};
  height: ${(props: any) => (props.height ? props.height : '100vh')};
  width: ${(props: any) => (props.width ? props.width : '100%')};
  border-radius: ${(props: any) => (props.borderRadius ? props.borderRadius : '0.25rem')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.01);
  left: 0;
  top: 0;
`;

const LoaderText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: dark1;
  margin-bottom: 5px;
  animation: ${loadingText} 4s infinite ease-in;
`;

export interface LoaderProps {
  type: LoaderType;
  style?: any;
  text?: string;
}

export const Loader: FC<LoaderProps> = ({ type, style, text }) => {
  const theme = useTheme();
  return (
    <StyledContainer
      width={style && style.width}
      height={style && style.height}
      borderRadius={style && style.borderRadius}
      flex={style && style.flex}
    >
      {text && <LoaderText>{text}</LoaderText>}
      {type === LoaderType.CLASSIC && <SpinnerClassic backgroundColor={theme.colors.primary} />}
      {type === LoaderType.CHASE_DOTS && <SpinnerChaseDots backgroundColor={theme.colors.primary} />}
      {type === LoaderType.DOUBLE_BOUNCE && <SpinnerDoubleBounce backgroundColor={theme.colors.primary} />}
      {type === LoaderType.BOUNCE && <SpinnerBounce backgroundColor={theme.colors.primary} />}
      {type === LoaderType.ROW_BOUNCE && <SpinnerRowBounce backgroundColor={theme.colors.primary} />}
      {type === LoaderType.CIRCLE && <SpinnerCircle backgroundColor={theme.colors.primary} />}
    </StyledContainer>
  );
};
