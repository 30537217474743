import React, { useEffect } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CommonButton } from '@bit/first-scope.common-button';
import { ReactComponent as LocationImage } from '../../../../../../../assets/locationGray.svg';
import { ReactComponent as AttachImage } from '../../../../../../../assets/attach.svg';
import { ReactComponent as DeAttachImage } from '../../../../../../../assets/deAttach.svg';
import { media } from '../../../../../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';

const WrapperButtons = styled.div`
  display: flex;
  margin-top: 15px;
  ${media.desktop`
    width: 100%;
    justify-content: space-between;
  `}
  ${media.phone`
    flex-direction: column;
`}
`;

const StyledButton = styled(CommonButton)`
  min-width: 160px;
  width: max-content;
  &.first {
    margin-right: 16px;
  }

  & > svg > path {
    stroke: ${(props: any) => props.color};
    transition: all ease 0.3s;
  }
  &:hover > svg > path {
    stroke: ${(props: any) => props.hoverColor};
  }
  & > svg > g > path {
    stroke: ${(props: any) => props.color};
    transition: all ease 0.3s;
  }
  &:hover > svg > g > path {
    stroke: ${(props: any) => props.hoverColor};
  }
  ${media.desktop`
    max-width: none;
  `}
  ${media.tablet`
    height: 40px;
  `}
  ${media.phone`
    width: 100%;
   
    &.first {
      margin: 0 0 10px 0;
    }
   
  `}
`;

export const ButtonsContainer = ({
  data,
  setShowAttachModal,
  handleDeAttachDeviceApi,
  setIsAttach,
  handleShowOnTheMap,
  isAttach,
  isAttachDeAttachButton,
  ...props
}: {
  data: any;
  setShowAttachModal: any;
  handleDeAttachDeviceApi: any;
  setIsAttach: any;
  handleShowOnTheMap: () => void;
  isAttach: any;
  isAttachDeAttachButton: boolean;
}) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);

  const { white, primary, red2 } = theme.colors;
  useEffect(() => {
    setIsAttach(data?.attached);
  }, [data?.attached]);

  return (
    <WrapperButtons {...props}>
      {isAttachDeAttachButton && (
        <>
          {!isAttach ? (
            <StyledButton onClick={() => setShowAttachModal(true)} className={'first'}>
              <AttachImage /> {language ? language.attachButton : 'Attach'}
            </StyledButton>
          ) : (
            <StyledButton
              className={'first'}
              onClick={handleDeAttachDeviceApi}
              color={red2}
              backgroundColor={white}
              borderColor={red2}
              hoverColor={white}
              backgroundColorHover={red2}
              borderColorHover={red2}
            >
              <DeAttachImage /> {language ? language.detachButton : 'Detach'}
            </StyledButton>
          )}
        </>
      )}

      <StyledButton
        onClick={handleShowOnTheMap}
        color={primary}
        hoverColor={white}
        backgroundColor={white}
        backgroundColorHover={primary}
        borderColorHover={primary}
      >
        <LocationImage /> {language ? language.goToLocationButton : 'Go to location'}
      </StyledButton>
    </WrapperButtons>
  );
};
