/**
 * @description
 * This is component mobile wrapper for buttons
 * all the functionalities cthings for mobile wrapper require
 * Highly customisable.
 *
 */

import React from 'react';

import { createPortal } from 'react-dom';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../styles/media';

const Wrapper = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 74px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: ${(props: any) => props.theme.shadows.additionalShadow9};
    padding: 0 20px;
    position: fixed;
    bottom: 0;
    z-index: ${(props: any) => (props.zIndex ? props.zIndex : '100')};
    border-top: 1px solid;
    border-color: gray3;
  `}
`;

export const MobilePortalContainer = ({
  children,
  zIndex,
  ...props
}: {
  children: any;
  zIndex?: string;
  className?: string;
}) => {
  const theme = useTheme();

  return createPortal(
    <Wrapper theme={theme} zIndex={zIndex} {...props}>
      {children}
    </Wrapper>,
    document.getElementById('mobile_container') || document.body,
  );
};
