/**
 * @description
 * This is component for attach filtering floor Plan mobile version.
 *
 */

import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { SelectWithModal } from '../../../../../../components/selectWithModal/SelectWithModal';
import { useMobileFilters } from '../../../../../../containers/common/campusMapInsight/Content/mobileFilterContext/mobileContext';
import { getIsAllAttachSelect } from '../../../../../../containers/common/campusMapInsight/Content/mobileFilterContext/selectorMobile';

const WrapperSelectWithModal = styled(SelectWithModal)`
  margin-top: 20px;
  padding: 20px;
`;

export const AttachFilterMobile = ({ languageStrings }: any) => {
  const { stateMobile, dispatchMobile } = useMobileFilters();
  const { attachFilter } = stateMobile?.secondModal?.secondModalAttachState;
  const { checkedAttachCount } = stateMobile?.firstModal?.firstModalAttachState;

  const isSelectAllAttachSecondModal = getIsAllAttachSelect(
    stateMobile?.secondModal?.secondModalAttachState?.attachFilter,
  );
  const isSelectAllAttachFirstModal = getIsAllAttachSelect(
    stateMobile?.firstModal?.firstModalAttachState?.attachFilter,
  );

  const handleSelectAllAttach = () =>
    dispatchMobile({ type: 'SELECT_ALL_ATTACH', payload: isSelectAllAttachSecondModal });

  const handleSelectOneAttach = (type: string) =>
    dispatchMobile({ type: 'SELECT_ONE_ATTACH', payload: { typeAttach: type } });

  const handleClearAllDevices = () => dispatchMobile({ type: 'SELECT_CLEAR_ALL_ATTACH' });

  const handleDone = () => dispatchMobile({ type: 'HANDLE_DONE_ATTACH_MODAL' });

  const handleCloseModal = () => dispatchMobile({ type: 'HANDLE_CLOSE_ATTACH_MODAL' });

  const arrAttachDetach = Object.keys(attachFilter).map((key: any) => attachFilter[key]);

  return (
    <WrapperSelectWithModal
      maxWidth={'100%'}
      minHeight={'40px'}
      name={languageStrings ? languageStrings.labelAttaching : 'Attaching'}
      fontSizeCount={'14px'}
      parameterList={arrAttachDetach}
      handleCheckedItem={handleSelectOneAttach}
      handleSelectAll={handleSelectAllAttach}
      isCheckedAll={isSelectAllAttachSecondModal}
      isSelectAllFirstModal={isSelectAllAttachFirstModal}
      checkedCount={checkedAttachCount}
      isDisabled={false}
      handleClearAll={handleClearAllDevices}
      closeModal={handleCloseModal}
      handleDone={handleDone}
    />
  );
};
