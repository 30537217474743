import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguageIdentity } from '../api/language';
import { selectLanguage, selectLanguages, setLanguage } from '../app/state/userSlice';
import { isSafari } from '../consts';
import { LsValueType } from '../enums/LsValueType';
import ls from '../utils/ls';

export const useLanguagePicker = () => {
  const dispatch = useDispatch();

  const languages = useSelector(selectLanguages);
  const language = useSelector(selectLanguage);

  const languageIndex = languages.findIndex((lan: any) => lan.id === language.id);

  const [openLangList, setOpenLangList] = useState(false);
  const languagePickerRef = useRef<any>(null);

  const languagePickerOutsideHandler = useCallback(
    (e: any) => {
      if (openLangList && !languagePickerRef.current.contains(e.target)) {
        setOpenLangList(false);
      }
    },
    [openLangList],
  );

  useEffect(() => {
    window.addEventListener('click', languagePickerOutsideHandler);
    return () => {
      window.removeEventListener('click', languagePickerOutsideHandler);
    };
  }, [languagePickerOutsideHandler]);

  const handleSetLanguage = (language: string) => {
    ls.set(LsValueType.locale, language);
    setLanguageIdentity(language);
    if (isSafari) {
      window.location.reload();
    } else {
      dispatch(setLanguage(language));
    }
    setOpenLangList(false);
  };

  return {
    changeLanguageHandler: (language: string) => handleSetLanguage(language),
    languagePickerRef,
    languages,
    languageIndex,
    openLangList,
    setOpenLangList,
  };
};
