/**
 * @description
 * This is component hoc for mobile draggable container
 * all the functionalities cthings require for withMobileDraggableContainer
 * Highly customisable.
 *
 */

import React, { useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { useSwipeable } from 'react-swipeable';

import { media } from '../../styles/media';
import { useStyleContext } from '../../styles/style.context';

const Container = styled.div`
  position: fixed;
  z-index: 99;
  bottom: 49px;
  left: 0;
  right: 0;
  transform: ${(props: any) => (props.mobileTable && props.isDraggable ? 'translateY(0)' : 'translateY(70%)')};
  background-color: white;
  padding-top: 10px;
  border-radius: ${(props: any) => `${props.theme.borderRadius.primary} ${props.theme.borderRadius.primary} 0 0`};
  &:before {
    content: '';
    ${media.tablet`
        position: absolute;
        z-index: 99;
        top: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 4px;
        background-color: black;
        opacity: 0.2;
      `}
  }
`;

const Background = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black3;
  opacity: 0.6;
  display: ${(props: any) => (props.mobileTable ? 'block' : 'none')};
`;

export const withMobileDraggableContainer = (isDraggable: boolean) => {
  const enhance = (Component: any) => ({ ...props }) => {
    const [style] = useStyleContext();
    const { mediaType } = style;
    const theme = useTheme();
    const [mobileTable, setMobileTable] = useState(false);

    const handlers = useSwipeable({
      onSwipedUp: () => setMobileTable(true),
      onSwipedDown: () => setMobileTable(false),
      preventDefaultTouchmoveEvent: true,
    });
    return (
      <>
        {mediaType.tablet ? (
          <>
            <Container mobileTable={mobileTable} isDraggable={isDraggable} {...handlers} theme={theme}>
              <Component {...props} />
            </Container>
            <Background mobileTable={mobileTable} />
          </>
        ) : (
          <Component {...props} />
        )}
      </>
    );
  };
  return enhance;
};
