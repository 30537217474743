/**
 * @description
 * This is component that displays map with zones
 */

import React, { useEffect, useState } from 'react';

import { useLoaderFunctions } from '../../../../../features/placeholderComponent/loader.context';
import { useDevices } from '../../../../../api/locations';
import DeviceListMap from '../../../../../components/deviceListMap/DeviceListMap';
import { MAPBOX_TOKEN } from '../../../../../consts';

export const MapWithZones = ({ boundingRect }: { boundingRect: any }) => {
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = ['ZonesMap'];
  const devices = useDevices(
    boundingRect,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );
  const [centre, setCentre] = useState<any>({ lat: 52.25603, lon: 20.98765 });
  const deviceList = devices.geopivoted;

  const [isInfoHomeId, setIsInfoHomeId] = useState('');

  const centreLat = devices.centre_coord.lat;
  const centreLon = devices.centre_coord.lng;

  useEffect(() => {
    centreLat && centreLon && setCentre({ lat: parseFloat(centreLat), lon: parseFloat(centreLon) });
  }, [centreLat, centreLon]);

  const handleSetCentre = (centre: any) => {
    setCentre({ lat: parseFloat(centre.lat), lon: parseFloat(centre.lng) });
  };

  return (
    <>
      {devices.centre_coord.lat && devices.centre_coord.lng && devices.geopivoted.length > 0 && (
        <DeviceListMap
          zoom={devices.zoom_level_mapbox > 0 ? devices.zoom_level_mapbox - 1 : 0}
          latitude={centre.lat}
          longitude={centre.lon}
          centerCoords={centre}
          setCentre={handleSetCentre}
          staticDisplay
          deviceList={deviceList}
          mapToken={MAPBOX_TOKEN}
          isInfoHomeId={isInfoHomeId}
          setIsInfoHomeId={setIsInfoHomeId}
          isForZones
        />
      )}
    </>
  );
};
