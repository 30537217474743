import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { TableRow } from '../components/tableRow/TableRow';
import { withLoader } from '../../../../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { TableHeader } from '../../firstTableInfo/components/tableHeader/TableHeader';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  & > div:nth-child(1) {
    height: 56px;
    display: flex;
    align-items: center;
  }
`;

const WrapperItems = styled.div`
  & > div:nth-child(7) {
    border-top: 2px solid;
    border-color: gray4;
  }
`;

export interface HeaderLabelsProps {
  name: string;
  isSorted: boolean;
}

export interface ComponentProps {
  headerLabels: any;
  headerGridTemplate: any;
  arr: any;
  type: string;
}

const ContentTable: FC<ComponentProps> = ({ headerLabels, headerGridTemplate, arr, type }) => {
  const theme = useTheme();

  const dateHandler = (value: string) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const hour = Math.abs(timezoneOffset / 60);
    const timeValue = Number(value.slice(14, 16)) + hour;
    const year = value.slice(6, 8);
    const validDate =
      value.replace(year, '20') + value.replace(value.slice(14, 16), timeValue.toString()).slice(13).slice(6);

    return validDate.replace(validDate.slice(14, 16), timeValue.toString());
  };

  return (
    <>
      <TableWrapper theme={theme}>
        <TableHeader
          labels={headerLabels}
          gridTemplateDesktop={headerGridTemplate.desktop}
          gridTemplateTablet={headerGridTemplate.tablet}
          gridTemplatePhone={headerGridTemplate.phone}
        />
        <WrapperItems type={type} isAttached={arr.length > 6}>
          {arr &&
            arr.map((item: any, index: number) => {
              const { value, property } = item;
              const validValue = property === 'Last Report' ? dateHandler(value) : value;
              return (
                <TableRow
                  key={index}
                  property={property}
                  value={validValue}
                  isFirst={index === 0}
                  isLast={index === arr.length - 1}
                  orderId={item?.orderId}
                  packageId={item?.packageId}
                  locationId={item?.locationId}
                  zoneId={item?.zoneId}
                />
              );
            })}
        </WrapperItems>
      </TableWrapper>
    </>
  );
};

export const InfoContentTable = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(ContentTable);
