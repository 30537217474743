/**
 * @description
 * This is modal window for select with filters in Inventories
 */
import React, { useCallback, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CloseRoundButton } from '../../../closeRoundButton/CloseRoundButton';
import { media } from '../../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchInput } from '../../../../components/chooseParameterSelect/components/searchInput/SearchInput';
import { Modal as ModalWrapper } from '@bit/first-scope.modal';
import { CheckBox } from '@bit/first-scope.controls';
import { ReactComponent as NodeIndicator } from '../../../../assets/NodeIndicator.svg';
import { ReactComponent as TrackerIndicator } from '../../../../assets/TrackerIndicator.svg';
import { ReactComponent as OfflineIndicator } from '../../../../assets/OfflineIndicator.svg';
import { TypeOfDeviceParameter } from '../../../../containers/common/campusMapInsight/Content/Content';
import { CommonButton } from '../../../../components/commonButton/CommonButton';

const StyledModal = styled(ModalWrapper)`
  z-index: 100000000000;
  ${media.phone`
  align-items: flex-end;
  & > div {
    width: 100%;
    align-items: flex-end;
  }
  `}
`;

const StyledContainer = styled(ModalContainer)`
  width: 528px;
  align-items: flex-start;
  padding: 24px;
  ${media.phone`
    width: 100%;
    border-radius: 24px 24px 0 0;
    padding: 16px;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled(Text)`
  font-size: 18px;
`;

const TextParameter = styled(Text)`
  font-size: 14px;
  margin-right: 5px;
`;

const ParameterItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: ${(props: any) => (props.isLast ? '12px' : '10px')};
  & > div {
    margin-right: 8px;
  }
  & > svg {
    margin-left: 4px;
  }
`;

const ParameterList = styled.ul`
  max-height: 240px;
  padding: 0;
  margin: 0;
  overflow-y: ${(props: any) => (props.heightOfList > 220 ? 'scroll' : 'auto')};
  &::-webkit-scrollbar {
    width: 4px;
    background: gray4;
    opacity: 1;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

const ParameterListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const StyledButton = styled(CommonButton)`
  width: calc(50% - 5px);
  height: 40px;
`;

const WrapperCheckBox = styled.div``;

const SearchWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`;

const WrapperTextIndicator = styled.div``;

export const Modal = ({
  isOpenModal,
  closeModal,
  name,
  parameterList,
  isSearchActive,
  handleCheckedItem,
  isDynamicData,
  handleSelectAll,
  isCheckedAll,
  handleClearAll,
  handleDone,
  languageStrings,
}: {
  isOpenModal?: boolean;
  closeModal: any;
  name?: string;
  parameterList: any[];
  isSearchActive: boolean;
  isDynamicData: boolean;
  handleCheckedItem: any;
  handleSelectAll: any;
  isCheckedAll: boolean;
  handleClearAll: any;
  handleDone: any;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const { gray1, gray2, white, primary, black } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [heightOfList, setHeightOfList] = useState(0);

  const filterParameterList = (flag: string) => {
    if (parameterList && parameterList.length > 0) {
      const filteredValue: any = parameterList.filter((item: any) => {
        return item?.name?.toLowerCase().indexOf(flag.toLowerCase()) !== -1;
      });
      return filteredValue;
    } else {
      return [];
    }
  };

  const isAllClear = parameterList.every((item: any) => item.isSelected === false);

  const handleInputOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  const clearModal = () => {
    handleClearAll();
  };

  const getIndicator = useCallback((type: TypeOfDeviceParameter) => {
    const getIndicator: any = {
      [TypeOfDeviceParameter.NODE]: <NodeIndicator />,
      [TypeOfDeviceParameter.TRACKER]: <TrackerIndicator />,
      [TypeOfDeviceParameter.OFFLINE]: <OfflineIndicator />,
    };
    return getIndicator[type];
  }, []);

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledContainer>
        <TitleWrapper>
          <Title type={TextType.TITLE_PRIMARY}>{name}</Title>
          <CloseRoundButton closeOnClick={closeModal} />
        </TitleWrapper>
        {isSearchActive && (
          <SearchWrapper>
            <SearchInput
              value={searchInputValue}
              handleOnChange={handleInputOnChange}
              handleClearInput={handleClearInput}
              isBorder
            />
          </SearchWrapper>
        )}
        <ParameterListWrapper>
          <ParameterList ref={listRef} heightOfList={heightOfList}>
            <ParameterItem
              onClick={() => {
                handleSelectAll();
              }}
            >
              <TextParameter type={TextType.TEXT_14_GRAY} color={gray1}>
                {languageStrings ? languageStrings.labelAll : 'All'}
              </TextParameter>

              <WrapperCheckBox onClick={handleSelectAll}>
                <CheckBox
                  label={''}
                  color={gray2}
                  backgroundColor={white}
                  backgroundColorHover={primary}
                  toggle={isCheckedAll}
                  disabled={false}
                  handleToggle={() => {}}
                />
              </WrapperCheckBox>
            </ParameterItem>
            {filterParameterList(searchInputValue).map((item: any, index: number) => {
              const { id, name, isSelected, type } = item;
              return (
                <ParameterItem
                  key={index}
                  isLast={index === parameterList.length - 1}
                  onClick={() => {
                    isDynamicData ? handleCheckedItem(id) : handleCheckedItem(type, parameterList);
                  }}
                >
                  <WrapperTextIndicator>
                    <TextParameter type={TextType.TEXT_12_GRAY} color={gray1}>
                      {name}
                    </TextParameter>
                    {getIndicator(type)}
                  </WrapperTextIndicator>
                  <CheckBox
                    label={''}
                    color={gray2}
                    backgroundColor={white}
                    backgroundColorHover={primary}
                    toggle={isSelected}
                    disabled={false}
                    handleToggle={() => {
                      isDynamicData ? handleCheckedItem(id) : handleCheckedItem(type, parameterList);
                    }}
                  />
                </ParameterItem>
              );
            })}
          </ParameterList>
        </ParameterListWrapper>
        <ButtonWrapper>
          <StyledButton onClick={clearModal} backgroundColor={white} color={black} disabled={isAllClear}>
            {languageStrings ? languageStrings.labelClear : 'Clear'}
          </StyledButton>
          <StyledButton onClick={handleDone} backgroundColor={primary} color={white}>
            {languageStrings ? languageStrings.doneButton : 'Done'}
          </StyledButton>
        </ButtonWrapper>
      </StyledContainer>
    </StyledModal>
  );
};
