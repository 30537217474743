import React, { FC, useCallback, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Stage } from './Stage';
import { media } from '../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';

const StagesContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 400px;
  margin-top: 4px;
  position: relative;
  ${media.tablet`
    margin-top: 0;
   `};
`;

const StagesBlock = styled.ul`
  box-sizing: border-box;
  width: 103%;
  flex: 1;
  padding: 0 4px 16px 0;
  margin: 0;
  list-style-type: none;
  overflow-y: ${(props: any) => (props.isStatic ? 'hidden' : 'auto')};
  border-radius: 8px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: 9px;
    width: 4px;
    margin: 15px 0;
  }
  ${media.tablet`
    padding: 0 0 16px 0;
    width: 100%;
   `};
`;

const BlockShadow = styled.div`
  width: 103%;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  right: 8px;
  bottom: ${(props: any) => (props.isStatic ? '0' : '45px')};
  left: 0;
  height: 34px;
  background: ${(props: any) =>
    props.isStatic ? 'none' : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 72.4%, #ffffff 100%)'};
  ${media.tablet`
    width: 100%;
   `};
`;

const AddStage = styled.button`
  height: 45px;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  border: 1px solid;
  border-color: gray3;
  outline: transparent;
  background-color: gray5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-family: Poppins, sans-serif;
  color: gray2;
  cursor: pointer;
  &:hover {
    background-color: gray4;
  }
  &:active {
    background-color: gray4;
  }
`;

export interface ComponentProps {
  value: any[];
  isStatic?: boolean;
  setValue?: any;
  stagesList?: any;
}

export const Stages: FC<ComponentProps> = ({ value = [], isStatic = false, setValue, stagesList }) => {
  const theme = useTheme();
  const [stages, setStages] = useState<any[]>(value);

  const language = useSelector(selectLanguageStrings);

  const onStageChange = useCallback(
    (stage: any, count: number) => {
      const index = count - 1;
      const newStages = [...stages.slice(0, index), stage, ...stages.slice(index + 1)];
      if (index !== -1) {
        setStages(newStages);
        setValue && setValue(newStages);
      }
    },
    [stages, setValue],
  );

  const onDeleteStage = useCallback(
    (count: number) => {
      const index = count - 1;
      const newStages = stages.filter((el: any, i: number) => index !== i);
      setStages(newStages);
      setValue && setValue(newStages);
    },
    [stages, setValue],
  );

  const handleAddStage = useCallback(() => {
    const newStages = [
      ...stages,
      {
        id: stagesList[0] ? stagesList[0].id : '',
        name: stagesList[0] ? stagesList[0].name : '',
      },
    ];
    setStages(newStages);

    setValue && setValue(newStages);
  }, [stages, setValue, stagesList]);

  const refItem = useRef<any>(null);

  const handleClick = (index: number) => {
    refItem.current.scrollTo({
      top: index === 0 ? 0 : 52 * index,
      behavior: 'smooth',
    });
  };

  return (
    <StagesContainer theme={theme}>
      <StagesBlock ref={refItem} isStatic={isStatic}>
        {stages && stages.length ? (
          stages.map((el: any, i: number) => {
            return (
              <Stage
                key={i}
                count={i + 1}
                stage={el}
                dataSelect={stagesList}
                onStageChange={onStageChange}
                onDelete={onDeleteStage}
                isStatic={isStatic}
                handleClick={() => {
                  handleClick(i);
                }}
                className={stages.length - 1 === i ? 'last' : ''}
              />
            );
          })
        ) : (
          <Stage
            count={1}
            stage={stagesList[0]}
            dataSelect={stagesList}
            onStageChange={onStageChange}
            onDelete={onDeleteStage}
            isStatic={isStatic}
            handleClick={() => {
              handleClick(0);
            }}
            className={'last'}
          />
        )}
      </StagesBlock>
      <BlockShadow isStatic={isStatic} />
      {!isStatic && (
        <AddStage theme={theme} onClick={handleAddStage}>
          {language ? language.addStageButton : '+ Add stage'}
        </AddStage>
      )}
    </StagesContainer>
  );
};
