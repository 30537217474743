import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as OnlineImage } from '../../../../../assets/wifi.svg';
import { ReactComponent as OfflineImage } from '../../../../../assets/wifi-off.svg';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

export enum WifiStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}
const Wrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: flex-start;
  margin-left: ${(props: any) => (props.isModal ? '6px' : '0')};
  & > span {
    /** @REVIEW dont customise children like this */
    font-weight: 400;
    margin-left: 4px;
  }
`;
export const Wifi = ({ status, state }: { status: number; state: TableCustomComponentState | undefined }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const language = useSelector(selectLanguageStrings);
  return (
    <Wrapper isModal={state === TableCustomComponentState.VIEW_MODAL}>
      {status > 0 ? (
        <>
          <OnlineImage />
          <Text type={TextType.TEXT_14_GRAY} color={gray1}>
            {language ? language.labelOnline : 'Online'}
          </Text>
        </>
      ) : (
        <>
          <OfflineImage />
          <Text type={TextType.TEXT_14_GRAY} color={gray1}>
            {language ? language.labelOffline : 'Offline'}
          </Text>
        </>
      )}
    </Wrapper>
  );
};
