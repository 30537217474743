/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings select period require
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';

import { ReactComponent as ArrowImage } from '../../assets/Shape.svg';

const ExtraWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Arrow = styled.div`
  display: flex;
  margin: 0px 0px 0px 8px;
  transition: all 0.5s ease;
  transform: ${(props: any) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  & > svg > path {
    fill: ${(props: any) => props.color};
    transition: fill 0.3s ease;
  }
`;

const ListWrapper = styled.ul`
  width: 128px;
  height: 108px;

  position: absolute;
  right: 0px;
  top: 8px;
  z-index: 1000;

  display: ${(props: any) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  background-color: white;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow4};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};

  padding: 6px 8px 10px 8px;
  transition: all 0.3s ease;
  opacity: ${(props: any) => (props.isOpen ? '1' : '0')};
`;

const Item = styled.li`
  font-size: 12px;
  font-weight: normal;
  color: ${(props: any) => props.colorOfItem};
  list-style: none;
  cursor: pointer;

  transition: color 0.3s ease;
  &.choosen {
    color: primary;
  }

  &:hover {
    color: primary;
  }
`;

export interface SelectPeriodProps {
  values: string[];
  className?: string;
}

export const SelectPeriod: FC<SelectPeriodProps> = ({
  values,

  className,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(/*-1*/ 0);
  const color = isHovered || isOpen || isSelected ? theme.colors.gray1 : theme.colors.gray2;
  return (
    <ExtraWrapper className={className}>
      <Wrapper>
        <PeriodWrapper
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
        >
          {values.map((value, index) => {
            return index === selectedIndex ? (
              <Text key={index} type={TextType.TEXT_12_BLACK} color={color}>
                {value}
              </Text>
            ) : null;
          })}
          <Arrow color={color} isOpen={isOpen}>
            <ArrowImage />
          </Arrow>
        </PeriodWrapper>
        <ListWrapper isOpen={isOpen} theme={theme}>
          {values.map((value, index) => {
            return (
              <Item
                colorOfItem={theme.colors.black}
                className={index === selectedIndex ? 'choosen' : ''}
                key={index}
                isOpen={isOpen}
                onClick={() => {
                  setSelectedIndex(index);
                  setIsOpen(false);
                  setIsSelected(true);
                }}
              >
                {value}
              </Item>
            );
          })}
        </ListWrapper>
      </Wrapper>
    </ExtraWrapper>
  );
};

SelectPeriod.defaultProps = {
  values: ['Last week', 'Last 30 days', 'Last 6 months', 'Last year'],
};
