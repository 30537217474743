/**
 * @description
 * This is graphs for device distribution
 * all the functionalities cthings require for Graphs
 *
 */

import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useDispatch, useSelector } from 'react-redux';
import { createDashboardColors, selectDasboardColors } from '../../../../../../app/state/colorsSlice';
import { useEffect } from 'react';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Scale = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
`;
const ScalePlaceholder = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  background-color: gray4;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  margin-bottom: 24px;
`;
const ScaleItem = styled.div`
  width: ${(props: any) => props.width};
  height: 100%;
  border-radius: ${(props: any) => props.borderRadius};
  background-color: ${(props: any) => props.backgroundColor};
`;
const ListValues = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
`;
const ItemValue = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  &.last {
    margin: 0;
  }
`;
const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Point = styled.div`
  width: 25px;
  height: 6px;
  background-color: ${(props: any) => props.backgroundColor};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  margin-right: 16px;
`;

export const Graph = ({ data }: { data: any[] }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = useSelector(selectDasboardColors);
  const { primary } = theme.borderRadius;

  const sortedData = data.sort((a: any, b: any) => b.count - a.count);
  const filteredData = sortedData.filter((item: any, index: number) => {
    const { distributionProportion } = item;
    if (distributionProportion && distributionProportion === 0) {
      return sortedData[index];
    } else {
      return null;
    }
  });
  const titleOfLastItem = filteredData.length > 0 ? filteredData[0].title : '';
  const indexOfLastItem = sortedData.findIndex((item: any) => item.title === titleOfLastItem) + 1;

  useEffect(() => {
    dispatch(createDashboardColors(sortedData.length));
  }, [sortedData.length]);

  return (
    <Wrapper>
      {data.length > 0 ? (
        <Scale>
          {data.map((item: any, index: number) => {
            return (
              <ScaleItem
                key={index}
                width={`${item.distributionProportion ? item.distributionProportion : 0}%`}
                backgroundColor={colors[index]}
                index={index}
                borderRadius={
                  index === 0 && index === indexOfLastItem && indexOfLastItem === data.length - 1
                    ? `${primary}`
                    : index === 0
                    ? `${primary} 0 0 ${primary}`
                    : index === indexOfLastItem
                    ? `0 ${primary} ${primary} 0`
                    : '0'
                }
                lastIndex={indexOfLastItem}
              />
            );
          })}
        </Scale>
      ) : (
        <ScalePlaceholder theme={theme} />
      )}
      <ListValues>
        {sortedData.map((item: any, index: number) => {
          const { title, distributionProportion } = item;
          return (
            <ItemValue key={index} className={index === data.length - 1 ? 'last' : ''}>
              <ValueWrapper>
                <Point backgroundColor={colors[index]} theme={theme} />
                <Text type={TextType.TEXT_12_GRAY} weight={'500'}>
                  {title}
                </Text>
              </ValueWrapper>
              <Text type={TextType.TEXT_12_BLACK} weight={'600'}>{`${
                distributionProportion ? distributionProportion : 0
              }%`}</Text>
            </ItemValue>
          );
        })}
      </ListValues>
    </Wrapper>
  );
};
