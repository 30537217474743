/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings manhole info on map require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { useHistory } from 'react-router-dom';

import { CommonButton } from '../commonButton/CommonButton';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as LocationImage } from '../../assets/location.svg';
import backgroundImage from '../../assets/Rectangle.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props: any) => props.margin};
  & > svg > path {
    stroke: ${(props: any) => props.color};
  }
`;

const ExtraWrapperManhole = styled.div`
  width: 240px;
  height: 136px;
  visibility: ${(props: any) => (props.isOpen || props.isClicked ? 'visible' : 'hidden')};
  opacity: ${(props: any) => (props.isOpen || props.isClicked ? '1' : '0')};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow7};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  position: relative;
  transition: all 0.3s ease;
  z-index: 10001;
`;

const WrapperManhole = styled.div`
  width: 240px;
  height: 146px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  visibility: ${(props: any) => (props.isOpen || props.isClicked ? 'visible' : 'hidden')};
  opacity: ${(props: any) => (props.isOpen || props.isClicked ? '1' : '0')};
  transition: all 0.3s ease;
  background: url(${backgroundImage});
  padding: 20px;
  position: absolute;
  bottom: ${(props: any) => (props.bottom ? props.bottom : '0px')};
`;

const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TextWrapper = styled.div`
  display: flex;
  margin: ${(props: any) => props.margin};
`;

export interface ManholeInfoProps {
  isClicked: boolean;
  isOpen: boolean;
  name: string;
  address: string;
  coordinates: string;

  className?: string;
  bottom?: string;
  to?: any;
}

export const ManholeInfo: FC<ManholeInfoProps> = ({
  isClicked,
  isOpen,
  name,
  address,
  coordinates,
  bottom,
  to,
  ...props
}) => {
  const theme = useTheme();
  const history = useHistory();
  const languageStrings = useSelector(selectLanguageStrings);
  const handleMouseDown = (e: any) => {
    e.preventDefault();
  };
  return (
    <ExtraWrapperManhole theme={theme} isOpen={isOpen} isClicked={isClicked} onMouseDown={handleMouseDown} {...props}>
      <WrapperManhole isOpen={isOpen} isClicked={isClicked} bottom={bottom}>
        <WrapperAddress>
          <TextWrapper margin="0 0 5px 0">
            <ImageWrapper theme={theme} color={theme.colors.primary} margin="0 6px 0 -3px">
              <LocationImage />
            </ImageWrapper>
            <Text type={TextType.TITLE_H4} color={theme.colors.black}>
              {name}
            </Text>
          </TextWrapper>
          <Text type={TextType.TEXT_12_GRAY} color={theme.colors.gray2}>
            {`${address} ${coordinates}`}
          </Text>
        </WrapperAddress>
        <CommonButton
          onClick={() => {
            to && history.push(to);
          }}
          height="25px"
          width="100%"
          noFocus
          backgroundColorHover={theme.colors.secondary2}
        >
          {languageStrings ? languageStrings.goInsideButton : 'Go Inside'}
        </CommonButton>
      </WrapperManhole>
    </ExtraWrapperManhole>
  );
};

ManholeInfo.defaultProps = {
  isOpen: true,
  address: 'Brest, Belarus',
  coordinates: '5551.4444 7895.5854',
};
