import React, { useEffect, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { Text, TextType } from '@bit/first-scope.text';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CommonButton } from '@bit/first-scope.common-button';
import { media } from '../../../../../styles/media';
import { uploadOrder } from '../../../../../api/manageOrders';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone`
      width: 100%;
    `}
  }
`;
const StyledModalContainer = styled(ModalContainer)`
  align-items: flex-start;
  padding: 24px;
  ${media.phone`
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: ${(props: any) => `${props.theme.borderRadius.primary} ${props.theme.borderRadius.primary} 0 0`};
    padding: 24px 16px;
  `}
`;
const Title = styled(Text)`
  font-size: 18px;
`;
const Label = styled.label`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${(props: any) =>
    (props.isFilePicked || props.isDragOver) && !props.isIncorrectTypeOfFile
      ? props.theme.colors.white1
      : 'transparent'};
  padding: 44px 0;
  border: ${(props: any) => (props.isFilePicked && !props.isIncorrectTypeOfFile ? 'none' : '1px dashed')};
  border-color: ${(props: any) => (props.isIncorrectTypeOfFile ? props.theme.colors.red3 : props.theme.colors.gray3)};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    background-color: white1;
  }
`;
const UploadInput = styled.input`
  display: none;
  &::-webkit-file-upload-button {
    display: none;
  }
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
const Button = styled(CommonButton)`
  margin: ${(props: any) => (props.withMargin ? '0 16px 0 0' : '0')};
  opacity: ${(props: any) => (props.disabled ? '0.1' : '1')};
  pointer-events: ${(props: any) => (props.disabled ? 'none' : 'auto')};
`;
//Component for uploading file with order in .xls and .xlsx format
export const UploadOrderModal = ({
  isOpenModal,
  handleCloseUploadModal,
}: {
  isOpenModal: boolean;
  handleCloseUploadModal: () => void;
}) => {
  const theme = useTheme();
  const { primary, black, gray1, white, secondary1, red3 } = theme.colors;
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isIncorrectTypeOfFile, setIsIncorrectTypeOfFile] = useState(false);
  const fileIsChoosed = !!(selectedFile && selectedFile.name && selectedFile.name.length > 0);
  const { addNotification } = useNotifierFunctions();

  const language = useSelector(selectLanguageStrings);

  const handleChangeUloadInput = (e: any) => {
    setSelectedFile(e.target.files[0]);
    setIsIncorrectTypeOfFile(false);
    setIsFilePicked(true);
  };
  const handleDragEnter = (e: any) => {
    e.preventDefault();
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    if (!isDragOver) {
      setIsDragOver(true);
    }
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setIsDragOver(false);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    setSelectedFile(e.dataTransfer.files[0]);
    const formatOfFile = e.dataTransfer.files[0].type;
    if (
      formatOfFile === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      formatOfFile === 'application/vnd.ms-excel'
    ) {
      setIsIncorrectTypeOfFile(false);
      setIsFilePicked(true);
    } else {
      setIsIncorrectTypeOfFile(true);
      setIsFilePicked(false);
    }
    setIsDragOver(false);
  };
  const handleSubmitFiles = () => {
    fileIsChoosed && uploadOrder(selectedFile, addNotification);
    handleCloseUploadModal();
  };
  const handleIsNeededTypeOfFile = (type: string) => {
    if (selectedFile && selectedFile.name.length > 0) {
      return (
        type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        type === 'application/vnd.ms-excel'
      );
    } else {
      return true;
    }
  };
  const buttons = [
    {
      value: language ? language.cancelButton : 'Cancel',
      onClick: () => {
        setSelectedFile(new File([''], ''));
        setIsFilePicked(false);
        setIsIncorrectTypeOfFile(false);
        handleCloseUploadModal();
      },
      width: '104px',
      height: '32px',
      backgroundColor: white,
      backgroundColorHover: primary,
      borderColorHover: primary,
      color: black,
      withMargin: true,
      disabled: false,
    },
    {
      value: language ? language.saveButton : 'Save',
      onClick: () => {
        setSelectedFile(new File([''], ''));
        setIsFilePicked(false);
        setIsIncorrectTypeOfFile(false);
        handleSubmitFiles();
      },
      width: '104px',
      height: '32px',
      backgroundColor: isFilePicked ? primary : white,
      backgroundColorHover: secondary1,
      borderColorHover: secondary1,
      color: isFilePicked ? white : primary,
      disabled: !isFilePicked || isIncorrectTypeOfFile,
    },
  ];

  useEffect(() => {
    const isNeededType = handleIsNeededTypeOfFile(selectedFile?.type || '');
    if (selectedFile && selectedFile.name.length > 0 && !isNeededType) {
      setIsIncorrectTypeOfFile(true);
    } else {
      setIsIncorrectTypeOfFile(false);
    }
  }, [selectedFile]);

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Title type={TextType.TEXT_14_BLACK} color={black} weight={'700'} margin={'0 0 20px 0'}>
          {language ? language.titleUploadOrders : 'Upload orders'}
        </Title>
        <Label
          for={'files'}
          isFilePicked={isFilePicked}
          theme={theme}
          isDragOver={isDragOver}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          isIncorrectTypeOfFile={isIncorrectTypeOfFile}
        >
          {fileIsChoosed && isIncorrectTypeOfFile ? (
            <Text type={TextType.TEXT_14_BLACK} weight={'500'} color={red3}>
              {language ? language.incorrectFormatOfFile : 'Incorrect format of file. Try again'}
            </Text>
          ) : fileIsChoosed ? (
            <>
              <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1} margin={'0 4px 0 0'}>
                {language ? language.fileText : 'File'}
              </Text>
              <Text type={TextType.TEXT_14_BLACK} weight={'500'} color={primary} margin={'0 4px 0 0'}>
                {selectedFile?.name}
              </Text>
              <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
                {language ? language.isUploadedText : 'is uploaded'}
              </Text>
            </>
          ) : (
            <>
              <Text type={TextType.TEXT_14_BLACK} weight={'500'} color={primary} margin={'0 4px 0 0'}>
                {language ? language.chooseExcelFileText : 'Choose Excel file'}
              </Text>
              <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
                {language ? language.dragItHereText : 'or drag it here (.xls or .xlsx)'}
              </Text>
            </>
          )}
        </Label>
        <UploadInput
          type={'file'}
          onChange={handleChangeUloadInput}
          accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
          id={'files'}
        />
        <ButtonsWrapper>
          {buttons.map((item: any) => {
            const {
              value,
              onClick,
              width,
              height,
              backgroundColor,
              backgroundColorHover,
              color,
              borderColorHover,
              withMargin,
              disabled,
            } = item;

            return (
              <Button
                onClick={onClick}
                width={width}
                height={height}
                backgroundColor={backgroundColor}
                backgroundColorHover={backgroundColorHover}
                color={color}
                borderColorHover={borderColorHover}
                withMargin={withMargin}
                disabled={disabled}
              >
                {value}
              </Button>
            );
          })}
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
