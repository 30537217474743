/**
 * @description
 * This is component for badges
 * all the functionalities cthings require for badges
 * Highly customisable.
 *
 */

import React, { FC, useState, useEffect } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { Text, TextType } from '@bit/first-scope.text';

const Wrapper = styled.div`
  width: 92px;
  height: 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props: any) => props.backgroundColor};

  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  padding: 0 8px;

  cursor: pointer;

  & > span {
    font-weight: 700;
    line-height: 15px;
    color: ${(props: any) => props.color};
  }
`;

export enum BadgesType {
  MISC_TEAL = 'MISC_TEAL',
  MISC_PURPLE = 'MISC_PURPLE',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  PRIMARY = 'PRIMARY',
  REMINDER = 'REMINDER',
  UNKNOWN = 'UNKNOWN',
}
export interface BadgesProps {
  type: BadgesType;
  content: string;
  className?: string;
}

export const Badges: FC<BadgesProps> = ({ type, content, ...props }) => {
  const theme = useTheme();

  const [background, setBackground] = useState<string | undefined>('');
  const [fontColor, setFontColor] = useState<string | undefined>('');

  useEffect(() => {
    switch (type) {
      case BadgesType.MISC_TEAL:
        setBackground(theme.colors.teal2);
        setFontColor(theme.colors.teal3);
        break;
      case BadgesType.MISC_PURPLE:
        setBackground(theme.colors.purple2);
        setFontColor(theme.colors.purple3);
        break;
      case BadgesType.SUCCESS:
        setBackground(theme.colors.green12);
        setFontColor(theme.colors.green2);
        break;
      case BadgesType.WARNING:
        setBackground(theme.colors.orange4);
        setFontColor(theme.colors.orange5);
        break;
      case BadgesType.DANGER:
        setBackground(theme.colors.red5);
        setFontColor(theme.colors.red2);
        break;
      case BadgesType.PRIMARY:
        setBackground(theme.colors.light);
        setFontColor(theme.colors.blue3);
        break;
      case BadgesType.REMINDER:
        setBackground(theme.colors.yellow1);
        setFontColor(theme.colors.yellow2);
        break;
      case BadgesType.UNKNOWN:
        setBackground(theme.colors.gray5);
        setFontColor(theme.colors.gray1);
        break;
      default:
        setBackground('');
        setFontColor('');
    }
  }, [type]);

  return (
    <Wrapper type={type} backgroundColor={background} color={fontColor} theme={theme} {...props}>
      <Text type={TextType.TEXT_10_WHITE}>{content}</Text>
    </Wrapper>
  );
};

Badges.defaultProps = {};
