import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

// const Wrapper = styled.div`
//   width: max-content;
//   display: flex;
//   align-items: flex-start;
// `;
const AttachText = styled(Text)`
  font-weight: 400;
`;
export const Attached = ({ status }: { status: boolean }) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const language = useSelector(selectLanguageStrings);
  return (
    <>
      {status === false ? (
        <AttachText type={TextType.TEXT_14_GRAY} color={gray2}>
          {language ? language.labelNo : 'No'}
        </AttachText>
      ) : (
        <AttachText type={TextType.TEXT_14_GRAY} color={gray1}>
          {language ? language.labelYes : 'Yes'}
        </AttachText>
      )}
    </>
  );
};
