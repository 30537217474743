/**
 * @description
 * This is graphs for device distribution
 * all the functionalities cthings require for Graphs
 *
 */

import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { VictoryAxis, VictoryChart, VictoryTheme, VictoryStack, VictoryBar } from 'victory';
import { media } from '../../../../../styles/media';
import { withLoader } from '../../../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { NoDataPlaceholder } from '../../../../../components/nodataPlaceholder/NoDataPlaceholder';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

export const Wrapper = styled.div`
  width: 585px;
  height: ${(props: any) => props.height};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow2};
  border-radius: ${(props: any) => props.theme.borderRadius.additional16};
  padding: 22px 22px 0 22px;

  & > div {
    & > svg {
      position: relative;
      bottom: 12px;
      left: 12px;
      transform: scale(1.1);
      & > g > g > line {
        stroke-dasharray: 0 !important;
      }
    }
  }
  ${media.tabletTemp`
    width: 100%;
    height: ${(props: any) => `calc(${props.height} - 60px)`};
    padding: 22px 22px 10px 15px;
    & > div {
      & > svg {
        padding-top:10px;
        transform: scale(1.15);
      }
    }
  `}
  ${media.tablet`
    width: 100%;
    & > div {
      & > svg {
        transform: scale(1.15);
      }
    }
  `}
`;
const Placeholder = styled(NoDataPlaceholder)`
  padding: 0 0 22px 0 !important;
`;

export interface ZonesCapacityGraphPlainProps {
  data: any[];
}

const ZonesCapacityGraphPlain: FC<ZonesCapacityGraphPlainProps> = ({ data, ...props }) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);
  const { gray2, primary, secondary3 } = theme.colors;

  const tickValuesAxisX =
    data.length > 0 ? [...data.map((item: any, index: number) => index + 1), data.length + 1] : [];
  const tickFormatAxisX =
    data.length > 0 ? [...data.map((item: any) => item.pointValue), language ? language.labelOverall : 'Overall'] : [];
  const currentValues =
    data.length > 0
      ? [
          ...data.map((item: any, index: number) => ({ point: index + 1, value: item.zoneCapacityProportion })),
          {
            point: data.length + 1,
            value:
              (data.map((item: any) => item.zoneValue).reduce((a: any, b: any) => a + b) /
                data.map((item: any) => item.zoneMaxCapacity).reduce((a: any, b: any) => a + b)) *
              100,
          },
        ]
      : [];
  const maxValues =
    data.length > 0
      ? [
          ...data.map((item: any, index: number) => ({
            point: index + 1,
            value: 100 - item.zoneCapacityProportion,
          })),
          {
            point: data.length + 1,
            value:
              100 -
              (data.map((item: any) => item.zoneValue).reduce((a: any, b: any) => a + b) /
                data.map((item: any) => item.zoneMaxCapacity).reduce((a: any, b: any) => a + b)) *
                100,
          },
        ]
      : [];

  return (
    <Wrapper height={'280px'} theme={theme} {...props}>
      <Text type={TextType.TEXT_12_GRAY} weight={'500'}>
        {language ? language.zonesCapacitysTitle : 'Zones Capacity'}
      </Text>
      {data.length > 0 ? (
        <VictoryChart width={580} height={262} domainPadding={5} theme={VictoryTheme.material}>
          <VictoryAxis
            standalone={false}
            dependentAxis
            domain={[0, 100]}
            tickValues={[0, 50, 100]}
            tickFormat={(t: any) => `${t}%`}
            style={{
              axis: { visibility: 'hidden' },
              tickLabels: {
                fill: `${gray2}`,
                fontFamily: '"Poppins", sans-serif',
                fontSize: '10px',
                fontWeight: '500',
              },
            }}
            orientation={'left'}
          />
          <VictoryAxis
            tickValues={tickValuesAxisX}
            tickFormat={tickFormatAxisX}
            style={{
              axis: { visibility: 'hidden' },
              tickLabels: {
                width: '12px',
                fill: `${gray2}`,
                fontFamily: '"Poppins", sans-serif',
                fontSize: data.length < 27 ? '12px' : '6px',
                fontWeight: '500',
              },
            }}
          />
          <VictoryStack>
            <VictoryBar
              data={currentValues}
              x={'point'}
              y={'value'}
              cornerRadius={{ topLeft: 6, topRight: 6, bottomLeft: 6, bottomRight: 6 }}
              style={{
                data: {
                  fill: primary,
                },
              }}
              barRatio={0.2}
            />
            <VictoryBar
              data={maxValues}
              x={'point'}
              y={'value'}
              cornerRadius={{ topLeft: 6, topRight: 6, bottomLeft: 0, bottomRight: 0 }}
              style={{ data: { fill: secondary3 } }}
              barRatio={0.2}
            />
          </VictoryStack>
        </VictoryChart>
      ) : (
        <Placeholder />
      )}
    </Wrapper>
  );
};

export const ZonesCapacityGraph = withLoader(undefined, PlaceholderType.GRAPH)(ZonesCapacityGraphPlain);
